import {useCallback, useContext, useEffect, useState} from 'react'
import {usePools} from './usePools'
import {BigNumber} from 'bignumber.js'
import {getTokenDecimals} from '../constants/helper'
import {
  TOKEN_BAND,
  TOKEN_BORING,
  TOKEN_BTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_DOGE,
  TOKEN_DOGE_PPTOKEN,
  TOKEN_HBTC,
  TOKEN_LINK,
  TOKEN_LTC_PPTOKEN,
  TOKEN_NEST,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH,
  TOKEN_YFI,
  TOKEN_YFII
} from '../constants/token'
import {Context} from '../contexts/BoringProvider'

type FarmMeta = {
  unlockAmount: string
  lockAmount: string
  lockPrice: string
  totalPrice: string
}

export const useFarmMeta = () => {
  const [meta, setMeta] = useState<FarmMeta>({
    lockAmount: '0',
    unlockAmount: '0',
    lockPrice: '0',
    totalPrice: '0'
  })
  const pools = usePools()
  const ctx = useContext(Context)

  const getPrice = useCallback(
    (tokenName: string) => {
      switch (tokenName) {
        case TOKEN_BTC_PPTOKEN:
          return ctx.price.bor
        case TOKEN_LTC_PPTOKEN:
          return ctx.price.bor
        case TOKEN_DOGE_PPTOKEN:
          return ctx.price.bor
        case TOKEN_OBTC:
          return ctx.price.btc
        case TOKEN_HBTC:
          return ctx.price.btc
        case TOKEN_OLTC:
          return ctx.price.ltc
        case TOKEN_DAI:
          return ctx.price.dai
        case TOKEN_USDC:
          return ctx.price.usdc
        case TOKEN_USDT:
          return ctx.price.usdt
        case TOKEN_WETH:
          return ctx.price.weth
        case TOKEN_YFII:
          return ctx.price.yfii
        case TOKEN_YFI:
          return ctx.price.yfi
        case TOKEN_LINK:
          return ctx.price.link
        case TOKEN_NEST:
          return ctx.price.nest
        case TOKEN_BAND:
          return ctx.price.band
        case TOKEN_ODOGE:
          return ctx.price.doge
        case TOKEN_BORING:
          return new BigNumber(ctx.price.bor).div(10000).toString()
        default:
          return ctx.price.ltc
      }
    },
    [ctx]
  )

  useEffect(() => {
    let unlocking = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName !== TOKEN_BORING) {
        unlocking = unlocking.plus(pool.unlockEarned)
      }
    }

    let locking = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName !== TOKEN_BORING) {
        locking = locking.plus(pool.lockEarned)
      }
    }

    let lockingP = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName !== TOKEN_BORING) {
        const tmp = new BigNumber(pool.userStakedAmount)
          .dividedBy(Math.pow(10, getTokenDecimals(pool.tokenName)))
          .multipliedBy(getPrice(pool.tokenName))
        lockingP = lockingP.plus(tmp)
      }
    }

    let totalP = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName !== TOKEN_BORING) {
        const tmp = new BigNumber(pool.totalStakedAmount)
          .dividedBy(Math.pow(10, getTokenDecimals(pool.tokenName)))
          .multipliedBy(getPrice(pool.tokenName))
        totalP = totalP.plus(tmp)
      }
    }

    setMeta({
      unlockAmount: unlocking.toString(),
      lockAmount: locking.toString(),
      lockPrice: lockingP.toString(),
      totalPrice: totalP.toString()
    })
  }, [pools, ctx, getPrice])

  return meta
}
