import {AccountType, ChainIDType} from './type'
import {getContract} from '../helpers/web3'
import {CONTRACT_BOR_BSC, CONTRACT_BORING_V2, CONTRACT_CROSS_LOCK} from '../constants/contract'
import web3 from 'web3'

export const crossLock = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  tokenAddress: string,
  to: string,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_CROSS_LOCK)
  return contract.methods.lock(tokenAddress, to, web3.utils.toWei(amount)).send({from: account})
}

export const crossBurn = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  to: string,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_BOR_BSC)
  return contract.methods.crossBurn(to, web3.utils.toWei(amount)).send({from: account})
}

export const calculateFee = async (
  provider: any,
  chainID: ChainIDType,
  token: string,
  amount: string,
  type: number
) => {
  const contract = getContract(provider, chainID, CONTRACT_CROSS_LOCK)
  return contract.methods.calculateFee(token, web3.utils.toWei(amount), type).call()
}

export const toBoring = async (provider: any, chainID: ChainIDType, account: AccountType, amount: string) => {
  const contract = getContract(provider, chainID, CONTRACT_BORING_V2)
  return contract.methods.toBoring(web3.utils.toWei(amount)).send({from: account})
}

export const toBor = async (provider: any, chainID: ChainIDType, account: AccountType, amount: string) => {
  const contract = getContract(provider, chainID, CONTRACT_BORING_V2)
  return contract.methods.toBor(web3.utils.toWei(amount)).send({from: account})
}
