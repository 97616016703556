import React, {useState} from 'react'
import {Datum} from '../../hooks/usePool'
import {getTokenShowName} from '../../constants/helper'
import {Box, Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core'
import FarmStakeModal from '../Models/Farm/Stake'
import FarmWithdrawModal from '../Models/Farm/Withdraw'
import {getTokenIcon} from '../../constants/icon'
import FarmClaimModal from '../Models/Farm/Claiim'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    poolRow: {
      '&:not(:last-child)': {
        marginBottom: '10px',
        paddingBottom: '10px',
        borderBottom: '1px solid #f0f0f0'
      },
      '& svg': {
        fontSize: '12px'
      }
    },
    unlock: {
      color: theme.palette.primary.main
    },
    btn: {
      minWidth: '24px',
      height: '24px',
      border: `1px solid ${theme.palette.primary.main}`,
      '& .MuiButton-label': {
        lineHeight: '0'
      },
      '& svg': {
        fontSize: '10px'
      }
    }
  })
)

const MobilePoolRow = ({data}: {data: Datum | undefined}) => {
  const classes = useStyles()
  const [openStakeModal, setOpenStakeModal] = useState(false)
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
  const [openClaimModal, setOpenClaimModal] = useState(false)

  const {t} = useTranslation()

  return data ? (
    <div className={classes.poolRow}>
      <FarmStakeModal open={openStakeModal} onClose={() => setOpenStakeModal(false)} data={data} />
      <FarmWithdrawModal open={openWithdrawModal} onClose={() => setOpenWithdrawModal(false)} data={data} />
      <FarmClaimModal open={openClaimModal} onClose={() => setOpenClaimModal(false)} data={data} />
      <Box display="flex" alignItems={'center'}>
        <Box width={150} display="flex" alignItems="center">
          <img width={18} src={getTokenIcon(data.tokenName)} alt={data.tokenName} />
          <Box ml={2}>{getTokenShowName(data.tokenName)}</Box>
        </Box>
        <Box width={80} textAlign={'center'}>
          {
            data.expired ? t("reward_ended") : (<>{data?.apy}%<Typography variant={'body2'}>APY</Typography></>)
          }
        </Box>
        <Box ml={'auto'}>
          <Button size={'small'} variant={'outlined'} color={'primary'} className={classes.btn}>
            <NavLink to={`/pool/${data.tokenName}`}>
              <ArrowForwardIosIcon fontSize={'small'} color={'primary'} />
            </NavLink>
          </Button>
        </Box>
      </Box>
    </div>
  ) : (
    <></>
  )
}

export default MobilePoolRow
