import React, {useContext, useState} from 'react'
import {Box, Card, createStyles, Divider, Grid, makeStyles, Theme, Typography} from '@material-ui/core'
import BOR from '../../../assets/images/bor.png'
import {displayBalance} from '../../../helpers/display'
import {useWeb3React} from '@web3-react/core'
import {useInterval} from '../../../hooks/useInterval'
import {totalBoringPledge, userBoringPledgeAmount} from '../../../contracts/tunnel'
import {boringEarned, earned} from '../../../contracts/feepool'
import {
  TOKEN_BOR,
  TOKEN_BORING,
  TOKEN_BTC,
  TOKEN_DAI,
  TOKEN_DOGE,
  TOKEN_LTC,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH
} from '../../../constants/token'
import PledgeSelectModal from '../../Models/Pledge/Select'
import {useTranslation} from 'react-i18next'
import {getTokenIcon} from '../../../constants/icon'
import {handleError, handleErrorAndZero, isBSC} from '../../../helpers'
import {BigNumber} from 'bignumber.js'
import {Context} from '../../../contexts/BoringProvider'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {LoadingDisplay} from '../../style'
import {useActiveWeb3React} from '../../../hooks'
import {getContractAddress, getContractWithABI, getWeb3} from '../../../helpers/web3'
import {MULTICALL_NETWORKS} from '../../../constants/multicall'
import {CHAIN_ETHER} from '@w3u/chains'
import MulticallABI from '../../../abi/Multicall.json'
import {getBoringFeePoolName, getBoringTunnelName} from '../../../constants/contract'
import {balanceOfABI, borPledgeInfoABI, earnedABI, getCall} from '../../../constants/abi'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    data: {
      borderRadius: '10px',
      '& img': {
        marginRight: '10px'
      }
    },
    link: {
      color: '#7B89B7',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      marginLeft: 'auto',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      '& svg': {
        fontSize: '12px',
        marginLeft: '3px'
      }
    }
  })
)

const HomePledge = () => {
  const classes = useStyles()
  const ctx = useContext(Context)
  const {library, account, chainId} = useActiveWeb3React()
  const chainID = chainId ?? CHAIN_ETHER
  const [btcPledgeAmount, setBTCPledgeAmount] = useState('0')
  const [ltcPledgeAmount, setLTCPledgeAmount] = useState('0')
  const [dogePledgeAmount, setDogePledgeAmount] = useState('0')
  const [userBTCPledge, setUserBTCPledge] = useState('0')
  const [userLTCPledge, setUserLTCPledge] = useState('0')
  const [userDogePledge, setUserDogePledge] = useState('0')
  const [earnedBTCBOR, setEarnedBTCBOR] = useState('0')
  const [earnedOBTC, setEarnedOBTC] = useState('0')
  const [earnedLTCBOR, setEarnedLTCBOR] = useState('0')
  const [earnedOLTC, setEarnedOLTC] = useState('0')
  const [earnedDOGEBOR, setEarnedDOGEBOR] = useState('0')
  const [earnedODOGE, setEarnedODOGE] = useState('0')

  const [openPledge, setOpenPledge] = useState(false)
  const {t} = useTranslation()

  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const web3 = getWeb3(library)
  const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'

  useInterval(
    () => {
      if (library) {
        const btcTunnelAddress = getContractAddress(chainID, getBoringTunnelName(TOKEN_BTC))
        const ltcTunnelAddress = getContractAddress(chainID, getBoringTunnelName(TOKEN_LTC))
        const dogeTunnelAddress = getContractAddress(chainID, getBoringTunnelName(TOKEN_DOGE))

        const btcFeePoolAddress = getContractAddress(chainID, getBoringFeePoolName(TOKEN_BTC))
        const ltcFeePoolAddress = getContractAddress(chainID, getBoringFeePoolName(TOKEN_LTC))
        const dogeFeePoolAddress = getContractAddress(chainID, getBoringFeePoolName(TOKEN_DOGE))

        const boringAddress = getContractAddress(chainID, TOKEN_BORING)

        try {
          const calls = [
            getCall(web3, boringAddress, balanceOfABI, [btcTunnelAddress]),
            getCall(web3, boringAddress, balanceOfABI, [ltcTunnelAddress]),
            getCall(web3, boringAddress, balanceOfABI, [dogeTunnelAddress]),
            getCall(web3, btcTunnelAddress, borPledgeInfoABI, [defaultAccount]),
            getCall(web3, ltcTunnelAddress, borPledgeInfoABI, [defaultAccount]),
            getCall(web3, dogeTunnelAddress, borPledgeInfoABI, [defaultAccount]),
            getCall(web3, btcFeePoolAddress, earnedABI, [defaultAccount]),
            getCall(web3, ltcFeePoolAddress, earnedABI, [defaultAccount]),
            getCall(web3, dogeFeePoolAddress, earnedABI, [defaultAccount])
          ]

          multicallContract.methods
            .aggregate(calls)
            .call()
            .then((values: any) => {
              setBTCPledgeAmount(String(web3.eth.abi.decodeParameter('uint256', values[1][0])))
              setLTCPledgeAmount(String(web3.eth.abi.decodeParameter('uint256', values[1][1])))
              setDogePledgeAmount(String(web3.eth.abi.decodeParameter('uint256', values[1][2])))

              setUserBTCPledge(String(web3.eth.abi.decodeParameter('uint256', values[1][3])))
              setUserLTCPledge(String(web3.eth.abi.decodeParameter('uint256', values[1][4])))
              setUserDogePledge(String(web3.eth.abi.decodeParameter('uint256', values[1][5])))

              const f = web3.eth.abi.decodeParameters(['uint256', 'uint256'], values[1][6]) as any
              setEarnedBTCBOR(f[0])
              setEarnedOBTC(f[1])
              const s = web3.eth.abi.decodeParameters(['uint256', 'uint256'], values[1][7]) as any
              setEarnedLTCBOR(s[0])
              setEarnedOLTC(s[1])
              const t = web3.eth.abi.decodeParameters(['uint256', 'uint256'], values[1][8]) as any
              setEarnedDOGEBOR(t[0])
              setEarnedODOGE(t[1])
            })
            .catch((e: any) => console.error('Pledge multicall: ', e))
        } catch (e) {
          console.log('Home/pledge', e)
        }
      }
    },
    3000,
    true
  )

  const totalPledging = () =>
    new BigNumber(btcPledgeAmount)
      .plus(ltcPledgeAmount)
      .plus(dogePledgeAmount)
      .toString()
  const userTotalPledging = () =>
    new BigNumber(userBTCPledge)
      .plus(userLTCPledge)
      .plus(userDogePledge)
      .toString()
  const totalEarnedBOR = () =>
    new BigNumber(earnedBTCBOR)
      .plus(earnedLTCBOR)
      .plus(earnedDOGEBOR)
      .toString()

  return (
    <Card>
      <Box p={{sm: 4, md: 6}}>
        <PledgeSelectModal
          setType={() => {
            console.log()
          }}
          open={openPledge}
          onClose={() => setOpenPledge(false)}
        />
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h6">{t('tunnel_operator')}</Typography>
          <Box className={classes.link} onClick={() => setOpenPledge(true)}>
            {t('stake_now')}
            <ArrowForwardIcon />
          </Box>
        </Box>
        <Typography variant="caption">{t('home_pledge_subtitle')}</Typography>
        <Box py={2} mt={4} className={classes.data}>
          <Grid container>
            <Grid item sm={6}>
              <Box mb={2}>
                <Box ml={7}>
                  <Typography variant="body2">{t('total_staked')}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img width={24} height={24} src={BOR} alt="bor" />
                  <Typography variant="h4">
                    <LoadingDisplay
                      ok={new BigNumber(totalPledging()).gt(0)}
                      result={displayBalance(totalPledging(), 18, 0)}
                      height={38}
                    />
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box mb={7}>
                <Box ml={7}>
                  <Typography variant="body2">{t('your_staked')}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img width={24} height={24} src={BOR} alt="bor" />
                  <Typography variant="h4">{displayBalance(userTotalPledging())}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mb={6}>
            <Divider />
          </Box>
          <Grid container>
            <Grid item sm={4}>
              <Box mb={1} ml={6}>
                <Typography variant="body2">{t('total_claimable')}</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <img width={20} height={20} src={getTokenIcon(TOKEN_OBTC)} alt="bor" />
                <Typography variant="h5">{displayBalance(earnedOBTC)}</Typography>
              </Box>
              <Box ml={6}>
                <Typography variant="body2">
                  ≈ $
                  {displayBalance(
                    new BigNumber(earnedOBTC)
                      .dividedBy(Math.pow(10, 18))
                      .multipliedBy(ctx.price.btc)
                      .toString()
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4}>
              <Box mb={1} ml={6}>
                <Typography variant="body2">{t('total_claimable')}</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <img width={20} height={20} src={getTokenIcon(TOKEN_OLTC)} alt="bor" />
                <Typography variant="h5">{displayBalance(earnedOLTC)}</Typography>
              </Box>
              <Box ml={6}>
                <Typography variant="body2">
                  ≈ $
                  {displayBalance(
                    new BigNumber(earnedOLTC)
                      .dividedBy(Math.pow(10, 18))
                      .multipliedBy(ctx.price.ltc)
                      .toString()
                  )}
                </Typography>
              </Box>
            </Grid>

            {isBSC() ? (
              <Grid item sm={4}>
                <Box mb={1} ml={6}>
                  <Typography variant="body2">{t('total_claimable')}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <img width={20} height={20} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
                  <Typography variant="h5">{displayBalance(totalEarnedBOR())}</Typography>
                </Box>
                <Box ml={6}>
                  <Typography variant="body2">
                    ≈ $
                    {displayBalance(
                      new BigNumber(totalEarnedBOR())
                        .dividedBy(Math.pow(10, 18))
                        .multipliedBy(ctx.price.bor)
                        .toString()
                    )}
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid item sm={4}>
                <Box mb={1} ml={6}>
                  <Typography variant="body2">{t('total_claimable')}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <img width={20} height={20} src={getTokenIcon(TOKEN_ODOGE)} alt="bor" />
                  <Typography variant="h5">{displayBalance(earnedODOGE)}</Typography>
                </Box>
                <Box ml={6}>
                  <Typography variant="body2">
                    ≈ $
                    {displayBalance(
                      new BigNumber(earnedODOGE)
                        .dividedBy(Math.pow(10, 18))
                        .multipliedBy(ctx.price.doge)
                        .toString()
                    )}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Card>
  )
}

export default HomePledge
