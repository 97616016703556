import {useCallback, useEffect, useState} from 'react'
import {useFetch} from 'use-http'
import {handleError} from '../helpers'

export const useCoinGeckoPrice = (id: string) => {
  const [price, setPrice] = useState('0')
  const {get} = useFetch(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`)

  const fetch = useCallback(() => {
    get('')
      .then(res => {
        setPrice(res[id]['usd'] || '0')
      })
      .catch(handleError)
  }, [get, id])

  useEffect(() => {
    fetch()
    let timer = setInterval(fetch, 20000)
    return () => clearInterval(timer)
  }, [fetch])

  return price
}
