import React from 'react'
import {Box, Dialog, Grid, Link, makeStyles} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import HomeMobileFarm from '../../../assets/images/home_mobile_farm.png'
import {getTokenIcon, getTokenIconByChainID} from '../../../constants/icon'
import {TOKEN_BOR, TOKEN_OBTC} from '../../../constants/token'
import OPortal from '../../../assets/images/oportal.png'


const useStyles = makeStyles({
  type: {
    cursor: 'pointer',
    color: '#363636',
    '&.active, &:hover': {
      color: '#1164fb',
      '& $logo': {
        border: '1px solid #1164FB'
      }
    }
  },
  logo: {
    width: '80px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e1e3e5',
    borderRadius: '20px'
  }
})

const CrossSelectModal = ({open, onClose}: {open: boolean; onClose: any}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={{xs: 6, md: 10}}>
        <Grid container>
          <Grid item xs={6}>
            <NavLink to={'/switch'} onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                </Box>
                BOR
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={6}>
            <Link href={'https://oportal.boringdao.com'} onClick={onClose} target="_blank">
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={OPortal} alt="polygon" />
                </Box>
                Others
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default CrossSelectModal
