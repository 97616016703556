import React, {useState} from 'react'
import {Box, Button, Checkbox, FormControlLabel, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {getTargetChainName} from '../../../../helpers'

const MintStepThird = ({onPrev, onNext}: {onPrev: any; onNext: any}) => {
  const {t} = useTranslation()
  const [first, setFirst] = useState(false)
  const [second, setSecond] = useState(false)

  return (
    <Box px={{xs: 0, md: 20}}>
      <Box mb={1}>
        <FormControlLabel
          control={<Checkbox color={'primary'} onChange={(e: any) => setFirst(e.target.checked)} />}
          label={<Typography variant={'subtitle2'}>{t('mint_step_3_checkbox_1')}</Typography>}
        />
      </Box>
      <Box mb={2}>
        <FormControlLabel
          control={<Checkbox color={'primary'} onChange={(e: any) => setSecond(e.target.checked)} />}
          label={
            <Typography variant={'subtitle2'}>{t('mint_step_3_checkbox_2', {target: getTargetChainName()})}</Typography>
          }
        />
      </Box>
      <Box pl={6}>
        <Typography variant={'body2'} color={'primary'}>
          {t('mint_step_3_confirm')}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={6}>
        <Button
          onClick={onPrev}
          size={'large'}
          style={{marginRight: '10px'}}
          variant={'contained'}
          color={'default'}
          disableElevation
        >
          {t('prev')}
        </Button>
        <Button
          onClick={onNext}
          disabled={!first || !second}
          size={'large'}
          style={{marginLeft: '10px'}}
          variant={'contained'}
          color={'primary'}
          disableElevation
        >
          {t('confirm')}
        </Button>
      </Box>
    </Box>
  )
}

export default MintStepThird
