import {useCallback, useEffect, useState} from 'react'
import {
  TOKEN_BAND,
  TOKEN_BOR,
  TOKEN_BORING,
  TOKEN_BTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_DOGE_PPTOKEN,
  TOKEN_HBTC,
  TOKEN_LINK,
  TOKEN_LTC_PPTOKEN,
  TOKEN_NEST,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH,
  TOKEN_YFI,
  TOKEN_YFII,
  TokenModal
} from '../constants/token'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {CONTRACT_ORACLE, getPoolName} from '../constants/contract'
import {calcAPY} from '../helpers'
import {Datum, initDatum} from './usePool'
import {useActiveWeb3React, useMountedRef} from './index'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import {CHAIN_ETHER} from '@w3u/chains'
import MulticallABI from '../abi/Multicall.json'
import {toBytes32} from '../helpers/solidity'
import {
  allowanceABI,
  balanceOfABI,
  earnedABI,
  getPriceABI,
  periodFinishABI,
  rewardRateABI,
  totalSupplyABI
} from '../constants/abi'

type Data = {
  boring: Datum
}

const initData = (): Data => {
  return {
    boring: initDatum(TOKEN_BORING)
  }
}

export const useOldPool = (tokenName: string) => {
  const mountedRef = useMountedRef()
  const [data, setData] = useState<Data>(initData())
  const {account, library, chainId, connector} = useActiveWeb3React()

  const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
  const chainID = chainId ?? CHAIN_ETHER
  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const oracleAddress = getContractAddress(chainID, CONTRACT_ORACLE)

  const web3 = getWeb3(library)

  const poolQ = useCallback(
    async (tokenName: string) => {
      try {
        const priceName = TokenModal[tokenName].priceName
        const poolName = getPoolName(tokenName)

        const tokenAddress = getContractAddress(chainId, tokenName)
        const poolAddress = getContractAddress(chainId, poolName)

        const calls = [
          {
            target: oracleAddress,
            callData: web3.eth.abi.encodeFunctionCall(getPriceABI, [toBytes32(TOKEN_BOR)])
          },
          {
            target: oracleAddress,
            callData: web3.eth.abi.encodeFunctionCall(getPriceABI, [toBytes32(priceName)])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(rewardRateABI, [])
          },
          {
            target: tokenAddress,
            callData: web3.eth.abi.encodeFunctionCall(balanceOfABI, [defaultAccount])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(balanceOfABI, [defaultAccount])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(totalSupplyABI, [])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(earnedABI, [defaultAccount])
          },
          {
            target: tokenAddress,
            callData: web3.eth.abi.encodeFunctionCall(allowanceABI, [defaultAccount, poolAddress])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(periodFinishABI, [])
          }
        ]

        let results: any = []
        const values = await multicallContract.methods
          .aggregate(calls)
          .call()
          .catch((e: any) => console.error('Fetching price: ', e))

        results.push(web3.eth.abi.decodeParameter('uint256', values[1][0]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][1]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][2]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][3]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][4]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][5]))
        results.push(web3.eth.abi.decodeParameters(['uint256', 'uint256'], values[1][6]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][7]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][8]))

        return Promise.resolve(results)
      } catch (e) {
        console.error(e)
      }
    },
    [library, account, chainId]
  )

  const handleResult = useCallback((tokenName: string, result: any) => {
    // console.log(tokenName, ": ", result[8], " -> ", Math.floor(new Date().getTime() / 1000))
    return {
      tokenName: tokenName,
      apy: calcAPY(tokenName, result[0], result[2], result[5], result[1]),
      userTokenBalance: result[3],
      userStakedAmount: result[4],
      totalStakedAmount: result[5],
      unlockEarned: result[6][0],
      lockEarned: result[6][1],
      userAllowance: result[7],
      expired: result[8] < Math.floor(new Date().getTime() / 1000)
    }
  }, [])

  const poolsQ = useCallback(() => {
    console.log('Fetching old boring pool======')
    const boringQ = poolQ(tokenName)

    return Promise.all([boringQ]).then(values => {
      if (!mountedRef.current) {
        return
      }

      setData({
        boring: handleResult(TOKEN_BORING, values[0])
      })
    })
  }, [handleResult, poolQ, mountedRef])

  useEffect(() => {
    if (library) {
      poolsQ()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      console.log('Old boring pools', ms)
      let timer = setInterval(poolsQ, ms)
      return () => clearInterval(timer)
    }
  }, [library, setData, poolsQ, connector])

  return data
}
