import React, {useContext, useState} from 'react'
import {Box, Button, CircularProgress, Typography, useMediaQuery, useTheme} from '@material-ui/core'
import TokenValue from '../TokenValue'
import {getMapTokenName, getMapTokenShowName, getTokenIcon} from '../../constants/icon'
import {TOKEN_BOR, TOKEN_BORING, TOKEN_BTC, TOKEN_LTC} from '../../constants/token'
import {displayBalance} from '../../helpers/display'
import {useInterval} from '../../hooks/useInterval'
import {boringClaimFee, boringEarned} from '../../contracts/feepool'
import {useWeb3React} from '@web3-react/core'
import {useTranslation} from 'react-i18next'
import {Context} from '../../contexts/BoringProvider'
import {BigNumber} from 'bignumber.js'
import {PriceContext} from '../../contexts/PriceProvider'
import WalletsModal from '../Models/Wallets'

const BoringClaim = ({type}: {type: string}) => {
  const ctx = useContext(Context)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {t} = useTranslation()
  const [earnedBOR, setEarnedBOR] = useState('')
  const [earnedOToken, setEarnedOToken] = useState('')
  const {library, chainId, account} = useWeb3React()
  const [doing, setDoing] = useState(false)
  const [openWallets, setOpenWallets] = useState(false)

  const priceCtx = useContext(PriceContext)

  useInterval(
    () => {
      if (account) {
        boringEarned(library, chainId, account, type)
          .then(res => {
            setEarnedBOR(res[0])
            setEarnedOToken(res[1])
          })
          .catch(e => console.error(e))
      }
    },
    3000,
    true
  )

  const canClaim = () => !(earnedBOR === '0' && earnedOToken === '0')

  const claim = async () => {
    setDoing(true)
    try {
      await boringClaimFee(library, chainId, account, type)
      console.log('=====> Claim successfully')
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  return (
    <>
      <WalletsModal open={openWallets} close={() => setOpenWallets(false)} />
      <Box textAlign={'center'} mb={4}>
        {isMobile && <Typography variant={'subtitle1'}>{t('reward')}</Typography>}
        {!isMobile && <Typography variant={'subtitle2'}>{t('reward')}</Typography>}
      </Box>
      <Box mb={{xs: 4, md: 10}}>
        <TokenValue
          tag={getMapTokenShowName(type)}
          img={getTokenIcon(getMapTokenName(type))}
          amount={displayBalance(earnedOToken, 18, 10)}
          price={type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge}
        />
      </Box>
      <Box mb={{xs: 4, md: 15}}>
        <TokenValue
          tag="BORING"
          img={getTokenIcon(TOKEN_BOR)}
          amount={displayBalance(earnedBOR, 18, 10)}
          price={new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18).toString()}
        />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        {account ? (
          <Button
            disabled={!canClaim() || doing}
            disableElevation={true}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={claim}
          >
            {doing && <CircularProgress size={12} />}
            {t('claim')}
          </Button>
        ) : (
          <Button
            onClick={() => setOpenWallets(true)}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            disableElevation
          >
            {t('unlock_wallet')}
          </Button>
        )}
      </Box>
    </>
  )
}

export default BoringClaim
