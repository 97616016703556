import Web3 from 'web3'
import {CONTRACTS} from '../constants/contract'
import {ChainIDType} from '../contracts/type'
import {AbiItem} from 'web3-utils'

// @ts-ignore
let web3: Web3 = null
export const getWeb3 = (provider: any) => {
  if (web3 === null) {
    return new Web3(provider)
  }

  return web3
}

export const getContractAddress = (chainID: number | undefined, name: string) => {
  chainID = chainID ? chainID : 1
  return CONTRACTS[name]['addresses'][chainID]
}

export const getContract = (provider: any, chainID: ChainIDType, name: string) => {
  chainID = chainID ? chainID : 1
  const web3 = getWeb3(provider)
  return new web3.eth.Contract(CONTRACTS[name].abi, CONTRACTS[name]['addresses'][chainID])
}

export const getContractWithABI = (provider: any, address: string, abi: any) => {
  const web3 = getWeb3(provider)
  return new web3.eth.Contract(abi, address)
}
