import {AccountType, ChainIDType} from './type'
import {getContract} from '../helpers/web3'
import {CONTRACT_SUSHI_MASTER_CHEF} from '../constants/contract'
import web3 from "web3";

export const sushiDeposit = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.deposit(pid, web3.utils.toWei(amount)).send({from: account})
}

export const sushiWithdraw = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.withdraw(pid, web3.utils.toWei(amount)).send({from: account})
}

export const sushiClaim = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.deposit(pid, 0).send({from: account})
}


export const sushiPendingSushi = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.pendingSushi(pid, account).call()
}

export const sushiUserInfo = async (
  provider: any,
  chainID: ChainIDType,
  pid: number,
  account: AccountType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.userInfo(pid, account).call()
}

export const sushiPoolInfo = async (
  provider: any,
  chainID: ChainIDType,
  pid: number
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.poolInfo(pid).call()
}

export const sushiTotalAllocPoint = async (
  provider: any,
  chainID: ChainIDType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.totalAllocPoint().call()
}

export const sushiSushiPerBlock = async (
  provider: any,
  chainID: ChainIDType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SUSHI_MASTER_CHEF)
  return contract.methods.sushiPerBlock().call()
}

export const sushiGetReserves = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
) => {
  const contract = getContract(provider, chainID, tokenName)
  return contract.methods.getReserves().call()
}

