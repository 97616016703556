import React from 'react'
import {Box, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme} from '@material-ui/core'
import Mint1Active from '../../../assets/images/mint_1_active.png'
import BSCMint1Active from '../../../assets/images/bsc_mint_1_active.png'
import BurnDotArrowActive from '../../../assets/images/burn_dot_arrow_active.png'
import BSCMint2Active from '../../../assets/images/bsc_mint_2_active.png'
import Mint2Active from '../../../assets/images/mint_2_active.png'
import Mint2 from '../../../assets/images/mint_2.png'
import BurnDotArrow from '../../../assets/images/burn_dot_arrow.png'
import Mint5 from '../../../assets/images/mint_5.png'
import BSCMint5Active from '../../../assets/images/bsc_mint_5_active.png'
import Mint5Active from '../../../assets/images/mint_5_active.png'
import {useTranslation} from 'react-i18next'
import {isBSC} from '../../../helpers'

const Mint1ActiveImg = isBSC() ? BSCMint1Active : Mint1Active
const Mint2ActiveImg = isBSC() ? BSCMint2Active : Mint2Active
const Mint5ActiveImg = isBSC() ? BSCMint5Active : Mint5Active

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dotArrow: {
      margin: '0 20px'
    },
    notice: {
      '& .active': {
        '& h6': {
          color: theme.palette.primary.main
        }
      }
    }
  })
)

const BurnNoticeTitle = ({step, type}: {step: number; type: string}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const display = isMobile ? 'none' : 'flex'
  const titleDisplay = (index: number) => {
    return !isMobile ? 'block' : step === index ? 'block' : 'none'
  }

  return (
    <Box display="flex" alignItems="center" className={classes.notice} mb={4}>
      <Box className={'active'} display={titleDisplay(0)}>
        <Typography variant="subtitle2">1. {t('burn_step_1_title')}</Typography>
        <Box mt={4} display={display} alignItems={'center'}>
          <img width={30} src={Mint1ActiveImg} alt="mint_1_active" />
          <img className={classes.dotArrow} width={360} src={BurnDotArrowActive} alt="dot_arrow_active" />
        </Box>
      </Box>
      <Box className={step > 0 ? 'active' : ''} display={titleDisplay(1)}>
        <Typography variant="subtitle2">2. {t('burn_step_2_title')}</Typography>
        <Box mt={4} display={display} alignItems={'center'}>
          {step > 0 ? (
            <>
              <img width={30} src={Mint2ActiveImg} alt="mint_2_active" />
              <img className={classes.dotArrow} width={360} src={BurnDotArrowActive} alt="dot_arrow_active" />
            </>
          ) : (
            <>
              <img width={30} src={Mint2} alt="mint_2" />
              <img className={classes.dotArrow} width={360} src={BurnDotArrow} alt="dot_arrow" />
            </>
          )}
        </Box>
      </Box>
      <Box className={step > 1 ? 'active' : ''} display={titleDisplay(2)}>
        <Typography variant="subtitle2">3. {t('done')}</Typography>
        <Box mt={4} display={display} alignItems={'center'}>
          {step > 1 ? (
            <img width={30} src={Mint5ActiveImg} alt="mint_5_active" />
          ) : (
            <img width={30} src={Mint5} alt="mint_5" />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default BurnNoticeTitle
