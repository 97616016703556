import React, {useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {getTokenDecimals, getTokenShowName} from '../../constants/helper'
import {BigNumber} from 'bignumber.js'
import {getContractAddress} from '../../helpers/web3'
import {CONTRACT_MASTER_CHEF} from '../../constants/contract'
import {approveMax} from '../../helpers/erc20'
import {TOKEN_BTC_PPTOKEN, TOKEN_LTC_PPTOKEN, TOKEN_OBTC, TOKEN_OLTC, TOKEN_WETH} from '../../constants/token'
import {NavLink} from 'react-router-dom'
import {Box, Button, CircularProgress, createStyles, Link, makeStyles, Theme, Typography} from '@material-ui/core'
import {getTokenIcon} from '../../constants/icon'
import {displayBalance} from '../../helpers/display'
import {useTranslation} from 'react-i18next'
import {usePancakePool} from '../../hooks/usePancakePool'
import BSCFarmStakeModal from '../Models/Farm/BSCStake'
import BSCFarmWithdrawModal from '../Models/Farm/BSCWithdraw'
import BSCFarmClaimModal from '../Models/Farm/BSCClaim'
import {useActiveWeb3React} from '../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    poolRow: {
      '&:not(:last-child)': {
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: '1px solid #f0f0f0'
      }
    },
    unlock: {
      color: theme.palette.primary.main
    }
  })
)

export const TokenObtain = ({tokenName}: {tokenName: string}) => {
  const {t} = useTranslation()
  const obtain = () => {
    switch (tokenName) {
      case TOKEN_BTC_PPTOKEN:
        return (
          <NavLink to={'/pledge/BTC'}>
            <Typography variant={'caption'} color={'primary'}>
              &nbsp;{t('get')}
            </Typography>
          </NavLink>
        )
      case TOKEN_LTC_PPTOKEN:
        return (
          <NavLink to={'/pledge/LTC'}>
            <Typography variant={'caption'} color={'primary'}>
              &nbsp;{t('get')}
            </Typography>
          </NavLink>
        )
      case TOKEN_OBTC:
        return (
          <NavLink to={'/mint'}>
            <Typography variant={'caption'} color={'primary'}>
              &nbsp;{t('get')}
            </Typography>
          </NavLink>
        )
      case TOKEN_OLTC:
        return (
          <NavLink to={'/mint'}>
            <Typography variant={'caption'} color={'primary'}>
              &nbsp;{t('get')}
            </Typography>
          </NavLink>
        )
      case TOKEN_WETH:
        return (
          <Link
            href={'https://app.uniswap.org/#/swap?outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'}
            target={'_blank'}
          >
            <Typography variant={'caption'} color={'primary'}>
              &nbsp;{t('wrap')}
            </Typography>
          </Link>
        )
    }
  }

  return <>{obtain()}</>
}

const PoolItem = ({tokenName}: {tokenName: string}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const data = usePancakePool(tokenName)

  const decimals = getTokenDecimals(tokenName)
  const [approved, setApproved] = useState(false)
  const [approveDoing, setApproveDoing] = useState(false)

  const [withdrawCan, setWithdrawCan] = useState(false)
  const [openStakeModal, setOpenStakeModal] = useState(false)
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
  const [openClaimModal, setOpenClaimModal] = useState(false)
  const [isClaimText, setIsClaimText] = useState(false)

  useEffect(() => {
    if (data) {
      if (new BigNumber(data.userTokenBalance).lt(data.userAllowance)) {
        setApproved(true)
      }

      if (new BigNumber(data.userStakedAmount).gt(0)) {
        setWithdrawCan(true)
      }
    }
  }, [data])

  const approving = async () => {
    if (account && data) {
      setApproveDoing(true)
      const tokenAddress = getContractAddress(chainId, data.tokenName)
      const poolAddress = getContractAddress(chainId, CONTRACT_MASTER_CHEF)
      try {
        await approveMax(library, tokenAddress, poolAddress, account)
      } catch (e) {
        console.error(e)
      }
      setApproveDoing(false)
    }
  }

  const canClaim = () => {
    if (data) {
      return new BigNumber(data.lockEarned).gt(0) || new BigNumber(data.unlockEarned).gt(0)
    } else {
      return false
    }
  }

  return (
    <div className={classes.poolRow}>
      <BSCFarmStakeModal open={openStakeModal} onClose={() => setOpenStakeModal(false)} data={data} />
      <BSCFarmWithdrawModal open={openWithdrawModal} onClose={() => setOpenWithdrawModal(false)} data={data} />
      <BSCFarmClaimModal open={openClaimModal} onClose={() => setOpenClaimModal(false)} data={data} />
      <Box display="flex" alignItems={'center'}>
        <Box width={180} display="flex" alignItems="center">
          <img height={18} src={getTokenIcon(tokenName)} alt={tokenName} />
          <Box ml={2}>{getTokenShowName(tokenName)}</Box>
        </Box>
        <Box width={120}>{data?.apy}%</Box>
        <Box width={170}>{displayBalance(data?.totalStakedAmount, decimals)}</Box>
        <Box width={170}>
          {displayBalance(data?.userTokenBalance, decimals)}
          {data && <TokenObtain tokenName={data.tokenName} />}
        </Box>
        <Box width={170}>{displayBalance(data?.userStakedAmount, decimals)}</Box>
        <Box className={classes.unlock} width={100}>
          {canClaim() ? (
            <>
              <Box onMouseEnter={() => setIsClaimText(true)} onMouseLeave={() => setIsClaimText(false)}>
                <Typography
                  onClick={() => setOpenClaimModal(true)}
                  style={{cursor: 'pointer'}}
                  variant={'body2'}
                  color={'primary'}
                >
                  {isClaimText && t('claim')}
                  {!isClaimText && displayBalance(new BigNumber(data.unlockEarned).plus(data.lockEarned).toString())}
                </Typography>
              </Box>
            </>
          ) : (
            <>{displayBalance(data?.unlockEarned)}</>
          )}
        </Box>
        <Box flexGrow={1} display={'flex'} justifyContent={'flex-end'}>
          {!approved ? (
            <>
              <Button
                onClick={approving}
                disableElevation={true}
                size={'small'}
                variant={'contained'}
                color={'primary'}
              >
                {approveDoing && <CircularProgress size={12} color={'inherit'} />}
                {t('approve')}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => setOpenStakeModal(true)}
                disableElevation={true}
                size={'small'}
                variant={'contained'}
                color={'primary'}
                style={{marginRight: '5px'}}
              >
                {approveDoing && <CircularProgress size={12} color={'inherit'} />}
                {t('stake')}
              </Button>
              <Button
                onClick={() => setOpenWithdrawModal(true)}
                disabled={!withdrawCan}
                disableElevation={true}
                size={'small'}
                variant={'outlined'}
                color={'primary'}
                style={{marginLeft: '5px'}}
              >
                {approveDoing && <CircularProgress size={12} color={'inherit'} />}
                {t('withdraw')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default PoolItem
