import React, {useState} from 'react'
import {Box, Button, CircularProgress, Dialog, makeStyles, Typography} from '@material-ui/core'
import {Datum} from '../../../../hooks/usePool'
import {getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BOR, TOKEN_BORING, TOKEN_YFI, TOKEN_YFII} from '../../../../constants/token'
import {displayBalance} from '../../../../helpers/display'
import {harvest} from '../../../../contracts/farm'
import {useWeb3React} from '@web3-react/core'
import {BigNumber} from 'bignumber.js'
import {BoringTooltip} from '../../../BoringTooltip'
import Question from '../../../../assets/images/question.png'
import {useTranslation} from 'react-i18next'
import {useActiveWeb3React} from '../../../../hooks'

const useStyles = makeStyles({
  grow: {
    flexGrow: 1
  },
  input: {
    border: '1px solid #D8E0ED',
    borderRadius: '6px',
    padding: '6px 15px',
    '& input': {
      fontSize: '20px'
    },
    '& img': {
      marginRight: '15px'
    }
  },
  value: {
    border: '1px solid #D8E0ED',
    borderRadius: '6px',
    background: '#F1F2F6',
    padding: '15px 20px',
    fontSize: '14px',
    color: '#444C66',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: '10px'
    }
  }
})

const FarmClaimModal = ({open, onClose, data}: {open: boolean; onClose: any; data: Datum}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const [doing, setDoing] = useState(false)

  const claiming = async () => {
    try {
      setDoing(true)
      await harvest(library, chainId, data.tokenName, account)
    } catch (e) {
      console.log(e)
    }
    setDoing(false)
  }

  const tooltip = () => {
    if (data.tokenName === TOKEN_YFI || data.tokenName === TOKEN_YFII || data.tokenName === TOKEN_BORING) {
      return t('farm_lock_notice_2')
    }

    return t('farm_lock_notice_1')
  }

  return (
    <Dialog open={open} onClose={onClose} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={8}>
        <Typography variant={'subtitle2'} align={'center'}>
          {t('claim')}
        </Typography>
        <Box mt={6} className={classes.value}>
          <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
          {t('total_rewards')}
          <Box ml={'auto'}>{displayBalance(new BigNumber(data.unlockEarned).plus(data.lockEarned).toString())}</Box>
        </Box>
        <Box mt={3} className={classes.value}>
          <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
          {t('locked_rewards')}
          <BoringTooltip enterTouchDelay={20} arrow title={<Typography variant={'body2'}>{tooltip()}</Typography>}>
            <img width={12} height={12} src={Question} alt="question" />
          </BoringTooltip>
          <Box ml={'auto'}>{displayBalance(data.lockEarned)}</Box>
        </Box>
        <Box mt={3} className={classes.value}>
          <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
          {t('claimable_rewards')}
          <Box ml={'auto'}>{displayBalance(data.unlockEarned)}</Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={6}>
          <Button
            onClick={onClose}
            size={'large'}
            fullWidth={true}
            style={{marginRight: '10px'}}
            variant={'contained'}
            color={'default'}
            disableElevation
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={claiming}
            fullWidth={true}
            size={'large'}
            style={{marginLeft: '10px'}}
            variant={'contained'}
            color={'primary'}
            disableElevation
          >
            {doing && <CircularProgress size={18} color={'inherit'} />}
            {t('claim')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default FarmClaimModal
