import React from 'react'
import {Box, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {getMapTokenShowName} from '../../../constants/icon'
import {TOKEN_BTC, TOKEN_LTC} from '../../../constants/token'
import {getTargetChainName} from '../../../helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      [theme.breakpoints.up('sm')]: {
        minHeight: '230px'
      }
    },
    mintNotice: {
      paddingLeft: '15px',
      lineHeight: '1.5',
      margin: '0',
      listStyle: 'none',
      '& li': {
        '&:before': {
          fontSize: '12px',
          content: `"•"`,
          position: 'relative',
          left: '-10px',
          top: '0'
        },
        marginBottom: '6px'
      }
    },
    example: {
      borderRadius: '4px',
      background: '#f8f8f8'
    },
    address: {
      border: '1px solid #f0f0f0',
      borderRadius: '4px'
    }
  })
)

const MintNotice = ({step, type}: {step: number; type: string}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const First = () => {
    return (
      <ul className={classes.mintNotice}>
        <li>{t('mint_step_1_1', {type: type, map_type: getMapTokenShowName(type)})}</li>
        <li>{t('mint_step_1_2', {type: type, map_type: getMapTokenShowName(type)})}</li>
        <li>{t('mint_step_1_3')}</li>
      </ul>
    )
  }

  const Second = () => {
    return (
      <ul className={classes.mintNotice}>
        <li>{t('mint_step_2_1')}</li>
        <li>{t('mint_step_2_2', {type: getMapTokenShowName(type)})}</li>
      </ul>
    )
  }

  const Third = () => {
    return (
      <Grid container>
        <Grid item sm={9}>
          <Box pr={{sm: 6}}>
            <ul className={classes.mintNotice}>
              <li>
                {t('mint_step_3_1', {type: type, map_type: getMapTokenShowName(type), target: getTargetChainName()})}
              </li>
              <li>
                {type === 'BTC'
                  ? t('mint_step_3_2_btc')
                  : type === 'LTC'
                    ? t('mint_step_3_2_ltc')
                    : t('mint_step_3_2_doge')}
              </li>
              <li>{t('mint_step_3_3', {type: type})}</li>
              <li>{t('mint_step_3_4', {type: type, target: getTargetChainName()})}</li>
            </ul>
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Typography variant={'subtitle2'} color={'primary'}>
            {t('example')}
          </Typography>
          <Box mt={2} p={4} className={classes.example}>
            <Typography variant={'subtitle2'} color={'inherit'} align={'center'}>
              {t('advanced')}
            </Typography>
            <Typography variant={'body2'} color={'inherit'}>
              OP_RETURN
            </Typography>
            <Box p={2} className={classes.address}>
              <del>0xc9877fdBJ...7GJK98kjhg</del>
              <Box>
                <Typography variant={'caption'} color={'primary'}>
                  Fill in your {getTargetChainName()} address like this↑
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const num = type === TOKEN_BTC ? 3 : type === TOKEN_LTC ? 6 : 36
  const time = type === TOKEN_BTC ? '30-60' : '20-30'

  const Four = () => {
    return (
      <>
        <Box mb={4}>{t('mint_step_4_1', {type: type, num: num, time: time})}</Box>
        <Box mb={4}>
          <strong>{t('mint_step_4_2', {map_type: getMapTokenShowName(type)})}</strong>
        </Box>
        <Box>
          <strong>1. {t('mint_step_4_3')}</strong>
        </Box>
        <Box ml={2}>{t('mint_step_4_3_1', {type: type, target: getTargetChainName()})}</Box>
        <Box mt={2}>
          <strong>2. {t('mint_step_4_4')}</strong>
        </Box>
        <Box ml={2}>{t('mint_step_4_4_1', {type: type})}</Box>
        <Box mt={2}>
          <strong>3. {t('mint_step_4_5', {map_type: getMapTokenShowName(type)})}</strong>
        </Box>
        <Box ml={2}>{t('mint_step_4_5_1', {type: type})}</Box>
      </>
    )
  }

  const Five = () => {
    return <Box>{t('mint_step_5_1', {type: type})}</Box>
  }

  return (
    <Box className={classes.wrap}>
      {step === 0 && <First />}
      {step === 1 && <Second />}
      {step === 2 && <Third />}
      {step === 3 && <Four />}
      {step > 3 && <Five />}
    </Box>
  )
}

export default MintNotice
