import React from 'react'
import {Box, createStyles, Link, makeStyles, Theme, Typography} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import {useTranslation} from 'react-i18next'
import {isBSC} from '../../../helpers'
import {LoadingDisplay} from '../../style'
import {BigNumber} from 'bignumber.js'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      boxShadow: '0px 3px 22px 0px rgba(228, 232, 245, 0.92)',
      borderRadius: '6px',
      minWidth: '40px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    data: {
      border: '1px solid #EBEDF2',
      borderRadius: '10px'
    },
    link: {
      color: '#9DA5C5',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      }
    }
  })
)

const Meta = ({
  img,
  title,
  titleColor,
  value,
  href,
  surprice
}: {
  img: string
  title: string
  titleColor?: any
  value: string
  href?: string
  surprice?: boolean
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const url = isBSC()
    ? 'https://exchange.pancakeswap.finance/#/swap'
    : 'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9'

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.img}>
        <img width={24} src={img} alt="obtc" />
      </Box>
      <Box ml={3}>
        <Box mb={1}>
          <Typography variant="body2" style={{fontSize: '12px'}} color={titleColor}>
            {title}
          </Typography>
        </Box>
        <Typography variant="h5">
          <LoadingDisplay ok={new BigNumber(value.replace(/,/g, '')).gt(0)} result={value} />
          {surprice && (
            <Link href={url} rel={'noopener nofollow'} target={'_blank'}>
              {/*{isBSC() && <img style={{marginLeft: '5px', width: '16px'}} src={Pancake} alt="pancake"/>}*/}
              {!isBSC() && (
                <img
                  style={{marginLeft: '5px'}}
                  src="https://cryptologos.cc/logos/sushiswap-sushi-logo.png?v=010"
                  height={16}
                  alt="sushi"
                />
              )}
            </Link>
          )}
        </Typography>
      </Box>
      {href && (
        <Box ml={'auto'}>
          <Link className={classes.link} href={href} rel={'noopener nofollow'} target={'_blank'}>
            <Box ml={2} display={'flex'} alignItems="center">
              {t('verify')}
              <ArrowRightIcon />
            </Box>
          </Link>
        </Box>
      )}
    </Box>
  )
}
export default Meta
