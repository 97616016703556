import React from 'react'
import {Box, Dialog, Link, Typography} from '@material-ui/core'
import {isEthereum} from '../../../helpers'

const WrongNetwork = ({open}: {open: boolean}) => {
  return (
    <Dialog open={open} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={{xs: 6, md: 10}} textAlign={'center'}>
        <Typography variant={'h6'}>Wrong Network</Typography>
        <Typography variant={'body1'}>
          {isEthereum() ? (
            <>
              Please connect to Ethereum mainnet, if you want to use BoringDAO on Binance Smart Chain, please click
              <Link href="https://bsc.boringdao.com/" target="_blank">
                {' '}
                here
              </Link>
            </>
          ) : (
            <>
              Please connect to Binance Smart Chain mainnet, if you want to use BoringDAO on Ethereum, please click
              <Link href="https://app.boringdao.com/" target="_blank">
                {' '}
                here
              </Link>
            </>
          )}
        </Typography>
      </Box>
    </Dialog>
  )
}

export default WrongNetwork
