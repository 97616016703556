import React from 'react'
import {Box, Card, Divider, Typography} from '@material-ui/core'
import MobilePoolRow from '../../MobilePoolRow'
import {usePools} from '../../../hooks/usePools'
import {useTranslation} from 'react-i18next'
import MobileCityItem from '../../MobileCityItem'
import {
  TOKEN_BORING,
  TOKEN_BORING_BTC_PPTOKEN,
  TOKEN_BORING_DOGE_PPTOKEN,
  TOKEN_BORING_LTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_SUNDER,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH
} from '../../../constants/token'

const BoringFarmMobile = () => {
  const pools = usePools()
  const {t} = useTranslation()

  return (
    <>
      <Box mt={3}>
        <Card style={{marginTop: '20px'}}>
          <Box p={4} mt={4}>
            <Typography variant={'subtitle1'}>{t('manifest_destiny')}</Typography>
            <Typography variant={'body2'}>{t('manifest_destiny_desc').replace('BOR', 'BORING')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobileCityItem tokenName={TOKEN_BORING_BTC_PPTOKEN} />
            <MobileCityItem tokenName={TOKEN_BORING_LTC_PPTOKEN} />
            <MobileCityItem tokenName={TOKEN_BORING_DOGE_PPTOKEN} />
          </Box>
        </Card>
        <Card style={{marginTop: '20px'}}>
          <Box p={4} mt={4}>
            <Typography variant={'subtitle1'}>{t('wild_wild_west')}</Typography>
            <Typography variant={'body2'}>{t('wild_wild_west_desc').replace('BOR', 'BORING')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobileCityItem tokenName={TOKEN_OBTC} />
            <MobileCityItem tokenName={TOKEN_OLTC} />
            <MobileCityItem tokenName={TOKEN_ODOGE} />
          </Box>
        </Card>
        <Card style={{marginTop: '20px'}}>
          <Box p={4} mt={4}>
            <Typography variant={'subtitle1'}>{t('satellite_city')}</Typography>
            <Typography variant={'body2'}>{t('satellite_city_desc').replace('BOR', 'BORING')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobileCityItem tokenName={TOKEN_WETH} />
            <MobileCityItem tokenName={TOKEN_USDT} />
            <MobileCityItem tokenName={TOKEN_USDC} />
            <MobileCityItem tokenName={TOKEN_DAI} />
            <MobileCityItem tokenName={TOKEN_BORING} />
          </Box>
        </Card>
        <Card style={{marginTop: '20px'}}>
          <Box p={4}>
            <Typography variant={'subtitle1'}>{t('gold_rush')}</Typography>
            <Typography variant={'body2'}>{t('gold_rush_desc')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobilePoolRow data={pools.boring} />
            <MobileCityItem tokenName={TOKEN_SUNDER} />
          </Box>
        </Card>
      </Box>
    </>
  )
}

export default BoringFarmMobile
