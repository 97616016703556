import React, {useState} from 'react'
import {Box, Card, createStyles, makeStyles, Theme, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import FarmData from './Data'
import ActionLink from '../ActionLink'
import {isBSC} from '../../../helpers'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import FarmSelectModal from '../../Models/FarmSelect'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      display: 'block',
      width: '100%'
    },
    link: {
      color: '#7B89B7',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      marginLeft: 'auto',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      '& svg': {
        fontSize: '12px',
        marginLeft: '3px'
      }
    }
  })
)

const HomeFarm = () => {
  const {t} = useTranslation()
  const [openFarm, setOpenFarm] = useState(false)
  const classes = useStyles()

  return (
    <Card>
      <FarmSelectModal open={openFarm} onClose={() => setOpenFarm(false)} />
      <Box p={{sm: 4, md: 6}}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h6">{t('farm')}</Typography>
          <Box ml="auto" onClick={() => setOpenFarm(true)}>
            <Typography className={classes.link}>
              {t('farm')}
              <ArrowForwardIcon />
            </Typography>
          </Box>
        </Box>
        <Typography variant="caption">
          {t('home_farm_subtitle', {token_type: isBSC() ? 'BEP 20' : 'ERC 20'})}
        </Typography>
        <FarmData />
      </Box>
    </Card>
  )
}

export default React.memo(HomeFarm)
