import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import App from './App'
import {createWeb3ReactRoot, Web3ReactProvider} from '@web3-react/core'
import Web3ReactManager from './components/Web3ReactManager'
import BoringProvider from './contexts/BoringProvider'
import PriceProvider from './contexts/PriceProvider'
import {SkeletonTheme} from 'react-loading-skeleton'
import 'babel-polyfill'

const Web3ProviderNetwork = createWeb3ReactRoot('BORING_NETWORK')
const getLibrary = (provider: any) => {
  return provider
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Web3ReactManager>
          <BoringProvider>
            <PriceProvider>
              <SkeletonTheme highlightColor="#f2f2f2" color="#f8f8f8">
                <App />
              </SkeletonTheme>
            </PriceProvider>
          </BoringProvider>
        </Web3ReactManager>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
