import React, {useEffect, useState} from 'react'
import {BigNumber} from 'bignumber.js'
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core'
import {displayBalance} from '../../helpers/display'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      background: '#F1F2F6',
      padding: '15px 15px',
      fontSize: '14px',
      color: '#444C66',
      '& img': {
        marginRight: '5px'
      }
    }
  })
)

const TokenValue = ({img, amount, price, tag}: {img: string; amount: string; price: string; tag?: string}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  useEffect(() => {
    if (amount && price) {
      // @ts-ignore
      let a = amount.replace(/,/g, '')
      const ret = new BigNumber(a).multipliedBy(price)
      setValue(ret.toString())
    } else {
      setValue('0')
    }
  }, [price, amount])

  return (
    <Box className={classes.value} display={'flex'} alignItems={'center'}>
      <Box display="flex" alignItems="center" mr={1}>
        <img width={16} src={img} alt="token" />
        {tag && <Box>{tag}</Box>}
      </Box>
      {amount ? amount : '0.00'}
      <Box style={{marginLeft: 'auto'}}>≈ $ {displayBalance(value, 18, 2)}</Box>
    </Box>
  )
}

export default TokenValue
