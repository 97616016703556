import {createMuiTheme} from '@material-ui/core'
import {isBSC, isMatic} from './helpers'

export const theme = createMuiTheme({
  spacing: 5,
  palette: {
    primary: {
      main: isBSC() ? '#EFB82D' : isMatic() ? '#824FE2' : '#1164FB'
    },
    secondary: {
      main: '#FD7C25'
    }
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#f1f2f6'
      }
    },
    MuiCheckbox: {
      root: {
        padding: '0 10px 0 12px'
      }
    },
    MuiStepper: {
      root: {
        padding: '0'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize'
      },
      contained: {
        backgroundColor: '#f1f2f6',
        '&:hover': {
          backgroundColor: '#f1f2f2'
        }
      },
      sizeSmall: {
        minWidth: '56px',
        padding: '4px 5px'
      },
      sizeLarge: {
        width: '150px',
        height: '50px'
      },
      containedSecondary: {
        backgroundColor: '#00D9A7',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#00D9A7 !important'
        },
        '&:active': {
          backgroundColor: '#00D9A7 !important'
        }
      },
      containedPrimary: {
        backgroundColor: isBSC() ? '#EFB82D' : isMatic() ? '#824FE2' : '#1164FB',
        color: '#fff',
        '&:hover': {
          backgroundColor: isBSC() ? '#EFB23D' : isMatic() ? '#824FE2' : '#3679f4'
        }
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: '20px',
        maxWidth: '550px !important',
        width: 'calc(100% - 30px) !important',
        margin: '10px !important'
      }
    },
    MuiTabs: {
      indicator: {
        display: 'none'
      }
    },
    MuiMenu: {
      paper: {
        padding: '5px 0',
        boxShadow: '0px 1px 10px 0px rgba(186, 192, 204, 0.41)',
        marginTop: '10px'
      },
      list: {
        padding: '5px 10px',
        minWidth: '260px',
        maxHeight: '400px'
      }
    },
    MuiMenuItem: {
      root: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        margin: '0 0 5px',
        fontSize: '12px !important',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        },
        '& img': {
          marginRight: '10px'
        },
        '&:hover': {
          background: '#f6f6f6'
        },
        '&$selected': {
          background: '#f6f6f6',
          '&:hover': {
            background: '#f6f6f6'
          }
        }
      }
    },
    MuiTab: {
      root: {
        color: '#A1A8C7',
        minWidth: '60px !important',
        '&:hover': {
          color: '#444C65'
        },
        '&$selected': {
          color: '#444C65'
        },
        '&:focus': {
          color: '#444C65'
        }
      }
    },
    MuiCard: {
      root: {
        boxShadow: '0px 5px 43px 0px rgba(228, 232, 245, 0.92)',
        borderRadius: '16px'
      }
    },
    MuiTypography: {
      root: {
        // fontFamily: 'Bahnschrift, "Roboto", "Helvetica", "Arial", sans-serif !important'
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'
      },
      caption: {
        color: '#7B89B7',
        lineHeight: '22px'
      },
      body2: {
        color: '#767d8f'
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto'
        },
        body: {
          overflow: 'inherit !important',
          backgroundColor: isBSC() ? '#F6ECD5' : '#E9EDF2',
          minHeight: '100vh',
          // fontFamily: '"Inter var", sans-serif',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'
        },
        a: {
          textDecoration: 'none'
        },
        '.MuiListItem-root': {
          fontSize: '14px !important'
        },
        '.Mui-disabled': {
          background: '#F1F2F6 !important'
        },
        '.Toastify__toast-container': {
          maxWidth: '260px',
          fontSize: '13px',
          left: '50%',
          transform: 'translateX(-50%)'
        },
        '.Toastify__toast': {
          borderRadius: '8px',
          top: '60px',
          padding: '8px 16px',
          color: '#000',

          '& svg': {
            marginRight: '5px'
          }
        },
        '.Toastify__toast--info': {
          background:
            'radial-gradient(circle, rgba(223,232,254,1) 0%, rgba(252,255,251,1) 45%, rgba(255,242,250,1) 80%);'
        },
        '.MuiStepLabel-root.Mui-disabled': {
          background: '#fff !important'
        },
        '.MuiFormControlLabel-root': {
          alignItems: 'flex-start !important',
          '& h6': {
            marginTop: '2px'
          }
        },
        '.MuiStepConnector-vertical': {
          background: '#fff !important'
        }
      }
    }
  }
})
