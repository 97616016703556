import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  createStyles,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core'
import {getMapTokenName, getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BOR, TOKEN_BTC, TOKEN_DOGE, TOKEN_LTC} from '../../../../constants/token'
import {verifyNumericInput} from '../../../../helpers/verify'
import {displayBalance} from '../../../../helpers/display'
import TokenValue from '../../../TokenValue'
import {BigNumber} from 'bignumber.js'
import {Context} from '../../../../contexts/BoringProvider'
import {AssetName} from '../../../../contracts/address'
import {useTranslation} from 'react-i18next'
import {isEthereum, isValidNumberInput} from '../../../../helpers'
import {toast} from 'react-toastify'
import ToastContent from '../../../ToastContent'
import {getMinMintValue} from '../../../../constants'
import {useWeb3React} from '@web3-react/core'
import {useActiveWeb3React} from '../../../../hooks'
import WalletsModal from '../../../Models/Wallets'

export const WithoutStyleInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: '#333A50',
      fontSize: '14px',
      '& .MuiSelect-selectMenu': {
        '&:focus': {
          background: 'none'
        },
        '& img': {
          margin: '4px 0 0'
        },
        '& p': {
          display: 'none'
        }
      }
    }
  })
)(InputBase)

export const WithoutStyleInput2 = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: '#333A50',
      fontSize: '14px',
      '& .MuiSelect-selectMenu': {
        '&:focus': {
          background: 'none'
        },
        '& img': {
          margin: '4px 0 0'
        }
      }
    }
  })
)(InputBase)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '15px'
      }
    },
    waring: {
      minHeight: '20px'
    }
  })
)

const MintStepFirst = ({
  initAmount,
  borAmount,
  onChange,
  onTypeChange,
  onIsPre,
  onClick
}: {
  initAmount: string
  borAmount: string
  onChange?: (v: string) => void
  onTypeChange?: (v: AssetName) => void
  onIsPre: () => void
  onClick: any
}) => {
  const classes = useStyles()
  const ctx = useContext(Context)
  const {t} = useTranslation()
  const [value, setValue] = useState(initAmount)
  const [can, setCan] = useState(false)
  const [type, setType] = useState<AssetName>(TOKEN_BTC)
  const [gain, setGain] = useState('')
  const [remain, setRemain] = useState('')
  const {chainId, account} = useActiveWeb3React()
  const [openWallets, setOpenWallets] = useState(false)

  const init = () => {
    setCan(false)
  }

  useEffect(() => {
    const canIssue = type === TOKEN_BTC ? ctx.canIssue.btc : type === TOKEN_LTC ? ctx.canIssue.ltc : ctx.canIssue.doge
    const total = type === TOKEN_BTC ? ctx.total.oBTC : type === TOKEN_LTC ? ctx.total.oLTC : ctx.total.oDOGE
    const remain = new BigNumber(canIssue).minus(total)
    if (remain.lt(0)) {
      setRemain('0')
    } else {
      setRemain(remain.toString())
    }
  }, [type, ctx])

  useEffect(() => {
    init()
    onChange && onChange(value)
    onTypeChange && onTypeChange(type)
    if (!isValidNumberInput(value)) {
      return
    }

    const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, 18))
    const fee = type === TOKEN_BTC ? ctx.networkFee.btc : type === TOKEN_LTC ? ctx.networkFee.ltc : ctx.networkFee.doge
    let ret = valueBN.multipliedBy(new BigNumber('0.998')).minus(new BigNumber(fee))

    setGain(ret.toString())
    if (valueBN.gt(0)) {
      setCan(true)
    }
  }, [onChange, value, type, ctx, onTypeChange])

  const price = () => {
    const p = type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge
    return new BigNumber(value).multipliedBy(p).toString()
  }

  const next = () => {
    const canIssue = type === TOKEN_BTC ? ctx.canIssue.btc : type === TOKEN_LTC ? ctx.canIssue.ltc : ctx.canIssue.doge
    const total = type === TOKEN_BTC ? ctx.total.oBTC : type === TOKEN_LTC ? ctx.total.oLTC : ctx.total.oDOGE

    const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, 18))
    const remaining = new BigNumber(canIssue).minus(total).toString()
    const remainingS = displayBalance(remain)
    if (valueBN.gt(remaining)) {
      if (new BigNumber(remaining).gt(0)) {
        toast.info(<ToastContent status={false} content={t('mint_max_notice', {value: remainingS})} />)
      } else {
        toast.info(<ToastContent status={false} content={t('cant_mint')} />)
      }
      return
    }

    if (valueBN.lt(new BigNumber(getMinMintValue(chainId, type)).multipliedBy(Math.pow(10, 18)))) {
      toast.info(
        <ToastContent status={false} content={t('mint_min_notice', {value: getMinMintValue(chainId, type)})} />
      )
      return
    }
    onClick()
  }

  return (
    <Box>
      <WalletsModal open={openWallets} close={() => setOpenWallets(false)} />
      <Box className={classes.input} display={'flex'} alignItems={'center'}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          value={type}
          onChange={(e: React.ChangeEvent<{value: unknown}>) => {
            return setType(e.target.value as AssetName)
          }}
          input={<WithoutStyleInput />}
        >
          <MenuItem value={'BTC'}>
            <img width={16} height={16} src={getTokenIcon(TOKEN_BTC)} alt="btc" />
            <Typography variant={'body2'}>BTC</Typography>
          </MenuItem>
          <MenuItem value={'LTC'}>
            <img width={16} height={16} src={getTokenIcon(TOKEN_LTC)} alt="ltc" />
            <Typography variant={'body2'}>LTC</Typography>
          </MenuItem>
          {isEthereum() && (
            <MenuItem value={'DOGE'}>
              <img width={16} height={16} src={getTokenIcon(TOKEN_DOGE)} alt="doge" />
              <Typography variant={'body2'}>DOGE</Typography>
            </MenuItem>
          )}
        </Select>
        <InputBase
          fullWidth={true}
          placeholder="0.00"
          value={value}
          onChange={(e: any) => verifyNumericInput(e, setValue)}
        />
        <Box style={{marginLeft: 'auto'}} flexShrink={0}>
          ≈ $ {displayBalance(price(), 18, 2)}
        </Box>
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant={'body2'}>{t('you_will_get')}</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <TokenValue
            img={getTokenIcon(getMapTokenName(type))}
            amount={displayBalance(gain, 18, 4)}
            price={type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TokenValue
            img={getTokenIcon(TOKEN_BOR)}
            amount={displayBalance(borAmount, 18, 4)}
            price={new BigNumber(ctx.price.bor).dividedBy(10000).toString()}
          />
        </Grid>
      </Grid>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4}>
        {account ? (
          <>
            <Button
              onClick={next}
              disabled={!can}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disableElevation
            >
              {t('next')}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setOpenWallets(true)}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            disableElevation
          >
            {t('unlock_wallet')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default MintStepFirst
