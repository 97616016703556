import React, {useEffect, useState} from 'react'
import {displayBalance} from '../../helpers/display'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  Divider,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core'
import TimeBanner from '../../assets/images/time-banner.png'
import {useWeb3React} from '@web3-react/core'
import {BigNumber} from 'bignumber.js'
import {claimMonth} from '../../contracts/time'
import {useDistributionMonth} from '../../hooks/useTimeDistributionMonth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 200px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 15px'
    },
    card: {
      maxWidth: '400px',
      width: '100%'
    },
    total: {
      background: `url(${TimeBanner})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: '160px',
      padding: '30px',
      borderRadius: '10px'
    },
    value: {
      border: '1px solid #D8E0ED',
      padding: '10px',
      borderRadius: '6px'
    }
  })
)

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff'
    }
  })
)(LinearProgress)

const TimeMonth = () => {
  const classes = useStyles()
  const data = useDistributionMonth()
  const {library, chainId, account} = useWeb3React()

  const [percentage, setPercentage] = useState(0)
  const [doing, setDoing] = useState(false)

  const canClaim = () => {
    return data && new BigNumber(data.claimable).gt(0)
  }

  useEffect(() => {
    try {
      if (account !== '') {
        const claimed = new BigNumber(data.claimed)
        const claimable = new BigNumber(data.claimable)
        const total = new BigNumber(data.total)
        const hundred = new BigNumber('100')
        if (total.eq(new BigNumber('0'))) {
          setPercentage(0)
          return
        }
        const current = claimable.plus(claimed)
        const result = current.div(total).multipliedBy(hundred)
        setPercentage(result.toNumber())
      }
    } catch (e) {
      console.error(e)
    }
  }, [data, account])

  const claiming = async () => {
    setDoing(true)
    try {
      await claimMonth(library, chainId, account)
      console.log('=====> Claim successfully')
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  return (
    <Box mt={{xs: 3, md: 6}}>
      <Box className={classes.main}>
        <Card className={classes.card}>
          <Box p={{xs: 4, md: 6}}>
            <Typography variant={'h6'} align={'center'}>
              Private Offering
            </Typography>
            <Box mt={2} mb={6} className={classes.total}>
              <Typography variant={'subtitle1'}>Total</Typography>
              <Box mt={2}>
                <Typography variant={'h4'}>{displayBalance(data?.total)}</Typography>
              </Box>
            </Box>
            <BorderLinearProgress variant="determinate" value={percentage} />
            <Box my={2}>
              <Typography align={'center'} variant={'body2'}>
                {percentage} %
              </Typography>
            </Box>
            <Divider />
            <Box mt={4}>
              <Typography variant={'body2'}>Already claimed</Typography>
              <Box mt={2} className={classes.value}>
                {displayBalance(data.claimed, 18, 4)}
              </Box>
            </Box>
            <Box mt={4} mb={6}>
              <Typography variant={'body2'}>Claimable</Typography>
              <Box mt={2} className={classes.value}>
                {displayBalance(data.claimable, 18, 4)}
              </Box>
            </Box>
            <Box textAlign={'center'}>
              <Button
                disabled={!canClaim() || doing}
                disableElevation={true}
                size={'large'}
                variant={'contained'}
                color={'primary'}
                onClick={claiming}
              >
                {doing && <CircularProgress size={14} color={'inherit'} />}
                Claim
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default TimeMonth
