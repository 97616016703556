import React from 'react'
import {Box, Button, Container, createStyles, Link, makeStyles, Theme} from '@material-ui/core'
import {getTokenIcon} from '../../constants/icon'
import {TOKEN_BNB, TOKEN_BOR, TOKEN_ETH, TOKEN_OBTC} from '../../constants/token'
import {useTitle} from 'react-use'
import Loopring from '../../assets/images/loopring.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      paddingBottom: '15px',
      marginBottom: '15px',
      borderBottom: '1px solid #f0f0f0'
    },
    double: {
      '& img:not(:first-child)': {
        marginLeft: '-5px'
      }
    },
    type: {
      padding: '6px',
      border: '2px solid #E4E9F0',
      borderRadius: '50%'
    },
    grow: {
      flexGrow: 1
    },
    pools: {
      borderRadius: '12px',
      boxShadow: '0px 5px 21px 0px rgba(228, 232, 245, 0.92)',
      background: '#fff'
    },
    unlock: {
      color: '#1164FB'
    }
  })
)

const Exchange = () => {
  useTitle('BoringDAO App | Exchange')
  const classes = useStyles()

  return (
    <Container maxWidth={'lg'}>
      <Box mt={10}>
        <Box display="flex">
          <Box width={200}>&nbsp;</Box>
          <Box width={380}>Asset</Box>
        </Box>
      </Box>
      <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
        <Box textAlign="center" style={{width: '170px'}} display={{xs: 'none', md: 'block'}}>
          Ethereum
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" className={classes.item}>
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img
                  className={classes.type}
                  width={42}
                  src="https://cryptologos.cc/logos/uniswap-uni-logo.png?v=010"
                  alt="token"
                />
                <Box className={classes.double} ml={-1}>
                  <img width={20} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                  <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>BORING/ETH</Box>
            </Box>
            <Box flexGrow={1} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" className={classes.item}>
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img
                  className={classes.type}
                  width={42}
                  src="https://cryptologos.cc/logos/uniswap-uni-logo.png?v=010"
                  alt="token"
                />
                <Box className={classes.double} ml={-1}>
                  <img width={20} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                  <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>BOR/ETH</Box>
            </Box>
            <Box flexGrow={1} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9&use=V2"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img
                  className={classes.type}
                  width={42}
                  src="https://cryptologos.cc/logos/sushiswap-sushi-logo.png?v=010"
                  alt="token"
                />
                <Box className={classes.double} ml={-1}>
                  <img width={20} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                  <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>BOR/ETH</Box>
            </Box>
            <Box flexGrow={1} className={classes.double} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
        <Box textAlign="center" style={{width: '170px'}} display={{xs: 'none', md: 'block'}}>
          BSC
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" className={classes.item}>
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img
                  className={classes.type}
                  width={42}
                  src="https://cryptologos.cc/logos/pancakeswap-cake-logo.png?v=010"
                  alt="token"
                />
                <Box ml={-1} className={classes.double}>
                  <img width={20} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                  <img width={20} src="https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=010" alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>BORING/BNB</Box>
            </Box>
            <Box flexGrow={1} className={classes.double} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img
                  className={classes.type}
                  width={42}
                  style={{padding: 0}}
                  src="https://assets.coingecko.com/coins/images/12651/small/dodo_logo.png?1601433025"
                  alt="token"
                />
                <Box ml={-1} className={classes.double}>
                  <img width={20} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                  <img width={20} src={getTokenIcon(TOKEN_BNB)} alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>BORING/BNB</Box>
            </Box>
            <Box flexGrow={1} className={classes.double} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://app.dodoex.io/exchange/BNB-BORING?network=bsc-mainnet"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
        <Box textAlign="center" style={{width: '170px'}} display={{xs: 'none', md: 'block'}}>
          Polygon
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center">
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img
                  className={classes.type}
                  style={{padding: '0'}}
                  width={42}
                  src="https://assets.coingecko.com/coins/images/13970/small/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659"
                  alt="token"
                />
                <Box className={classes.double} ml={-1}>
                  <img width={20} src={getTokenIcon(TOKEN_BOR)} alt="obtc" />
                  <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>BORING/ETH</Box>
            </Box>
            <Box flexGrow={1} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://quickswap.exchange/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xff88434e29d1e2333ad6baa08d358b436196da6b"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
        <Box textAlign="center" style={{width: '170px'}} display={{xs: 'none', md: 'block'}}>
          Loopring
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center">
            <Box width={{xs: 'auto', md: 380}} display="flex" alignItems="center">
              <Box display="flex" alignItems="flex-end">
                <img className={classes.type} style={{padding: '0'}} width={42} src={Loopring} alt="token" />
                <Box className={classes.double} ml={-1}>
                  <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                  <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                </Box>
              </Box>
              <Box ml={5}>oBTC/ETH</Box>
            </Box>
            <Box flexGrow={1} />
            <Box>
              <Button variant="contained" size="small" color="primary" disableElevation={true}>
                <Link
                  href="https://exchange.loopring.io/swap/OBTC-ETH"
                  rel={'noopener nofollow'}
                  target={'_blank'}
                  color={'inherit'}
                >
                  Enter
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Exchange
