import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  Container,
  createStyles,
  Hidden,
  InputBase,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core'
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import BOR from '../../assets/images/bor.png'
import TokenModal from '../Models/BOR'
import {useWeb3React} from '@web3-react/core'
import {displayBalance} from '../../helpers/display'
import {getContractAddress} from '../../helpers/web3'
import {TOKEN_BOR, TOKEN_BTC, TOKEN_DOGE, TOKEN_LTC, TOKEN_OBTC, TOKEN_ODOGE, TOKEN_OLTC} from '../../constants/token'
import {isBSC, isEthereum, isMatic, isOKExChain} from '../../helpers'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PledgeSelectModal from '../Models/Pledge/Select'
import Menu from '../../assets/images/menu.png'
import Sidebar from '../Sidebar'
import {useTVL} from '../../hooks/useTVL'
import {getMapTokenShowName, getTokenIcon} from '../../constants/icon'
import {Context} from '../../contexts/BoringProvider'
import Network from '../Network'
import WalletsModal from '../Models/Wallets'
import ChainLink from '../ChainLink'
import AppLogo from '../AppLogo'
import FarmSelectModal from '../Models/FarmSelect'
import CrossSelectModal from '../Models/CrossSelect'
import BoringTokenModal from '../Models/Boring'
import ExchangeSelectModal from '../Models/ExchangeSelect'
import {LoadingDisplay} from '../style'
import {BigNumber} from 'bignumber.js'

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#F1F2F6',
      borderRadius: '6px',
      color: '#333A50',
      fontSize: '14px',
      marginLeft: '20px',
      '& .MuiSelect-selectMenu': {
        padding: '10px 24px 10px 12px',
        '&:focus': {
          background: 'none'
        }
      }
    }
  })
)(InputBase)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '80px',
      padding: '20px 0',
      background: '#fff',
      boxShadow: '0px 5px 43px 0px rgba(228, 232, 245, 0.92);',
      [theme.breakpoints.down('sm')]: {
        height: '60px',
        padding: '10px 0'
      }
    },
    container: {
      height: '100%'
    },
    nav: {
      marginLeft: '5px',
      fontSize: '14px',
      '& a': {
        display: 'flex',
        alignItems: 'center',
        color: '#A1A8C7',
        padding: '0 7px',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none'
        },
        '&.active': {
          color: theme.palette.primary.main
        }
      }
    },
    bar: {
      cursor: 'pointer',
      background: '#F1F2F6',
      padding: '8px 12px',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      color: '#333A50',
      '& img': {
        width: '16px',
        height: '16px'
      }
    },
    grow: {
      flexGrow: 1
    },
    backdrop: {
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
      background: 'rgba(0, 0, 0, .5)'
    }
  })
)

const Header = () => {
  const ctx = useContext(Context)
  const classes = useStyles()
  const {t, i18n} = useTranslation()
  const {account, chainId} = useWeb3React()
  const location = useLocation()
  const isPledgePage = location.pathname.includes('/pledge')
  const isFarmPage = location.pathname.includes('/farm')
  const isBTCPledge = location.pathname.includes('/pledge/BTC')
  const isLTCPledge = location.pathname.includes('/pledge/LTC')

  const [type, setType] = useState(TOKEN_BTC)
  const [openBOR, setOpenBOR] = useState(false)
  const [openOBTC, setOpenOBTC] = useState(false)
  const [openOLTC, setOpenOLTC] = useState(false)
  const [openODOGE, setOpenODOGE] = useState(false)
  const [openPledge, setOpenPledge] = useState(false)
  const [openFarm, setOpenFarm] = useState(false)
  const [openCross, setOpenCross] = useState(false)
  const [openExchange, setOpenExchange] = useState(false)
  // sidebar switcher
  const [open, setOpen] = useState(false)
  const tvl = useTVL()
  const [openWallets, setOpenWallets] = useState(false)

  useEffect(() => {
    if (!isPledgePage) return
    if (isBTCPledge) {
      setType(TOKEN_BTC)
    } else if (isLTCPledge) {
      setType(TOKEN_LTC)
    } else {
      setType(TOKEN_DOGE)
    }
  }, [isBTCPledge, isLTCPledge, isPledgePage])

  const currentLanguage = () => {
    const lng = i18n.language
    return lng === 'en-US' ? 'en' : lng === 'zh-CN' ? 'zh' : lng
  }

  const bd = open ? `${classes.backdrop}` : ``

  return (
    <header className={classes.root}>
      <div className={bd} onClick={() => setOpen(false)} />
      <Sidebar
        isOpen={open}
        close={() => {
          setOpen(false)
        }}
      />
      <FarmSelectModal open={openFarm} onClose={() => setOpenFarm(false)} />
      <PledgeSelectModal setType={setType} open={openPledge} onClose={() => setOpenPledge(false)} />
      <WalletsModal open={openWallets} close={() => setOpenWallets(false)} />
      <BoringTokenModal
        open={openBOR}
        close={() => setOpenBOR(false)}
        balance={displayBalance(ctx.balance.bor)}
        contractAddress={getContractAddress(chainId, TOKEN_BOR)}
      />
      <TokenModal
        open={openOLTC}
        close={() => setOpenOLTC(false)}
        type="oltc"
        balance={displayBalance(ctx.balance.oLTC)}
        contractAddress={getContractAddress(chainId, TOKEN_OLTC)}
      />
      <TokenModal
        open={openOBTC}
        close={() => setOpenOBTC(false)}
        type="obtc"
        balance={displayBalance(ctx.balance.oBTC)}
        contractAddress={getContractAddress(chainId, TOKEN_OBTC)}
      />
      <TokenModal
        open={openODOGE}
        close={() => setOpenODOGE(false)}
        type="odoge"
        balance={displayBalance(ctx.balance.oDOGE)}
        contractAddress={getContractAddress(chainId, TOKEN_ODOGE)}
      />
      <CrossSelectModal open={openCross} onClose={() => setOpenCross(false)} />
      <ExchangeSelectModal open={openExchange} onClose={() => setOpenExchange(false)} />

      <Container maxWidth={'lg'} className={classes.container}>
        <Box display="flex" alignItems="center" height={'100%'} justifyContent={'space-between'}>
          <Hidden mdUp>
            <Box pr={19}>
              <img
                src={Menu}
                alt="menu"
                width={18}
                height={18}
                onClick={() => {
                  setOpen(true)
                }}
              />
            </Box>
          </Hidden>
          <AppLogo />
          <Hidden smDown>
            <Box display="flex" className={classes.nav}>
              {(isEthereum() || isBSC()) && (
                <NavLink exact to="/">
                  {t('home')}
                </NavLink>
              )}
              {(isEthereum() || isBSC()) && <NavLink to="/mint">{t('mint')}</NavLink>}
              {(isEthereum() || isBSC()) && (
                <Link className={isPledgePage ? 'active' : ''} onClick={() => setOpenPledge(true)}>
                  {t('tunnel')}({getMapTokenShowName(type)})
                  <ArrowDropDownIcon fontSize={'small'} />
                </Link>
              )}
              {isEthereum() && (
                // <Link className={isFarmPage ? 'active' : ''} onClick={() => setOpenFarm(true)}>
                //   {t('farm')}
                // </Link>
                <NavLink exact to="/farm/boring">
                  {t('farm')}
                </NavLink>
              )}
              {(isBSC() || isMatic()) && <NavLink to="/farm">{t('farm')}</NavLink>}
              {/*{isEthereum() && <NavLink to="/barter"> {t('barter')} </NavLink>}*/}
              {(isEthereum() || isBSC()) && chainId !== 3 && chainId !== 42 && (
                <Link href="https://oportal.boringdao.com/" target="_blank">
                  {t('oportal')}
                </Link>
                // <Link onClick={() => setOpenCross(true)}>{t('oportal')}</Link>
              )}
              <NavLink to="/ecosystem">{t('ecosystem')}</NavLink>
              {/*{isEthereum() && <NavLink to="/transfer">BORING</NavLink>}*/}
              {isBSC() && (
                <Link target="_blank" href="https://app.dodoex.io/exchange/BOR-BORING?network=bsc-mainnet">
                  BORING
                </Link>
              )}
              {isEthereum() && (chainId === 3 || chainId === 42) && <NavLink to="/eth-to-okc"> ETH to OKC </NavLink>}
              {isOKExChain() && chainId === 65 && <NavLink to="/okc-to-eth"> OKC to ETH </NavLink>}
              {isEthereum() && <Link onClick={() => setOpenExchange(true)}>{t('others')}</Link>}
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box textAlign={'right'} style={{minWidth: '116px'}}>
              <Typography variant={'caption'} color={'primary'}>
                • {t('total_value_locked')}
              </Typography>
              <Typography style={{marginTop: '-5px'}} variant={'body1'}>
                <LoadingDisplay ok={new BigNumber(tvl.replace(/,/g, '')).gt(0)} result={'$' + tvl} height={24} />
              </Typography>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box display={'flex'} ml={'auto'}>
              {account ? (
                <>
                  {/*<ChainLink />*/}
                  <Network />
                  <div className={classes.bar} onClick={() => setOpenBOR(true)}>
                    <img style={{marginRight: '5px'}} src={BOR} alt="bor" /> {displayBalance(ctx.balance.boring)}
                  </div>
                  <div className={classes.bar} onClick={() => setOpenOBTC(true)}>
                    <img src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                  </div>
                  {(isEthereum() || isBSC()) && (
                    <div className={classes.bar} onClick={() => setOpenOLTC(true)}>
                      <img src={getTokenIcon(TOKEN_OLTC)} alt="oltc" />
                    </div>
                  )}
                  {isEthereum() && (
                    <div className={classes.bar} onClick={() => setOpenODOGE(true)}>
                      <img src={getTokenIcon(TOKEN_ODOGE)} alt="odoge" />
                    </div>
                  )}
                </>
              ) : (
                <Button
                  onClick={() => {
                    setOpenWallets(true)
                  }}
                  variant="outlined"
                  color="primary"
                  style={{fontSize: '12px'}}
                >
                  {t('unlock_wallet')}
                </Button>
              )}
              <Select
                style={{fontSize: '13px'}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  elevation: 1,
                  getContentAnchorEl: null
                }}
                onChange={(e: React.ChangeEvent<{value: unknown}>) => {
                  return i18n.changeLanguage(e.target.value as string)
                }}
                value={currentLanguage()}
                input={<BootstrapInput />}
              >
                <MenuItem value={'zh'}>简体中文</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
              </Select>
            </Box>
          </Hidden>
        </Box>
      </Container>
    </header>
  )
}

export default Header
