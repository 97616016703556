import React from 'react'
import {Box, Grid} from '@material-ui/core'
import DataCard from '../../DataCard'
import DollarWhite from '../../../assets/images/dollar_white.png'
import {BigNumber} from 'bignumber.js'
import {displayBalance} from '../../../helpers/display'
import {useFarmMeta} from '../../../hooks/useFarmMeta'
import {useTranslation} from 'react-i18next'
import BorWhite from '../../../assets/images/bor_white.png'

const FarmMeta = () => {
  const {t} = useTranslation()
  const meta = useFarmMeta()

  return (
    <Box mt={6}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <DataCard img={DollarWhite} title={t('total_staked')} data={displayBalance(meta.totalPrice, 18, 0)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataCard img={DollarWhite} title={t('your_staked')} data={displayBalance(meta.lockPrice, 18, 3)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataCard
            img={BorWhite}
            title={t('total_rewards')}
            data={displayBalance(new BigNumber(meta.unlockAmount).plus(meta.lockAmount).toString())}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default FarmMeta
