import {useWeb3React} from '@web3-react/core'
import {useCallback, useEffect, useState} from 'react'
import {claimableMonth, claimedMonth, userTotalTokenMonth} from '../contracts/time'
import {useMountedRef} from './index'

export interface Distribution {
  total: string
  claimed: string
  claimable: string
}

export const useDistributionMonth = () => {
  const mountedRef = useMountedRef()
  const [info, setInfo] = useState<Distribution>({
    total: '0',
    claimed: '0',
    claimable: '0'
  })
  const {account, library, chainId} = useWeb3React()

  const get = useCallback(async () => {
    try {
      const totalPromise = userTotalTokenMonth(library, chainId, account).catch(e => {
        console.error(e)
        return ''
      })
      const claimedPromise = claimedMonth(library, chainId, account).catch(e => {
        console.error(e)
        return ''
      })
      const claimablePromise = claimableMonth(library, chainId, account).catch(e => {
        console.error(e)
        return ''
      })

      Promise.all([totalPromise, claimedPromise, claimablePromise])
        .then(values => {
          console.debug('=====> Fetching time distribution')
          if (mountedRef.current) {
            setInfo({
              total: values[0],
              claimed: values[1],
              claimable: values[2]
            })
          }
        })
        .catch(e => {
          console.error(e)
        })
    } catch (e) {
      console.error(e)
    }
  }, [library, account, chainId, mountedRef])

  useEffect(() => {
    if (account && library) {
      get()
      let timer = setInterval(get, 5000)
      return () => clearInterval(timer)
    }
  }, [account, library, setInfo, get])

  return info
}
