import {useCallback, useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {BigNumber} from 'bignumber.js'
import web3 from 'web3'
import {TokenModal} from '../constants/token'
import {allowance, balanceOf, totalSupply} from '../helpers/erc20'
import {getContractAddress} from '../helpers/web3'
import {CONTRACT_SUSHI_MASTER_CHEF} from '../constants/contract'
import {handleError, handleErrorAndZero} from '../helpers'
import {useCoinGeckoPrice} from './useCoinGeckoPrice'
import {
  sushiGetReserves,
  sushiPendingSushi,
  sushiPoolInfo,
  sushiSushiPerBlock,
  sushiTotalAllocPoint,
  sushiUserInfo
} from '../contracts/sushi'
import {getCoinGeckoPrice} from '../api/request'
import {getTokenDecimals} from '../constants/helper'
import {useActiveWeb3React} from './index'

export type Datum = {
  tokenName: string
  apy: string
  userTokenBalance: string
  userStakedAmount: string
  totalStakedAmount: string
  unlockEarned: string
  lockEarned: string
  userAllowance: string
}

export const initDatum = (tokenName: string): Datum => {
  return {
    tokenName: tokenName,
    apy: '0',
    userTokenBalance: '0',
    userStakedAmount: '0',
    totalStakedAmount: '0',
    unlockEarned: '0',
    lockEarned: '0',
    userAllowance: '0'
  }
}

export const useSushiPool = (tokenName: string) => {
  const [data, setData] = useState<Datum>(initDatum(tokenName))
  const {account, library, chainId} = useActiveWeb3React()
  const cakePrice = useCoinGeckoPrice('sushi')
  const pingName = TokenModal[tokenName].ping
  const pingPriceID = TokenModal[pingName].tokenPriceID

  const calcAPY = useCallback(
    (cakeRate: string, lockValue) => {
      const bp = new BigNumber(cakePrice)
      const br = new BigNumber(web3.utils.fromWei(cakeRate))
      const a = bp.multipliedBy(br).multipliedBy(365 * 3600 * 24)
      const lockBN = new BigNumber(lockValue)
      if (lockBN.eq(new BigNumber('0'))) {
        return '0.00'
      }
      const result = a.div(lockBN).multipliedBy(100)
      return result.toFixed(2)
    },
    [cakePrice]
  )

  const get = useCallback(async () => {
    try {
      const pid = TokenModal[tokenName].pid

      const tokenAddress = getContractAddress(chainId, tokenName)
      const chefAddress = getContractAddress(chainId, CONTRACT_SUSHI_MASTER_CHEF)
      const userTokenBalanceQ = balanceOf(library, tokenAddress, account).catch(handleError)
      const userInfoQ = sushiUserInfo(library, chainId, pid, account)
      const totalStakedAmountQ = balanceOf(library, tokenAddress, chefAddress).catch(handleError)
      const allowanceQ = allowance(library, tokenAddress, account, chefAddress).catch(handleError)
      const poolInfoQ = sushiPoolInfo(library, chainId, pid).catch(handleErrorAndZero)
      const totalPointQ = sushiTotalAllocPoint(library, chainId).catch(handleErrorAndZero)
      const cakePerBlockQ = sushiSushiPerBlock(library, chainId).catch(handleErrorAndZero)
      const pingPriceQ = getCoinGeckoPrice(pingPriceID).catch(handleErrorAndZero)
      const totalSupplyQ = totalSupply(library, tokenAddress).catch(handleErrorAndZero)
      const getReserves = sushiGetReserves(library, chainId, tokenName).catch(handleError)
      const pendingCakeQ = sushiPendingSushi(library, chainId, account, pid).catch(handleErrorAndZero)

      Promise.all([
        userTokenBalanceQ,
        userInfoQ,
        totalStakedAmountQ,
        allowanceQ,
        poolInfoQ,
        totalPointQ, // 5
        cakePerBlockQ,
        pingPriceQ,
        totalSupplyQ,
        getReserves, // 9
        pendingCakeQ
      ]).then(values => {
        const priceResult = values[7] as any
        const pingPrice = priceResult[pingPriceID]['usd'] || '0'
        const totalSupply = values[8]
        const totalStaking = values[2]
        const totalValue = new BigNumber(values[9][0]).multipliedBy(pingPrice).multipliedBy(2)
        const lockValue = new BigNumber(totalStaking)
          .dividedBy(totalSupply)
          .multipliedBy(totalValue)
          .dividedBy(Math.pow(10, getTokenDecimals(tokenName)))

        const point = values[4][1]
        // const totalPoint = values[5]
        const totalPoint = '54367'
        const rate = new BigNumber(point)
          .dividedBy(totalPoint)
          .multipliedBy(values[6])
          .dividedBy(12)
        setData({
          tokenName: tokenName,
          apy: calcAPY(rate.toFixed(0), lockValue),
          userTokenBalance: values[0],
          userStakedAmount: values[1][0],
          totalStakedAmount: values[2],
          unlockEarned: values[10],
          lockEarned: '0',
          userAllowance: values[3]
        })
      })
    } catch (e) {
      console.error(e)
    }
  }, [library, account, chainId, pingPriceID, calcAPY, tokenName])

  useEffect(() => {
    if (account && library) {
      get()
      let timer = setInterval(get, 5000)
      return () => clearInterval(timer)
    }
  }, [account, library, setData, get])

  return data
}
