import React from 'react'
import {Box, Card, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme} from '@material-ui/core'
import {LoadingDisplay} from '../style'
import {BigNumber} from 'bignumber.js'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: '36px',
      height: '36px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

const DataCard = ({
  img,
  title,
  data,
  background,
  shadow,
  needLoading
}: {
  img: string
  title: string
  data: string
  background?: string
  shadow?: string
  needLoading?: boolean
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const bg = background ? background : theme.palette.primary.main
  const sh = shadow ? shadow : `0px 3px 7px 0px ${theme.palette.primary.main}`

  return (
    <Card style={{borderRadius: '10px'}}>
      <Box display="flex" alignItems="center" px={{xs: 3, md: 6}} py={{xs: 2, md: 6}}>
        <Box className={classes.logo} style={{background: bg, boxShadow: sh}}>
          <img width={20} src={img} alt="addon" />
        </Box>
        <Box ml={{xs: 2, md: 4}}>
          <Typography variant="subtitle2">{title}</Typography>
          {isMobile ? (
            // @ts-ignore
            <LoadingDisplay
              ok={new BigNumber(data.replace(/,/g, '')).gt(needLoading ? 0 : -1)}
              result={<Typography variant="h6">{data}</Typography>}
            />
          ) : (
            // @ts-ignore
            <LoadingDisplay
              ok={new BigNumber(data.replace(/,/g, '')).gt(needLoading ? 0 : -1)}
              result={<Typography variant="h5">{data}</Typography>}
            />
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default DataCard
