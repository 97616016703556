import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  Grid,
  InputBase,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {useInterval} from '../../hooks/useInterval'
import {totalBoringPledge, userBoringPledgeAmount} from '../../contracts/tunnel'
import {useWeb3React} from '@web3-react/core'
import {displayBalance} from '../../helpers/display'
import {boringEarned} from '../../contracts/feepool'
import DataCard from '../../components/DataCard'
import {getMapTokenShowName, getTokenIcon} from '../../constants/icon'
import {TOKEN_BOR, TOKEN_BORING, TOKEN_BTC, TOKEN_LTC, TOKEN_OBTC, TOKEN_ODOGE, TOKEN_OLTC} from '../../constants/token'
import {verifyNumericInput} from '../../helpers/verify'
import {getContractAddress} from '../../helpers/web3'
import {allowance, approveMax} from '../../helpers/erc20'
import {getBoringBoringDAOName} from '../../constants/contract'
import {BigNumber} from 'bignumber.js'
import PledgeNotice from '../../components/PledgeNotice'
import {handleError, handleErrorAndZero} from '../../helpers'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {boringPledge} from '../../contracts/dao'
import {useParams} from 'react-router-dom'
import {Context} from '../../contexts/BoringProvider'
import {BoringTooltip} from '../../components/BoringTooltip'
import Question from '../../assets/images/question.png'
import {AssetName} from '../../contracts/address'
import {useTranslation} from 'react-i18next'
import {MaxButton} from '../../components/MaxButton'
import BorWhite from '../../assets/images/bor_white.png'
import PledgeSelect from '../../components/Models/PledgeSelect'
import BoringClaim from '../../components/BoringClaim'
import BoringRedeem from '../../components/BoringRedeem'
import {useActiveWeb3React, useBORPrice} from '../../hooks'
import {useTitle} from 'react-use'
import WalletsModal from '../../components/Models/Wallets'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dotArrow: {
      margin: '0 20px'
    },
    mintNotice: {
      paddingLeft: '15px',
      lineHeight: '1.8'
    },
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '5px'
      }
    },
    value: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      background: '#F1F2F6',
      padding: '15px 15px',
      fontSize: '14px',
      color: '#444C66',
      '& img': {
        marginLeft: '0px',
        cursor: 'pointer'
      }
    }
  })
)

const BoringPledge = () => {
  useTitle('BoringDAO App | Pledge')
  const {t} = useTranslation()
  const classes = useStyles()
  const ctx = useContext(Context)
  const {type} = useParams<{type: string}>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {library, chainId, account} = useActiveWeb3React()
  const [pledgeAmount, setPledgeAmount] = useState('0')
  const [userPledge, setUserPledge] = useState('0')
  const [earnedBOR, setEarnedBOR] = useState('0')
  const [earnedOToken, setEarnedOToken] = useState('0')
  const [amount, setAmount] = useState('')
  const [userAllowance, setUserAllowance] = useState('')

  const [can, setCan] = useState(false)
  const [doing, setDoing] = useState(false)

  const [openPledge, setOpenPledge] = useState(true)
  const boringPrice = useBORPrice()

  useEffect(() => {
    setCan(false)
    if (amount) {
      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
      if (amountBN.lte(ctx.balance.boring) && amountBN.gt(0)) {
        setCan(true)
      }
    }
  }, [amount, ctx])

  useInterval(
    () => {
      if (library) {
        const borAddress = getContractAddress(chainId, TOKEN_BORING)
        const daoAddress = getContractAddress(chainId, getBoringBoringDAOName(type))
        allowance(library, borAddress, account, daoAddress)
          .then(setUserAllowance)
          .catch(handleErrorAndZero)
        totalBoringPledge(library, chainId, type)
          .then(res => setPledgeAmount(res))
          .catch(handleError)
        userBoringPledgeAmount(library, chainId, account, type)
          .then(setUserPledge)
          .catch(handleErrorAndZero)
        boringEarned(library, chainId, account, type)
          .then(res => {
            setEarnedBOR(res[0])
            setEarnedOToken(res[1])
          })
          .catch(handleErrorAndZero)
      }
    },
    3000,
    true
  )

  const pledgeApprove = () => {
    const borAddress = getContractAddress(chainId, TOKEN_BORING)
    const daoAddress = getContractAddress(chainId, getBoringBoringDAOName(type))
    approveMax(library, borAddress, daoAddress, account).catch(handleError)
  }

  const onPledge = async () => {
    try {
      setDoing(true)
      console.log(`=====> Pledge to ${type} tunnel`)
      await boringPledge(library, chainId, account, amount, type as AssetName)
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  useEffect(() => setOpenPledge(true), [type])

  const max = () =>
    setAmount(new BigNumber(ctx.balance.boring).dividedBy(Math.pow(10, 18)).toFixed(3, BigNumber.ROUND_DOWN))

  const approved = new BigNumber(userAllowance).gt(ctx.balance.boring)
  const icon =
    type === TOKEN_BTC
      ? getTokenIcon(TOKEN_OBTC)
      : type === TOKEN_LTC
      ? getTokenIcon(TOKEN_OLTC)
      : getTokenIcon(TOKEN_ODOGE)

  return (
    <Container maxWidth={'lg'}>
      {/*<PledgeSelect type={type} open={openPledge} onClose={() => setOpenPledge(false)} />*/}
      <Box mt={{xs: 3, md: 6}}>
        <Grid container spacing={isMobile ? 2 : 6}>
          <Grid item xs={6} md={6} lg={3}>
            <DataCard
              needLoading={true}
              img={BorWhite}
              title={t('total_pledged') + ' (BORING)'}
              data={displayBalance(pledgeAmount, 18, 0)}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <DataCard img={BorWhite} title={t('your_pledged') + ' (BORING)'} data={displayBalance(userPledge)} />
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <DataCard img={BorWhite} title={t('total_claimable') + ' (BORING)'} data={displayBalance(earnedBOR)} />
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <DataCard
              img={icon}
              background={'#fff'}
              shadow={'0px 3px 7px 0px rgba(252, 176, 89, 0.46)'}
              title={t('total_claimable') + ' (' + getMapTokenShowName(type) + ')'}
              data={displayBalance(earnedOToken)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={{xs: 3, md: 6}}>
        <Card>
          <Box p={{xs: 4, md: 8}}>
            <Grid container spacing={10}>
              <Grid item xs={12} lg={4}>
                <Box textAlign={'center'} mb={4}>
                  {isMobile && <Typography variant={'subtitle1'}>{t('pledge')}</Typography>}
                  {!isMobile && <Typography variant={'subtitle2'}>{t('pledge')}</Typography>}
                </Box>
                <Box mb={2} className={classes.input} display={'flex'} alignItems={'center'}>
                  <Box display="flex" alignItems="center" mr={2}>
                    <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
                    BORING
                  </Box>
                  <InputBase
                    fullWidth={true}
                    placeholder="0.00"
                    value={amount}
                    onChange={(e: any) => verifyNumericInput(e, setAmount)}
                  />
                  <MaxButton onClick={max}>Max</MaxButton>
                </Box>
                <Typography align={'right'} variant={'body2'}>
                  {t('balance')}：{displayBalance(ctx.balance.boring)}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant={'body2'}>{t('pptoken_get', {map_type: getMapTokenShowName(type)})}</Typography>
                  <BoringTooltip
                    enterTouchDelay={20}
                    arrow
                    title={<Typography variant={'body2'}>{t('pptoken_get_tooltip')}</Typography>}
                  >
                    <img width={12} height={12} src={Question} alt="question" />
                  </BoringTooltip>
                </Box>
                <Box mt={0} mb={{xs: 4, md: 15}}>
                  <Box className={classes.value} display={'flex'} alignItems={'center'}>
                    <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
                    <Box ml={2}>{amount}</Box>
                    <Box ml={'auto'}>
                      ≈ $ {new BigNumber(amount !== '' ? amount : 0).multipliedBy(boringPrice ?? 0).toFixed(3)}
                    </Box>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  {account ? (
                    <>
                      {approved ? (
                        <Button
                          disabled={approved}
                          style={{marginRight: '10px'}}
                          disableElevation={true}
                          size={'large'}
                          variant={'contained'}
                          color={'secondary'}
                        >
                          <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'} />
                          {t('approved')}
                        </Button>
                      ) : (
                        <Button
                          style={{marginRight: '10px'}}
                          disableElevation={true}
                          size={'large'}
                          variant={'contained'}
                          color={'secondary'}
                          onClick={pledgeApprove}
                        >
                          {t('approve')}
                        </Button>
                      )}
                      <Button
                        style={{marginLeft: '10px'}}
                        disableElevation={true}
                        size={'large'}
                        variant={'contained'}
                        color={'primary'}
                        disabled={!can || doing || !approved}
                        onClick={onPledge}
                      >
                        {doing && <CircularProgress size={14} color={'inherit'} />}
                        {t('pledge')}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <BoringClaim type={type} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <BoringRedeem type={type} />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <PledgeNotice type={type} isNew={true} />
        </Card>
      </Box>
    </Container>
  )
}

export default BoringPledge
