import {AccountType, ChainIDType} from './type'
import {getContract} from '../helpers/web3'
import {CONTRACT_SATELLITE_CITY} from '../constants/contract'
import web3 from 'web3'
import {TOKEN_USDC, TOKEN_USDT} from '../constants/token'
import {BigNumber} from 'bignumber.js'

export const cityDeposit = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
  account: AccountType,
  pid: number,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  if (tokenName === TOKEN_USDC || tokenName === TOKEN_USDT) {
    return contract.methods.deposit(pid, new BigNumber(amount).multipliedBy(10**6).toString()).send({from: account})
  }

  return contract.methods.deposit(pid, web3.utils.toWei(amount)).send({from: account})
}

export const cityWithdraw = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
  account: AccountType,
  pid: number,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  console.log(pid)
  console.log(amount)
  console.log(account)
  if (tokenName === TOKEN_USDC || tokenName === TOKEN_USDT) {
    return contract.methods.withdraw(pid, new BigNumber(amount).multipliedBy(10**6).toString()).send({from: account})
  }
  return contract.methods.withdraw(pid, web3.utils.toWei(amount)).send({from: account})
}

export const cityClaim = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  return contract.methods.deposit(pid, 0).send({from: account})
}


export const cityPendingBoring = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  return contract.methods.pendingBoring(pid, account).call()
}

export const cityUserInfo = async (
  provider: any,
  chainID: ChainIDType,
  pid: number,
  account: AccountType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  return contract.methods.userInfo(pid, account).call()
}

export const cityPoolInfo = async (
  provider: any,
  chainID: ChainIDType,
  pid: number
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  return contract.methods.poolInfo(pid).call()
}

export const cityTotalAllocPoint = async (
  provider: any,
  chainID: ChainIDType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  return contract.methods.totalAllocPoint().call()
}

export const cityBoringPerBlock = async (
  provider: any,
  chainID: ChainIDType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_SATELLITE_CITY)
  return contract.methods.boringPerBlock().call()
}

export const cityGetReserves = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
) => {
  const contract = getContract(provider, chainID, tokenName)
  return contract.methods.getReserves().call()
}

