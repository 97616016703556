import React, {useContext, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {useTranslation} from 'react-i18next'
import {Box, Button, createStyles, InputBase, makeStyles, MenuItem, Select, Theme, withStyles} from '@material-ui/core'
import {getTokenIcon} from '../../constants/icon'
import {TOKEN_BOR, TOKEN_ETH, TOKEN_OBTC, TOKEN_OLTC} from '../../constants/token'
import {displayBalance, ellipseAddress} from '../../helpers/display'
import TokenModal from '../Models/BOR'
import {getContractAddress} from '../../helpers/web3'
import {Context} from '../../contexts/BoringProvider'
import WrongNetwork from '../Models/WrongNetwork'
import {useNetworkChecker} from '../../hooks/useNetworkChecker'
import WalletsModal from '../Models/Wallets'
import {isBSC, isEthereum} from '../../helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      height: '60px',
      padding: '0 15px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      background: '#ffffff',
      boxShadow: '0px 0px 59px 0px rgba(192, 205, 224, 0.48)',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    eth: {
      border: '1px solid #E9EDF2',
      borderRadius: '20px'
    },
    token: {
      width: '30px',
      height: '30px',
      borderRadius: '6px',
      background: '#eeeeef',
      padding: '5px'
    }
  })
)

export const LngInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#F1F2F6',
      borderRadius: '6px',
      color: '#333A50',
      fontSize: '14px',
      '& .MuiSelect-selectMenu': {
        padding: '10px 24px 10px 12px',
        '&:focus': {
          background: 'none'
        }
      }
    }
  })
)(InputBase)

const MobileBar = () => {
  const ctx = useContext(Context)
  const classes = useStyles()
  const {account, chainId} = useWeb3React()
  const {t, i18n} = useTranslation()
  const [openBOR, setOpenBOR] = useState(false)
  const [openOBTC, setOpenOBTC] = useState(false)
  const [openOLTC, setOpenOLTC] = useState(false)
  const [openWallets, setOpenWallets] = useState(false)

  const isCorrectNetwork = useNetworkChecker()

  const currentLanguage = () => {
    const lng = i18n.language
    return lng === 'en-US' ? 'en' : lng === 'zh-CN' ? 'zh' : lng
  }

  return (
    <Box className={classes.root} display={'flex'} alignItems={'center'}>
      <WalletsModal open={openWallets} close={() => setOpenWallets(false)} />
      <TokenModal
        open={openBOR}
        close={() => setOpenBOR(false)}
        balance={displayBalance(ctx.balance.bor)}
        contractAddress={getContractAddress(chainId, TOKEN_BOR)}
      />
      <TokenModal
        open={openOBTC}
        close={() => setOpenOBTC(false)}
        type="obtc"
        balance={displayBalance(ctx.balance.oBTC)}
        contractAddress={getContractAddress(chainId, TOKEN_OBTC)}
      />
      <TokenModal
        open={openOLTC}
        close={() => setOpenOLTC(false)}
        type="oltc"
        balance={displayBalance(ctx.balance.oLTC)}
        contractAddress={getContractAddress(chainId, TOKEN_OLTC)}
      />
      {account ? (
        <>
          <WrongNetwork open={!isCorrectNetwork} />
          <Box
            display={'flex'}
            alignItems={'center'}
            p={1}
            className={classes.eth}
            mr={2}
            onClick={() => setOpenWallets(true)}
          >
            <img src={getTokenIcon(TOKEN_ETH)} width={24} height={24} alt="eth" style={{marginRight: '5px'}} />
            {ellipseAddress(account)}
          </Box>
          <Box className={classes.token} mr={2} onClick={() => setOpenBOR(true)}>
            <img width={20} height={20} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
          </Box>
          <Box className={classes.token} mr={2} onClick={() => setOpenOBTC(true)}>
            <img width={20} height={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
          </Box>
          {(isEthereum() || isBSC()) && (
            <Box className={classes.token} mr={2} onClick={() => setOpenOLTC(true)}>
              <img width={20} height={20} src={getTokenIcon(TOKEN_OLTC)} alt="oltc" />
            </Box>
          )}
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              },
              elevation: 1,
              getContentAnchorEl: null
            }}
            onChange={(e: React.ChangeEvent<{value: unknown}>) => {
              return i18n.changeLanguage(e.target.value as string)
            }}
            value={currentLanguage()}
            input={<LngInput />}
          >
            <MenuItem value={'zh'}>CN</MenuItem>
            <MenuItem value={'en'}>EN</MenuItem>
          </Select>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              setOpenWallets(true)
            }}
            variant="outlined"
            color="primary"
          >
            {t('unlock_wallet')}
          </Button>
        </>
      )}
    </Box>
  )
}

export default MobileBar
