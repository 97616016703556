import {AbiItem} from 'web3-utils'
import {toBytes32} from '../helpers/solidity'
import {TOKEN_BOR} from './token'

export const getPriceABI: AbiItem = {
  name: 'getPrice',
  type: 'function',
  inputs: [
    {
      internalType: 'bytes32',
      name: '_symbol',
      type: 'bytes32'
    }
  ]
}

export const rewardRateABI: AbiItem = {
  inputs: [],
  name: 'rewardRate',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const balanceOfABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }
  ],
  name: 'balanceOf',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const totalSupplyABI: AbiItem = {
  inputs: [],
  name: 'totalSupply',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const earnedABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }
  ],
  name: 'earned',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const allowanceABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'owner',
      type: 'address'
    },
    {
      internalType: 'address',
      name: 'spender',
      type: 'address'
    }
  ],
  name: 'allowance',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const periodFinishABI: AbiItem = {
  inputs: [],
  name: 'periodFinish',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const params2ABI: AbiItem = {
  inputs: [
    {
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    },
    {
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }
  ],
  name: 'params2',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const canIssueAmountABI: AbiItem = {
  inputs: [],
  name: 'canIssueAmount',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true
}

export const pledgeRatioABI: AbiItem = {
  inputs: [],
  name: 'pledgeRatio',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true
}

export const assetMultiSignAddressABI: AbiItem = {
  inputs: [
    {
      internalType: 'string',
      name: '',
      type: 'string'
    }
  ],
  name: 'assetMultiSignAddress',
  outputs: [
    {
      internalType: 'string',
      name: '',
      type: 'string'
    }
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true
}

export const borPledgeInfoABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: '',
      type: 'address'
    }
  ],
  name: 'borPledgeInfo',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true
}

export const totalTVLABI: AbiItem = {
  inputs: [],
  name: 'totalTVL',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true
}

export const userInfoABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    },
    {
      internalType: 'address',
      name: '',
      type: 'address'
    }
  ],
  name: 'userInfo',
  outputs: [
    {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: 'rewardDebt',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const poolInfoABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  name: 'poolInfo',
  outputs: [
    {
      internalType: 'contract IERC20',
      name: 'lpToken',
      type: 'address'
    },
    {
      internalType: 'uint256',
      name: 'allocPoint',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: 'lastRewardBlock',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: 'accBoringPerShare',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const totalAllocPointABI: AbiItem = {
  inputs: [],
  name: 'totalAllocPoint',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const boringPerBlockABI: AbiItem = {
  inputs: [],
  name: 'boringPerBlock',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const userUnlockAmountABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'acc',
      type: 'address'
    },
    {
      internalType: 'uint256',
      name: '_pid',
      type: 'uint256'
    }
  ],
  name: 'userUnlockAmount',
  outputs: [
    {
      internalType: 'uint256',
      name: 'unlockAmount',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: 'lockAmount',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const pendingBoringABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_pid',
      type: 'uint256'
    },
    {
      internalType: 'address',
      name: '_user',
      type: 'address'
    }
  ],
  name: 'pendingBoring',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const mainAmountABI: AbiItem = {
  inputs: [],
  name: 'mainAmount',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const rewardPerSecondABI: AbiItem = {
  inputs: [],
  name: 'rewardPerSecond',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const pendingRewardABI: AbiItem = {
  inputs: [],
  name: 'pendingReward',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const getCall = (web3: any, address: string, abi: AbiItem, args: string[]) => {
  return {
    target: address,
    callData: web3.eth.abi.encodeFunctionCall(abi, args)
  }
}
