import React from 'react'
import {Box, Button, Container, createStyles, Grid, makeStyles, Theme, useMediaQuery, useTheme} from '@material-ui/core'
import PoolRow from '../../components/PoolRow'
import {useTranslation} from 'react-i18next'
import {usePools} from '../../hooks/usePools'
import BoringFarmMobile from '../../components/Farm/BoringMobile'
import BoringFarmMeta from '../../components/Farm/BoringMeta'
import CityItem from '../../components/CityItem'
import {
  TOKEN_BORING,
  TOKEN_BORING_BTC_PPTOKEN,
  TOKEN_BORING_DOGE_PPTOKEN,
  TOKEN_BORING_LTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_SUNDER,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH
} from '../../constants/token'
import {useTitle} from 'react-use'
import Skeleton from 'react-loading-skeleton'
import {useCityPool} from '../../hooks/useCityPool'
import {BigNumber} from 'bignumber.js'
import {useOldPool} from '../../hooks/useOldPool'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    pools: {
      borderRadius: '12px',
      boxShadow: '0px 5px 21px 0px rgba(228, 232, 245, 0.92)',
      background: '#fff'
    },
    unlock: {
      color: '#1164FB'
    }
  })
)

const BoringFarm = () => {
  useTitle('BoringDAO App | Farm(BORING)')
  const classes = useStyles()
  const theme = useTheme()
  const {t} = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const oldBoringPool = useOldPool(TOKEN_BORING)
  const testPool = useCityPool(TOKEN_BORING)

  return (
    <Container maxWidth={'lg'}>
      {isMobile ? (
        <BoringFarmMobile />
      ) : (
        <>
          <BoringFarmMeta />
          <Box mt={10}>
            <Box display="flex">
              <Box width={170}>&nbsp;</Box>
              <Box width={175}>POOL</Box>
              <Box width={120}>APR</Box>
              <Box width={170}>{t('total_staked')}</Box>
              <Box width={165}>{t('balance')}</Box>
              <Box width={175}>{t('your_staked')}</Box>
              <Box width={100}>{t('rewards')}</Box>
            </Box>
          </Box>
          {new BigNumber(testPool.apy).gt(0) ? (
            <>
              <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
                <Box style={{width: '140px'}}>{t('manifest_destiny')}</Box>
                <Box flexGrow={1}>
                  <CityItem tokenName={TOKEN_BORING_BTC_PPTOKEN} />
                  <CityItem tokenName={TOKEN_BORING_LTC_PPTOKEN} />
                  <CityItem tokenName={TOKEN_BORING_DOGE_PPTOKEN} />
                </Box>
              </Box>
              <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
                <Box style={{width: '140px'}}>{t('wild_wild_west')}</Box>
                <Box flexGrow={1}>
                  <CityItem tokenName={TOKEN_OBTC} />
                  <CityItem tokenName={TOKEN_OLTC} />
                  <CityItem tokenName={TOKEN_ODOGE} />
                </Box>
              </Box>
              <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
                <Box style={{width: '140px'}}>{t('satellite_city')}</Box>
                <Box flexGrow={1}>
                  <CityItem tokenName={TOKEN_WETH} />
                  <CityItem tokenName={TOKEN_USDT} />
                  <CityItem tokenName={TOKEN_USDC} />
                  <CityItem tokenName={TOKEN_DAI} />
                  <CityItem tokenName={TOKEN_BORING} />
                </Box>
              </Box>
              <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
                <Box style={{width: '140px'}}>{t('gold_rush')}</Box>
                <Box flexGrow={1}>
                  <PoolRow data={oldBoringPool.boring} />
                  <CityItem tokenName={TOKEN_SUNDER} />
                </Box>
              </Box>
              {/*<Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>*/}
              {/*  <Box style={{width: '140px'}}>Twoway</Box>*/}
              {/*  <Box ml="auto">*/}
              {/*    <Button*/}
              {/*      variant="contained"*/}
              {/*      size="small"*/}
              {/*      color="primary"*/}
              {/*      component="a"*/}
              {/*      href="https://oportal.boringdao.com/farm"*/}
              {/*      target="_blank"*/}
              {/*    >*/}
              {/*      Go*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</Box>*/}
            </>
          ) : (
            <Box mt={6} p={5} display="flex" className={classes.pools}>
              <Grid container spacing={10}>
                <Grid item xs={2}>
                  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    <Skeleton circle={true} width={80} height={80} />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box mb={6}>
                    <Skeleton height={30} />
                  </Box>
                  <Box mb={6}>
                    <Skeleton height={30} />
                  </Box>
                  <Skeleton height={30} />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Container>
  )
}

export default BoringFarm
