import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  InputBase,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import {TOKEN_BNB, TOKEN_BOR, TOKEN_BSC, TOKEN_ETH} from '../../constants/token'
import {getTokenIcon} from '../../constants/icon'
import ArrowRight from '../../assets/images/arrow-right.png'
import {verifyNumericInput} from '../../helpers/verify'
import {useTranslation} from 'react-i18next'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
// @ts-ignore
import validator from 'multicoin-address-validator'
import {toast} from 'react-toastify'
import ToastContent from '../../components/ToastContent'
import {allowance, approveMax} from '../../helpers/erc20'
import {handleError} from '../../helpers'
import {useInterval} from '../../hooks/useInterval'
import {useWeb3React} from '@web3-react/core'
import {getContractAddress} from '../../helpers/web3'
import {CONTRACT_CROSS_LOCK} from '../../constants/contract'
import {BigNumber} from 'bignumber.js'
import {Context} from '../../contexts/BoringProvider'
import {displayBalance} from '../../helpers/display'
import {MaxButton} from '../../components/MaxButton'
import {calculateFee, crossLock} from '../../contracts/cross'
import {useActiveWeb3React} from '../../hooks'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 200px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '18px'
      },
      '& img': {
        marginRight: '15px'
      }
    },
    value: {
      border: '1px solid #D8E0ED',
      background: '#F1F2F6',
      borderRadius: '6px',
      padding: '15px 15px',
      display: 'flex',
      alignItems: 'center',
      color: '#444C66',
      '& img': {
        marginRight: '10px'
      }
    }
  })
)

const Lock = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const ctx = useContext(Context)

  const borAddress = getContractAddress(chainId, TOKEN_BOR)
  const lockAddress = getContractAddress(chainId, CONTRACT_CROSS_LOCK)

  const [amount, setAmount] = useState('')
  const [can, setCan] = useState(true)
  const [doing, setDoing] = useState(false)
  const [aprDoing, setAprDoing] = useState(false)
  const [address, setAddress] = useState('')
  const [userAllowance, setUserAllowance] = useState('')
  const [fee, setFee] = useState('0')
  const [result, setResult] = useState('0')

  const max = () =>
    setAmount(new BigNumber(ctx.balance.bor).dividedBy(Math.pow(10, 18)).toFixed(3, BigNumber.ROUND_DOWN))

  const approved = new BigNumber(userAllowance).gt(ctx.balance.bor)

  useInterval(
    () => {
      allowance(library, borAddress, account, lockAddress)
        .then(setUserAllowance)
        .catch(handleError)
    },
    5000,
    true
  )

  useEffect(() => {
    setCan(false)
    if (amount) {
      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
      if (amountBN.lte(ctx.balance.bor) && amountBN.gt(0)) {
        setCan(true)
      }
    }

    setFee('0')
    setResult('0')
    if (amount !== '') {
      calculateFee(library, chainId, borAddress, amount, 0)
        .then(res => {
          setFee(res[0])
          setResult(res[1])
        })
        .catch(handleError)
    }
  }, [amount, ctx, borAddress, chainId, library])

  const approving = async () => {
    setAprDoing(true)
    await approveMax(library, borAddress, lockAddress, account).catch(handleError)
    setAprDoing(false)
  }

  const lock = async () => {
    // @ts-ignore
    if (!validator.validate(address, TOKEN_ETH)) {
      toast.info(<ToastContent content={`Invalid bsc address`} status={false} />)
      return
    }

    const amountBN = new BigNumber(amount)
    if (amountBN.lt(new BigNumber(1))) {
      toast.info(<ToastContent status={false} content={`The minimum amount is 1`} />)
      return
    }

    try {
      setDoing(true)
      console.log(`Cross lock ${amount} amount to ${address}`)
      await crossLock(library, chainId, account, borAddress, address, amount)
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  return (
    <Container maxWidth={'lg'}>
      <Box mt={{xs: 3, md: 6}}>
        <Card className={classes.main}>
          <Grid container>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Box p={{xs: 6, md: 12}}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box flexShrink={0} flexGrow={1}>
                    <Typography variant={'body2'}>From</Typography>
                    <Box className={classes.value} mt={1}>
                      <img width={16} height={16} src={getTokenIcon(TOKEN_ETH)} alt="eth" />
                      Ethereum
                    </Box>
                  </Box>
                  <img src={ArrowRight} style={{margin: '20px 15px 0'}} width={20} height={20} alt="arrow-right" />
                  <Box flexShrink={0} flexGrow={1}>
                    <Typography variant={'body2'}>To</Typography>
                    <Box className={classes.value} mt={1}>
                      <img width={16} height={16} src={getTokenIcon(TOKEN_BSC)} alt="bnb" />
                      BSC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Box>
                  </Box>
                </Box>
                <Box mt={6} mb={2} className={classes.input} display={'flex'} alignItems={'center'}>
                  <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
                  <InputBase
                    fullWidth={true}
                    placeholder="0.00"
                    value={amount}
                    onChange={(e: any) => verifyNumericInput(e, setAmount)}
                  />
                  <MaxButton onClick={max}>Max</MaxButton>
                </Box>
                <Typography align={'right'} variant={'body2'}>
                  {t('balance')}：{displayBalance(ctx.balance.bor)}
                </Typography>
                <Box mt={4} mb={6} className={classes.input} display={'flex'} alignItems={'center'}>
                  <InputBase
                    fullWidth={true}
                    placeholder="Enter your BSC address"
                    value={address}
                    onChange={(e: any) => setAddress(e.target.value)}
                  />
                </Box>
                <Typography variant={'body2'}>{t('crosschain_fee')}</Typography>
                <Box className={classes.value} mt={1} mb={4}>
                  <img src={getTokenIcon(TOKEN_BOR)} width={16} height={16} alt="bor" />
                  {displayBalance(fee)}
                </Box>
                <Typography variant={'body2'}>{t('you_will_get')}</Typography>
                <Box className={classes.value} mt={1}>
                  <img src={getTokenIcon(TOKEN_BOR)} width={16} height={16} alt="bor" />
                  {displayBalance(result)}
                </Box>
                <Box display={'flex'} mt={6} alignItems={'center'} justifyContent={'center'}>
                  {approved ? (
                    <Button
                      disabled={approved}
                      style={{marginRight: '10px'}}
                      disableElevation={true}
                      size={'large'}
                      variant={'contained'}
                      color={'secondary'}
                    >
                      <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'} />
                      {t('approved')}
                    </Button>
                  ) : (
                    <Button
                      style={{marginRight: '10px'}}
                      disableElevation={true}
                      size={'large'}
                      variant={'contained'}
                      color={'secondary'}
                      onClick={approving}
                    >
                      {aprDoing && <CircularProgress size={14} color={'inherit'} />}
                      {t('approve')}
                    </Button>
                  )}
                  <Button
                    style={{marginLeft: '10px'}}
                    disableElevation={true}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={!can || doing || !approved}
                    onClick={lock}
                  >
                    {doing && <CircularProgress size={14} color={'inherit'} />}
                    {t('confirm')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Container>
  )
}

export default Lock
