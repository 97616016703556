import {getContract} from '../helpers/web3'
import {CONTRACT_BARTER, CONTRACT_BARTER_SWAP} from '../constants/contract'
import {AccountType, ChainIDType} from './type'
import {TOKEN_RENBTC} from '../constants/token'
import BigNumber from 'bignumber.js'

// 0 -> obtc, 1 -> renBTC, 2 -> wBTC
export const estimate = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  type: string,
  amount: string
) => {
  amount = amount ? amount : '0'
  const contract = getContract(provider, chainID, CONTRACT_BARTER)
  const num = type === TOKEN_RENBTC ? 1 : 2
  const value = new BigNumber(amount).multipliedBy(Math.pow(10, 8)).toNumber()
  return contract.methods.estimate(num, value).call()
}

export const trade = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  type: string,
  amount: string,
  minDY: string
) => {
  amount = amount ? amount : '0'
  const contract = getContract(provider, chainID, CONTRACT_BARTER)
  const value = new BigNumber(amount).multipliedBy(Math.pow(10, 8)).toString()
  const num = type === TOKEN_RENBTC ? 1 : 2
  return contract.methods.trade(num, value, minDY).send({from: account})
}

export const fee = async (provider: any, chainID: ChainIDType) => {
  const contract = getContract(provider, chainID, CONTRACT_BARTER_SWAP)
  return contract.methods.fee().call()
}
