import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {useInterval} from '../hooks/useInterval'
import {getPrice} from '../contracts/oracle'
import {handleError, handleErrorAndZero} from '../helpers'
import {balanceOf} from '../helpers/erc20'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {
  TOKEN_BAND,
  TOKEN_BOR,
  TOKEN_BORING,
  TOKEN_BTC,
  TOKEN_BTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_DOGE,
  TOKEN_DOGE_PPTOKEN,
  TOKEN_LINK,
  TOKEN_LTC,
  TOKEN_LTC_PPTOKEN,
  TOKEN_NEST,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH,
  TOKEN_YFI,
  TOKEN_YFII
} from '../constants/token'
import {totalSupplyWithName} from '../contracts'
import {mintNetworkFee} from '../contracts/param'
import {boringCanIssueAmount, boringPledgeRatio} from '../contracts/tunnel'
import {getAssetMultiSignAddress} from '../contracts/address'
import {useActiveWeb3React} from '../hooks'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import {CHAIN_ETHER} from '@w3u/chains'
import MulticallABI from '../abi/Multicall.json'
import {CONTRACT_ADDRESS_BOOK, CONTRACT_ORACLE, CONTRACT_PARAM_BOOK, getBoringTunnelName} from '../constants/contract'
import {
  assetMultiSignAddressABI,
  balanceOfABI,
  canIssueAmountABI,
  getCall,
  getPriceABI,
  params2ABI,
  pledgeRatioABI,
  totalSupplyABI
} from '../constants/abi'
import {toBytes32} from '../helpers/solidity'
import {BigNumber} from 'bignumber.js'

type tokenPrice = {
  bor: string
  btc: string
  ltc: string
  dai: string
  usdc: string
  usdt: string
  weth: string
  yfi: string
  yfii: string
  link: string
  nest: string
  band: string
  doge: string
}

type balance = {
  bor: string
  oBTC: string
  oLTC: string
  pptOBTC: string
  pptOLTC: string
  oDOGE: string
  pptODOGE: string
  boring: string
}

type total = {
  bor: string
  oBTC: string
  oLTC: string
  oDOGE: string
}

type networkFee = {
  btc: string
  ltc: string
  doge: string
}

type canIssue = {
  btc: string
  ltc: string
  doge: string
}

type tokenPledgeRatio = {
  btc: string
  ltc: string
  doge: string
}

type multiAddress = {
  btc: string
  ltc: string
  doge: string
}

export interface BoringContext {
  price: tokenPrice
  balance: balance
  total: total
  networkFee: networkFee
  canIssue: canIssue
  pledgeRatio: tokenPledgeRatio
  multiAddress: multiAddress
}

const initContext = () => {
  return {
    price: {
      bor: '0',
      btc: '0',
      ltc: '0',
      dai: '0',
      usdc: '0',
      usdt: '0',
      weth: '0',
      yfi: '0',
      yfii: '0',
      link: '0',
      nest: '0',
      band: '0',
      doge: '0'
    },
    balance: {bor: '0', oBTC: '0', oLTC: '0', pptOBTC: '0', pptOLTC: '0', oDOGE: '0', pptODOGE: '0', boring: '0'},
    total: {bor: '0', oBTC: '0', oLTC: '0', oDOGE: '0'},
    networkFee: {btc: '0', ltc: '0', doge: '0'},
    canIssue: {btc: '0', ltc: '0', doge: '0'},
    pledgeRatio: {btc: '0', ltc: '0', doge: '0'},
    multiAddress: {btc: '', ltc: '', doge: ''}
  }
}

export const Context = createContext<BoringContext>(initContext())

const BoringProvider = ({children}: {children: ReactNode}) => {
  const {library, chainId, account, connector} = useActiveWeb3React()
  const [ctx, setCtx] = useState<BoringContext>(initContext())

  const chainID = chainId ?? CHAIN_ETHER
  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const web3 = getWeb3(library)
  const oracleAddress = getContractAddress(chainID, CONTRACT_ORACLE)
  const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
  const paramAddress = getContractAddress(chainID, CONTRACT_PARAM_BOOK)
  const bookAddress = getContractAddress(chainID, CONTRACT_ADDRESS_BOOK)

  const request = useCallback(() => {
    if (chainId !== CHAIN_ETHER) return
    if (!library) return
    console.log('Fetching======')
    const borAddress = getContractAddress(chainId, TOKEN_BOR)
    const obtcAddress = getContractAddress(chainId, TOKEN_OBTC)
    const oltcAddress = getContractAddress(chainId, TOKEN_OLTC)
    const pptOBTCAddress = getContractAddress(chainId, TOKEN_BTC_PPTOKEN)
    const pptOLTCAddress = getContractAddress(chainId, TOKEN_LTC_PPTOKEN)
    const odogeAddress = getContractAddress(chainId, TOKEN_ODOGE)
    const pptODOGEAddress = getContractAddress(chainId, TOKEN_DOGE_PPTOKEN)
    const boringAddress = getContractAddress(chainId, TOKEN_BORING)

    const btcTunnelAddress = getContractAddress(chainID, getBoringTunnelName(TOKEN_BTC))
    const ltcTunnelAddress = getContractAddress(chainID, getBoringTunnelName(TOKEN_LTC))
    const dogeTunnelAddress = getContractAddress(chainID, getBoringTunnelName(TOKEN_DOGE))

    const calls = [
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_BOR)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_BTC)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_LTC)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_DAI)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_USDC)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_USDT)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_WETH)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_YFI)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_YFII)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_LINK)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_NEST)]),
      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_BAND)]),

      getCall(web3, borAddress, balanceOfABI, [defaultAccount]),
      getCall(web3, obtcAddress, balanceOfABI, [defaultAccount]),
      getCall(web3, oltcAddress, balanceOfABI, [defaultAccount]),
      getCall(web3, pptOBTCAddress, balanceOfABI, [defaultAccount]),
      getCall(web3, pptOLTCAddress, balanceOfABI, [defaultAccount]),

      getCall(web3, borAddress, totalSupplyABI, []),
      getCall(web3, obtcAddress, totalSupplyABI, []),
      getCall(web3, oltcAddress, totalSupplyABI, []),

      getCall(web3, paramAddress, params2ABI, [toBytes32(TOKEN_BTC), toBytes32('network_fee')]),
      getCall(web3, paramAddress, params2ABI, [toBytes32(TOKEN_LTC), toBytes32('network_fee')]),

      getCall(web3, btcTunnelAddress, canIssueAmountABI, []),
      getCall(web3, ltcTunnelAddress, canIssueAmountABI, []),

      getCall(web3, btcTunnelAddress, pledgeRatioABI, []),
      getCall(web3, ltcTunnelAddress, pledgeRatioABI, []),

      getCall(web3, bookAddress, assetMultiSignAddressABI, [TOKEN_BTC]),
      getCall(web3, bookAddress, assetMultiSignAddressABI, [TOKEN_LTC]),

      getCall(web3, oracleAddress, getPriceABI, [toBytes32(TOKEN_DOGE)]),
      getCall(web3, odogeAddress, balanceOfABI, [defaultAccount]),
      getCall(web3, pptODOGEAddress, balanceOfABI, [defaultAccount]),
      getCall(web3, odogeAddress, totalSupplyABI, []),
      getCall(web3, paramAddress, params2ABI, [toBytes32(TOKEN_DOGE), toBytes32('network_fee')]),
      getCall(web3, dogeTunnelAddress, canIssueAmountABI, []),
      getCall(web3, dogeTunnelAddress, pledgeRatioABI, []),
      getCall(web3, bookAddress, assetMultiSignAddressABI, [TOKEN_DOGE]),
      getCall(web3, boringAddress, balanceOfABI, [defaultAccount])
    ]

    try {
      multicallContract.methods
        .aggregate(calls)
        .call()
        .then((values: any) => {
          setCtx({
            price: {
              bor: String(web3.eth.abi.decodeParameter('uint256', values[1][0])),
              btc: String(web3.eth.abi.decodeParameter('uint256', values[1][1])),
              ltc: String(web3.eth.abi.decodeParameter('uint256', values[1][2])),
              dai: String(web3.eth.abi.decodeParameter('uint256', values[1][3])),
              usdc: String(web3.eth.abi.decodeParameter('uint256', values[1][4])),
              usdt: String(web3.eth.abi.decodeParameter('uint256', values[1][5])),
              weth: String(web3.eth.abi.decodeParameter('uint256', values[1][6])),
              yfi: String(web3.eth.abi.decodeParameter('uint256', values[1][7])),
              yfii: String(web3.eth.abi.decodeParameter('uint256', values[1][8])),
              link: String(web3.eth.abi.decodeParameter('uint256', values[1][9])),
              nest: String(web3.eth.abi.decodeParameter('uint256', values[1][10])),
              band: String(web3.eth.abi.decodeParameter('uint256', values[1][11])),
              doge: String(web3.eth.abi.decodeParameter('uint256', values[1][28]))
            },
            balance: {
              bor: String(web3.eth.abi.decodeParameter('uint256', values[1][12])),
              oBTC: String(web3.eth.abi.decodeParameter('uint256', values[1][13])),
              oLTC: String(web3.eth.abi.decodeParameter('uint256', values[1][14])),
              pptOBTC: String(web3.eth.abi.decodeParameter('uint256', values[1][15])),
              pptOLTC: String(web3.eth.abi.decodeParameter('uint256', values[1][16])),
              oDOGE: String(web3.eth.abi.decodeParameter('uint256', values[1][29])),
              pptODOGE: String(web3.eth.abi.decodeParameter('uint256', values[1][30])),
              boring: String(web3.eth.abi.decodeParameter('uint256', values[1][36]))
            },
            total: {
              bor: String(web3.eth.abi.decodeParameter('uint256', values[1][17])),
              oBTC: String(web3.eth.abi.decodeParameter('uint256', values[1][18])),
              oLTC: String(web3.eth.abi.decodeParameter('uint256', values[1][19])),
              oDOGE: String(web3.eth.abi.decodeParameter('uint256', values[1][31]))
            },
            networkFee: {
              btc: String(web3.eth.abi.decodeParameter('uint256', values[1][20])),
              ltc: String(web3.eth.abi.decodeParameter('uint256', values[1][21])),
              doge: String(web3.eth.abi.decodeParameter('uint256', values[1][32]))
            },
            canIssue: {
              btc: String(web3.eth.abi.decodeParameter('uint256', values[1][22])),
              ltc: String(web3.eth.abi.decodeParameter('uint256', values[1][23])),
              doge: String(web3.eth.abi.decodeParameter('uint256', values[1][33]))
            },
            pledgeRatio: {
              btc: String(web3.eth.abi.decodeParameter('uint256', values[1][24])),
              ltc: String(web3.eth.abi.decodeParameter('uint256', values[1][25])),
              doge: String(web3.eth.abi.decodeParameter('uint256', values[1][34]))
            },
            multiAddress: {
              btc: String(web3.eth.abi.decodeParameter('string', values[1][26])),
              ltc: String(web3.eth.abi.decodeParameter('string', values[1][27])),
              doge: String(web3.eth.abi.decodeParameter('string', values[1][35]))
            }
          })
        })
        .catch((e: any) => console.error('Fetching all: ', e))
    } catch (e) {
      console.error('Boring provider: ', e)
    }
  }, [library, chainId, account])

  useEffect(() => {
    if (library) {
      request()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      console.log('Boring', ms)
      let timer = setInterval(request, ms)
      return () => clearInterval(timer)
    }
  }, [library, request])

  return <Context.Provider value={ctx}>{children}</Context.Provider>
}

export default BoringProvider
