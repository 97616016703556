import {Theme, Tooltip, withStyles} from '@material-ui/core'

export const BoringTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    background: 'radial-gradient(circle, rgba(223,232,254,1) 0%, rgba(252,255,251,1) 45%, rgba(255,242,250,1) 80%);',
    // background: 'linear-gradient(92deg, rgba(227, 255, 223, 0.3), rgba(112, 146, 255, 0.25), rgba(251, 130, 255, 0.19), rgba(255, 253, 112, 0.1), rgba(129, 255, 112, 0.04), rgba(112, 146, 255, 0));',
    boxShadow: '0px 1px 7px 0px rgba(228, 232, 245, 0.7)',
    padding: '15px',
    color: '#7B89B7',
    maxWidth: 280,
    fontSize: '12px',
    // hyphens: 'auto',
    // textAlign: 'justify',
    borderRadius: '10px'
  },
  arrow: {
    backgroundColor: '#FCFFFB',
    color: '#FCFFFB'
    // border: '1px solid #dadde9',
  }
}))(Tooltip)
