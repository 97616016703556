import React, {useContext, useState} from 'react'
import {
  Box,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Link
} from '@material-ui/core'
import HomeMint from '../../components/Home/Mint'
import HomeBarter from '../../components/Home/Barter'
import HomePledge from '../../components/Home/Pledge'
import HomeFarm from '../../components/Home/Farm'
import {TOKEN_BTC} from '../../constants/token'
import {getMapTokenShowName} from '../../constants/icon'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'
import HomeMobileMint from '../../assets/images/home_mobile_mint.png'
import HomeMobilePledge from '../../assets/images/home_mobile_pledge.png'
import HomeMobileFarm from '../../assets/images/home_mobile_farm.png'
import HomeMobileBarter from '../../assets/images/home_mobile_barter.png'
import PledgeSelectModal from '../../components/Models/Pledge/Select'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {isBSC, isEthereum} from '../../helpers'
import BSCHomeFarm from '../../components/Home/BSCFarm'
import HomeMobileSwitch from '../../assets/images/cross.png'
import HomeCross from '../../components/Home/Cross'
import {useTitle} from 'react-use'
import FarmSelectModal from '../../components/Models/FarmSelect'
import {PriceContext} from '../../contexts/PriceProvider'
import HomeBoringChef from '../../components/Home/BoringChef'
import Logo33 from '../../assets/images/33/logo33.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    home: {
      '& .MuiTypography-h6': {
        marginRight: '10px'
      }
    },
    pure: {
      background: '#fff',
      height: `${isBSC() ? 'calc(100vh - 120px)' : 'calc(100vh)'}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center'
    }
  })
)

const Home = () => {
  useTitle('BoringDAO App | Dashboard')
  const classes = useStyles()
  const theme = useTheme()
  const {t} = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openPledge, setOpenPledge] = useState(false)
  const [type, setType] = useState(TOKEN_BTC)
  const [openFarm, setOpenFarm] = useState(false)
  console.log('home')

  return (
    <>
      <PledgeSelectModal setType={setType} open={openPledge} onClose={() => setOpenPledge(false)} />
      <FarmSelectModal open={openFarm} onClose={() => setOpenFarm(false)} />
      {isMobile ? (
        <Box px={6} pt={4} pb={8} className={classes.pure}>
          <NavLink to={'/mint'}>
            <img width={80} src={HomeMobileMint} alt="bor" />
            <Typography variant={'subtitle1'} color={'error'}>
              {t('mint')}
            </Typography>
          </NavLink>
          <Box onClick={() => setOpenPledge(true)}>
            <img width={80} src={HomeMobilePledge} alt="bor" />
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'subtitle1'} color={'primary'}>
                {t('tunnel')}({getMapTokenShowName(type)})
              </Typography>
              <ArrowDropDownIcon fontSize={'small'} color={'primary'} />
            </Box>
          </Box>
          {isEthereum() && (
            <NavLink to={'/farm/boring'}>
              <img width={80} src={HomeMobileFarm} alt="bor" />
              <Typography variant={'subtitle1'} color={'error'}>
                {t('farm')}
              </Typography>
            </NavLink>
            // <Link onClick={() => setOpenFarm(true)}>
            //   <img width={80} src={HomeMobileFarm} alt="bor" />
            //   <Typography variant={'subtitle1'} style={{color: '#D881EB'}}>
            //{/*{t('farm')}*/}
            // </Typography>
            // </Link>
          )}
          {!isEthereum() && (
            <NavLink to={'/farm'}>
              <img width={80} src={HomeMobileFarm} alt="bor" />
              <Typography variant={'subtitle1'} style={{color: '#D881EB'}}>
                {t('farm')}
              </Typography>
            </NavLink>
          )}
          {isEthereum() ? (
            <Link href="https://33bond.boringdao.com/bond" target="_blank">
              <img width={80} src={Logo33} alt="bor" />
              <Typography variant={'subtitle1'} color={'secondary'}>
                BORING, BORING
              </Typography>
            </Link>
          ) : (
            <></>
          )}
          <NavLink to={'/switch'}>
            <img width={70} src={HomeMobileSwitch} alt="bor" />
            <Typography variant={'subtitle1'} color={'secondary'}>
              {t('cross', {from: isBSC() ? 'BSC' : 'ETH', to: isBSC() ? 'ETH' : 'BSC'})}
            </Typography>
          </NavLink>
        </Box>
      ) : (
        <Container maxWidth={'lg'}>
          <Box mt={6} className={classes.home}>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <Box mb={6}>
                  <HomeMint />
                </Box>
                {isEthereum() && <HomeCross />}
              </Grid>
              <Grid item md={6}>
                <Box mb={6}>
                  <HomePledge />
                </Box>
                {isEthereum() && (
                  <Box mb={6}>
                    {/*<HomeBarter />*/}
                    <HomeBoringChef />
                  </Box>
                )}
                {isEthereum() && <HomeFarm />}
                {isBSC() && <BSCHomeFarm />}
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </>
  )
}

// Home.whyDidYouRender = true

export default Home
