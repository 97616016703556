import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: '#7B89B7',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      marginLeft: 'auto',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      '& svg': {
        fontSize: '12px',
        marginLeft: '3px'
      }
    }
  })
)

const ActionLink = ({to, title}: {to: string; title: string}) => {
  const classes = useStyles()

  return (
    <NavLink to={to} className={classes.link}>
      {title}
      <ArrowForwardIcon />
    </NavLink>
  )
}

export default ActionLink
