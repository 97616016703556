import {useCallback, useEffect, useState} from 'react'
import {BigNumber} from 'bignumber.js'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {CONTRACT_BORING_CHEF_LOCK} from '../constants/contract'
import {useActiveWeb3React} from './index'
import {CHAIN_ETHER} from '@w3u/chains'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import MulticallABI from '../abi/Multicall.json'
import {getCall, mainAmountABI, rewardPerSecondABI} from '../constants/abi'

type Datum = {
  apy: string
}

export const initDatum = (tokenName: string): Datum => {
  return {
    apy: '0'
  }
}

export const calc33ROI = (boringPerSecond: string, totalStaked: string): string => {
  const lockValue = new BigNumber(totalStaked)
  const m = new BigNumber(boringPerSecond).multipliedBy(3600 * 24)

  if (lockValue.eq(0)) return '0.00'

  const result = m.div(lockValue)

  return result.toFixed(4)
}

export const useBoringChef = (tokenName: string) => {
  const [data, setData] = useState<Datum>(initDatum(tokenName))
  const {account, library, chainId, connector} = useActiveWeb3React()

  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const web3 = getWeb3(library)

  const get = useCallback(async () => {
    try {
      const cityAddress = getContractAddress(chainId, CONTRACT_BORING_CHEF_LOCK)

      const calls = [
        getCall(web3, cityAddress, rewardPerSecondABI, []),
        getCall(web3, cityAddress, mainAmountABI, []),
        getCall(web3, cityAddress, rewardPerSecondABI, [])
      ]

      multicallContract.methods
        .aggregate(calls)
        .call()
        .then((values: any) => {
          console.log(values)
          try {
            const rewardPerSecond = String(web3.eth.abi.decodeParameter('uint256', values[1][0]))
            const mainAmount = String(web3.eth.abi.decodeParameter('uint256', values[1][1]))
            const pendingReward = String(web3.eth.abi.decodeParameter('uint256', values[1][2]))
            const totalStakedAmount = new BigNumber(mainAmount).plus(pendingReward).toString()

            const roi = calc33ROI(rewardPerSecond, totalStakedAmount)
            const tmp = new BigNumber(roi).plus(1)
            const apy = tmp
              .pow(365)
              .multipliedBy(100)
              .toFixed(2)
            // @ts-ignore
            setData({
              apy: apy
            })
          } catch (e) {
            console.error('useBoringChef: ', e)
          }
        })
        .catch((e: any) => console.error('useBoringChef multicall: ', e))
    } catch (e) {
      console.error(e)
    }
  }, [library, account, chainId, tokenName])

  useEffect(() => {
    if (library) {
      get()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      // console.log(connector)
      // console.log(connector && connector.constructor.name)
      console.log('City pool ', ms)
      let timer = setInterval(get, ms)
      return () => clearInterval(timer)
    }
  }, [library, setData, get])

  return data
}
