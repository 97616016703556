import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  InputBase,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {getMapTokenShowName, getTokenIcon} from '../../constants/icon'
import {
  TOKEN_BOR,
  TOKEN_BORING_BTC_PPTOKEN,
  TOKEN_BORING_DOGE_PPTOKEN,
  TOKEN_BORING_LTC_PPTOKEN,
  TOKEN_BTC,
  TOKEN_LTC
} from '../../constants/token'
import {verifyNumericInput} from '../../helpers/verify'
import {useInterval} from '../../hooks/useInterval'
import {
  boringRedeemLockTxLimit,
  boringUserLockLength,
  boringWithdrawUnlock,
  userBoringLockAmount,
  userBoringPledgeAmount
} from '../../contracts/tunnel'
import {useWeb3React} from '@web3-react/core'
import {displayBalance} from '../../helpers/display'
import {BigNumber} from 'bignumber.js'
import Question from '../../assets/images/question.png'
import {BoringTooltip} from '../BoringTooltip'
import {boringRedeem} from '../../contracts/dao'
import {AssetName} from '../../contracts/address'
import {useTranslation} from 'react-i18next'
import {MaxButton} from '../MaxButton'
import {toast} from 'react-toastify'
import ToastContent from '../ToastContent'
import {getContractAddress} from '../../helpers/web3'
import {balanceOf} from '../../helpers/erc20'
import {handleError} from '../../helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dotArrow: {
      margin: '0 20px'
    },
    mintNotice: {
      paddingLeft: '15px',
      lineHeight: '1.8'
    },
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '5px'
      }
    },
    value: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      background: '#F1F2F6',
      padding: '15px 20px',
      fontSize: '14px',
      color: '#444C66',
      '& img': {
        marginRight: '10px'
      }
    },
    desc: {
      color: '#777C8B',
      '& img': {
        cursor: 'pointer',
        marginLeft: '5px'
      }
    },
    popover: {
      pointerEvents: 'none'
    },
    btn: {
      minWidth: '40px',
      height: '20px',
      marginLeft: '10px'
    }
  })
)

const BoringRedeem = ({type}: {type: string}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {t} = useTranslation()
  const classes = useStyles()

  const {library, chainId, account} = useWeb3React()
  const [amount, setAmount] = useState('')
  const [lock, setLock] = useState('')
  const [unlock, setUnlock] = useState('')
  const [canClaim, setCanClaim] = useState(false)
  const [userPledge, setUserPledge] = useState('0')

  const [can, setCan] = useState(false)
  const [doing, setDoing] = useState(false)

  const [pptBTCBalance, setPPTBTCBalance] = useState('0')
  const [pptLTCBalance, setPPTLTCBalance] = useState('0')
  const [pptDogeBalance, setPPTDogeBalance] = useState('0')

  const pptPrice = () => {
    return type === TOKEN_BTC ? pptBTCBalance : type === TOKEN_LTC ? pptLTCBalance : pptDogeBalance
  }

  useEffect(() => {
    const pptOBTCAddress = getContractAddress(chainId, TOKEN_BORING_BTC_PPTOKEN)
    const pptOLTCAddress = getContractAddress(chainId, TOKEN_BORING_LTC_PPTOKEN)
    const pptODOGEAddress = getContractAddress(chainId, TOKEN_BORING_DOGE_PPTOKEN)

    balanceOf(library, pptOBTCAddress, account)
      .then(res => setPPTBTCBalance(res))
      .catch(handleError)
    balanceOf(library, pptOLTCAddress, account)
      .then(res => setPPTLTCBalance(res))
      .catch(handleError)
    balanceOf(library, pptODOGEAddress, account)
      .then(res => setPPTDogeBalance(res))
      .catch(handleError)
  }, [chainId, library, account])

  useEffect(() => {
    setCan(false)
    const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
    if (amountBN.gt(0) && amountBN.lte(userPledge)) {
      setCan(true)
    }
  }, [amount, userPledge])

  useInterval(
    () => {
      if (account) {
        userBoringPledgeAmount(library, chainId, account, type)
          .then(res => setUserPledge(res))
          .catch(e => console.error(e))
        userBoringLockAmount(library, chainId, account, type)
          .then(res => {
            setLock(res[0])
            setUnlock(res[1])
            if (new BigNumber(res[1]).gt(0)) {
              setCanClaim(true)
            }
          })
          .catch(e => console.error(e))
      }
    },
    3000,
    true
  )

  const onRedeem = async () => {
    try {
      const lockN = await boringUserLockLength(library, chainId, account, type as AssetName)
      const limitN = await boringRedeemLockTxLimit(library, chainId, type as AssetName)
      console.log(lockN)
      console.log(limitN)
      if (new BigNumber(lockN).gte(limitN)) {
        toast.info(<ToastContent status={false} content={`Redeem count is greater than limit`} />, {
          autoClose: 2000
        })
        return
      }
      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
      if (amountBN.gt(pptPrice())) {
        toast.info(<ToastContent status={false} content={`You don't have enough PP Token to redeem`} />, {
          autoClose: 2000
        })
        return
      }
      setDoing(true)
      await boringRedeem(library, chainId, account, amount, type as AssetName)
      console.log(`=====> Redeem from ${type} tunnel successfully`)
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  const withdraw = async () => {
    try {
      await boringWithdrawUnlock(library, chainId, account, type)
      console.log(`=====> Withdraw unlock successfully`)
    } catch (e) {
      console.error(e)
    }
  }

  const max = () => setAmount(new BigNumber(userPledge).dividedBy(Math.pow(10, 18)).toString())

  return (
    <>
      <Box textAlign={'center'} mb={4}>
        {isMobile && <Typography variant={'subtitle1'}>{t('redeem')}</Typography>}
        {!isMobile && <Typography variant={'subtitle2'}>{t('redeem')}</Typography>}
      </Box>
      <Box mb={4} className={classes.input} display={'flex'} alignItems={'center'}>
        <Box display="flex" alignItems="center" mr={2}>
          <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
          <Box> BORING </Box>
        </Box>
        <InputBase
          value={amount}
          fullWidth={true}
          placeholder="0.00"
          onChange={(e: any) => verifyNumericInput(e, setAmount)}
        />
        <MaxButton onClick={max}>Max</MaxButton>
      </Box>
      <Box mb={{xs: 4, md: 10}} className={classes.desc}>
        <Box mb={2} display={'flex'} alignItems={'center'}>
          {t('pptoken_cost', {map_type: getMapTokenShowName(type)})}
          <BoringTooltip
            enterTouchDelay={20}
            arrow
            title={<Typography variant={'body2'}>{t('pptoken_cost_tooltip')}</Typography>}
          >
            <img width={12} height={12} src={Question} alt="question" />
          </BoringTooltip>
          <Box ml={'auto'}>
            {amount}({t('balance')}: {displayBalance(pptPrice())})
          </Box>
        </Box>
        <Box mb={2} display={'flex'} alignItems={'center'}>
          {t('your_bor_staked')}
          <Box ml={'auto'}>{displayBalance(userPledge)} BORING</Box>
        </Box>
        <Box mb={2} display={'flex'} alignItems={'center'}>
          {t('vesting')}({t('lock_for_hours')})<Box ml={'auto'}>{displayBalance(lock)} BORING</Box>
        </Box>
        <Box mb={2} display={'flex'} alignItems={'center'}>
          {t('total_claimable')}
          <Box ml={'auto'}>
            {displayBalance(unlock)} BORING
            <Button
              onClick={withdraw}
              variant="outlined"
              size="small"
              color="primary"
              className={classes.btn}
              disabled={!canClaim}
            >
              {t('claim')}
            </Button>
          </Box>
        </Box>
      </Box>
      {account && (
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            disabled={!can || doing}
            disableElevation={true}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={onRedeem}
          >
            {doing && <CircularProgress size={16} color={'inherit'} />}
            {t('redeem')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default BoringRedeem
