import axios from 'axios'

export const baseUrl = 'https://api.coingecko.com/api/v3'

//axios 的实例及拦截器配置
const axiosInstance = axios.create({
  baseURL: baseUrl,
})

axiosInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    console.log(err, 'request error')
    throw err
  }
)

export { axiosInstance }
