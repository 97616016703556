import {useCallback, useContext, useEffect, useState} from 'react'
import {BigNumber} from 'bignumber.js'
import {TOKEN_BORING, TOKEN_BORING_BTC_PPTOKEN, TokenModal} from '../constants/token'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {CONTRACT_SATELLITE_CITY} from '../constants/contract'
import {isEthereum} from '../helpers'
import {getTokenDecimals} from '../constants/helper'
import {Context} from '../contexts/BoringProvider'
import {PriceContext} from '../contexts/PriceProvider'
import {useActiveWeb3React} from './index'
import {CHAIN_ETHER} from '@w3u/chains'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import MulticallABI from '../abi/Multicall.json'
import {
  allowanceABI,
  balanceOfABI,
  boringPerBlockABI,
  getCall,
  pendingBoringABI,
  poolInfoABI,
  totalAllocPointABI,
  totalSupplyABI,
  userInfoABI
} from '../constants/abi'

export type Datum = {
  tokenName: string
  apy: string
  userTokenBalance: string
  userStakedAmount: string
  totalStakedAmount: string
  unlockEarned: string
  lockEarned: string
  userAllowance: string
  expired?: boolean
}

export const initDatum = (tokenName: string): Datum => {
  return {
    tokenName: tokenName,
    apy: '0',
    userTokenBalance: '0',
    userStakedAmount: '0',
    totalStakedAmount: '0',
    unlockEarned: '0',
    lockEarned: '0',
    userAllowance: '0'
  }
}

export const useCityPool = (tokenName: string) => {
  const [data, setData] = useState<Datum>(initDatum(tokenName))
  const {account, library, chainId, connector} = useActiveWeb3React()
  const ctx = useContext(Context)
  const priceCtx = useContext(PriceContext)
  const priceID = TokenModal[tokenName].tokenPriceID
  const ctxPriceName = TokenModal[tokenName].ctxPriceName

  const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const web3 = getWeb3(library)

  const calcAPY = useCallback((rate: string, lockValue: BigNumber, boringPrice: string) => {
    const bp = new BigNumber(boringPrice)
    const br = new BigNumber(web3.utils.fromWei(rate))
    const a = bp.multipliedBy(br).multipliedBy(365 * 3600 * 24)
    const lockBN = new BigNumber(lockValue)
    if (lockBN.eq(new BigNumber('0'))) {
      return '0.00'
    }
    const result = a.div(lockBN).multipliedBy(100)
    return result.toFixed(2)
  }, [])

  const get = useCallback(async () => {
    try {
      const pid = TokenModal[tokenName].pid

      const tokenAddress = getContractAddress(chainId, tokenName)
      const cityAddress = getContractAddress(chainId, CONTRACT_SATELLITE_CITY)

      const calls = [
        getCall(web3, tokenAddress, balanceOfABI, [defaultAccount]),
        getCall(web3, cityAddress, userInfoABI, [pid, defaultAccount]),
        getCall(web3, tokenAddress, balanceOfABI, [cityAddress]),
        getCall(web3, tokenAddress, allowanceABI, [defaultAccount, cityAddress]),
        getCall(web3, cityAddress, poolInfoABI, [pid]),
        getCall(web3, cityAddress, totalAllocPointABI, []),
        getCall(web3, cityAddress, boringPerBlockABI, []),
        getCall(web3, tokenAddress, totalSupplyABI, []),
        getCall(web3, cityAddress, pendingBoringABI, [pid, defaultAccount])
      ]

      multicallContract.methods
        .aggregate(calls)
        .call()
        .then((values: any) => {
          try {
            // @ts-ignore
            const price = priceCtx[ctxPriceName]
            const totalStaking = String(web3.eth.abi.decodeParameter('uint256', values[1][2]))
            const lockValue = new BigNumber(totalStaking)
              .multipliedBy(price)
              .dividedBy(Math.pow(10, getTokenDecimals(tokenName)))
            const four = web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256'],
              values[1][4]
            ) as any
            const point = four[1]
            const totalPoint = String(web3.eth.abi.decodeParameter('uint256', values[1][5]))
            const rate = new BigNumber(point)
              .dividedBy(totalPoint)
              .multipliedBy(String(web3.eth.abi.decodeParameter('uint256', values[1][6])))
              .dividedBy(isEthereum() ? 15 : 3)

            const first = web3.eth.abi.decodeParameters(['uint256', 'uint256'], values[1][1])

            setData({
              tokenName: tokenName,
              apy: calcAPY(rate.toFixed(0), lockValue, priceCtx[TOKEN_BORING]),
              userTokenBalance: String(web3.eth.abi.decodeParameter('uint256', values[1][0])),
              userStakedAmount: first[0],
              totalStakedAmount: String(web3.eth.abi.decodeParameter('uint256', values[1][2])),
              unlockEarned: String(web3.eth.abi.decodeParameter('uint256', values[1][8])),
              lockEarned: '0',
              userAllowance: String(web3.eth.abi.decodeParameter('uint256', values[1][3])),
              expired: Number(point) === 0
            })
          } catch (e) {
            console.error(e)
          }
        })
        .catch((e: any) => console.error('CityPool multicall: ', e))
    } catch (e) {
      console.error(e)
    }
  }, [library, account, chainId, calcAPY, priceID, tokenName, ctx, priceCtx])

  useEffect(() => {
    if (library) {
      get()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      // console.log(connector)
      // console.log(connector && connector.constructor.name)
      console.log('City pool ', ms)
      let timer = setInterval(get, ms)
      return () => clearInterval(timer)
    }
  }, [library, setData, get])

  return data
}
