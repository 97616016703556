import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, CircularProgress, Dialog, InputBase, makeStyles, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {useInterval} from '../../../../hooks/useInterval'
import {getContractAddress} from '../../../../helpers/web3'
import {TOKEN_BOR} from '../../../../constants/token'
import {getBoringDAOName} from '../../../../constants/contract'
import {allowance, approveMax} from '../../../../helpers/erc20'
import {handleError} from '../../../../helpers'
import {totalPledge} from '../../../../contracts/tunnel'
import {useWeb3React} from '@web3-react/core'
import {displayBalance} from '../../../../helpers/display'
import {getTokenIcon} from '../../../../constants/icon'
import {verifyNumericInput} from '../../../../helpers/verify'
import {MaxButton} from '../../../MaxButton'
import {BigNumber} from 'bignumber.js'
import {Context} from '../../../../contexts/BoringProvider'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {pledge} from '../../../../contracts/dao'
import {AssetName} from '../../../../contracts/address'
import {LTCThreshold} from '../../../../constants'
import {useActiveWeb3React, useMountedRef} from '../../../../hooks'

const useStyles = makeStyles({
  type: {
    cursor: 'pointer',
    color: '#363636',
    '&:hover': {
      color: '#1164fb',
      '& $logo': {
        border: '1px solid #1164FB'
      }
    }
  },
  input: {
    border: '1px solid #D8E0ED',
    borderRadius: '6px',
    padding: '6px 15px',
    '& input': {
      fontSize: '20px'
    },
    '& img': {
      marginRight: '15px'
    }
  },
  logo: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e1e3e5',
    borderRadius: '20px'
  }
})

const PledgePrepare = ({open, onClose, type}: {open: boolean; onClose: any; type: string}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const mountedRef = useMountedRef()
  const {library, account, chainId} = useActiveWeb3React()
  const ctx = useContext(Context)

  const [pledgeAmount, setPledgeAmount] = useState('0')
  const [userAllowance, setUserAllowance] = useState('0')
  const [amount, setAmount] = useState('')
  const [can, setCan] = useState(false)
  const [doing, setDoing] = useState(false)

  const max = () => setAmount(new BigNumber(ctx.balance.bor).dividedBy(Math.pow(10, 18)).toString())
  const approved = new BigNumber(userAllowance).gt(ctx.balance.bor)

  useEffect(() => {
    setCan(false)
    if (amount) {
      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
      if (amountBN.lte(ctx.balance.bor) && amountBN.gt(0)) {
        setCan(true)
      }
    }
  }, [amount, ctx])

  useInterval(
    () => {
      if (account) {
        const borAddress = getContractAddress(chainId, TOKEN_BOR)
        const daoAddress = getContractAddress(chainId, getBoringDAOName(type))
        allowance(library, borAddress, account, daoAddress)
          .then(res => {
            if (mountedRef.current) {
              setUserAllowance(res)
            }
          })
          .catch(handleError)
        totalPledge(library, chainId, type)
          .then(res => {
            if (mountedRef.current) {
              setPledgeAmount(res)
            }
          })
          .catch(handleError)
      }
    },
    3000,
    true
  )

  const pledgeApprove = () => {
    const borAddress = getContractAddress(chainId, TOKEN_BOR)
    const daoAddress = getContractAddress(chainId, getBoringDAOName(type))
    approveMax(library, borAddress, daoAddress, account).catch(handleError)
  }

  const onPledge = async () => {
    try {
      setDoing(true)
      console.log(`=====> Pledge to ${type} tunnel`)
      await pledge(library, chainId, account, amount, type as AssetName)
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  return (
    <Dialog open={open} onClose={onClose} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={{xs: 6, md: 10}}>
        <Box mb={4}>
          <Typography variant={'h5'} align={'center'}>
            {t('pledge')}
          </Typography>
        </Box>
        <Typography variant={'body2'} align={'center'}>
          {t('prepare_desc')}
        </Typography>
        <Box mt={4}>
          <Typography variant={'h5'} align={'center'}>
            {displayBalance(pledgeAmount, 18, 0)} / {LTCThreshold}
            <img src={getTokenIcon(TOKEN_BOR)} width={18} style={{marginLeft: '10px'}} alt="bor" />
          </Typography>
        </Box>
        <Box my={4} className={classes.input} display={'flex'} alignItems={'center'}>
          <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
          <InputBase
            fullWidth={true}
            placeholder="0.00"
            value={amount}
            onChange={(e: any) => verifyNumericInput(e, setAmount)}
          />
          <MaxButton onClick={max}>Max</MaxButton>
        </Box>
        <Typography align={'right'} variant={'body2'}>
          {t('balance')}：{displayBalance(ctx.balance.bor)}
        </Typography>
        <Box display={'flex'} alignItems={'center'} mt={4} justifyContent={'center'}>
          {approved ? (
            <Button
              disabled={approved}
              style={{marginRight: '10px'}}
              disableElevation={true}
              size={'large'}
              variant={'contained'}
              color={'secondary'}
            >
              <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'} />
              {t('approved')}
            </Button>
          ) : (
            <Button
              style={{marginRight: '10px'}}
              disableElevation={true}
              size={'large'}
              variant={'contained'}
              color={'secondary'}
              onClick={pledgeApprove}
            >
              {t('approve')}
            </Button>
          )}
          <Button
            style={{marginLeft: '10px'}}
            disableElevation={true}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            disabled={!can || doing}
            onClick={onPledge}
          >
            {doing && <CircularProgress size={14} color={'inherit'} />}
            {t('pledge')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default PledgePrepare
