import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core'
import {TOKEN_BOR_BNB, TOKEN_CAKE, TokenModal} from '../../constants/token'
import {NavLink, useParams} from 'react-router-dom'
import {getTokenIcon} from '../../constants/icon'
import {displayBalance} from '../../helpers/display'
import {getTokenDecimals, getTokenShowName} from '../../constants/helper'
import {verifyNumericInput} from '../../helpers/verify'
import {useWeb3React} from '@web3-react/core'
import {BigNumber} from 'bignumber.js'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {getContractAddress} from '../../helpers/web3'
import {CONTRACT_MASTER_CHEF} from '../../constants/contract'
import {approveMax} from '../../helpers/erc20'
import {useTranslation} from 'react-i18next'
import {TokenObtain} from '../../components/PoolRow'
import {MaxButton} from '../../components/MaxButton'
import {usePancakePool} from "../../hooks/usePancakePool";
import {cakeDeposit, cakeWithdraw} from "../../contracts/pancake";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    data: {
      border: '1px solid #E8E9EB',
      borderRadius: '15px',
      padding: '20px',
      marginBottom: '20px'
    },
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '15px'
      }
    },
    claimable: {
      border: '1px solid #E8E9EB',
      borderRadius: '15px'
    }
  })
)

export const BoringStyleInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: '#333A50',
      fontSize: '14px',
      border: '1px solid #f0f0f0',
      width: '100%',
      padding: '5px 10px',
      '& .MuiSelect-selectMenu': {
        '& a': {
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        },
        '&:focus': {
          background: 'none'
        },
        '& img': {
          marginRight: '10px'
        }
      }
    }
  })
)(InputBase)

const PancakePool = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useWeb3React()
  const {tokenName} = useParams<{ tokenName: string }>()
  const decimals = getTokenDecimals(tokenName)

  const pool = usePancakePool(tokenName)

  const [value, setValue] = useState('')
  const [doing, setDoing] = useState(false)
  const [can, setCan] = useState(false)

  const [withdrawValue, setWithdrawValue] = useState('')
  const [withdrawDoing, setWithdrawDoing] = useState(false)
  const [withdrawCan, setWithdrawCan] = useState(false)

  const [claimDoing, setClaimDoing] = useState(false)
  const [approved, setApproved] = useState(false)

  const pid = TokenModal[tokenName].pid


  useEffect(() => {
    setApproved(false)
    if (pool) {
      if (new BigNumber(pool.userTokenBalance).lt(pool.userAllowance)) {
        setApproved(true)
      }
    }
  }, [pool])

  useEffect(() => {
    setCan(false)
    const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, getTokenDecimals(tokenName)))
    if (valueBN.gt(0) && valueBN.lte(pool.userTokenBalance)) {
      setCan(true)
    }
  }, [value, tokenName, pool])

  useEffect(() => {
    setWithdrawCan(false)
    const valueBN = new BigNumber(withdrawValue).multipliedBy(Math.pow(10, getTokenDecimals(tokenName)))
    if (valueBN.gt(0) && valueBN.lte(pool.userStakedAmount)) {
      setWithdrawCan(true)
    }
  }, [withdrawValue, pool, tokenName])

  const withdrawing = async () => {
    try {
      setWithdrawDoing(true)
      console.log(pid)
      console.log(withdrawValue)
      await cakeWithdraw(library, chainId, account, pid, withdrawValue)
    } catch (e) {
      console.log(e)
    }
    setWithdrawDoing(false)
  }

  const staking = async () => {
    try {
      setDoing(true)
      console.log(pid)
      console.log(value)
      await cakeDeposit(library, chainId, account, pid, value)
    } catch (e) {
      console.log(e)
    }
    setDoing(false)
  }

  const list = () => {
    const items = []
    const all = [TOKEN_BOR_BNB]
    for (let i = 0; i < all.length; i++) {
      items.push(
        <MenuItem value={all[i]}>
          <NavLink to={`/pool/pancake/${all[i]}`}>
            <img height={16} src={getTokenIcon(all[i])} alt="token"/>
            <Typography variant={'body2'}>{getTokenShowName(all[i])}</Typography>
          </NavLink>
        </MenuItem>
      )
    }

    return items
  }

  const approving = async () => {
    if (account && pool) {
      const tokenAddress = getContractAddress(chainId, tokenName)
      const poolAddress = getContractAddress(chainId, CONTRACT_MASTER_CHEF)
      try {
        await approveMax(library, tokenAddress, poolAddress, account)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const claiming = async () => {
    try {
      setClaimDoing(true)
      await cakeDeposit(library, chainId, account, pid, '0')
    } catch (e) {
      console.log(e)
    }
    setClaimDoing(false)
  }

  const max = () => setValue(new BigNumber(pool.userTokenBalance).dividedBy(Math.pow(10, decimals)).toString())
  const maxWithdraw = () =>
    setWithdrawValue(new BigNumber(pool.userStakedAmount).dividedBy(Math.pow(10, decimals)).toString())

  return (
    <Container maxWidth={'lg'}>
      <Box mt={{xs: 3, md: 6}}>
        <Card>
          <Box p={4}>
            <div className={classes.data}>
              <Grid container>
                <Grid item xs={6}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img
                      src={getTokenIcon(tokenName)}
                      height={20}
                      alt="token"
                      style={{marginRight: '5px'}}
                    />
                    <Typography variant={'h6'}>{displayBalance(pool.totalStakedAmount, decimals)}</Typography>
                  </Box>
                  <Box textAlign={'center'}>{t('total_staked')}</Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img
                      src={getTokenIcon(tokenName)}
                      height={20}
                      alt="token"
                      style={{marginRight: '5px'}}
                    />
                    <Typography variant={'h6'}>{displayBalance(pool.userStakedAmount, decimals)}</Typography>
                  </Box>
                  <Box textAlign={'center'}>{t('your_staked')}</Box>
                </Grid>
              </Grid>
            </div>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              value={tokenName}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                console.log(e.target.value)
              }}
              input={<BoringStyleInput/>}
            >
              {list()}
            </Select>
            <Box className={classes.input} display={'flex'} alignItems={'center'} mt={4}>
              <img src={getTokenIcon(tokenName)} height={16} alt="token"/>
              <InputBase
                fullWidth={true}
                placeholder="0.00"
                value={value}
                onChange={(e: any) => verifyNumericInput(e, setValue)}
              />
              <MaxButton onClick={max}>Max</MaxButton>
            </Box>
            <Box my={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {t('balance')}: {displayBalance(pool.userTokenBalance, decimals)}
              {pool && <TokenObtain tokenName={pool.tokenName}/>}
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4} mb={4}>
              {approved ? (
                <Button
                  disabled={approved}
                  style={{marginRight: '10px'}}
                  disableElevation={true}
                  size={'large'}
                  variant={'contained'}
                  color={'secondary'}
                >
                  <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'}/>
                  {t('approved')}
                </Button>
              ) : (
                <Button
                  style={{marginRight: '10px'}}
                  disableElevation={true}
                  size={'large'}
                  variant={'contained'}
                  color={'secondary'}
                  onClick={approving}
                >
                  {t('approve')}
                </Button>
              )}
              <Button
                onClick={staking}
                size={'large'}
                style={{marginLeft: '10px'}}
                variant={'contained'}
                color={'primary'}
                disabled={!can || !approved}
                disableElevation
              >
                {doing && <CircularProgress size={18} color={'inherit'}/>}
                {t('stake')}
              </Button>
            </Box>
            <Divider/>
            <Box className={classes.input} display={'flex'} alignItems={'center'} mt={4} mb={4}>
              <img src={getTokenIcon(tokenName)} height={16} alt="token"/>
              <InputBase
                fullWidth={true}
                placeholder="0.00"
                value={withdrawValue}
                onChange={(e: any) => verifyNumericInput(e, setWithdrawValue)}
              />
              <MaxButton onClick={maxWithdraw}>Max</MaxButton>
            </Box>
            <Button
              onClick={withdrawing}
              fullWidth={true}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disabled={!withdrawCan}
              disableElevation
            >
              {withdrawDoing && <CircularProgress size={18} color={'inherit'}/>}
              {t('withdraw')}
            </Button>
            <Box className={classes.claimable} p={3} mb={4} mt={4}>
              <Typography variant={'subtitle1'}>{t('claimable_rewards')}</Typography>
              <Box display={'flex'} alignItems={'center'} mt={1}>
                <img src={getTokenIcon(TOKEN_CAKE)} height={14} alt="token" style={{marginRight: '5px'}}/>
                <Typography variant={'subtitle1'}>{displayBalance(pool.unlockEarned, 18, 18)}</Typography>
              </Box>
            </Box>
            <Button
              onClick={claiming}
              fullWidth={true}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disabled={!new BigNumber(pool.unlockEarned).gt(0)}
              disableElevation
            >
              {claimDoing && <CircularProgress size={18} color={'inherit'}/>}
              {t('claim')}
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  )
}

export default PancakePool
