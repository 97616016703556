import Skeleton from 'react-loading-skeleton'
import React, {ReactNode} from 'react'

export const LoadingDisplay = ({
  ok,
  result,
  width,
  height
}: {
  ok: boolean
  result: ReactNode
  width?: number
  height?: number
}) => {
  return <>{ok ? result : <Skeleton width={width ? width : 120} height={height ? height : 28} />}</>
}
