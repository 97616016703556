import {useCallback, useContext, useEffect, useState} from 'react'
import {BigNumber} from 'bignumber.js'
import {TOKEN_BTC, TOKEN_DOGE, TOKEN_LTC} from '../constants/token'
import {Context} from '../contexts/BoringProvider'
import {useActiveWeb3React, useMountedRef} from './index'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import {CHAIN_ETHER} from '@w3u/chains'
import MulticallABI from '../abi/Multicall.json'
import {getTunnelName} from '../constants/contract'
import {getCall, totalTVLABI} from '../constants/abi'

export type Data = {
  tokenName: string
  apy: string
  userTokenBalance: string
  userStakedAmount: string
  totalStakedAmount: string
  unlockEarned: string
  lockEarned: string
  userAllowance: string
}

export const useTVL = () => {
  const [tvl, setTVL] = useState<string>('0.000')
  const {account, library, chainId, connector} = useActiveWeb3React()
  const ctx = useContext(Context)
  const mountedRef = useMountedRef()

  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const web3 = getWeb3(library)

  const calc = useCallback(
    (btcTVL: string, ltcTVL: string, dogeTVL: string) => {
      if (new BigNumber(ctx.price.bor).gt(0)) {
        const obtcAmount = new BigNumber(ctx.total.oBTC).div(Math.pow(10, 18))
        const obtcPrice = new BigNumber(ctx.price.btc).div(Math.pow(10, 18))
        const oltcAmount = new BigNumber(ctx.total.oLTC).div(Math.pow(10, 18))
        const oltcPrice = new BigNumber(ctx.price.ltc).div(Math.pow(10, 18))
        const odogeAmount = new BigNumber(ctx.total.oDOGE).div(Math.pow(10, 18))
        const odogePrice = new BigNumber(ctx.price.doge).div(Math.pow(10, 18))
        const btcTunnel = new BigNumber(btcTVL).div(Math.pow(10, 18))
        const ltcTunnel = new BigNumber(ltcTVL).div(Math.pow(10, 18))
        const dogeTunnel = new BigNumber(dogeTVL).div(Math.pow(10, 18))
        const obtcTVL = obtcAmount.multipliedBy(obtcPrice)
        const oltcTVL = oltcAmount.multipliedBy(oltcPrice)
        const odogeTVL = odogeAmount.multipliedBy(odogePrice)

        return obtcTVL
          .plus(oltcTVL)
          .plus(odogeTVL)
          .plus(btcTunnel)
          .plus(ltcTunnel)
          .plus(dogeTunnel)
          .toFormat(0)
      }

      return '0.000'
    },
    [ctx]
  )

  const get = useCallback(async () => {
    if (chainId !== CHAIN_ETHER) return
    try {
      const btcTunnelAddress = getContractAddress(chainId, getTunnelName(TOKEN_BTC))
      const ltcTunnelAddress = getContractAddress(chainId, getTunnelName(TOKEN_LTC))
      const dogeTunnelAddress = getContractAddress(chainId, getTunnelName(TOKEN_DOGE))

      const calls = [
        getCall(web3, btcTunnelAddress, totalTVLABI, []),
        getCall(web3, ltcTunnelAddress, totalTVLABI, []),
        getCall(web3, dogeTunnelAddress, totalTVLABI, [])
      ]

      console.log(222222)
      multicallContract.methods
        .aggregate(calls)
        .call()
        .then((values: any) => {
          if (mountedRef.current) {
            setTVL(
              calc(
                String(web3.eth.abi.decodeParameter('uint256', values[1][0])),
                String(web3.eth.abi.decodeParameter('uint256', values[1][1])),
                String(web3.eth.abi.decodeParameter('uint256', values[1][2]))
              )
            )
          }
        })
        .catch((e: any) => console.error('TotalTVL multicall: ', e))
    } catch (e) {
      console.error(e)
    }
  }, [library, account, chainId, calc, mountedRef])

  useEffect(() => {
    if (library) {
      get()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      console.log('TVL', ms)
      let timer = setInterval(get, ms)
      return () => clearInterval(timer)
    }
  }, [library, setTVL, get, connector])

  return tvl
}
