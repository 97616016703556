import React from 'react'
import {Box, Button} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {getMapTokenShowName} from '../../../../constants/icon'
import Done from '../../../../assets/images/done.png'
import BSCDone from '../../../../assets/images/bsc_done.png'
import {isBSC} from '../../../../helpers'

const DoneImg = isBSC() ? BSCDone : Done

const MintStepFive = ({onPrev, onNext, type}: {onPrev: any; type: string; onNext: any}) => {
  const {t} = useTranslation()
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <img src={DoneImg} width={50} alt="done" />
      <Box textAlign={'center'} px={{xs: 4, md: 10}} mt={6} mb={6}>
        {t('mint_step_5_desc', {type: type, map_type: getMapTokenShowName(type)})}
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Button
          onClick={onPrev}
          size={'large'}
          style={{marginRight: '10px'}}
          variant={'contained'}
          color={'default'}
          disableElevation
        >
          {t('back')}
        </Button>
        <Button
          onClick={onNext}
          size={'large'}
          style={{marginLeft: '10px'}}
          variant={'contained'}
          color={'primary'}
          disableElevation
        >
          {t('check_process')}
        </Button>
      </Box>
    </Box>
  )
}

export default MintStepFive
