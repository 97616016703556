import React from 'react'
import {Box, Button} from '@material-ui/core'
import {useTranslation} from "react-i18next";
import Done from "../../../../assets/images/done.png";

const BurnStepThird = ({ onPrev, type }: { onPrev: any, type: string }) => {
  const {t} = useTranslation()
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <img src={Done} width={50} alt="done"/>
      <Box textAlign={'center'} px={{xs: 4, md: 10}} my={6}>
        {t('burn_step_3_desc', {type: type})}
      </Box>
      <Button
        onClick={onPrev}
        size={'large'}
        style={{ marginLeft: '10px' }}
        variant={'contained'}
        color={'primary'}
        disableElevation
      >
        {t('back')}
      </Button>
    </Box>
  )
}

export default BurnStepThird
