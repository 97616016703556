import React, {useEffect, useState} from "react";
import {Box, Button, TextField, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const MintStepSix = ({onPrev, onNext, setTxID, type}: { onPrev: any, type: string, onNext: any, setTxID: any }) => {
  const {t} = useTranslation()

  const [id, setID] = useState('')
  const [can, setCan] = useState(false)

  useEffect(() => {
    setCan(id !== '')
    setTxID(id)
  }, [id, setTxID])

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography variant={'subtitle2'}>{t('mint_step_6_title', {type: type})}</Typography>
      <Box my={4} style={{width: '100%'}}>
        <TextField label="TXID" variant="outlined" fullWidth onChange={(e) => {
          setID(e.target.value as string)
        }}/>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Button onClick={onPrev}
                size={'large'}
                style={{marginRight: '10px'}}
                variant={'contained'} color={'default'}
                disableElevation>{t('back')}</Button>
        <Button onClick={onNext}
                disabled={!can}
                size={'large'}
                style={{marginLeft: '10px'}}
                variant={'contained'} color={'primary'}
                disableElevation>{t('check_process')}</Button>
      </Box>
    </Box>
  )
}

export default MintStepSix