import {TOKEN_BTC, TOKEN_LTC} from './token'
import {NetworkConnector} from '@web3-react/network-connector'

export const MinBTCMintValue = '0.1'
export const MinBTCBurnValue = '0.002'
export const BurnFee = '0.0001'

export const MinLTCMintValue = '50'
export const MinLTCBurnValue = '10'

export const MinDOGEMintValue = '20000'
export const MinDOGEBurnValue = '18000'

export const LTCThreshold = '3000'

export const NetworkContextName = 'BORING_NETWORK'

export const getMinMintValue = (chainId: number | undefined, tokenName: string) => {
  if (chainId === 97 || chainId === 56) {
    return tokenName === TOKEN_BTC ? '0.01' : '10'
  }
  return tokenName === TOKEN_BTC ? MinBTCMintValue : tokenName === TOKEN_LTC ? MinLTCMintValue : MinDOGEMintValue
}

export const getMinBurnValue = (chainId: number | undefined, tokenName: string) => {
  if (chainId === 97 || chainId === 56) {
    return tokenName === TOKEN_BTC ? '0.002' : '1'
  }
  return tokenName === TOKEN_BTC ? MinBTCBurnValue : tokenName === TOKEN_LTC ? MinLTCBurnValue : MinDOGEBurnValue
}

const NETWORK_URLS: {[key: number]: string} = {
  // localhost
  1: `https://mainnet.infura.io/v3/31001812569d49699b64d36a89672a9a`
  // first
  // 1: `https://mainnet.infura.io/v3/105bd241eec1455ebed7be1a2b4637ee`,
  // 1: `https://mainnet.infura.io/v3/ace830ef99c44e87a3dfe0d4d0d52225`
  // 1: 'https://eth-mainnet.alchemyapi.io/v2/xt19QLfMwjVcrvqiqfbHWDJV7_Yx1vn0'
}

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: 1
})
