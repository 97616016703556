import {AccountType, ChainIDType} from './type'
import {getContract} from '../helpers/web3'
import {CONTRACT_TIME_DISTRIBUTION, CONTRACT_TIME_DISTRIBUTION_MONTH} from '../constants/contract'

export const userTotalToken = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION)
  return contract.methods.userTotalToken().call({from: account})
}

export const claimed = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION)
  return contract.methods.claimed().call({from: account})
}

export const claimable = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION)
  return contract.methods.pendingClaim().call({from: account})
}

export const claim = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION)
  return contract.methods.claim().send({from: account})
}

export const userTotalTokenMonth = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION_MONTH)
  return contract.methods.userTotalToken().call({from: account})
}

export const claimedMonth = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION_MONTH)
  return contract.methods.claimed().call({from: account})
}

export const claimableMonth = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION_MONTH)
  return contract.methods.pendingClaim().call({from: account})
}

export const claimMonth = async (provider: any, chainID: ChainIDType, account: AccountType) => {
  const contract = getContract(provider, chainID, CONTRACT_TIME_DISTRIBUTION_MONTH)
  return contract.methods.claim().send({from: account})
}
