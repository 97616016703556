interface Info {
  [chainID: number]: {
    name: string
    symbol: string
    decimals: number
    rpcUrls: string
    blockExplorerUrls: string
  }
}

export const chainInfo: Info = {
  137: {
    name: 'Matic Network',
    symbol: 'Matic',
    decimals: 18,
    rpcUrls: 'https://rpc-mainnet.maticvigil.com',
    blockExplorerUrls: 'https://explorer-mainnet.maticvigil.com/'
  },
  80001: {
    name: 'Mumbai',
    symbol: 'Matic',
    decimals: 18,
    rpcUrls: 'https://rpc-mumbai.maticvigil.com',
    blockExplorerUrls: 'https://explorer-mumbai.maticvigil.com/'
  },
  128: {
    name: 'HecoChain MainNetwork',
    symbol: 'HT',
    decimals: 18,
    rpcUrls: 'https://http-mainnet.hecochain.com',
    blockExplorerUrls: 'https://hecoinfo.com/'
  },
  256: {
    name: 'HecoChain TestNetwork',
    symbol: 'ETH',
    decimals: 18,
    rpcUrls: 'https://http-testnet.hecochain.com',
    blockExplorerUrls: 'https://testnet.hecoinfo.com'
  },
  56: {
    name: 'Binance Smart Chain',
    symbol: 'BNB',
    decimals: 18,
    rpcUrls: 'https://bsc-dataseed1.binance.org/',
    blockExplorerUrls: 'https://bscscan.com'
  },
  97: {
    name: 'Binance Smart Chain Test Network',
    symbol: 'BNB',
    decimals: 18,
    rpcUrls: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    blockExplorerUrls: 'https://testnet.bscscan.com'
  },
  65: {
    name: 'OKExChain Testnet',
    symbol: 'OKT',
    decimals: 18,
    rpcUrls: 'https://exchaintest.okexcn.com',
    blockExplorerUrls: 'https://www.oklink.com/okexchain-test'
  }
  // 1: {
  // 	name: 'Ethereum',
  // 	symbol: 'ETH',
  // 	decimals: 18,
  // 	rpcUrls: 'https://mainnet.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e',
  // 	blockExplorerUrls: 'https://etherscan.io'
  // },
  // 3: {
  // 	name: 'Ropsten',
  // 	symbol: 'ETH',
  // 	decimals: 18,
  // 	rpcUrls: 'https://ropsten.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e',
  // 	blockExplorerUrls: 'https://ropsten.etherscan.io'
  // },
  // 42: {
  // 	name: 'Kovan',
  // 	symbol: 'ETH',
  // 	decimals: 18,
  // 	rpcUrls: 'https://kovan.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e',
  // 	blockExplorerUrls: 'https://kovan.etherscan.io'
  // },
  // 4: {
  // 	name: 'Rinkeby',
  // 	symbol: 'ETH',
  // 	decimals: 18,
  // 	rpcUrls: 'https://rinkeby.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e',
  // 	blockExplorerUrls: 'https://rinkeby.etherscan.io'
  // },
  // 5: {
  // 	name: 'Goerli',
  // 	symbol: 'ETH',
  // 	decimals: 18,
  // 	rpcUrls: 'https://goerli.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e',
  // 	blockExplorerUrls: 'https://goerli.etherscan.io'
  // },
}
