import React from 'react'
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core'
import {useTranslation} from "react-i18next";
import {getMapTokenShowName} from "../../../constants/icon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      [theme.breakpoints.up('sm')]: {
        minHeight: '230px',
      }
    },
    mintNotice: {
      paddingLeft: '15px',
      lineHeight: '1.6',
      listStyle: 'none',
      '& li': {
        '&:before': {
          fontSize: '12px',
          content: `"•"`,
          position: 'relative',
          left: '-10px',
          top: '0'
        },
        marginBottom: '10px'
      }
    }
  })
)

const BurnNotice = ({step, type}: { step: number, type: string }) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const First = () => {
    return (
      <ul className={classes.mintNotice}>
        <li>{t('burn_step_1_1', {map_type: getMapTokenShowName(type)})}</li>
        <li>{t('burn_step_1_2', {type: type})}</li>
        <li>{t('burn_step_1_3', {type: type})}</li>
      </ul>
    )
  }

  const Second = () => {
    return (
      <ul className={classes.mintNotice}>
        <li>{t('burn_step_2_1', {type: type})}</li>
        <li>{t('burn_step_2_2', {type: type})}</li>
      </ul>
    )
  }

  const Third = () => {
    return (
      <ul className={classes.mintNotice}>
        <li>
          {t('burn_step_3_1', {type: type})}
        </li>
      </ul>
    )
  }

  return (
    <Box className={classes.wrap}>
      {step === 0 && <First/>}
      {step === 1 && <Second/>}
      {step === 2 && <Third/>}
    </Box>
  )
}

export default BurnNotice
