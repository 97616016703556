import React from 'react'
import Meta from '../../Meta'
import DollarGray from '../../../../assets/images/dollar_gray.png'
import {useTVL} from '../../../../hooks/useTVL'
import {useTranslation} from 'react-i18next'

const MintTVL = () => {
  const tvl = useTVL()
  const {t} = useTranslation()

  return <Meta img={DollarGray} title={t('total_value_locked')} value={tvl} />
}

export default React.memo(MintTVL)
