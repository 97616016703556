import React, {useContext} from 'react'
import {Box, Card, createStyles, Divider, Grid, makeStyles, Theme, Typography} from '@material-ui/core'
import Banner from '../../../assets/images/banner.png'
import BSCBanner from '../../../assets/images/bsc-banner.png'
import BOR from '../../../assets/images/bor.png'
import {useTranslation} from 'react-i18next'
import {useWeb3React} from '@web3-react/core'
import {
  TOKEN_BORING,
  TOKEN_BTC,
  TOKEN_DOGE,
  TOKEN_LTC,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC
} from '../../../constants/token'
import {displayBalance} from '../../../helpers/display'
import {getTokenIcon} from '../../../constants/icon'
import Meta from '../Meta'
import {Context} from '../../../contexts/BoringProvider'
import {getContractAddress} from '../../../helpers/web3'
import {useMultiAddressBalance} from '../../../hooks/useMultiAddressBalance'
import MintTVL from './TVL'
import ActionLink from '../ActionLink'
import {getEtherScanUrl, isBSC, isEthereum} from '../../../helpers'
import HomeMint1 from '../../../assets/images/home_mint_1.png'
import HomeMint2 from '../../../assets/images/home_mint_2.png'
import HomeMint3 from '../../../assets/images/home_mint_3.png'
import HomeMint4 from '../../../assets/images/home_mint_4.png'
import {BigNumber} from 'bignumber.js'
import {PriceContext} from '../../../contexts/PriceProvider'
import {useActiveWeb3React} from '../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      display: 'block',
      width: '100%',
      minHeight: '213px'
    },
    block: {
      border: '1px solid #F1F2F6',
      borderRadius: '6px',
      padding: '14px 15px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: '5px'
      },
      '& p': {
        fontSize: '12px !important'
      }
    },
    wrapper: {
      maxHeight: '205px',
      background: '#F2F4FA',
      padding: '20px',
      borderRadius: '8px',
      overflow: 'scroll'
    }
  })
)

const HomeMint = () => {
  const ctx = useContext(Context)
  const classes = useStyles()
  const [t] = useTranslation()
  const {chainId} = useActiveWeb3React()

  const multiBalances = useMultiAddressBalance()
  const priceCtx = useContext(PriceContext)

  return (
    <Card>
      <Box p={{sm: 4, md: 6}}>
        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="h6">{t('mint')}</Typography>
          <Typography variant="caption">{t('home_mint_subtitle')}</Typography>
          <ActionLink to={'/mint'} title={t('mint_now')} />
        </Box>
        <Box mb={6}>
          {isEthereum() && <img src={Banner} className={classes.banner} alt="banner" />}
          {isBSC() && <img src={BSCBanner} className={classes.banner} alt="banner" />}
        </Box>
        <Grid container>
          <Grid item md={6}>
            <MintTVL />
          </Grid>
          <Grid item md={6}>
            <Meta
              img={BOR}
              title={t('price')}
              titleColor="primary"
              value={new BigNumber(priceCtx[TOKEN_BORING]).toFixed(4)}
              surprice={false}
            />
          </Grid>
        </Grid>
        <Box className={classes.wrapper} mt={6}>
          <Grid container>
            <Grid item lg={6}>
              <Box mr={4}>
                <Meta
                  img={getTokenIcon(TOKEN_BTC)}
                  title={'BTC ' + t('home_mint_deposited')}
                  value={new BigNumber(multiBalances.btc).toFormat(2)}
                  href={`https://btc.com/${ctx.multiAddress.btc}`}
                />
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Meta
                img={getTokenIcon(TOKEN_OBTC)}
                title={'oBTC ' + t('home_mint_minted')}
                value={displayBalance(ctx.total.oBTC, 18, 2)}
                href={getEtherScanUrl(chainId, getContractAddress(chainId, TOKEN_OBTC))}
              />
            </Grid>
          </Grid>
          <Box my={4}>
            <Divider />
          </Box>
          <Grid container>
            <Grid item lg={6}>
              <Box mr={4}>
                <Meta
                  img={getTokenIcon(TOKEN_LTC)}
                  title={'LTC ' + t('home_mint_deposited')}
                  value={new BigNumber(multiBalances.ltc).toFormat()}
                  href={`https://explorer.btc.com/ltc/address/${ctx.multiAddress.ltc}`}
                />
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Meta
                img={getTokenIcon(TOKEN_OLTC)}
                title={'oLTC ' + t('home_mint_minted')}
                value={displayBalance(ctx.total.oLTC, 18, 0)}
                href={getEtherScanUrl(chainId, getContractAddress(chainId, TOKEN_OLTC))}
              />
            </Grid>
          </Grid>
          {isEthereum() ? (
            <>
              <Box my={4}>
                <Divider />
              </Box>
              <Grid container>
                <Grid item lg={6}>
                  <Box mr={4}>
                    <Meta
                      img={getTokenIcon(TOKEN_DOGE)}
                      title={'DOGE ' + t('home_mint_deposited')}
                      value={new BigNumber(multiBalances.doge).toFormat()}
                      href={`https://doge3.trezor.io/address/${ctx.multiAddress.doge}`}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Meta
                    img={getTokenIcon(TOKEN_ODOGE)}
                    title={'oDOGE ' + t('home_mint_minted')}
                    value={displayBalance(ctx.total.oDOGE, 18, 0)}
                    href={getEtherScanUrl(chainId, getContractAddress(chainId, TOKEN_ODOGE))}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Box>
        {isBSC() ? (
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <Box mt={4} className={classes.block}>
                <img src={HomeMint1} alt="home" />
                <Typography variant={'body2'}>{t('home_mint_desc1')}</Typography>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box mt={4} className={classes.block}>
                <img src={HomeMint2} alt="home" />
                <Typography variant={'body2'}>{t('home_mint_desc2')}</Typography>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box className={classes.block}>
                <img src={HomeMint3} alt="home" />
                <Typography variant={'body2'}>{t('home_mint_desc3')}</Typography>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box className={classes.block}>
                <img src={HomeMint4} alt="home" />
                <Typography variant={'body2'}>{t('home_mint_desc4')}</Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </Card>
  )
}

export default React.memo(HomeMint)
