import {
  TOKEN_AC,
  TOKEN_ALPHA,
  TOKEN_BANCOR,
  TOKEN_BAND,
  TOKEN_BNB,
  TOKEN_BOR,
  TOKEN_BOR_BNB,
  TOKEN_BORING,
  TOKEN_BSC,
  TOKEN_BTC,
  TOKEN_BTC_PPTOKEN,
  TOKEN_CAKE,
  TOKEN_CAKE_BNB,
  TOKEN_CURVE,
  TOKEN_DAI,
  TOKEN_DODO,
  TOKEN_DOGE,
  TOKEN_ETH,
  TOKEN_ETH_BOR_ETH,
  TOKEN_ETH_OBTC_WETH,
  TOKEN_FORTUBE,
  TOKEN_HARVEST,
  TOKEN_HBTC,
  TOKEN_HT,
  TOKEN_KSWAP,
  TOKEN_LINK,
  TOKEN_LTC,
  TOKEN_LTC_PPTOKEN,
  TOKEN_MPH,
  TOKEN_NEST,
  TOKEN_NEW_ODOGE,
  TOKEN_NEW_OLTC,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OKT,
  TOKEN_OLTC,
  TOKEN_POLYGON,
  TOKEN_QUICKSWAP,
  TOKEN_RENBTC,
  TOKEN_SUNDER,
  TOKEN_SUSHI,
  TOKEN_UNI,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WAULT,
  TOKEN_WBTC,
  TOKEN_WETH,
  TOKEN_YFI,
  TOKEN_YFII
} from './token'
import BOR from '../assets/images/bor.png'
import OBTC from '../assets/images/obtc.png'
import DAI from '../assets/images/dai.png'
import USDC from '../assets/images/usdc.png'
import WETH from '../assets/images/weth.png'
import USDT from '../assets/images/usdt.png'
import LINK from '../assets/images/chainlink.png'
import YFI from '../assets/images/yfi.png'
import YFII from '../assets/images/yfii.png'
import NEST from '../assets/images/nest.png'
import BAND from '../assets/images/bank.png'
import HBTC from '../assets/images/hbtc.png'
import BTC from '../assets/images/btc.png'
import LTC from '../assets/images/ltc.png'
import OLTC from '../assets/images/oltc.png'
import RENBTC from '../assets/images/renBTC.png'
import WBTC from '../assets/images/wbtc.png'
import ETH from '../assets/images/eth.png'
import BORBNB from '../assets/images/bor_bnb.png'
import CAKE from '../assets/images/pancake.png'
import BORETH from '../assets/images/bor_eth.png'
import OBTCETH from '../assets/images/obtc_eth.png'
import OKT from '../assets/images/okc.png'
import DOGE from '../assets/images/doge.png'
import ODOGE from '../assets/images/odoge.svg'
import BSC from '../assets/images/bsc.png'
import Matic from '../assets/images/polygon.png'
import Sunder from '../assets/images/tokens/sunder.png'

export const getMapTokenName = (tokenName: string) => {
  switch (tokenName) {
    case TOKEN_BTC:
      return TOKEN_OBTC
    case TOKEN_LTC:
      return TOKEN_OLTC
    case TOKEN_DOGE:
      return TOKEN_ODOGE
    default:
      return TOKEN_OBTC
  }
}

export const getMapTokenShowName = (tokenName: string) => {
  switch (tokenName) {
    case TOKEN_BTC:
      return 'oBTC'
    case TOKEN_LTC:
      return 'oLTC'
    case TOKEN_DOGE:
      return 'oDOGE'
    default:
      return 'oBTC'
  }
}

export const getFarmTokens = () => {
  return [
    TOKEN_BOR,
    TOKEN_OBTC,
    TOKEN_OLTC,
    TOKEN_DAI,
    TOKEN_USDT,
    TOKEN_USDC,
    TOKEN_WETH,
    TOKEN_YFI,
    TOKEN_YFII,
    TOKEN_LINK,
    TOKEN_NEST,
    TOKEN_BAND
  ]
}

export const getTokenIcon = (tokenName: string | undefined) => {
  switch (tokenName) {
    case TOKEN_SUNDER:
      return Sunder
    case TOKEN_KSWAP:
      return 'https://app.kswap.finance/static/media/logo.eea96dcb.svg'
    case TOKEN_BANCOR:
      return 'https://cryptologos.cc/logos/bancor-bnt-logo.svg?v=013'
    case TOKEN_QUICKSWAP:
      return 'https://assets.coingecko.com/coins/images/13970/small/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659'
    case TOKEN_POLYGON:
      return Matic
    case TOKEN_WAULT:
      return 'https://swap.wault.finance/bsc/images/wswap-background-fixed.png'
    case TOKEN_UNI:
      return 'https://cryptologos.cc/logos/uniswap-uni-logo.svg?v=013'
    case TOKEN_AC:
      return 'https://app.acbtc.fi/static/media/acoconut-btc.f35b685d.svg'
    case TOKEN_FORTUBE:
      return 'https://for.tube/static/img/FOR.1faf664f.png'
    case TOKEN_MPH:
      return 'https://cryptologos.cc/logos/88mph-mph-logo.svg?v=013'
    case TOKEN_HARVEST:
      return 'https://harvest.finance/static/media/tractor-new.1c34f435.svg'
    case TOKEN_ALPHA:
      return 'https://assets.coingecko.com/coins/images/12738/small/AlphaToken_256x256.png?1617160876'
    case TOKEN_CURVE:
      return 'https://cryptologos.cc/logos/curve-dao-token-crv-logo.svg?v=013'
    case TOKEN_DODO:
      return 'https://assets.coingecko.com/coins/images/12651/small/dodo_logo.png?1601433025'
    case TOKEN_OKT:
      return OKT
    case TOKEN_ETH_BOR_ETH:
      return BORETH
    case TOKEN_ETH_OBTC_WETH:
      return OBTCETH
    case TOKEN_SUSHI:
      return 'https://assets.coingecko.com/coins/images/12271/small/512x512_Logo_no_chop.png?1606986688'
    case TOKEN_CAKE:
      return CAKE
    case TOKEN_BOR_BNB:
      return BORBNB
    case TOKEN_CAKE_BNB:
      return BORBNB
    case TOKEN_BTC_PPTOKEN:
      return BOR
    case TOKEN_LTC_PPTOKEN:
      return BOR
    case TOKEN_BTC:
      return BTC
    case TOKEN_LTC:
      return LTC
    case TOKEN_DOGE:
      return DOGE
    case TOKEN_ODOGE:
      return ODOGE
    case TOKEN_NEW_ODOGE:
      return ODOGE
    case TOKEN_OLTC:
      return OLTC
    case TOKEN_NEW_OLTC:
      return OLTC
    case TOKEN_BORING:
      return BOR
    case TOKEN_BOR:
      return BOR
    case TOKEN_OBTC:
      return OBTC
    case TOKEN_DAI:
      return DAI
    case TOKEN_USDC:
      return USDC
    case TOKEN_USDT:
      return USDT
    case TOKEN_WETH:
      return WETH
    case TOKEN_LINK:
      return LINK
    case TOKEN_NEST:
      return NEST
    case TOKEN_BAND:
      return BAND
    case TOKEN_YFII:
      return YFII
    case TOKEN_YFI:
      return YFI
    case TOKEN_HBTC:
      return HBTC
    case TOKEN_RENBTC:
      return RENBTC
    case TOKEN_WBTC:
      return WBTC
    case TOKEN_ETH:
      return ETH
    case TOKEN_BSC:
      return BSC
    case TOKEN_BNB:
      return 'https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615'
    case TOKEN_HT:
      return 'https://assets.coingecko.com/coins/images/2822/small/huobi-token-logo.png?1547036992'
    default:
      return BOR
  }
}

export const getTokenIconByChainID = (chainID: number | undefined) => {
  switch (chainID) {
    case 65:
      return getTokenIcon(TOKEN_OKT)
    case 256:
      return getTokenIcon(TOKEN_HT)
    case 97:
      return getTokenIcon(TOKEN_BNB)
    case 56:
      return getTokenIcon(TOKEN_BNB)
    case 137:
      return Matic
    case 80001:
      return Matic
    default:
      return getTokenIcon(TOKEN_ETH)
  }
}
