import {AccountType, ChainIDType} from './type'
import {getContract, getWeb3} from '../helpers/web3'
import web3 from 'web3'
import {toBytes32} from '../helpers/solidity'
import {AssetName} from './address'
import {getBoringBoringDAOName, getBoringDAOName} from '../constants/contract'

export const calculateMintBORAmount = async (provider: any, chainID: ChainIDType, type: string, amount: string) => {
  if (!amount) {
    return ''
  }

  const contract = getContract(provider, chainID, getBoringBoringDAOName(type))
  const key = toBytes32(type)
  return contract.methods.calculateMintBORAmount(key, web3.utils.toWei(amount)).call()
}

export const pledge = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  amount: string,
  type: AssetName
) => {
  const contract = getContract(provider, chainID, getBoringDAOName(type))
  return contract.methods.pledge(toBytes32(type), web3.utils.toWei(amount)).send({from: account})
}

export const boringPledge = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  amount: string,
  type: AssetName
) => {
  const contract = getContract(provider, chainID, getBoringBoringDAOName(type))
  return contract.methods.pledge(toBytes32(type), web3.utils.toWei(amount)).send({from: account})
}


export const redeem = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  amount: string,
  type: AssetName
) => {
  const contract = getContract(provider, chainID, getBoringDAOName(type))
  const tunnelKey = toBytes32(type)
  await contract.methods.redeem(tunnelKey, web3.utils.toWei(amount)).send({from: account})
}

export const boringRedeem = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  amount: string,
  type: AssetName
) => {
  const contract = getContract(provider, chainID, getBoringBoringDAOName(type))
  const tunnelKey = toBytes32(type)
  await contract.methods.redeem(tunnelKey, web3.utils.toWei(amount)).send({from: account})
}

export const burnBToken = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  amount: string,
  assetAddress: string,
  assetType: string
) => {
  const contract = getContract(provider, chainID, getBoringBoringDAOName(assetType))
  const tunnelKey = toBytes32(assetType)
  return contract.methods.burnBToken(tunnelKey, web3.utils.toWei(amount), assetAddress).send({from: account})
}

export const getFinalMintEvents = async (provider: any, chainID: ChainIDType, type: string) => {
  const contract = getContract(provider, chainID, getBoringDAOName(type))
  const logs = await contract.getPastEvents('allEvents', {fromBlock: 10409912})
  const successes = {}
  const fails = {}
  for (const log of logs) {
    try {
      if (log.event === 'ApproveMintSuccess') {
        const res = decodeMintSuccessLog(provider, log.raw.data, log.raw.topics)
        // @ts-ignore
        successes[res._txid] = {
          to: res.to,
          amount: res._amount
        }
      } else if (log.event === 'NotEnoughPledgeValue') {
        const res = decodeNotEnoughPledgeValueLog(provider, log.raw.data, log.raw.topics)
        // @ts-ignore
        fails[res._txid] = {
          to: res.to,
          amount: res._amount
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    successes: successes,
    fails: fails
  }
}

const decodeMintSuccessLog = (provider: any, data: string, topics: string[]) => {
  const web3 = getWeb3(provider)
  const inputs = [
    {
      indexed: false,
      internalType: 'bytes32',
      name: '_tunnelKey',
      type: 'bytes32'
    },
    {
      indexed: false,
      internalType: 'string',
      name: '_txid',
      type: 'string'
    },
    {
      indexed: false,
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    },
    {
      indexed: false,
      internalType: 'address',
      name: 'to',
      type: 'address'
    },
    {
      indexed: false,
      internalType: 'string',
      name: 'assetAddress',
      type: 'string'
    }
  ]
  return web3.eth.abi.decodeLog(inputs, data, topics)
}

const decodeNotEnoughPledgeValueLog = (provider: any, data: string, topics: string[]) => {
  const w3 = getWeb3(provider)
  const inputs = [
    {
      indexed: true,
      internalType: 'bytes32',
      name: '_tunnelKey',
      type: 'bytes32'
    },
    {
      indexed: true,
      internalType: 'string',
      name: '_txid',
      type: 'string'
    },
    {
      indexed: false,
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    },
    {
      indexed: false,
      internalType: 'address',
      name: 'to',
      type: 'address'
    },
    {
      indexed: false,
      internalType: 'address',
      name: 'trustee',
      type: 'address'
    },
    {
      indexed: false,
      internalType: 'string',
      name: 'assetAddress',
      type: 'string'
    }
  ]
  return w3.eth.abi.decodeLog(inputs, data, topics)
}
