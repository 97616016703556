import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  Card,
  Container,
  createStyles,
  Divider,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@material-ui/core'
import {getTokenIcon} from '../../constants/icon'
import {TOKEN_BOR, TOKEN_OBTC, TOKEN_RENBTC, TOKEN_WBTC} from '../../constants/token'
import {verifyNumericInput} from '../../helpers/verify'
import TokenValue from '../../components/TokenValue'
import {displayBalance} from '../../helpers/display'
import {useInterval} from '../../hooks/useInterval'
import {useWeb3React} from '@web3-react/core'
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'
import {AssetName} from '../../contracts/address'
import {WithoutStyleInput} from '../../components/Mint/Step/First'
import {allowance, approveMax, balanceOf} from '../../helpers/erc20'
import {getContractAddress} from '../../helpers/web3'
import {handleError, isValidNumberInput} from '../../helpers'
import {Context} from '../../contexts/BoringProvider'
import {estimate, fee, trade} from '../../contracts/barter'
import {CONTRACT_BARTER} from '../../constants/contract'
import BigNumber from 'bignumber.js'
import {MaxButton} from '../../components/MaxButton'
import {useTranslation} from 'react-i18next'
import {useTitle} from 'react-use'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 200px)'
    },
    desc: {
      paddingLeft: '15px',
      marginBottom: '0',
      color: '#444C65',
      lineHeight: '1.8'
    },
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '15px'
      }
    }
  })
)

const Barter = () => {
  useTitle("BoringDAO App | Barter")
  const {t} = useTranslation()
  const ctx = useContext(Context)
  const classes = useStyles()
  const {library, chainId, account} = useWeb3React()
  const [amount, setAmount] = useState('')
  const [approved, setApproved] = useState(false)
  const [can, setCan] = useState(false)
  const [bor, setBOR] = useState('')
  const [obtc, setOBTC] = useState('')
  const [type, setType] = useState(TOKEN_WBTC)
  const [renBTCBalance, setRenBTCBalance] = useState('0')
  const [wBTCBalance, setWBTCBalance] = useState('0')
  const [feeing, setFeeing] = useState('')
  const [renUserAllowance, setRenUserAllowance] = useState('')
  const [wUserAllowance, setWUserAllowance] = useState('')
  console.log(feeing)

  useInterval(
    () => {
      if (account) {
        const renAddress = getContractAddress(chainId, TOKEN_RENBTC)
        const wAddress = getContractAddress(chainId, TOKEN_WBTC)
        const barterAddress = getContractAddress(chainId, CONTRACT_BARTER)
        balanceOf(library, renAddress, account)
          .then(setRenBTCBalance)
          .catch(handleError)
        balanceOf(library, wAddress, account)
          .then(setWBTCBalance)
          .catch(handleError)
        fee(library, chainId)
          .then(setFeeing)
          .catch(handleError)
        allowance(library, renAddress, account, barterAddress)
          .then(setRenUserAllowance)
          .catch(handleError)
        allowance(library, wAddress, account, barterAddress)
          .then(setWUserAllowance)
          .catch(handleError)
      }
    },
    3000,
    true
  )

  useEffect(() => {
    if (account) {
      setBOR('')
      setOBTC('')
      setCan(false)
      if (isValidNumberInput(amount) && amount !== '0') {
        estimate(library, chainId, account, type, amount)
          .then(res => {
            setOBTC(res[0])
            setBOR(res[1])
          })
          .catch(handleError)
      }

      const userAllowance = type === TOKEN_RENBTC ? renUserAllowance : wUserAllowance
      const balance = type === TOKEN_RENBTC ? renBTCBalance : wBTCBalance
      if (new BigNumber(userAllowance).gt(balance)) {
        setApproved(true)
      }

      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 8))

      if (amountBN.gt(0) && amountBN.lte(balance)) {
        setCan(true)
      }
    }
  }, [amount, type, chainId, library, account, renBTCBalance, renUserAllowance, wBTCBalance, wUserAllowance])

  const getBalance = () => {
    return type === TOKEN_RENBTC ? renBTCBalance : wBTCBalance
  }

  const barter = () => {
    const dyBN = new BigNumber(obtc).multipliedBy(0.99)
    trade(library, chainId, account, type, amount, dyBN.toFixed(0, BigNumber.ROUND_CEIL)).catch(handleError)
  }

  const approving = async () => {
    try {
      const tokenAddress = getContractAddress(chainId, type)
      const barterAddress = getContractAddress(chainId, CONTRACT_BARTER)
      await approveMax(library, tokenAddress, barterAddress, account)
      console.log(`=====> Approve successfully`)
    } catch (e) {
      console.error(e)
    }
  }

  const max = () => setAmount(new BigNumber(getBalance()).dividedBy(Math.pow(10, 8)).toString())

  return (
    <Container maxWidth={'lg'}>
      <Box mt={{xs: 3, md: 6}}>
        <Card className={classes.main}>
          <Box p={{xs: 6, md: 12}}>
            <Typography variant={'subtitle2'} color={'primary'}>
              {t('barter')}
            </Typography>
            <Grid container spacing={6}>
              <Grid item md={6} sm={12}>
                <ul className={classes.desc}>
                  <li>{t('barter_1')}</li>
                  <li>{t('barter_2')}</li>
                  <li>{t('barter_3')}</li>
                </ul>
              </Grid>
              <Grid item md={6} sm={12}>
                <ul className={classes.desc}>
                  <li>{t('barter_4')}</li>
                  <li>{t('barter_5')}</li>
                </ul>
              </Grid>
            </Grid>
            <Box my={{xs: 6, md: 8}}>
              <Divider />
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Grid item md={6} xs={12}>
                <Box className={classes.input} display={'flex'} alignItems={'center'}>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                    value={type}
                    onChange={(e: React.ChangeEvent<{value: unknown}>) => {
                      return setType(e.target.value as AssetName)
                    }}
                    input={<WithoutStyleInput />}
                  >
                    <MenuItem value={TOKEN_RENBTC}>
                      <img width={16} height={16} src={getTokenIcon(TOKEN_RENBTC)} alt="renBTC" />
                      <Typography variant={'body2'}>renBTC</Typography>
                    </MenuItem>
                    <MenuItem value={TOKEN_WBTC}>
                      <img width={16} height={16} src={getTokenIcon(TOKEN_WBTC)} alt="WBTC" />
                      <Typography variant={'body2'}>WBTC</Typography>
                    </MenuItem>
                  </Select>
                  <InputBase
                    value={amount}
                    fullWidth={true}
                    placeholder="0.00"
                    onChange={(e: any) => verifyNumericInput(e, setAmount)}
                  />
                  <MaxButton onClick={max}>Max</MaxButton>
                </Box>
                <Box my={2} textAlign={'right'}>
                  {t('balance')}: {displayBalance(getBalance(), 8, 4)}
                </Box>
                {/*<Box mb={2} mt={4}>*/}
                {/*  <Typography variant={'body2'}>{t('barter_fee')}</Typography>*/}
                {/*</Box>*/}
                {/*<TokenValue*/}
                {/*  img={getTokenIcon(TOKEN_OBTC)}*/}
                {/*  amount={displayBalance(feeing, 8, 2)}*/}
                {/*  price={ctx.price.btc}*/}
                {/*/>*/}
                <Box mt={4} mb={2}>
                  <Typography variant={'body2'}>{t('you_will_get')}</Typography>
                </Box>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TokenValue
                      img={getTokenIcon(TOKEN_OBTC)}
                      amount={displayBalance(obtc, 18, 4)}
                      price={ctx.price.btc}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TokenValue
                      img={getTokenIcon(TOKEN_BOR)}
                      amount={displayBalance(bor, 18, 6)}
                      price={ctx.price.bor}
                    />
                  </Grid>
                </Grid>
                <Box mt={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  {approved ? (
                    <Button
                      disabled={approved}
                      style={{marginRight: '10px'}}
                      disableElevation={true}
                      size={'large'}
                      variant={'contained'}
                      color={'secondary'}
                    >
                      <CheckCircleTwoToneIcon style={{color: '#0AC571'}} />
                      {t('approved')}
                    </Button>
                  ) : (
                    <Button
                      onClick={approving}
                      style={{marginRight: '10px'}}
                      disableElevation={true}
                      size={'large'}
                      variant={'contained'}
                      color={'secondary'}
                    >
                      {t('approve')}
                    </Button>
                  )}
                  <Button
                    style={{marginLeft: '10px'}}
                    disableElevation={true}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={!can}
                    onClick={barter}
                  >
                    {t('confirm')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
    </Container>
  )
}

export default Barter
