import React, {useCallback} from 'react'
import {Box, Button, Card, createStyles, Link, makeStyles, Theme, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import Home33BG from '../../../assets/images/33/home33-bg.png'
import {NavLink} from 'react-router-dom'
import Logo33 from '../../../assets/images/33/logo33.png'
import {useBoringChef} from '../../../hooks/useBoringChef'
import {TOKEN_BORING} from '../../../constants/token'
import Countdown, {CountdownRenderProps} from 'react-countdown'
import {BigNumber} from 'bignumber.js'

const primaryColor = '#feb400'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      display: 'block',
      width: '100%'
    },
    main: {
      backgroundImage: `url(${Home33BG})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      color: '#fff',
      '& h5': {
        fontWeight: '900 !important'
      },
      '& .stake-btn': {
        background: `${primaryColor} !important`,
        color: '#fff'
      }
    },
    apy: {
      textShadow: '0 0 3px black'
    }
  })
)

const HomeBoringChef = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const data = useBoringChef(TOKEN_BORING)

  const renderer = useCallback(({days, hours, minutes, seconds, completed}: CountdownRenderProps) => {
    if (completed) {
      return (
        <Box>
          {t('home_33_desc')}, 0 {t('days')}, 0 {t('hours')}, 0 {t('minutes')}.
        </Box>
      )
    }

    return (
      <>
        {t('home_33_desc')}, {days} {t('days')}, {hours} {t('hours')}, {minutes} {t('minutes')}.
      </>
    )
  }, [])

  return (
    <Box p={{sm: 4, md: 6}} className={classes.main}>
      <Box display="flex" alignItems="center">
        <Box>
          <Box mb={2}>
            <Typography variant="h5">(BORING, BORING)</Typography>
          </Box>
          <Typography variant="subtitle2">$BORING Single Stake (3, 3)</Typography>
        </Box>
        <Box ml="auto">
          <Link href="https://33bond.boringdao.com/" target="_blank">
            <Button variant="contained" className="stake-btn" disableElevation={true}>
              {t('stake')}
            </Button>
          </Link>
        </Box>
      </Box>
      <Typography variant="subtitle2">
        <Countdown date={1636614618000 + 24 * 3600 * 1000} renderer={renderer} />
      </Typography>
      <Box mt={4} display="flex" alignItems="center">
        <Box>
          <img src={Logo33} alt="logo33" width={130} />
        </Box>
        <Box ml="auto" fontSize={46} color={primaryColor} fontWeight={700} className={classes.apy}>
          APY {new BigNumber(data.apy).gt(10 ** 6) ? '> 1M' : `${data.apy}%`}
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(HomeBoringChef)
