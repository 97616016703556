import {getContract, getContractAddress} from '../helpers/web3'
import {getBoringTunnelName, getTunnelName} from '../constants/contract'
import {balanceOf} from '../helpers/erc20'
import {AccountType, ChainIDType} from './type'
import {TOKEN_BOR, TOKEN_BORING} from '../constants/token'
import {AssetName} from './address'

export const totalPledge = async (provider: any, chainID: ChainIDType, type: string) => {
  chainID = chainID ? chainID : 1
  const borAddress = getContractAddress(chainID, TOKEN_BOR)
  const tunnelAddress = getContractAddress(chainID, getTunnelName(type))
  return balanceOf(provider, borAddress, tunnelAddress)
}

export const totalBoringPledge = async (provider: any, chainID: ChainIDType, type: string) => {
  chainID = chainID ? chainID : 1
  const boringAddress = getContractAddress(chainID, TOKEN_BORING)
  const tunnelAddress = getContractAddress(chainID, getBoringTunnelName(type))
  return balanceOf(provider, boringAddress, tunnelAddress)
}

export const userPledgeAmount = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getTunnelName(type))
  return contract.methods.borPledgeInfo(account).call({from: account})
}

export const userBoringPledgeAmount = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(type))
  return contract.methods.borPledgeInfo(account).call({from: account})
}

export const totalTVL = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getTunnelName(type))
  return contract.methods.totalTVL().call()
}

export const boringTotalTVL = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(type))
  return contract.methods.totalTVL().call()
}

export const pledgeRatio = async (provider: any, chainID: ChainIDType, type: string) => {
  const contract = getContract(provider, chainID, getTunnelName(type))
  return contract.methods.pledgeRatio().call()
}

export const boringPledgeRatio = async (provider: any, chainID: ChainIDType, type: string) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(type))
  return contract.methods.pledgeRatio().call()
}

export const userLockAmount = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getTunnelName(type))
  return contract.methods.userLockAmount().call({from: account})
}

export const userBoringLockAmount = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(type))
  return contract.methods.userLockAmount().call({from: account})
}

export const withdrawUnlock = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getTunnelName(type))
  return contract.methods.withdrawUnlock().send({from: account})
}

export const boringWithdrawUnlock = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(type))
  return contract.methods.withdrawUnlock().send({from: account})
}

export const canIssueAmount = async (provider: any, chainID: ChainIDType, tokenName: AssetName) => {
  const contract = getContract(provider, chainID, getTunnelName(tokenName))
  return contract.methods.canIssueAmount().call()
}

export const boringCanIssueAmount = async (provider: any, chainID: ChainIDType, tokenName: AssetName) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(tokenName))
  return contract.methods.canIssueAmount().call()
}

export const userLockLength = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  tokenName: AssetName
) => {
  const contract = getContract(provider, chainID, getTunnelName(tokenName))
  return contract.methods.userLockLength(account).call()
}

export const boringUserLockLength = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  tokenName: AssetName
) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(tokenName))
  return contract.methods.userLockLength(account).call()
}

export const redeemLockTxLimit = async (provider: any, chainID: ChainIDType, tokenName: AssetName) => {
  const contract = getContract(provider, chainID, getTunnelName(tokenName))
  return contract.methods.redeemLockTxLimit().call()
}

export const boringRedeemLockTxLimit = async (provider: any, chainID: ChainIDType, tokenName: AssetName) => {
  const contract = getContract(provider, chainID, getBoringTunnelName(tokenName))
  return contract.methods.redeemLockTxLimit().call()
}
