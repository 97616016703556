import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, CircularProgress, createStyles, InputBase, makeStyles, Theme, Typography} from '@material-ui/core'
import {getTokenIcon} from '../../constants/icon'
import {TOKEN_BOR} from '../../constants/token'
import {handleError, registerToken} from '../../helpers'
import {verifyNumericInput} from '../../helpers/verify'
import {displayBalance} from '../../helpers/display'
import {Context} from '../../contexts/BoringProvider'
import {BigNumber} from 'bignumber.js'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import {useTranslation} from 'react-i18next'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {allowance, approveMax} from '../../helpers/erc20'
import {useInterval} from '../../hooks/useInterval'
import {useWeb3React} from '@web3-react/core'
import {getContractAddress} from '../../helpers/web3'
import {CONTRACT_BORING_V2} from '../../constants/contract'
import {toBoring} from '../../contracts/cross'
import {MaxButton} from '../../components/MaxButton'
import {useActiveWeb3React} from '../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '15px'
      }
    },
    value: {
      border: '1px solid #D8E0ED',
      background: '#F1F2F6',
      borderRadius: '6px',
      padding: '15px 15px',
      display: 'flex',
      alignItems: 'center',
      color: '#444C66',
      '& img': {
        marginRight: '10px'
      }
    },
    waring: {
      minHeight: '20px'
    }
  })
)

const Boring = () => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const ctx = useContext(Context)
  const {t} = useTranslation()
  const [userAllowance, setUserAllowance] = useState('')
  const {library, account, chainId} = useActiveWeb3React()
  const borAddress = getContractAddress(chainId, TOKEN_BOR)
  const boringV2Address = getContractAddress(chainId, CONTRACT_BORING_V2)
  const [aprDoing, setAprDoing] = useState(false)
  const [can, setCan] = useState(true)
  const [doing, setDoing] = useState(false)

  useInterval(
    () => {
      allowance(library, borAddress, account, boringV2Address)
        .then(setUserAllowance)
        .catch(handleError)
    },
    5000,
    true
  )

  useEffect(() => {
    setCan(false)
    if (value) {
      const amountBN = new BigNumber(value).multipliedBy(Math.pow(10, 18))
      if (amountBN.lte(ctx.balance.bor) && amountBN.gt(0)) {
        setCan(true)
      }
    }
  }, [value, ctx])

  const approving = async () => {
    setAprDoing(true)
    await approveMax(library, borAddress, boringV2Address, account).catch(handleError)
    setAprDoing(false)
  }

  const approved = new BigNumber(userAllowance).gt(ctx.balance.bor)

  const transfer = async () => {
    try {
      setDoing(true)
      await toBoring(library, chainId, account, value)
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  const max = () =>
    setValue(new BigNumber(ctx.balance.bor).dividedBy(Math.pow(10, 18)).toFixed(3, BigNumber.ROUND_DOWN))

  return (
    <>
      <Box className={classes.input} display={'flex'} alignItems={'center'}>
        <img src={getTokenIcon(TOKEN_BOR)} alt="token" width={16} style={{marginRight: '5px'}} />
        <Box mr={3}>
          <Typography variant="body2">BOR</Typography>
        </Box>
        <InputBase
          fullWidth={true}
          placeholder="0.00"
          value={value}
          onChange={(e: any) => verifyNumericInput(e, setValue)}
        />
        <MaxButton onClick={max} style={{marginRight: '10px'}}>
          Max
        </MaxButton>
        <Box style={{marginLeft: 'auto'}} flexShrink={0} ml={2}>
          ≈ $ {displayBalance(new BigNumber(value).multipliedBy(ctx.price.bor).toString(), 18, 2)}
        </Box>
      </Box>
      <Typography align={'right'} variant={'body2'}>
        {t('balance')}：{displayBalance(ctx.balance.bor)}
      </Typography>
      <Box textAlign="center" my={6}>
        <ArrowDownwardIcon fontSize="small" color="primary" />
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant={'body2'}>{t('you_will_get')}</Typography>
      </Box>
      <Box className={classes.value} mt={1}>
        <img src={getTokenIcon(TOKEN_BOR)} width={16} height={16} alt="bor" style={{marginRight: '5px'}} />
        <Box mr={3}>
          <Typography variant="body2">BORING</Typography>
        </Box>
        {displayBalance(
          new BigNumber(value)
            .multipliedBy(10000)
            .multipliedBy(Math.pow(10, 18))
            .toString()
        )}
      </Box>
      <Box my={2} textAlign="right">
        <Typography
          style={{cursor: 'pointer', color: '#1164FB'}}
          variant="caption"
          onClick={() =>
            registerToken(
              boringV2Address,
              'BORING',
              18,
              'https://raw.githubusercontent.com/BoringDAO/assets/main/images/bor.png'
            )
          }
        >
          Add BORING to Metamask
        </Typography>
      </Box>
      <Box display={'flex'} mt={6} alignItems={'center'} justifyContent={'center'}>
        {approved ? (
          <Button
            disabled={approved}
            style={{marginRight: '10px'}}
            disableElevation={true}
            size={'large'}
            variant={'contained'}
            color={'secondary'}
          >
            <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'} />
            {t('approved')}
          </Button>
        ) : (
          <Button
            style={{marginRight: '10px'}}
            disableElevation={true}
            size={'large'}
            variant={'contained'}
            color={'secondary'}
            onClick={approving}
          >
            {aprDoing && <CircularProgress size={14} color={'inherit'} />}
            {t('approve')}
          </Button>
        )}
        <Button
          style={{marginLeft: '10px'}}
          disableElevation={true}
          size={'large'}
          variant={'contained'}
          color={'primary'}
          disabled={!can || doing || !approved}
          onClick={transfer}
        >
          {doing && <CircularProgress size={14} color={'inherit'} />}
          {t('confirm')}
        </Button>
      </Box>
    </>
  )
}

export default Boring
