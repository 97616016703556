import React from 'react'
import BOR from '../../../assets/images/bor.png'
import OBTC from '../../../assets/images/obtc.png'
import {Box, Dialog, Divider, Link, makeStyles, Typography} from '@material-ui/core'
import {ellipseAddress} from '../../../helpers/display'
import Copy from '../../../assets/images/copy.png'
import CopyToClipboard from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import ToastContent from '../../ToastContent'
import {getEtherScanUrl} from '../../../helpers'
import {useWeb3React} from '@web3-react/core'
import {getTokenIcon} from '../../../constants/icon'
import {TOKEN_ODOGE, TOKEN_OLTC} from '../../../constants/token'
import {useActiveWeb3React} from '../../../hooks'

const useStyles = makeStyles({
  grow: {
    flexGrow: 1
  },
  address: {
    '& img': {
      marginLeft: '5px',
      cursor: 'pointer'
    }
  }
})

const TokenModal = ({
  open,
  close,
  type,
  balance,
  contractAddress
}: {
  open: boolean
  close: any
  type?: string
  balance: string
  contractAddress: string
}) => {
  const classes = useStyles()
  const {chainId} = useActiveWeb3React()

  const displayIcon = () => {
    switch (type) {
      case 'obtc':
        return <img src={OBTC} alt="obtc" width={54} height={54} />
      case 'oltc':
        return <img src={getTokenIcon(TOKEN_OLTC)} width={54} height={54} alt="oltc" />
      case 'odoge':
        return <img src={getTokenIcon(TOKEN_ODOGE)} width={54} height={54} alt="odoge" />
      default:
        return <img src={BOR} alt="bor" width={54} height={54} />
    }
  }

  return (
    <Dialog open={open} keepMounted maxWidth="sm" fullWidth={true} onClose={close} transitionDuration={300}>
      <Box p={{xs: 6, md: 10}}>
        <Box mb={10} textAlign="center">
          {displayIcon()}
        </Box>
        <Box display="flex">
          <Typography variant="body2">Balance: </Typography>
          <div className={classes.grow} />
          <Typography variant="body2">{balance}</Typography>
        </Box>
        <Box my={4}>
          <Divider />
        </Box>
        <Box display="flex" alignItems={'center'} className={classes.address}>
          <Typography variant="body2">Contract:&nbsp;&nbsp;</Typography>
          <div className={classes.grow} />
          <Link href={getEtherScanUrl(chainId, contractAddress)} target={'_blank'}>
            <Typography variant="body2">{ellipseAddress(contractAddress, 8)}</Typography>
          </Link>
          <CopyToClipboard
            text={contractAddress}
            onCopy={() => {
              toast.info(<ToastContent status={true} content={`Address copied`} />, {
                autoClose: 2000
              })
            }}
          >
            <img src={Copy} alt="copy" width={12} height={12} />
          </CopyToClipboard>
        </Box>
      </Box>
    </Dialog>
  )
}

export default TokenModal
