import {useEagerConnect} from '../../hooks'
import {useWeb3React} from '@web3-react/core'
import {network, NetworkContextName} from '../../constants'
import {useEffect} from 'react'

export default function Web3ReactManager({children}: {children: JSX.Element}) {
  const {active} = useWeb3React()
  const {active: networkActive, error: networkError, activate: activateNetwork} = useWeb3React(NetworkContextName)

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // on page load, do nothing until we've tried to connect to the injected connector

  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network)
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active])

  if (!triedEager) {
    return null
  }

  return children
}
