import React, {useState} from 'react'
import LogoSVG from '../../assets/images/logo.svg'
import {makeStyles} from '@material-ui/core/styles'
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Close from '../../assets/images/close.png'
import {Box, Divider, Link} from '@material-ui/core'
import {getSidebarLinkIcon, links} from '../../constants/link'
import PledgeSelectModal from '../Models/Pledge/Select'
import {TOKEN_BOR, TOKEN_BTC} from '../../constants/token'
import {getMapTokenShowName, getTokenIcon} from '../../constants/icon'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import HomeMobileMint from '../../assets/images/home_mobile_mint.png'
import HomeMobilePledge from '../../assets/images/home_mobile_pledge.png'
import HomeMobileFarm from '../../assets/images/home_mobile_farm.png'
import HomeMobileBarter from '../../assets/images/home_mobile_barter.png'
import HomeMobilSwitch from '../../assets/images/cross.png'
import {isBSC, isEthereum, isMatic, isOKExChain} from '../../helpers'
import {useWeb3React} from '@web3-react/core'
import FarmSelectModal from '../Models/FarmSelect'
import CrossSelectModal from '../Models/CrossSelect'
import ExchangeSelectModal from '../Models/ExchangeSelect'
import {useActiveWeb3React} from '../../hooks'
import OPortal from '../../assets/images/oportal-logo.png'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: '300px',
    zIndex: 10000,
    background: '#ffffff',
    transform: 'translateX(-300px)',
    transition: 'transform .3s'
  },
  open: {
    transform: 'translateX(0)'
  },
  back: {
    transform: 'translateX(-300px)'
  },
  logo: {
    display: 'inline-block',
    padding: '20px 30px'
  },
  hrefNav: {
    '& a': {
      padding: '8px 0 8px 80px'
    }
  },
  nav: {
    padding: '0 30px',
    listStyle: 'none',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0 10px 0',
      color: '#383838',
      fontSize: '14px',
      '&.active': {
        color: '#208EF6'
      }
    },
    '& img': {
      width: '18px',
      margin: '-3px 15px 0 0'
    }
  },
  close: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    width: '16px'
  }
})

const Sidebar = ({isOpen, close}: {isOpen: any; close: any}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const rootClasses = isOpen ? `${classes.root} ${classes.open}` : `${classes.root} ${classes.back}`
  const [openPledge, setOpenPledge] = useState(false)
  const [type, setType] = useState(TOKEN_BTC)
  const location = useLocation()
  const isPledgePage = location.pathname.includes('/pledge')
  const {chainId} = useActiveWeb3React()
  const [openFarm, setOpenFarm] = useState(false)
  const isFarmPage = location.pathname.includes('/farm')
  const [openCross, setOpenCross] = useState(false)
  const [openExchange, setOpenExchange] = useState(false)

  return (
    <div className={rootClasses}>
      <CrossSelectModal open={openCross} onClose={() => setOpenCross(false)} />
      <FarmSelectModal open={openFarm} onClose={() => setOpenFarm(false)} />
      <PledgeSelectModal setType={setType} open={openPledge} onClose={() => setOpenPledge(false)} />
      <ExchangeSelectModal open={openExchange} onClose={() => setOpenExchange(false)} />
      <NavLink to={'/'} className={classes.logo} onClick={close}>
        <img height={26} src={LogoSVG} alt="Logo" />
      </NavLink>
      <img
        className={classes.close}
        src={Close}
        alt="close"
        onClick={() => {
          close()
        }}
      />
      <ul className={classes.nav}>
        {(isEthereum() || isBSC()) && (
          <li>
            <NavLink to="/mint" onClick={close}>
              <img src={HomeMobileMint} alt="mint" />
              {t('mint')}
            </NavLink>
          </li>
        )}
        {(isEthereum() || isBSC()) && (
          <li>
            <Link
              className={isPledgePage ? 'active' : ''}
              onClick={() => {
                setOpenPledge(true)
                close()
              }}
            >
              <img src={HomeMobilePledge} alt="pledge" />
              {t('tunnel')}({getMapTokenShowName(type)})
              <ArrowDropDownIcon fontSize={'small'} />
            </Link>
          </li>
        )}
        {isEthereum() && (
          // <Link
          //   className={isFarmPage ? 'active' : ''}
          //   onClick={() => {
          //     setOpenFarm(true)
          //     close()
          //   }}
          // >
          //   <img src={HomeMobileFarm} alt="farm" />
          //   {t('farm')}
          // </Link>
          <li>
            <NavLink to="/farm/boring" onClick={close}>
              <img src={HomeMobileFarm} alt="farm" />
              {t('farm')}
            </NavLink>
          </li>
        )}
        {(isBSC() || isMatic()) && (
          <li>
            <NavLink to="/farm" onClick={close}>
              <img src={HomeMobileFarm} alt="farm" />
              {t('farm')}
            </NavLink>
          </li>
        )}
        {(isEthereum() || isBSC()) && chainId !== 3 && chainId !== 42 && (
          <Link href="https://oportal.boringdao.com/" target="_blank">
            <img src={OPortal} alt="oportal" />
            {t('oportal')}
          </Link>
        )}
        {(isEthereum() || isBSC()) && (
          <li>
            <NavLink to="/ecosystem" onClick={close}>
              <img src={HomeMobilSwitch} alt="token" />
              {t('ecosystem')}
            </NavLink>
          </li>
        )}
        {/*{(isEthereum() || isBSC()) && chainId !== 3 && chainId !== 42 && (*/}
        {/*  <li>*/}
        {/*    <Link*/}
        {/*      onClick={() => {*/}
        {/*        setOpenCross(true)*/}
        {/*        close()*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <img src={HomeMobilSwitch} alt="switch" />*/}
        {/*      {t('oportal')}*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {isEthereum() && (
          <li>
            <Link
              onClick={() => {
                setOpenExchange(true)
                close()
              }}
            >
              <img src={HomeMobileBarter} alt="oportal" />
              {t('others')}
            </Link>
          </li>
        )}
        {isBSC() && (
          <li>
            <Link target="_blank" href="https://app.dodoex.io/exchange/BOR-BORING?network=bsc-mainnet">
              <img src={getTokenIcon(TOKEN_BOR)} alt="token" />
              BORING
            </Link>
          </li>
        )}

        {isEthereum() && (chainId === 3 || chainId === 42) && <NavLink to="/eth-to-okc">ETH to OKC</NavLink>}
        {isOKExChain() && (
          <NavLink to="/okc-to-eth">
            <img src={HomeMobilSwitch} alt="switch" />
            OKC to ETH
          </NavLink>
        )}
        <Divider style={{marginTop: '20px'}} />
      </ul>
      <ul className={`${classes.nav} ${classes.hrefNav}`}>
        {links.map((value, index) => {
          return (
            <li key={value.name}>
              <a href={value.href} rel="noopener noreferrer" target="blank">
                <img src={getSidebarLinkIcon(value.name)} alt={value.name} /> {value.name}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Sidebar
