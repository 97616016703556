import React, {useState} from 'react'
import {Box, Card, Container, createStyles, Grid, makeStyles, Tab, Tabs, Theme} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import Boring from '../Boring'
import UnBoring from '../UnBoring'
import {useTitle} from 'react-use'
import {usePools} from '../../hooks/usePools'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 200px)'
    }
  })
)

const Transfer = () => {
  useTitle('BoringDAO App | Transfer')
  const classes = useStyles()
  const [tab, setTab] = useState(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  const pools = usePools()

  return (
    <Container maxWidth={'lg'}>
      <Box mt={{xs: 3, md: 6}}>
        <Card className={classes.main}>
          <Box pt={{xs: 4, md: 6}} pb={6} px={{xs: 4, md: 8}}>
            <Box mt={20} />
            <Tabs
              style={{marginTop: '-15px'}}
              textColor={'primary'}
              indicatorColor={'primary'}
              value={tab}
              onChange={handleTabChange}
              centered
            >
              <Tab disableRipple={true} label="Boring" />
              <Tab disableRipple={true} label="UnBoring" />
            </Tabs>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
              <Grid item sm={6}>
                {tab === 0 && <Boring />}
                {tab === 1 && <UnBoring />}
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
    </Container>
  )
}

export default Transfer
