import {getContract} from '../helpers/web3'
import {getBoringFeePoolName, getFeePoolName} from '../constants/contract'
import {AccountType, ChainIDType} from './type'

export const earned = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getFeePoolName(type))
  return contract.methods.earned(account).call({from: account})
}

export const boringEarned = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getBoringFeePoolName(type))
  return contract.methods.earned(account).call({from: account})
}

export const claimFee = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getFeePoolName(type))
  return contract.methods.claimFee().send({from: account})
}

export const boringClaimFee = async (provider: any, chainID: ChainIDType, account: AccountType, type: string) => {
  const contract = getContract(provider, chainID, getBoringFeePoolName(type))
  return contract.methods.claimFee().send({from: account})
}
