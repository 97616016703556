import React, {useContext, useEffect, useState} from 'react'
import TokenValue from '../../../TokenValue'
import {getMapTokenName, getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BOR, TOKEN_BTC, TOKEN_DOGE, TOKEN_LTC} from '../../../../constants/token'
import {Box, Button, Grid, Typography} from '@material-ui/core'
import {displayBalance} from '../../../../helpers/display'
import {BigNumber} from 'bignumber.js'
import {Context} from '../../../../contexts/BoringProvider'
import {useTranslation} from 'react-i18next'

const MintStepSecond = ({
  amount,
  type,
  borAmount,
  onPrev,
  onNext
}: {
  amount: string
  type: string
  borAmount: string
  onPrev: any
  onNext: any
}) => {
  const ctx = useContext(Context)
  const {t} = useTranslation()
  const [gain, setGain] = useState('')

  const mintCharge = (amount: string) => {
    if (!amount) return ''
    return new BigNumber(amount).multipliedBy(0.002).toFixed(6)
  }

  useEffect(() => {
    const valueBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
    const fee = type === TOKEN_BTC ? ctx.networkFee.btc : type === TOKEN_LTC ? ctx.networkFee.ltc : ctx.networkFee.doge
    let ret = valueBN.multipliedBy(new BigNumber('0.998')).minus(new BigNumber(fee))

    setGain(ret.toString())
  }, [amount, type, ctx])

  const getPrice = () => {
    return type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge
  }

  return (
    <>
      <Box mb={4}>
        <TokenValue img={getTokenIcon(type)} amount={amount} price={getPrice()} />
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <Box mb={2}>
            <Typography variant={'body2'}>{t('mint_fee')}</Typography>
          </Box>
          <TokenValue
            img={getTokenIcon(type)}
            amount={mintCharge(amount)}
            price={type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box mb={2}>
            <Typography variant={'body2'}>{t('mint_network_fee')}</Typography>
          </Box>
          <TokenValue
            img={getTokenIcon(type)}
            amount={displayBalance(
              type === TOKEN_BTC ? ctx.networkFee.btc : type === TOKEN_LTC ? ctx.networkFee.ltc : ctx.networkFee.doge,
              18,
              6
            )}
            price={getPrice()}
          />
        </Grid>
      </Grid>
      <Box mt={4} mb={2}>
        <Typography variant={'body2'}>{t('you_will_get')}</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <TokenValue
            img={getTokenIcon(getMapTokenName(type))}
            amount={displayBalance(gain, 18, 4)}
            price={getPrice()}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TokenValue img={getTokenIcon(TOKEN_BOR)} amount={displayBalance(borAmount, 18, 4)} price={new BigNumber(ctx.price.bor).dividedBy(10000).toString()} />
        </Grid>
      </Grid>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4}>
        <Button
          onClick={onPrev}
          size={'large'}
          style={{marginRight: '10px'}}
          variant={'contained'}
          color={'default'}
          disableElevation
        >
          {t('prev')}
        </Button>
        <Button
          onClick={onNext}
          size={'large'}
          style={{marginLeft: '10px'}}
          variant={'contained'}
          color={'primary'}
          disableElevation
        >
          {t('confirm')}
        </Button>
      </Box>
    </>
  )
}

export default MintStepSecond
