import {useWeb3React} from '@web3-react/core'
import {useCallback, useEffect, useRef, useState} from 'react'
import {injectedConnector} from '../connectors'
import {isMobile} from 'react-device-detect'
import {useFetch} from 'use-http'
import {handleError, handleErrorAndZero} from '../helpers'
import {getPrice} from '../contracts/oracle'
import {TOKEN_BORING} from '../constants/token'
import {NetworkContextName} from '../constants'
import {HttpProvider} from 'web3-core'

export function useActiveWeb3React() {
  const context = useWeb3React()
  const contextNetwork = useWeb3React<HttpProvider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect() {
  const {activate, active} = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injectedConnector, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injectedConnector, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, [activate])

  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Return component mount state
 */
export const useMountedRef = () => {
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  })

  return mountedRef
}

export const useBORPrice = () => {
  const mountedRef = useMountedRef()
  const [price, setPrice] = useState('0')
  const {get} = useFetch('https://api.coingecko.com/api/v3/simple/price?ids=boringdao&vs_currencies=usd')

  const fetch = useCallback(() => {
    get('')
      .then(res => {
        if (mountedRef.current) {
          setPrice(res.boringdao.usd)
        }
      })
      .catch(handleError)
  }, [get, mountedRef])

  useEffect(() => {
    fetch()
    let timer = setInterval(fetch, 20000)
    return () => clearInterval(timer)
  }, [fetch])

  return price
}

export const useBoringPrice = () => {
  const {library, chainId} = useWeb3React()
  const [price, setPrice] = useState('0')

  const get = useCallback(async () => {
    const price = await getPrice(library, chainId, TOKEN_BORING).catch(handleErrorAndZero)
    setPrice(price)
  }, [library, chainId])

  useEffect(() => {
    get()
    let timer = setInterval(fetch, 20000)
    return () => clearInterval(timer)
  }, [get])

  return price
}
