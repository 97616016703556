import {Button, Theme, withStyles} from '@material-ui/core'
import {isBSC} from '../../helpers'

export const MaxButton = withStyles((theme: Theme) => ({
  root: {
    minWidth: '38px',
    fontSize: '12px',
    padding: '2px',
    background: isBSC() ? '#fbefd2' : '#0066ff21',
    color: theme.palette.primary.main,
    '&:hover': {
      background: isBSC() ? '#ffebbb' : '#0066ff33'
    }
  }
}))(Button)
