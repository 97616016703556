import React, {useState} from 'react'
import {Box, Card, createStyles, makeStyles, Theme, Typography} from '@material-ui/core'
import CrossBanner from '../../../assets/images/cross-banner.png'
import {useTranslation} from 'react-i18next'
import CrossSelectModal from '../../Models/CrossSelect'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      display: 'block',
      width: '100%'
    },
    link: {
      color: '#7B89B7',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      marginLeft: 'auto',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      '& svg': {
        fontSize: '12px',
        marginLeft: '3px'
      }
    }
  })
)

const HomeCross = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <Card>
      <CrossSelectModal open={open} onClose={() => setOpen(false)} />
      <Box p={{sm: 4, md: 6}}>
        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="h6">{t('oportal')}</Typography>
          <Box ml="auto" onClick={() => setOpen(true)}>
            <Typography className={classes.link}>
              {t('oportal')}
              <ArrowForwardIcon />
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body2">{t('oportal_subtitle')}</Typography>
        </Box>
        <Box mt={3}>
          <img className={classes.banner} src={CrossBanner} alt="barter_banner" />
        </Box>
      </Box>
    </Card>
  )
}

export default React.memo(HomeCross)
