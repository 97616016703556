import {CssBaseline, ThemeProvider} from '@material-ui/core'
import React from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Header from './components/Header'
import {theme} from './theme'
import Home from './pages/Home'
import Mint from './pages/Mint'
import Pledge from './pages/Pledge'
import Footer from './components/Footer'
import Barter from './pages/Barter'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MobileBar from './components/MobileBar'
import Farm from './pages/Farm'
import Pool from './pages/Pool'
import Time from './pages/Time'
import './wdyr'
import {isBSC, isEthereum, isMatic, isOKExChain} from './helpers'
import Lock from './pages/Lock/Lock'
import Unlock from './pages/Unlock/Unlock'
import BSCFarm from './pages/BSCFarm'
import BSCPool from './pages/BSCPool'
import PancakePool from './pages/PancakePool'
import SushiPool from './pages/SushiPool'
import ToOKC from './pages/ToOKC/ToOKC'
import FromOKC from './pages/FromOKC/FromOKC'
import OBTCFarm from './pages/OBTCFarm'
import TimeMonth from './pages/TimeMonth'
import MaticFarm from './pages/MaticFarm'
import MaticPool from './pages/MaticPool'
import Transfer from './pages/Transfer'
import CityPool from './pages/CityPool'
import Exchange from './pages/Exchange'
import BoringFarm from './pages/BoringFarm'
import BoringPledge from './pages/BoringPledge'
import Ecosyatem from './pages/Ecosystem'

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          newestOnTop={false}
          closeButton={<></>}
          pauseOnFocusLoss
          pauseOnHover
          autoClose={2000}
        />
        <MobileBar />
        <Router>
          <Header />
          <Switch>
            <Route path="/ecosystem">
              <Ecosyatem />
            </Route>
            <Route path="/exchange">
              <Exchange />
            </Route>
            <Route path="/mint">
              <Mint />
            </Route>
            <Route path="/pledge/bor/:type">
              <Pledge />
            </Route>
            <Route path="/pledge/bor">
              <Redirect to="/pledge/bor/BTC" />
            </Route>
            <Route path="/pledge/:type">
              {isEthereum() && <BoringPledge />}
              {isBSC() && <Pledge />}
            </Route>
            <Route path="/pledge">
              <Redirect to="/pledge/BTC" />
            </Route>
            <Route path="/farm/obtc">{isEthereum() && <OBTCFarm />}</Route>
            <Route path="/farm/boring">{isEthereum() && <BoringFarm />}</Route>
            <Route path="/farm">
              {isEthereum() && <Farm />}
              {isBSC() && <BSCFarm />}
              {isMatic() && <MaticFarm />}
            </Route>
            <Route path="/pool/pancake/:tokenName">
              <PancakePool />
            </Route>
            <Route path="/pool/city/:tokenName">
              <CityPool />
            </Route>
            <Route path="/pool/sushi/:tokenName">
              <SushiPool />
            </Route>
            <Route path="/pool/:type">
              {isEthereum() && <Pool />}
              {isBSC() && <BSCPool />}
              {isMatic() && <MaticPool />}
            </Route>
            <Route path="/switch">
              {isBSC() && <Unlock />}
              {!isBSC() && <Lock />}
            </Route>
            <Route path="/eth-to-okc">
              <ToOKC />
            </Route>
            <Route path="/okc-to-eth">
              <FromOKC />
            </Route>
            <Route path="/barter">
              <Barter />
            </Route>
            <Route path="/time-distribution">
              <Time />
            </Route>
            <Route path="/time-distribution-month">
              <TimeMonth />
            </Route>
            <Route path="/transfer">
              <Transfer />
            </Route>
            <Route path="/">
              {isOKExChain() && <Redirect to="/okc-to-eth" />}
              {!isOKExChain() && !isMatic() && <Home />}
              {isMatic() && <Redirect to="/farm" />}
            </Route>
          </Switch>
          <Footer />
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
