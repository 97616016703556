import {TokenModal} from "./token";

export const getTokenDecimals = (tokenName: string) => {
  const data = TokenModal[tokenName]
  if (!data) {
    return 18
  }

  return data['decimals'] ? data['decimals'] : 18
}

export const getTokenFixed = (tokenName: string) => {
  const data = TokenModal[tokenName]
  if (!data) {
    return 3
  }

  return data['toFixed'] === undefined ? 3 : data['toFixed']
}

export const getTokenShowName = (tokenName: string) => {
  const data = TokenModal[tokenName]
  if (!data) {
    return 'Unknown'
  }

  return data['showName'] ? data['showName'] : 'Unknown'
}