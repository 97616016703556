import {useCallback, useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {isBSC, isEthereum, isHeco, isMatic, isOKExChain, setupNetwork} from '../helpers'
import {useActiveWeb3React} from './index'

export type Data = {
  tokenName: string
  apy: string
  userTokenBalance: string
  userStakedAmount: string
  totalStakedAmount: string
  unlockEarned: string
  lockEarned: string
  userAllowance: string
}

export const useNetworkChecker = () => {
  const [ok, setOK] = useState(false)
  const {chainId} = useActiveWeb3React()

  const setup = useCallback(async () => {
    if (chainId?.toString() !== process.env.REACT_APP_CHAIN_ID) {
      if (
        process.env.REACT_APP_CHAIN_ID === '1' ||
        process.env.REACT_APP_CHAIN_ID === '3' ||
        process.env.REACT_APP_CHAIN_ID === '4' ||
        process.env.REACT_APP_CHAIN_ID === '5' ||
        process.env.REACT_APP_CHAIN_ID === '42'
      ) {
        return false
      } else {
        const hasSetup = await setupNetwork(process.env.REACT_APP_CHAIN_ID)
        console.log(hasSetup)
      }
    }
  }, [chainId])

  const check = useCallback(() => {
    if (isBSC() && (chainId === 97 || chainId === 56)) {
      return true
    }

    if (isEthereum() && (chainId === 1 || chainId === 3 || chainId === 42)) {
      return true
    }

    if (isOKExChain() && chainId === 65) {
      return true
    }

    if (isMatic() && (chainId === 80001 || chainId === 137)) {
      return true
    }

    return isHeco() && (chainId === 256 || chainId === 128)
  }, [chainId])

  useEffect(() => {
    setOK(check())
    setup()
  }, [chainId, check])

  return ok
}
