import ERC20 from '../abi/ERC20.json'
import Web3 from "web3";
import {AccountType} from "../contracts/type";
import {MaxUint256} from "@ethersproject/constants";

export const totalSupply = async (provider: any, tokenAddress: string) => {
  const web3 = new Web3(provider)
  // @ts-ignore
  const contract = new web3.eth.Contract(ERC20.abi, tokenAddress)
  return contract.methods.totalSupply().call()
}

export const balanceOf = async (provider: any, tokenAddress: string, account: null | string | undefined) => {
  const web3 = new Web3(provider)
  // @ts-ignore
  const contract = new web3.eth.Contract(ERC20.abi, tokenAddress)
  return contract.methods.balanceOf(account).call()
}

export const approve = async (
  provider: any,
  tokenAddress: string,
  spender: AccountType,
  amount: string,
  account: AccountType
) => {
  const web3 = new Web3(provider)
  // @ts-ignore
  const contract = new web3.eth.Contract(ERC20.abi, tokenAddress)
  return contract.methods.approve(spender, amount).send({from: account})
}

export const approveMax = async (
  provider: any,
  tokenAddress: string,
  spender: AccountType,
  account: AccountType) => {
  const web3 = new Web3(provider)
  // @ts-ignore
  const contract = new web3.eth.Contract(ERC20.abi, tokenAddress)
  return contract.methods.approve(spender, MaxUint256.toString()).send({from: account})
}

export const allowance = async (
  provider: any,
  tokenAddress: string,
  owner: AccountType,
  spender: string
) => {
  const web3 = new Web3(provider)
  // @ts-ignore
  const contract = new web3.eth.Contract(ERC20.abi, tokenAddress)
  return contract.methods.allowance(owner, spender).call()
}
