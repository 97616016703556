import {AccountType, ChainIDType} from './type'
import {getContract} from '../helpers/web3'
import {getPoolName} from '../constants/contract'
import {BigNumber} from 'bignumber.js'
import {getTokenDecimals} from '../constants/helper'

export const stake = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
  account: AccountType,
  amount: string
) => {
  const contract = getContract(provider, chainID, getPoolName(tokenName))
  const value = new BigNumber(amount).multipliedBy(Math.pow(10, getTokenDecimals(tokenName)))
  return contract.methods.stake(value.toFixed()).send({from: account})
}

export const withdraw = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
  account: AccountType,
  amount: string
) => {
  const contract = getContract(provider, chainID, getPoolName(tokenName))
  return contract.methods
    .withdraw(new BigNumber(amount).multipliedBy(Math.pow(10, getTokenDecimals(tokenName))).toFixed())
    .send({from: account})
}

export const harvest = async (provider: any, chainID: ChainIDType, tokenName: string, account: AccountType) => {
  const contract = getContract(provider, chainID, getPoolName(tokenName))
  return contract.methods.getReward().send({from: account})
}

export const rewardRate = async (provider: any, chainID: ChainIDType, poolName: string) => {
  const contract = getContract(provider, chainID, poolName)
  return contract.methods.rewardRate().call()
}

export const stakingEarned = async (provider: any, chainID: ChainIDType, tokenName: string, account: AccountType) => {
  const contract = getContract(provider, chainID, getPoolName(tokenName))
  return contract.methods.earned(account).call({from: account})
}

export const periodFinish = async (provider: any, chainID: ChainIDType, poolName: string) => {
  const contract = getContract(provider, chainID, poolName)
  return contract.methods.periodFinish().call()
}
