import {axiosInstance} from './config'

const zapperApiKey = '96e0cc51-a62e-42ca-acee-910ea7d2a241'

export const getCoinGeckoPrice = (tokenID: string) => {
  return axiosInstance.get(`/simple/price?ids=${tokenID}&vs_currencies=usd`)
}
export const getZapperFarmsSingleStaking = () => {
  return axiosInstance.get(`https://api.zapper.fi/v1/farms/single-staking?api_key=${zapperApiKey}`)
}
export const getZapperFarmsMasterchef = () => {
  return axiosInstance.get(`https://api.zapper.fi/v1/farms/masterchef?api_key=${zapperApiKey}`)
}
export const getZapperFarmsGauge = () => {
  return axiosInstance.get(`https://api.zapper.fi/v1/farms/gauge?api_key=${zapperApiKey}`)
}
