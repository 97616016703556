import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {useFetch} from 'use-http'
import {useInterval} from '../../../../hooks/useInterval'
import {handleError} from '../../../../helpers'
// @ts-ignore
import Validator from 'multicoin-address-validator'
import {getFinalMintEvents} from '../../../../contracts/dao'
import {useWeb3React} from '@web3-react/core'
import {Context} from '../../../../contexts/BoringProvider'
import {AssetName} from '../../../../contracts/address'
import {ellipseAddress} from '../../../../helpers/display'
import {TOKEN_BTC} from '../../../../constants/token'
import {useActiveWeb3React} from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    id: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      background: '#F1F2F6',
      padding: '15px 20px',
      fontSize: '14px',
      color: '#444C66',
      display: 'flex',
      alignItems: 'center'
    }
  })
)

const MintStepSeven = ({onPrev, onNext, txID, type}: {onPrev: any; type: AssetName; onNext: any; txID: string}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ctx = useContext(Context)
  const {library, chainId} = useActiveWeb3React()
  const {t} = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const req = useFetch('https://sochain.com/api/v2/')
  const [confirmations, setConfirmations] = useState(0)
  const [secondStatus, setSecondStatus] = useState(true)
  const [thirdStatus, setThirdStatus] = useState(true)
  const [fourStatus, setFourStatus] = useState(true)

  const multiAddress = type === TOKEN_BTC ? ctx.multiAddress.btc : ctx.multiAddress.ltc

  const check = () => {
    req
      .get(`/tx/${type}/${txID}`)
      .then(async resp => {
        // 0. check confirmations
        const data = resp.data
        if (!data) {
          return false
        }

        const c = data.confirmations ? data.confirmations : 0
        const address = data.inputs ? data.inputs[0].address : ''
        setConfirmations(c)
        if (c <= 6) {
          return
        }
        console.log(`confirmations: ${c}`)
        // 1. check OP_REETURN
        const {ethAddress, ret} = checkOpReturn(resp)
        console.log(`eth_address: ${ethAddress}`)

        if (!ret) {
          console.log(`get address(${address}) info`)
          req
            .get(`/get_tx_received/${type}/${address}/${txID}`)
            .then(res => {
              for (const tx of res.data.txs) {
                if (c - tx.confirmations < 24) {
                  req
                    .get(`/tx/BTC/${tx.txid}`)
                    .then(res => {
                      for (const input of res.data.inputs) {
                        if (input.address === multiAddress) {
                          setActiveStep(3)
                        }
                      }
                    })
                    .catch(handleError)
                }
              }
            })
            .catch(handleError)
          return
        }

        // 2. check event
        await checkEvent(ethAddress)
      })
      .catch(handleError)
  }

  const checkOpReturn = (res: any) => {
    const outputs = res.data.outputs
    let ret = false
    let eth = ''
    for (const output of outputs) {
      if (output.address === 'nulldata') {
        let asm = output.script_asm
        eth = '0x' + asm.replace('OP_RETURN ', '')
        // @ts-ignore
        if (!Validator.validate(eth, 'eth')) {
          console.error(`eth address is wrong eth address`)
        } else {
          ret = true
        }
      }
    }
    setActiveStep(2)
    setSecondStatus(ret)
    setThirdStatus(ret)
    setFourStatus(ret)
    return {ethAddress: eth, ret}
  }

  const checkEvent = async (ethAddress: string) => {
    let ret = false
    let done = false
    const {successes, fails} = await getFinalMintEvents(library, chainId, type)
    for (let key of Object.keys(fails)) {
      // @ts-ignore
      const addr = fails[key]?.to
      if (addr.toLowerCase() === ethAddress.toLowerCase()) {
        if (key === txID) {
          done = true
          ret = false
        }
      }
    }

    for (let key of Object.keys(successes)) {
      // @ts-ignore
      const addr = successes[key]?.to
      if (addr.toLowerCase() === ethAddress.toLowerCase()) {
        if (key === txID) {
          done = true
          ret = true
        }
      }
    }
    if (done) {
      setActiveStep(3)
      setFourStatus(ret)
    }
    return ret
  }

  useInterval(
    () => {
      check()
    },
    15000,
    true
  )

  useEffect(() => {
    if (confirmations > 6) {
      setActiveStep(1)
    }
  }, [confirmations])

  return (
    <Box>
      <Box mb={6}>
        <Stepper
          alternativeLabel={!isMobile}
          activeStep={activeStep}
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          <Step key={'first'}>
            <StepLabel>
              <Typography variant={'caption'}>
                {t('mint_step_7_1')}({confirmations}/6)
              </Typography>
            </StepLabel>
          </Step>
          <Step key={'second'}>
            <StepLabel>
              <Typography variant={'caption'}>
                {secondStatus && t('mint_step_7_2_right')}
                {!secondStatus && t('mint_step_7_2_wrong')}
              </Typography>
            </StepLabel>
          </Step>
          <Step key={'third'}>
            <StepLabel>
              <Typography variant={'caption'}>
                {thirdStatus && t('mint_step_7_3_right')}
                {!thirdStatus && t('mint_step_7_3_wrong', {type: type})}
              </Typography>
            </StepLabel>
          </Step>
          <Step key={'four'}>
            <StepLabel>
              <Typography variant={'caption'}>
                {fourStatus && t('mint_step_7_4_right')}
                {!fourStatus && t('mint_step_7_4_wrong')}
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Typography variant={'subtitle2'}>TXID</Typography>
      <Box mt={2} mb={4} className={classes.id} style={{width: '100%'}}>
        {isMobile && ellipseAddress(txID, 10)}
        {!isMobile && txID}
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Button
          onClick={onPrev}
          size={'large'}
          style={{marginRight: '10px'}}
          variant={'contained'}
          color={'default'}
          disableElevation
        >
          {t('back')}
        </Button>
        <Button
          onClick={onNext}
          size={'large'}
          style={{marginLeft: '10px'}}
          variant={'contained'}
          color={'primary'}
          disableElevation
        >
          {t('done')}
        </Button>
      </Box>
    </Box>
  )
}

export default MintStepSeven
