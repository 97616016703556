import React from 'react'
import {Box, createStyles, Divider, Grid, makeStyles, Theme, Typography} from '@material-ui/core'
import Lock from '../../../../assets/images/lock.png'
import {BigNumber} from 'bignumber.js'
import {getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BOR} from '../../../../constants/token'
import {displayBalance} from '../../../../helpers/display'
import {useTranslation} from 'react-i18next'
import {useBSCFarmMeta} from "../../../../hooks/useBSCFarmMeta";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    banner: {
      display: 'block',
      width: '100%'
    },
    data: {
      background: '#F2F4FA',
      borderRadius: '10px',
      '& img': {
        marginRight: '5px'
      }
    },
    tokenList: {
      '& img': {
        marginRight: '10px'
      },
      '&>div': {
        '&:not(:last-child)': {
          marginBottom: '20px'
        }
      }
    },
    iconList: {
      '& img': {
        marginRight: '0'
      }
    }
  })
)
const BSCFarmData = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const meta = useBSCFarmMeta()

  return (
    <Box p={4} mt={4} className={classes.data}>
      <Grid container>
        <Grid item sm={8}>
          <Box mt={10}>
            <Box ml={4}>
              <Typography variant="body2">{t('total_staked')}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <img width={13} src={Lock} alt="bor" />
              <Typography variant="h4">
                {new BigNumber(meta.totalPrice).dividedBy(Math.pow(10, 18)).toFormat(0)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box mb={1}>
            <Typography variant="subtitle2" color="secondary">
              {t('your_staked')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img width={11} src={Lock} alt="bor" />
            <Typography variant="h5">{displayBalance(meta?.lockPrice)}</Typography>
          </Box>
          <Box my={4}>
            <Divider />
          </Box>
          <Box mb={1}>
            <Typography variant="subtitle2" color="primary">
              {t('total_rewards')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
            <Typography variant="h5">
              {displayBalance(new BigNumber(meta.unlockAmount).plus(meta.lockAmount).toString())}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(BSCFarmData)
