import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, createStyles, InputBase, makeStyles, MenuItem, Select, Theme, Typography} from '@material-ui/core'
import {getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BTC, TOKEN_LTC, TOKEN_OBTC, TOKEN_ODOGE, TOKEN_OLTC} from '../../../../constants/token'
import {verifyNumericInput} from '../../../../helpers/verify'
import {displayBalance} from '../../../../helpers/display'
import TokenValue from '../../../TokenValue'
import {BurnFee, getMinBurnValue} from '../../../../constants'
import BigNumber from 'bignumber.js'
import {AssetName} from '../../../../contracts/address'
import {WithoutStyleInput} from '../../../Mint/Step/First'
import {Context} from '../../../../contexts/BoringProvider'
// @ts-ignore
import validator from 'multicoin-address-validator'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import ToastContent from '../../../ToastContent'
import {MaxButton} from '../../../MaxButton'
import {useWeb3React} from '@web3-react/core'
import {isEthereum} from '../../../../helpers'
import {useActiveWeb3React} from '../../../../hooks'
import WalletsModal from '../../../Models/Wallets'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      padding: '6px 15px',
      '& input': {
        fontSize: '20px'
      },
      '& img': {
        marginRight: '15px'
      }
    },
    addressInput: {
      '& input': {
        fontSize: '16px'
      }
    },
    waring: {
      minHeight: '20px'
    }
  })
)

const BurnStepFirst = ({
  initAmount,
  onClick,
  onChange,
  onTypeChange,
  onIsPre,
  onAddressChange
}: {
  initAmount: string
  onClick: any
  onChange: any
  onTypeChange?: (v: AssetName) => void
  onIsPre: () => void
  onAddressChange: any
}) => {
  const {chainId, account} = useActiveWeb3React()
  const ctx = useContext(Context)
  const {t} = useTranslation()
  const classes = useStyles()
  const [value, setValue] = useState(initAmount)
  const [address, setAddress] = useState('')
  const [can, setCan] = useState(false)
  const [gain, setGain] = useState('')
  const [type, setType] = useState<AssetName>(TOKEN_BTC)
  const [openWallets, setOpenWallets] = useState(false)

  const tokenBalance = type === TOKEN_BTC ? ctx.balance.oBTC : type === TOKEN_LTC ? ctx.balance.oLTC : ctx.balance.oDOGE

  useEffect(() => {
    setCan(false)
    onChange && onChange(value)
    onAddressChange && onAddressChange(address)
    onTypeChange && onTypeChange(type)

    const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, 18))
    const minBN = new BigNumber(BurnFee).multipliedBy(Math.pow(10, 18))
    setGain(valueBN.minus(minBN).toString())

    const minBurnBN = new BigNumber(getMinBurnValue(chainId, type)).multipliedBy(Math.pow(10, 18))

    if (address !== '') {
      if (valueBN.gte(minBurnBN) && valueBN.lte(tokenBalance)) {
        setCan(true)
        return
      }
    }
  }, [chainId, value, type, onChange, onAddressChange, onTypeChange, address, tokenBalance])

  const noNext = () => {
    // @ts-ignore
    if (!validator.validate(address, type)) {
      toast.info(<ToastContent content={`Invalid ${type} address`} status={false} />)
      return
    }

    onClick()
  }

  const price = () => {
    const p = type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge
    return new BigNumber(value).multipliedBy(p).toString()
  }

  const max = () => setValue(new BigNumber(tokenBalance).dividedBy(Math.pow(10, 18)).toString())

  return (
    <Box>
      <WalletsModal open={openWallets} close={() => setOpenWallets(false)} />
      <Box className={classes.input} display={'flex'} alignItems={'center'}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          value={type}
          onChange={(e: React.ChangeEvent<{value: unknown}>) => {
            // return
            return setType(e.target.value as AssetName)
          }}
          input={<WithoutStyleInput />}
        >
          <MenuItem value={'BTC'}>
            <img width={16} height={16} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
            <Typography variant={'body2'}>oBTC</Typography>
          </MenuItem>
          <MenuItem value={'LTC'}>
            <img width={16} height={16} src={getTokenIcon(TOKEN_OLTC)} alt="oltc" />
            <Typography variant={'body2'}>oLTC</Typography>
          </MenuItem>
          {isEthereum() && (
            <MenuItem value={'DOGE'}>
              <img width={16} height={16} src={getTokenIcon(TOKEN_ODOGE)} alt="odoge" />
              <Typography variant={'body2'}>oDOGE</Typography>
            </MenuItem>
          )}
        </Select>
        <InputBase
          fullWidth={true}
          placeholder="0.00"
          value={value}
          onChange={(e: any) => verifyNumericInput(e, setValue)}
        />
        <MaxButton onClick={max}>Max</MaxButton>
        <Box style={{marginLeft: '10px'}} flexShrink={0}>
          ≈ $ {displayBalance(price(), 18, 2)}
        </Box>
      </Box>
      <Box my={2} textAlign={'right'}>
        {t('balance')}: {displayBalance(tokenBalance)}
      </Box>
      <Box className={classes.input} display={'flex'} alignItems={'center'}>
        <img width={16} height={16} src={getTokenIcon(type)} alt="token" />
        <InputBase
          className={classes.addressInput}
          fullWidth={true}
          placeholder={t('enter_your_address', {type: type})}
          value={address}
          onChange={(e: any) => setAddress(e.target.value)}
        />
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant={'body2'}>{t('you_will_get')}</Typography>
      </Box>
      <TokenValue
        img={getTokenIcon(type)}
        amount={displayBalance(gain, 18, 4)}
        price={type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge}
      />
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4}>
        {account ? (
          <Button
            disabled={!can}
            onClick={noNext}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            disableElevation
          >
            {t('next')}
          </Button>
        ) : (
          <Button
            onClick={() => setOpenWallets(true)}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            disableElevation
          >
            {t('unlock_wallet')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default BurnStepFirst
