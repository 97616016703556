import React from 'react'
import {isBSC, isEthereum, isMatic, isOKExChain} from '../../helpers'
import {getTokenIcon} from '../../constants/icon'
import {TOKEN_BOR} from '../../constants/token'
import {Link, useMediaQuery, useTheme} from '@material-ui/core'
import Logo from '../../assets/images/logo.svg'

const AppLogo = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Link href='https://www.boringdao.com/'>
      {isMobile && isEthereum() && <img height={26} src={getTokenIcon(TOKEN_BOR)} alt="bor" />}
      {!isMobile && isEthereum() && <img height={32} src={getTokenIcon(TOKEN_BOR)} alt="bor" />}
      {isBSC() && <img src='https://raw.githubusercontent.com/BoringDAO/assets/main/images/bor_bsc_logo.png' height={32} alt="logo" />}
      {isOKExChain() && <img src={Logo} height={26} alt="logo" />}
      {isMatic() && <img src='https://raw.githubusercontent.com/BoringDAO/assets/main/images/bor_mactic_logo.png' height={26} alt="logo" />}
    </Link>
  )
}

export default AppLogo
