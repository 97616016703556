import React from 'react'
import {Box, Container, createStyles, makeStyles, Theme, useMediaQuery, useTheme} from '@material-ui/core'
import PoolRow from '../../components/PoolRow'
import {useTranslation} from 'react-i18next'
import {usePools} from '../../hooks/usePools'
import FarmMeta from '../../components/Farm/Meta'
import FarmMobile from '../../components/Farm/Mobile'
import {useTitle} from 'react-use'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    pools: {
      borderRadius: '12px',
      boxShadow: '0px 5px 21px 0px rgba(228, 232, 245, 0.92)',
      background: '#fff'
    },
    unlock: {
      color: '#1164FB'
    }
  })
)

const Farm = () => {
  useTitle("BoringDAO App | Farm(BOR)")
  const classes = useStyles()
  const theme = useTheme()
  const {t} = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const pools = usePools()

  return (
    <Container maxWidth={'lg'}>
      {isMobile ? (
        <FarmMobile />
      ) : (
        <>
          <FarmMeta />
          <Box mt={10}>
            <Box display="flex">
              <Box width={170}>&nbsp;</Box>
              <Box width={175}>POOL</Box>
              <Box width={120}>APR</Box>
              <Box width={170}>{t('total_staked')}</Box>
              <Box width={165}>{t('balance')}</Box>
              <Box width={175}>{t('your_staked')}</Box>
              <Box width={100}>{t('rewards')}</Box>
            </Box>
          </Box>
          <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box style={{width: '140px'}}>{t('manifest_destiny')}</Box>
            <Box flexGrow={1}>
              <PoolRow data={pools.bpp} />
              <PoolRow data={pools.lpp} />
              <PoolRow data={pools.dpp} />
            </Box>
          </Box>
          <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box style={{width: '140px'}}>{t('wild_wild_west')}</Box>
            <Box flexGrow={1}>
              <PoolRow data={pools.obtc} />
              <PoolRow data={pools.oltc} />
              <PoolRow data={pools.odoge} />
            </Box>
          </Box>
          <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box style={{width: '140px'}}>{t('satellite_city')}</Box>
            <Box flexGrow={1}>
              <PoolRow data={pools.dai} />
              <PoolRow data={pools.usdc} />
              <PoolRow data={pools.usdt} />
              <PoolRow data={pools.weth} />
              <PoolRow data={pools.yfi} />
              <PoolRow data={pools.yfii} />
              <PoolRow data={pools.link} />
              <PoolRow data={pools.nest} />
              <PoolRow data={pools.band} />
              <PoolRow data={pools.hbtc} />
            </Box>
          </Box>
        </>
      )}
    </Container>
  )
}

export default Farm
