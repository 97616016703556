import React from 'react'
import {Box, Dialog, Grid, makeStyles} from '@material-ui/core'
import {getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BTC, TOKEN_DOGE, TOKEN_LTC, TOKEN_OBTC, TOKEN_ODOGE, TOKEN_OLTC} from '../../../../constants/token'
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {isEthereum} from '../../../../helpers'

const useStyles = makeStyles({
  type: {
    cursor: 'pointer',
    color: '#363636',
    '&.active, &:hover': {
      color: '#1164fb',
      '& $logo': {
        border: '1px solid #1164FB'
      }
    }
  },
  logo: {
    width: '88px',
    height: '88px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e1e3e5',
    borderRadius: '20px'
  }
})

const PledgeSelectModal = ({open, onClose, setType}: {open: boolean; onClose: any; setType: any}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const location = useLocation()
  const isBTCPledge = location.pathname.includes('/pledge/BTC')
  const isLTCPlege = location.pathname.includes('/pledge/LTC')
  const isDOGEPlege = location.pathname.includes('/pledge/DOGE')
  const btcClass = `${classes.type} ${isBTCPledge ? 'active' : ''}`
  const ltcClass = `${classes.type} ${isLTCPlege ? 'active' : ''}`
  const dogeClass = `${classes.type} ${isDOGEPlege ? 'active' : ''}`

  return (
    <Dialog open={open} onClose={onClose} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={{xs: 6, md: 10}}>
        <Grid container>
          <Grid item xs={isEthereum() ? 4 : 6}>
            <NavLink
              to={'/pledge/BTC'}
              onClick={() => {
                setType(TOKEN_BTC)
                onClose()
              }}
            >
              <Box className={btcClass} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box className={classes.logo} mb={2}>
                  <img width={48} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                </Box>
                oBTC {t('tunnel')}
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={isEthereum() ? 4 : 6}>
            <NavLink
              to={'/pledge/LTC'}
              onClick={() => {
                setType(TOKEN_LTC)
                onClose()
              }}
            >
              <Box className={ltcClass} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box className={classes.logo} mb={2}>
                  <img width={48} src={getTokenIcon(TOKEN_OLTC)} alt="oltc" />
                </Box>
                oLTC {t('tunnel')}
              </Box>
            </NavLink>
          </Grid>
          {isEthereum() && (
            <Grid item xs={4}>
              <NavLink
                to={'/pledge/DOGE'}
                onClick={() => {
                  setType(TOKEN_DOGE)
                  onClose()
                }}
              >
                <Box className={dogeClass} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Box className={classes.logo} mb={2}>
                    <img width={48} src={getTokenIcon(TOKEN_ODOGE)} alt="odoge" />
                  </Box>
                  oDOGE {t('tunnel')}
                </Box>
              </NavLink>
            </Grid>
          )}
        </Grid>
      </Box>
    </Dialog>
  )
}

export default PledgeSelectModal
