export const TOKEN_BTC = 'BTC'
export const TOKEN_LTC = 'LTC'
export const TOKEN_DOGE = 'DOGE'
export const TOKEN_BOR = 'BOR'
export const TOKEN_BORING = 'BORING'
export const TOKEN_OBTC = 'OBTC'
export const TOKEN_OLTC = 'OLTC'
export const TOKEN_NEW_OLTC = 'NEW_OLTC'
export const TOKEN_ODOGE = 'ODOGE'
export const TOKEN_NEW_ODOGE = 'NEW_ODOGE'
export const TOKEN_BTC_PPTOKEN = 'BTC_PPT'
export const TOKEN_LTC_PPTOKEN = 'LTC_PPT'
export const TOKEN_DOGE_PPTOKEN = 'DOGE_PPT'
export const TOKEN_BORING_BTC_PPTOKEN = 'BORING_BTC_PPT'
export const TOKEN_BORING_LTC_PPTOKEN = 'BORING_LTC_PPT'
export const TOKEN_BORING_DOGE_PPTOKEN = 'BORING_DOGE_PPT'
export const TOKEN_DAI = 'DAI'
export const TOKEN_USDC = 'USDC'
export const TOKEN_USDT = 'USDT'
export const TOKEN_WETH = 'WETH'
export const TOKEN_YFI = 'YFI'
export const TOKEN_YFII = 'YFII'
export const TOKEN_LINK = 'LINK'
export const TOKEN_NEST = 'NEST'
export const TOKEN_BAND = 'BAND'
export const TOKEN_HBTC = 'HBTC'
export const TOKEN_RENBTC = 'RENBTC'
export const TOKEN_WBTC = 'WBTC'
export const TOKEN_ETH = 'ETH'
export const TOKEN_BNB = 'BNB'
export const TOKEN_BSC = 'BSC'
export const TOKEN_OKT = 'OKT'
export const TOKEN_HT = 'HT'
export const TOKEN_BOR_OBTC = 'BOR_OBTC'
export const TOKEN_BOR_BNB = 'BOR_BNB'
export const TOKEN_BOR_USDT = 'BOR_USDT'
export const TOKEN_BOR_BUSD = 'BOR_BUSD'
export const TOKEN_CAKE_BNB = 'CAKE_BNB'
export const TOKEN_CAKE = 'CAKE'
export const TOKEN_ETH_BOR_ETH = 'ETH_BOR_ETH'
export const TOKEN_ETH_OBTC_WETH = 'ETH_OBTC_WETH'
export const TOKEN_SUSHI = 'SUSHI'
export const TOKEN_DODO = 'DODO'
export const TOKEN_UNI = 'UNi'

export const TOKEN_CURVE = 'CURVE'
export const TOKEN_ALPHA = 'ALPHA'
export const TOKEN_HARVEST = 'HARVEST'
export const TOKEN_MPH = 'MPH'
export const TOKEN_AC = 'AC'
export const TOKEN_FORTUBE = 'FORTUBE'

export const TOKEN_WAULT = 'WAULT'
export const TOKEN_POLYGON = 'POLYGON'
export const TOKEN_QUICKSWAP = 'QUICKSWAP'

export const TOKEN_BANCOR = 'BANCOR'
export const TOKEN_KSWAP = 'KSWAP'
export const TOKEN_SUNDER = 'SUNDER'

export const TokenModal: {[key: string]: any} = {
  [TOKEN_SUNDER]: {
    priceName: TOKEN_SUNDER,
    ctxPriceName: TOKEN_SUNDER,
    showName: 'SUNDER',
    toFixed: 0,
    pid: 13
  },
  [TOKEN_ETH_BOR_ETH]: {
    priceName: TOKEN_ETH_BOR_ETH,
    showName: 'BOR-ETH LP',
    pid: 70,
    ping: TOKEN_BOR,
    pong: TOKEN_ETH
  },
  [TOKEN_DODO]: {
    priceName: TOKEN_DODO,
    tokenPriceID: 'dodo',
    showName: 'DODO',
    pid: 1
  },
  [TOKEN_ETH_OBTC_WETH]: {
    priceName: TOKEN_ETH_OBTC_WETH,
    showName: 'OBTC-WETH LP',
    pid: 81,
    ping: TOKEN_OBTC,
    pong: TOKEN_ETH
  },
  [TOKEN_BOR_OBTC]: {
    priceName: TOKEN_BOR_OBTC,
    showName: 'PP Token(oBTC)'
  },
  [TOKEN_BOR_BNB]: {
    priceName: TOKEN_BOR_BNB,
    showName: 'BOR-BNB LP',
    pid: 308,
    ping: TOKEN_BOR
  },
  [TOKEN_BOR]: {
    tokenPriceID: 'boringdao',
    showName: 'BOR'
  },
  [TOKEN_BORING]: {
    priceName: TOKEN_BOR,
    tokenPriceID: 'boringdao',
    ctxPriceName: TOKEN_BORING,
    showName: 'BORING',
    toFixed: 0,
    pid: 12
  },
  [TOKEN_CAKE]: {
    tokenPriceID: 'pancakeswap-token'
  },
  [TOKEN_CAKE_BNB]: {
    priceName: TOKEN_CAKE_BNB,
    showName: 'CAKE-BNB LP',
    pid: 1,
    ping: TOKEN_CAKE,
    pong: TOKEN_BNB
  },
  [TOKEN_BOR_USDT]: {
    priceName: TOKEN_BOR_USDT,
    showName: 'PP Token(oBTC)'
  },
  [TOKEN_BOR_BUSD]: {
    priceName: TOKEN_BOR_BUSD,
    showName: 'PP Token(oBTC)'
  },
  [TOKEN_BTC_PPTOKEN]: {
    tokenPriceID: 'boringdao',
    priceName: TOKEN_BOR,
    showName: 'PP Token(oBTC)',
    pid: 0
  },
  [TOKEN_BORING_BTC_PPTOKEN]: {
    tokenPriceID: 'boringdao',
    priceName: TOKEN_BOR,
    ctxPriceName: TOKEN_BORING,
    showName: 'PP Token(oBTC)',
    pid: 0,
    toFixed: 0
  },
  [TOKEN_BORING_LTC_PPTOKEN]: {
    tokenPriceID: 'boringdao',
    priceName: TOKEN_BOR,
    ctxPriceName: TOKEN_BORING,
    showName: 'PP Token(oLTC)',
    pid: 6,
    toFixed: 0
  },
  [TOKEN_BORING_DOGE_PPTOKEN]: {
    tokenPriceID: 'boringdao',
    priceName: TOKEN_BOR,
    ctxPriceName: TOKEN_BORING,
    showName: 'PP Token(oDoge)',
    pid: 8,
    toFixed: 0
  },
  [TOKEN_LTC_PPTOKEN]: {
    priceName: TOKEN_BOR,
    showName: 'PP Token(oLTC)'
  },
  [TOKEN_DOGE_PPTOKEN]: {
    priceName: TOKEN_BOR,
    showName: 'PP Token(oDOGE)'
  },
  [TOKEN_OBTC]: {
    pid: 1,
    priceName: 'BTC',
    ctxPriceName: TOKEN_BTC,
    showName: 'oBTC',
    tokenPriceID: 'boringdao-btc'
  },
  [TOKEN_OLTC]: {
    pid: 7,
    priceName: 'LTC',
    ctxPriceName: TOKEN_LTC,
    showName: 'oLTC',
    tokenPriceID: 'litecoin'
  },
  [TOKEN_NEW_OLTC]: {
    pid: 10,
    priceName: 'LTC',
    showName: 'oLTC',
    tokenPriceID: 'litecoin'
  },
  [TOKEN_ODOGE]: {
    pid: 9,
    priceName: 'DOGE',
    ctxPriceName: TOKEN_DOGE,
    showName: 'oDOGE',
    tokenPriceID: 'dogecoin'
  },
  [TOKEN_NEW_ODOGE]: {
    pid: 11,
    priceName: 'DOGE',
    showName: 'oDOGE',
    tokenPriceID: 'dogecoin'
  },
  [TOKEN_DAI]: {
    tokenPriceID: 'dai',
    priceName: TOKEN_DAI,
    ctxPriceName: TOKEN_DAI,
    showName: 'DAI',
    pid: 5
  },
  [TOKEN_USDC]: {
    tokenPriceID: 'dai',
    priceName: TOKEN_USDC,
    ctxPriceName: TOKEN_USDC,
    showName: 'USDC',
    decimals: 6,
    pid: 4
  },
  [TOKEN_USDT]: {
    tokenPriceID: 'dai',
    priceName: TOKEN_USDT,
    ctxPriceName: TOKEN_USDT,
    showName: 'USDT',
    decimals: 6,
    pid: 3
  },
  [TOKEN_WETH]: {
    tokenPriceID: 'ethereum',
    priceName: TOKEN_WETH,
    ctxPriceName: TOKEN_WETH,
    showName: 'WETH',
    pid: 2
  },
  [TOKEN_YFI]: {
    priceName: TOKEN_YFI,
    showName: 'YFI'
  },
  [TOKEN_YFII]: {
    priceName: TOKEN_YFII,
    showName: 'YFII'
  },
  [TOKEN_LINK]: {
    priceName: TOKEN_LINK,
    showName: 'LINK'
  },
  [TOKEN_NEST]: {
    priceName: TOKEN_NEST,
    showName: 'NEST'
  },
  [TOKEN_BAND]: {
    priceName: TOKEN_BAND,
    showName: 'BAND'
  },
  [TOKEN_HBTC]: {
    priceName: TOKEN_HBTC,
    showName: 'HBTC'
  }
}
