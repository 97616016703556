import React, {useEffect, useState} from 'react'
import {Box, Button, CircularProgress, Dialog, InputBase, makeStyles, Typography} from '@material-ui/core'
import {Datum} from '../../../../hooks/usePool'
import {getTokenIcon} from '../../../../constants/icon'
import {verifyNumericInput} from '../../../../helpers/verify'
import {displayBalance} from '../../../../helpers/display'
import {getTokenDecimals} from '../../../../constants/helper'
import {useWeb3React} from '@web3-react/core'
import {BigNumber} from 'bignumber.js'
import {MaxButton} from '../../../MaxButton'
import {useTranslation} from 'react-i18next'
import {cakeDeposit} from '../../../../contracts/pancake'
import {TokenModal} from '../../../../constants/token'
import {cityDeposit} from '../../../../contracts/city'
import {useActiveWeb3React} from '../../../../hooks'

const useStyles = makeStyles({
  grow: {
    flexGrow: 1
  },
  input: {
    border: '1px solid #D8E0ED',
    borderRadius: '6px',
    padding: '6px 15px',
    '& input': {
      fontSize: '20px'
    },
    '& img': {
      marginRight: '15px'
    }
  }
})

const CityFarmStakeModal = ({open, onClose, data}: {open: boolean; onClose: any; data: Datum}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const [value, setValue] = useState('')
  const decimals = getTokenDecimals(data.tokenName)
  const [doing, setDoing] = useState(false)
  const [can, setCan] = useState(false)
  const pid = TokenModal[data.tokenName].pid

  const staking = async () => {
    try {
      setDoing(true)
      console.log(`Deposit(pid: ${pid}, value: ${value})`)
      await cityDeposit(library, chainId, data.tokenName, account, pid, value)
    } catch (e) {
      console.log(e)
    }
    setDoing(false)
  }

  useEffect(() => {
    setCan(false)
    const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, getTokenDecimals(data.tokenName)))
    if (valueBN.gt(0) && valueBN.lte(data.userTokenBalance)) {
      setCan(true)
    }
  }, [data, value])

  const max = () => setValue(new BigNumber(data.userTokenBalance).dividedBy(Math.pow(10, decimals)).toString())

  return (
    <Dialog open={open} onClose={onClose} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={8}>
        <Typography variant={'subtitle2'} align={'center'}>
          {t('stake')}
        </Typography>
        <Box className={classes.input} display={'flex'} alignItems={'center'} mt={6}>
          <img src={getTokenIcon(data.tokenName)} height={16} alt="token" />
          <InputBase
            fullWidth={true}
            placeholder="0.00"
            value={value}
            onChange={(e: any) => verifyNumericInput(e, setValue)}
          />
          <MaxButton onClick={max}>Max</MaxButton>
        </Box>
        <Box my={2} textAlign={'right'}>
          {t('balance')}: {displayBalance(data.userTokenBalance, decimals)}
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4}>
          <Button
            onClick={onClose}
            size={'large'}
            fullWidth={true}
            style={{marginRight: '10px'}}
            variant={'contained'}
            color={'default'}
            disableElevation
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={staking}
            fullWidth={true}
            size={'large'}
            style={{marginLeft: '10px'}}
            variant={'contained'}
            color={'primary'}
            disabled={!can}
            disableElevation
          >
            {doing && <CircularProgress size={18} color={'inherit'} />}
            {t('stake')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default CityFarmStakeModal
