import {AccountType, ChainIDType} from './type'
import {getContract} from '../helpers/web3'
import {CONTRACT_MASTER_CHEF} from '../constants/contract'
import web3 from "web3";

export const cakeDeposit = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.deposit(pid, web3.utils.toWei(amount)).send({from: account})
}

export const cakeWithdraw = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
  amount: string
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.withdraw(pid, web3.utils.toWei(amount)).send({from: account})
}

export const cakeClaim = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.deposit(pid, 0).send({from: account})
}


export const cakePendingCake = async (
  provider: any,
  chainID: ChainIDType,
  account: AccountType,
  pid: number,
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.pendingCake(pid, account).call()
}

export const cakeUserInfo = async (
  provider: any,
  chainID: ChainIDType,
  pid: number,
  account: AccountType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.userInfo(pid, account).call()
}

export const cakePoolInfo = async (
  provider: any,
  chainID: ChainIDType,
  pid: number
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.poolInfo(pid).call()
}

export const cakeTotalAllocPoint = async (
  provider: any,
  chainID: ChainIDType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.totalAllocPoint().call()
}

export const cakeCakePerBlock = async (
  provider: any,
  chainID: ChainIDType,
) => {
  const contract = getContract(provider, chainID, CONTRACT_MASTER_CHEF)
  return contract.methods.cakePerBlock().call()
}

export const cakeGetReserves = async (
  provider: any,
  chainID: ChainIDType,
  tokenName: string,
) => {
  const contract = getContract(provider, chainID, tokenName)
  return contract.methods.getReserves().call()
}

