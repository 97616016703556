import BoringDAO from '../abi/BoringDAO.json'
import BOR from '../abi/BORToken.json'
import OBTC from '../abi/BToken.json'
import Tunnel from '../abi/Tunnel.json'
import FeePool from '../abi/FeePool.json'
import Oracle from '../abi/Oracle.json'
import TimeDistribution from '../abi/TimeDistribution.json'
import StakingRewards from '../abi/StakingRewards.json'
import ParamBook from '../abi/ParamBook.json'
import AddressBook from '../abi/AddressBook.json'
import Barter from '../abi/Barter.json'
import BarterSwap from '../abi/BarterSwapOBTC.json'
import CrossLock from '../abi/CrossLock.json'
import BORBSC from '../abi/BorBSC.json'
import MasterChef from '../abi/MasterChef.json'
import BoringChef from '../abi/BoringChef.json'
import PancakePair from '../abi/PancakePair.json'
import SushiMasterChef from '../abi/SushiMasterChef.json'
import BoringV2 from '../abi/BoringV2.json'
import Stake33Lock from '../abi/Staking33.json'

import {
  TOKEN_BAND,
  TOKEN_BOR,
  TOKEN_BOR_BNB,
  TOKEN_BORING,
  TOKEN_BORING_BTC_PPTOKEN,
  TOKEN_BORING_DOGE_PPTOKEN,
  TOKEN_BORING_LTC_PPTOKEN,
  TOKEN_BTC,
  TOKEN_BTC_PPTOKEN,
  TOKEN_CAKE_BNB,
  TOKEN_DAI,
  TOKEN_DODO,
  TOKEN_DOGE,
  TOKEN_DOGE_PPTOKEN,
  TOKEN_ETH_BOR_ETH,
  TOKEN_ETH_OBTC_WETH,
  TOKEN_HBTC,
  TOKEN_LINK,
  TOKEN_LTC,
  TOKEN_LTC_PPTOKEN,
  TOKEN_NEST,
  TOKEN_NEW_ODOGE,
  TOKEN_NEW_OLTC,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_RENBTC,
  TOKEN_SUNDER,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WBTC,
  TOKEN_WETH,
  TOKEN_YFI,
  TOKEN_YFII
} from './token'

export const CONTRACT_ORACLE = 'Oracle'
export const CONTRACT_PARAM_BOOK = 'ParamBook'
export const CONTRACT_ADDRESS_BOOK = 'AddressBook'
export const CONTRACT_BARTER = 'Barter'
export const CONTRACT_BARTER_SWAP = 'BarterSwap'
export const CONTRACT_TIME_DISTRIBUTION = 'TimeDistribution'
export const CONTRACT_TIME_DISTRIBUTION_MONTH = 'TimeDistributionMonth'
export const CONTRACT_CROSS_LOCK = 'CrossLock'
export const CONTRACT_BOR_BSC = 'BORBSC'
export const CONTRACT_MASTER_CHEF = 'MasterChef'
export const CONTRACT_SUSHI_MASTER_CHEF = 'SushiMasterChef'
export const CONTRACT_BORING_V2 = 'BoringV2'
export const CONTRACT_SATELLITE_CITY = 'SatelliteCity'
export const CONTRACT_BORING_CHEF_LOCK = 'BoringChefLock'

export const getPoolName = (tokenName: string) => tokenName + 'Pool'
export const getTunnelName = (tokenName: string) => tokenName + 'Tunnel'
export const getBoringTunnelName = (tokenName: string) => tokenName + 'BoringTunnel'
export const getFeePoolName = (tokenName: string) => tokenName + 'FeePool'
export const getBoringFeePoolName = (tokenName: string) => tokenName + 'BoringFeePool'
export const getBoringDAOName = (tokenName: string) => tokenName + 'BoringDAO'
export const getBoringBoringDAOName = (tokenName: string) => tokenName + 'BoringBoringDAO'

export interface ContractSetup {
  addresses: {
    [chainID: number]: string
  }
  abi: any[]
}

export const CONTRACTS: {[name: string]: ContractSetup} = {
  [CONTRACT_BORING_V2]: {
    addresses: {
      1: '0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
      42: '0xa9C744B12AB13Cd4cAC6f3cbbE33113d5DBB09Ee',
      56: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F'
    },
    abi: BoringV2.abi
  },
  [TOKEN_BORING]: {
    addresses: {
      1: '0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
      42: '0x55FB3193F6e42D6753691c8aA38800B2753FB7d0',
      56: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
      97: '0xd5fb474DCd065d243C37F52Ad3815e0d8bc906ED'
    },
    abi: BoringV2.abi
  },
  [getBoringDAOName(TOKEN_BTC)]: {
    addresses: {
      1: '0xe3547ba476907cebe554ab2a1c9f64378fb14f3b',
      3: '0xCB6138CB08B145750A51B7b7243E532e441C42F5', // 1.1
      56: '0x8E3b62EDD67F14c1CcfAdE0EA302a228e8796D7C',
      97: '0x08DE288650B5DaE42a18Caef64720aABf0300151'
    },
    abi: BoringDAO.abi
  },
  [getBoringDAOName(TOKEN_LTC)]: {
    addresses: {
      1: '0x58e25cCD2843caE992eFBC15C8D4C64f4f70809A',
      3: '0x5b846eD0889Bf507D2A721d81e694aFE674bB731', // 1.1
      56: '0x8E3b62EDD67F14c1CcfAdE0EA302a228e8796D7C',
      97: '0x08DE288650B5DaE42a18Caef64720aABf0300151'
    },
    abi: BoringDAO.abi
  },
  [getBoringDAOName(TOKEN_DOGE)]: {
    addresses: {
      1: '0x58e25cCD2843caE992eFBC15C8D4C64f4f70809A',
      3: '0x5b846eD0889Bf507D2A721d81e694aFE674bB731'
    },
    abi: BoringDAO.abi
  },
  [getBoringBoringDAOName(TOKEN_BTC)]: {
    addresses: {
      1: '0x77F79FEa3d135847098Adb1fdc6B10A0218823F5'
    },
    abi: BoringDAO.abi
  },
  [getBoringBoringDAOName(TOKEN_LTC)]: {
    addresses: {
      1: '0x77F79FEa3d135847098Adb1fdc6B10A0218823F5'
    },
    abi: BoringDAO.abi
  },
  [getBoringBoringDAOName(TOKEN_DOGE)]: {
    addresses: {
      1: '0x77F79FEa3d135847098Adb1fdc6B10A0218823F5'
    },
    abi: BoringDAO.abi
  },
  [getBoringTunnelName(TOKEN_BTC)]: {
    addresses: {
      1: '0x6ADff7bedD9D7BF4794421D078CCED17AA4EC39c'
    },
    abi: Tunnel.abi
  },
  [getBoringTunnelName(TOKEN_LTC)]: {
    addresses: {
      1: '0x457EacbD56eB29f8Ec75c22fCC549e6b2D8cCde9'
    },
    abi: Tunnel.abi
  },
  [getBoringTunnelName(TOKEN_DOGE)]: {
    addresses: {
      1: '0xd0c4b4fBF2a076A9d936F34809E445eBe926Fbee'
    },
    abi: Tunnel.abi
  },
  [getTunnelName(TOKEN_BTC)]: {
    addresses: {
      1: '0x258a1eb6537ae84cf612f06b557b6d53f49cc9a1',
      3: '0xd30dA60559B55EFa8A9B2ABBD019bAcAa9cd5001',
      56: '0xdd096DE00814f83c621EdEb837b26107470974Fb',
      97: '0xC576BB22c782295F0F135f0aba603EDEf8c1E29D'
    },
    abi: Tunnel.abi
  },
  [getTunnelName(TOKEN_LTC)]: {
    addresses: {
      1: '0xD7D997Dd57114E1e2d64ab8c0d767A0d6b2426F0',
      3: '0x7d1e3De9Eed8Ff32f464E3a05b8F766F240958fC',
      56: '0x4502eea15ef70256e3903643e3191ea54f0588A6',
      97: '0xa589d847AE0c2cb09F05a6793A85fCA494BC16F5'
    },
    abi: Tunnel.abi
  },
  [getTunnelName(TOKEN_DOGE)]: {
    addresses: {
      1: '0x22b1AC6B2d55ade358E5b7f4281ed1Dd2f6f0077',
      3: '0x8B351152C4808CE1aF10838D624D7685Ad538768'
    },
    abi: Tunnel.abi
  },
  [getFeePoolName(TOKEN_BTC)]: {
    addresses: {
      1: '0xd34b969b891ec5e58d71a0b202edac9574bf9d63',
      3: '0x76D3c148ca3fa3cA4539c11d408bFba7DFBA5cb6',
      56: '0xAD2f7fFa140E0D1B82e1cD584D5bE1F232dD90FF',
      97: '0xf50ed0b1F50108eFC46333a34cf98bb00229A87B'
    },
    abi: FeePool.abi
  },
  [getFeePoolName(TOKEN_LTC)]: {
    addresses: {
      1: '0x06D95481Af387e0940a11560f5A34691eA0E4386',
      3: '0x40a3A997116aC5e2cCEdF11E381382fbde1e3677',
      56: '0x09378D347E56C2CA0Ff6Bc52A26BEB8EA259D79b',
      97: '0x3Ca6A7A39e44d45C1F149067FA78D06041907186'
    },
    abi: FeePool.abi
  },
  [getFeePoolName(TOKEN_DOGE)]: {
    addresses: {
      1: '0x5387AeD486B9Ee6AC7833640F8ddFD6BBcD01d96',
      3: '0x4B99E723E08135aF24610b78836D3F89cf039f7E'
    },
    abi: FeePool.abi
  },
  [getBoringFeePoolName(TOKEN_BTC)]: {
    addresses: {
      1: '0x2b781634e4cb0b5236cC957DABA88F911FD66fCD'
    },
    abi: FeePool.abi
  },
  [getBoringFeePoolName(TOKEN_LTC)]: {
    addresses: {
      1: '0xC23F4d5AC44A7d8e56c70741e6205dAF3F10f998'
    },
    abi: FeePool.abi
  },
  [getBoringFeePoolName(TOKEN_DOGE)]: {
    addresses: {
      1: '0x0e2d371F2A9925219d4e936e8F227F0Ab9D4dBDe'
    },
    abi: FeePool.abi
  },
  [CONTRACT_TIME_DISTRIBUTION]: {
    addresses: {
      1: '0x03f94b04387da475ee3b80bcbe2cb92a2d7f0687'
    },
    abi: TimeDistribution.abi
  },
  [CONTRACT_TIME_DISTRIBUTION_MONTH]: {
    addresses: {
      1: '0x170A0406549D5dE65e5605460Ef0BadC0E5c069d',
      3: '0x82f4F4f2Fc23A0ac3ed58302f45e229f2c76830C'
    },
    abi: TimeDistribution.abi
  },
  [CONTRACT_ORACLE]: {
    addresses: {
      1: '0xADee8b009d8e4c3D95bf415c7EEF53d8F9147716',
      3: '0xF178D2EE54Dbfb22b06404667a2c2846EEc76414',
      56: '0x4D051416321c532dC775b4327051C4521483d987',
      97: '0x93eF5889DB8B3bbe9158ac97e49b5EE653907f1A',
      137: '0xAC1B9afAccC359b4d51D4b71Efae2425cDbB758b'
    },
    abi: Oracle.abi
  },
  [CONTRACT_PARAM_BOOK]: {
    addresses: {
      1: '0xbc91c117157d7c47135b13c97ec4a46fa1ca1ab1',
      3: '0x727BE15ca0458DC8A70f7F342CF85820e80C688A',
      56: '0x6122611EFBf744ff4bbF31EFd2522b37Fc37C0c5',
      97: '0x797051A08a1753b3E054DDC1245AD812DAe9050F'
    },
    abi: ParamBook.abi
  },
  [CONTRACT_ADDRESS_BOOK]: {
    addresses: {
      1: '0x1b0e954aa1b88791c8251e8328287f3feb96d719',
      3: '0x90371aE783E8689eB76D4A01E65feBb50AC06B39',
      56: '0x864bFfBBa1d0B350711fE61ba690d24D3f37C976',
      97: '0xe47d46f0c3DD2EDDa83f3508645cbAf3a6FC2550'
    },
    abi: AddressBook.abi
  },
  [CONTRACT_BARTER]: {
    addresses: {
      1: '0x9E6C268DEE2638Be72b1aCBD8afF88117bA4f9b8'
    },
    abi: Barter.abi
  },
  [CONTRACT_BARTER_SWAP]: {
    addresses: {
      1: '0xd81dA8D904b52208541Bade1bD6595D8a251F8dd'
    },
    abi: BarterSwap.abi
  },
  [TOKEN_BOR]: {
    addresses: {
      1: '0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9',
      3: '0xC877Ec4f22317A30BA04A17796d4497490A76e22',
      56: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
      97: '0x4261bb282EB6bb678A693B4684DBdD07a0d3D245',
      256: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
      42: '0x257c512f2532dE45C1B88c9E6ceE12d3bF943596',
      65: '0x4ee9Ef559D9219c54b0Bc06f3B0aB17a52ef936e',
      137: '0x7d8c139d5bfBafdfD1D2D0284e7fC862babE4137',
      80001: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED'
    },
    abi: BOR.abi
  },
  [CONTRACT_BOR_BSC]: {
    addresses: {
      56: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
      97: '0x4261bb282EB6bb678A693B4684DBdD07a0d3D245',
      65: '0x4ee9Ef559D9219c54b0Bc06f3B0aB17a52ef936e'
    },
    abi: BORBSC.abi
  },
  [TOKEN_BTC_PPTOKEN]: {
    addresses: {
      1: '0xae5096c8d5c5461f3f65904f7adca932807e14f7',
      3: '0xBbAE6314fa4fA80F6BA572f8796654424dfd233F',
      56: '0x8119e88df7Cf4836c51d5dA958C0F89136bEfBfB',
      97: '0x7a020baA57551E24111a8b5539198e49EAFf78bB'
    },
    abi: BOR.abi
  },
  [TOKEN_LTC_PPTOKEN]: {
    addresses: {
      1: '0x8816cEDc6bB4AdbD7E0F3bbB6dE9EBBD0f7C7f00',
      3: '0x212668Ddb74d3E715C1aD00B1e7167ED70f7FEA6',
      56: '0x5e8480A069572B6876E720EC659A52fAE36dc038',
      97: '0x40B537A25aAE75aA4c857EF680076481b77811e2'
    },
    abi: BOR.abi
  },
  [TOKEN_DOGE_PPTOKEN]: {
    addresses: {
      1: '0x8b2eAF139fb7Af0C6De2481F0cADbceb5CF224C2',
      3: '0x73625161393E1CE7389c73b9e709bbE3A285307D'
    },
    abi: BOR.abi
  },
  [TOKEN_BORING_BTC_PPTOKEN]: {
    addresses: {
      1: '0x6C189Baa963060DAEEC77B7305b055216090bFC4'
    },
    abi: BOR.abi
  },
  [TOKEN_BORING_LTC_PPTOKEN]: {
    addresses: {
      1: '0x48c57b4E50Ae145E36d1b26D3470A6b9a68F3Ee9'
    },
    abi: BOR.abi
  },
  [TOKEN_BORING_DOGE_PPTOKEN]: {
    addresses: {
      1: '0xD221f7F04b2055E2f937063D415D6BcDCc1D70De'
    },
    abi: BOR.abi
  },
  [TOKEN_OBTC]: {
    addresses: {
      1: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
      3: '0x04fF10c7AFc9747aE39b52422A7682a5df28c229',
      56: '0x0314fc7180dD2A4370A4039ccf11f2C1a4a9C33B',
      97: '0x03eD9220fa63F7c7Fc1aEaE44C992B559Ba5D62A',
      137: '0x90fB380DdEbAF872cc1F8d8e8C604Ff2f4697c19',
      80001: '0xFd164af933a49594c6f3F9B210c753c2e9b5c6A2'
    },
    abi: OBTC.abi
  },
  [TOKEN_OLTC]: {
    addresses: {
      1: '0x07C44B5Ac257C2255AA0933112c3b75A6BFf3Cb1',
      3: '0x75Cd6127E42072ffFd51B6f2108755CEAa6e597E',
      56: '0xd4694898DDf455bA703446Bba52034d1212B24D9',
      97: '0x00c072D3B2a8f511Ee0e0Bf53400923495BEc6a0'
    },
    abi: OBTC.abi
  },
  [TOKEN_NEW_OLTC]: {
    addresses: {
      1: '0x07C44B5Ac257C2255AA0933112c3b75A6BFf3Cb1',
      3: '0x75Cd6127E42072ffFd51B6f2108755CEAa6e597E',
      56: '0xd4694898DDf455bA703446Bba52034d1212B24D9',
      97: '0x00c072D3B2a8f511Ee0e0Bf53400923495BEc6a0'
    },
    abi: OBTC.abi
  },
  [TOKEN_ODOGE]: {
    addresses: {
      1: '0x9c306A78b1a904e83115c05Ac67c1Ef07C653651',
      3: '0x54512E183456B2e3c240Dc9a773cD5c1010BF768'
    },
    abi: OBTC.abi
  },
  [TOKEN_NEW_ODOGE]: {
    addresses: {
      1: '0x9c306A78b1a904e83115c05Ac67c1Ef07C653651',
      3: '0x54512E183456B2e3c240Dc9a773cD5c1010BF768'
    },
    abi: OBTC.abi
  },
  [TOKEN_DAI]: {
    addresses: {
      1: '0x6b175474e89094c44da98b954eedeac495271d0f'
    },
    abi: BOR.abi
  },
  [TOKEN_USDC]: {
    addresses: {
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
    },
    abi: BOR.abi
  },
  [TOKEN_USDT]: {
    addresses: {
      1: '0xdac17f958d2ee523a2206206994597c13d831ec7'
    },
    abi: OBTC.abi
  },
  [TOKEN_WETH]: {
    addresses: {
      1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
    },
    abi: OBTC.abi
  },
  [TOKEN_YFI]: {
    addresses: {
      1: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e'
    },
    abi: OBTC.abi
  },
  [TOKEN_YFII]: {
    addresses: {
      1: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83'
    },
    abi: OBTC.abi
  },
  [TOKEN_LINK]: {
    addresses: {
      1: '0x514910771af9ca656af840dff83e8264ecf986ca'
    },
    abi: OBTC.abi
  },
  [TOKEN_NEST]: {
    addresses: {
      1: '0x04abeda201850ac0124161f037efd70c74ddc74c'
    },
    abi: OBTC.abi
  },
  [TOKEN_BAND]: {
    addresses: {
      1: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55'
    },
    abi: OBTC.abi
  },
  [TOKEN_HBTC]: {
    addresses: {
      1: '0x0316EB71485b0Ab14103307bf65a021042c6d380'
    },
    abi: OBTC.abi
  },
  [TOKEN_RENBTC]: {
    addresses: {
      1: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d'
    },
    abi: OBTC.abi
  },
  [TOKEN_WBTC]: {
    addresses: {
      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
    },
    abi: OBTC.abi
  },
  // Pools
  [getPoolName(TOKEN_BOR)]: {
    addresses: {
      1: '0x28BD8dA66bF98182DBcD630e5281BA8362e16d17',
      56: '0x3e695b79f0B7A5Ee4912C9E98f2cA8dc5e2125Ce'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_BORING)]: {
    addresses: {
      1: '0xd54D2e3024D5E5Cabab32770b3ab2601139Aba56',
      56: '0x2Fd99fFa55a686c43F47E60799B71ece4e5e08A6'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_BTC_PPTOKEN)]: {
    addresses: {
      1: '0xcF4f8A5e19a1C7AC69729aa215A46ac24E7090d6',
      3: '0xF794e9ECdC38a698Ff86Bf681C298aCC49Da99B2',
      56: '0xA0901886b3b555CdF6B7C3Fa5eEEF6CfbE95BA9E',
      97: '0x550158556CF29765BA2923D46fdD099Dde2b48fC'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_LTC_PPTOKEN)]: {
    addresses: {
      1: '0x7716aF543eB18a1ccb22c8BC1460A4409519F9E7',
      3: '0x5154419E4cBb8c3038ACB09d8995119C8AEDde4E',
      56: '0x67e38AF64236f1b884ac15231717f5DCDdF43C6f',
      97: '0x217fcaeA0210Ef0564f4C421e0c8074A20e2A540'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_DOGE_PPTOKEN)]: {
    addresses: {
      1: '0x27dd88eEe957AE18665983C818c35dCCef8818A8',
      3: '0x1ff3DD9E4931F629f8eE41Bbea1B02F0be99E72c'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_OBTC)]: {
    addresses: {
      1: '0xF29E1BE74D1F9e3436E8b7AD2756f19A904E7b48',
      3: '0x90637E189BF3Ae51207D9866647a3e47A7e029da',
      137: '0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9',
      80001: '0xa12cF91751F9C7547a2543de6Eb781B2a89e4296'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_OLTC)]: {
    addresses: {
      1: '0x25ab2DAF6A4AB862C0DBfE899d019F11DcD5135f',
      3: '0x64D168b3C616aacB78726C8a4A059A6F108dA040'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_ODOGE)]: {
    addresses: {
      1: '0x5e9F5AF18E1B9505D8ce2ab4e8A349a98abf51C1',
      3: '0xC104ac65f90483F5F0A36969FB565A085a313261'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_LINK)]: {
    addresses: {
      1: '0xEa8BBbb296D9d15e91E65ea2b189663805BB5916'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_DAI)]: {
    addresses: {
      1: '0x41edC2C7212367FC59983890aad48B92b0Fe296d'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_USDC)]: {
    addresses: {
      1: '0x89F0112A9c75D987686C608ca1840f9C7344B7fF'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_WETH)]: {
    addresses: {
      1: '0xe42b6deA46AA64120b95e75D084f42579ED8a384'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_USDT)]: {
    addresses: {
      1: '0xA6172034B1750842f12de7722Bb6cD5D4f107761'
    },
    abi: StakingRewards.abi
  },

  [getPoolName(TOKEN_YFI)]: {
    addresses: {
      1: '0xb035Dd8e7Ebb8B73A99270A12DE9D448d15de2bf'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_YFII)]: {
    addresses: {
      1: '0xC80DBede0E3CabC52c5a4a3bc9611913e49b8dCc'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_NEST)]: {
    addresses: {
      1: '0xfaacABc2468736f43eDC57f5e6080B8b48BbD050'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_BAND)]: {
    addresses: {
      1: '0xF99125968D45f88d625ADCF79700628ACDA65D6b'
    },
    abi: StakingRewards.abi
  },
  [getPoolName(TOKEN_HBTC)]: {
    addresses: {
      1: '0xb09a612Ebe2AA5750C51eb317820C6f2866A9ca6'
    },
    abi: StakingRewards.abi
  },
  [CONTRACT_CROSS_LOCK]: {
    addresses: {
      1: '0x63A61B21A5Ed529986D572AF747A22D96493ABA3',
      3: '0x8228DF71A560Df7772BEbE82aA6548a73BCDB41e',
      42: '0x578a06B69699A55DaA19A07f0Bfd2F414505058e'
    },
    abi: CrossLock.abi
  },
  [TOKEN_BOR_BNB]: {
    addresses: {
      56: '0xe094c686aD6cDda57b9564457F541FBF099B948A'
    },
    abi: PancakePair.abi
  },
  [TOKEN_CAKE_BNB]: {
    addresses: {
      56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6'
    },
    abi: PancakePair.abi
  },
  [TOKEN_ETH_BOR_ETH]: {
    addresses: {
      1: '0x44D34985826578e5ba24ec78c93bE968549BB918'
    },
    abi: PancakePair.abi
  },
  [TOKEN_ETH_OBTC_WETH]: {
    addresses: {
      1: '0x53E9fB796b2feb4B3184AFDf601C2A2797548d88'
    },
    abi: PancakePair.abi
  },
  [CONTRACT_MASTER_CHEF]: {
    addresses: {
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      97: '0x572316D56d8Bf5E4E38e0800498CC8a30E5846Ce'
    },
    abi: MasterChef.abi
  },
  [CONTRACT_SUSHI_MASTER_CHEF]: {
    addresses: {
      1: '0xc2EdaD668740f1aA35E4D8f227fB8E17dcA888Cd'
    },
    abi: SushiMasterChef.abi
  },
  [CONTRACT_SATELLITE_CITY]: {
    addresses: {
      1: '0x204c87CDA5DAAC87b2Fc562bFb5371a0B066229C',
      56: '0xa20782184Ee571a37Ba10Ce5707026Ad94e52Ff5',
      97: '0x8c6830e86ac13BD5A4AecD8efD7e35D193Fbb657'
    },
    abi: BoringChef.abi
  },
  [TOKEN_DODO]: {
    addresses: {
      56: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
      97: '0xdbFbf076b3815B5028Cd613468661B5EdAb99F60'
    },
    abi: BOR.abi
  },
  [CONTRACT_BORING_CHEF_LOCK]: {
    addresses: {
      42: '0xaAede40E1Cd4eACC62019139E45312252548c207',
      1: '0xDe62e89d672f27685eA14F30e98b59eca2845931'
    },
    abi: Stake33Lock.abi
  },
  [TOKEN_SUNDER]: {
    addresses: {
      1: '0xbDbf245c690d54b67C6e610A28486A2C6dE08bE6'
    },
    abi: BOR.abi
  }
}
