import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {getMapTokenShowName} from '../../constants/icon'

const PledgeNotice = ({type, isNew}: {type: string, isNew?: boolean}) => {
  const {t} = useTranslation()
  const name = isNew ? "BORING" : "BOR"

  return (
    <Box p={{xs: 4, md: 8}}>
      <Box mb={4}>
        <Typography variant={'subtitle1'}>{t('tunnel_operator')}</Typography>
      </Box>
      <Box mb={4}>
        <Typography gutterBottom={true} variant={'subtitle2'}>
          {t('tunnel_desc_1')}
        </Typography>
        <Typography variant={'body2'}>{t('tunnel_desc_1_1').replace("BOR", name)}</Typography>
      </Box>
      <Box mb={4}>
        <Typography gutterBottom={true} variant={'subtitle2'}>
          {t('tunnel_desc_2')}
        </Typography>
        <Typography variant={'body2'}>{t('tunnel_desc_2_1', {map_type: getMapTokenShowName(type)}).replace("BOR", name)}</Typography>
        <Typography variant={'body2'}>{t('tunnel_desc_2_2', {map_type: getMapTokenShowName(type)}).replace("BOR", name)}</Typography>
      </Box>
      <Box>
        <Typography gutterBottom={true} variant={'subtitle2'}>
          PP Token
        </Typography>
        <Typography variant={'body2'}>{t('tunnel_desc_3_1').replace("BOR", name)}</Typography>
        <Typography variant={'body2'}>{t('tunnel_desc_3_2').replace("BOR", name)}</Typography>
      </Box>
    </Box>
  )
}

export default PledgeNotice
