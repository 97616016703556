import {InjectedConnector} from '@web3-react/injected-connector'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'
import {BscConnector} from '@binance-chain/bsc-connector'
import {WalletLinkConnector} from '@web3-react/walletlink-connector'

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 256, 65, 137, 80001]
})

export const bscConnector = new BscConnector({
  supportedChainIds: [56, 97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
})

// mainnet only
export const walletConnect = new WalletConnectConnector({
  rpc: {
    1: 'https://mainnet.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e'
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const walletLink = new WalletLinkConnector({
  url: 'https://mainnet.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e',
  appName: 'BoringDAO',
  appLogoUrl: 'https://app.boringdao.com/static/media/bor.0a18b81a.png'
})
