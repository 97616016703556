import {useCallback, useContext, useEffect, useState} from 'react'
import {usePools} from './usePools'
import {BigNumber} from 'bignumber.js'
import {getTokenDecimals} from '../constants/helper'
import {
  TOKEN_BORING,
  TOKEN_BORING_BTC_PPTOKEN,
  TOKEN_BORING_DOGE_PPTOKEN,
  TOKEN_BORING_LTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH
} from '../constants/token'
import {Context} from '../contexts/BoringProvider'
import {useCityPool} from './useCityPool'
import {PriceContext} from '../contexts/PriceProvider'

type FarmMeta = {
  unlockAmount: string
  lockAmount: string
  lockPrice: string
  totalPrice: string
}

export const useBoringFarmMeta = () => {
  const [meta, setMeta] = useState<FarmMeta>({
    lockAmount: '0',
    unlockAmount: '0',
    lockPrice: '0',
    totalPrice: '0'
  })
  const pools = usePools()
  const ctx = useContext(Context)
  const priceCtx = useContext(PriceContext)

  const pptBTC = useCityPool(TOKEN_BORING_BTC_PPTOKEN)
  const pptLTC = useCityPool(TOKEN_BORING_LTC_PPTOKEN)
  const pptDoge = useCityPool(TOKEN_BORING_DOGE_PPTOKEN)
  const obtc = useCityPool(TOKEN_OBTC)
  const oltc = useCityPool(TOKEN_OLTC)
  const odoge = useCityPool(TOKEN_ODOGE)
  const dai = useCityPool(TOKEN_DAI)
  const usdt = useCityPool(TOKEN_USDT)
  const usdc = useCityPool(TOKEN_USDC)
  const weth = useCityPool(TOKEN_WETH)

  const getPrice = useCallback(
    (tokenName: string) => {
      switch (tokenName) {
        case TOKEN_BORING:
          return new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18).toString()
        case TOKEN_BORING_BTC_PPTOKEN:
          return new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18).toString()
        case TOKEN_BORING_LTC_PPTOKEN:
          return new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18).toString()
        case TOKEN_BORING_DOGE_PPTOKEN:
          return new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18).toString()
        case TOKEN_OBTC:
          return new BigNumber(ctx.price.btc).toString()
        case TOKEN_OLTC:
          return new BigNumber(ctx.price.ltc).toString()
        case TOKEN_ODOGE:
          return new BigNumber(ctx.price.doge).toString()
        case TOKEN_WETH:
          return new BigNumber(ctx.price.weth).toString()
        case TOKEN_USDT:
          return new BigNumber(ctx.price.usdt).toString()
        case TOKEN_USDC:
          return new BigNumber(ctx.price.usdc).toString()
        case TOKEN_DAI:
          return new BigNumber(ctx.price.dai).toString()
        default:
          return '0'
      }
    },
    [ctx, priceCtx]
  )

  useEffect(() => {
    let unlocking = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName === TOKEN_BORING) {
        unlocking = unlocking.plus(new BigNumber(pool.unlockEarned).div(10000))
      }
    }

    unlocking = unlocking.plus(new BigNumber(pptBTC.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(pptLTC.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(pptDoge.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(obtc.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(oltc.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(odoge.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(weth.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(usdt.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(usdc.unlockEarned))
    unlocking = unlocking.plus(new BigNumber(dai.unlockEarned))

    let locking = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName === TOKEN_BORING) {
        locking = locking.plus(new BigNumber(pool.lockEarned).div(10000))
      }
    }

    let lockingP = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName === TOKEN_BORING) {
        const tmp = new BigNumber(pool.userStakedAmount)
          .dividedBy(Math.pow(10, getTokenDecimals(pool.tokenName)))
          .multipliedBy(getPrice(pool.tokenName))
        lockingP = lockingP.plus(tmp)
      }
    }
    lockingP = lockingP.plus(
      new BigNumber(pptBTC.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(pptBTC.tokenName)))
        .multipliedBy(getPrice(pptBTC.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(pptLTC.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(pptLTC.tokenName)))
        .multipliedBy(getPrice(pptLTC.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(pptDoge.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(pptDoge.tokenName)))
        .multipliedBy(getPrice(pptDoge.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(obtc.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(obtc.tokenName)))
        .multipliedBy(getPrice(obtc.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(oltc.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(oltc.tokenName)))
        .multipliedBy(getPrice(oltc.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(odoge.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(odoge.tokenName)))
        .multipliedBy(getPrice(odoge.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(weth.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(weth.tokenName)))
        .multipliedBy(getPrice(weth.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(usdt.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(usdt.tokenName)))
        .multipliedBy(getPrice(usdt.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(usdc.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(usdc.tokenName)))
        .multipliedBy(getPrice(usdc.tokenName))
    )
    lockingP = lockingP.plus(
      new BigNumber(dai.userStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(dai.tokenName)))
        .multipliedBy(getPrice(dai.tokenName))
    )

    let totalP = new BigNumber(0)
    for (const pool of Object.values(pools)) {
      if (pool.tokenName === TOKEN_BORING) {
        const tmp = new BigNumber(pool.totalStakedAmount)
          .dividedBy(Math.pow(10, getTokenDecimals(pool.tokenName)))
          .multipliedBy(getPrice(pool.tokenName))
        totalP = totalP.plus(tmp)
      }
    }

    totalP = totalP.plus(
      new BigNumber(pptBTC.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(pptBTC.tokenName)))
        .multipliedBy(getPrice(pptBTC.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(pptLTC.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(pptLTC.tokenName)))
        .multipliedBy(getPrice(pptLTC.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(pptDoge.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(pptDoge.tokenName)))
        .multipliedBy(getPrice(pptDoge.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(obtc.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(obtc.tokenName)))
        .multipliedBy(getPrice(obtc.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(oltc.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(oltc.tokenName)))
        .multipliedBy(getPrice(oltc.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(odoge.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(odoge.tokenName)))
        .multipliedBy(getPrice(odoge.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(weth.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(weth.tokenName)))
        .multipliedBy(getPrice(weth.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(usdt.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(usdt.tokenName)))
        .multipliedBy(getPrice(usdt.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(usdc.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(usdc.tokenName)))
        .multipliedBy(getPrice(usdc.tokenName))
    )
    totalP = totalP.plus(
      new BigNumber(dai.totalStakedAmount)
        .dividedBy(Math.pow(10, getTokenDecimals(dai.tokenName)))
        .multipliedBy(getPrice(dai.tokenName))
    )

    setMeta({
      unlockAmount: unlocking.toString(),
      lockAmount: locking.toString(),
      lockPrice: lockingP.toString(),
      totalPrice: totalP.toString()
    })
  }, [pools, ctx, getPrice])

  return meta
}
