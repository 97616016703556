import React from 'react'
import {Box, createStyles, Dialog, makeStyles, Theme, Typography} from '@material-ui/core'
import {useWeb3React} from '@web3-react/core'
import {useTranslation} from 'react-i18next'
import Metamask from '../../../assets/images/metamask.png'
import WalletConnection from '../../../assets/images/wallet_connectIcon.svg'
import Coinbase from '../../../assets/images/coinbase.svg'
import {bscConnector, injectedConnector, walletConnect, walletLink} from '../../../connectors'
import {AbstractConnector} from '@web3-react/abstract-connector'
import {isBSC, isEthereum} from '../../../helpers'
import BSC from '../../../assets/images/bsc.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      '& .MuiPaper-root': {
        maxWidth: '460px !important'
      }
    },
    wallet: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '16px 20px',
      cursor: 'pointer',
      position: 'relative',
      '&.active': {
        background: '#fafafa',
        borderColor: theme.palette.primary.main,
        '& span': {
          display: 'block',
          top: '10px',
          left: '20px',
          width: '8px',
          height: '8px',
          marginRight: '10px',
          borderRadius: '8px',
          background: '#BCE369'
        }
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`
      },
      '& img': {
        marginLeft: 'auto'
      }
    },
    grow: {
      flexGrow: 1
    },
    address: {
      '& img': {
        marginLeft: '5px',
        cursor: 'pointer'
      }
    }
  })
)

const WalletsModal = ({open, close}: {open: boolean; close: any}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {activate, connector} = useWeb3React()

  return (
    <Dialog
      className={classes.main}
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth={true}
      onClose={close}
      transitionDuration={300}
    >
      <Box p={6}>
        <Typography variant="h6">{t('unlock_wallet')}</Typography>
        <Box
          mt={4}
          className={`${classes.wallet} ${injectedConnector === connector ? 'active' : ''}`}
          onClick={() => {
            close()
            return activate(injectedConnector, undefined, true)
          }}
        >
          <span />
          <Typography variant="subtitle1">Metamask</Typography>
          <img src={Metamask} width={28} alt="metamask" />
        </Box>
        {isEthereum() && (
          <Box
            mt={4}
            className={`${classes.wallet} ${walletConnect === connector ? 'active' : ''}`}
            onClick={() => {
              close()
              return activate(walletConnect as AbstractConnector, undefined, true)
            }}
          >
            <span />
            <Typography variant="subtitle1">WalletConnect</Typography>
            <img src={WalletConnection} width={28} alt="wallet_connection" />
          </Box>
        )}
        {isEthereum() && (
          <Box
            mt={4}
            className={`${classes.wallet} ${walletLink === connector ? 'active' : ''}`}
            onClick={() => {
              close()
              return activate(walletLink as AbstractConnector, undefined, true)
            }}
          >
            <span />
            <Typography variant="subtitle1">Coinbase Wallet</Typography>
            <img src={Coinbase} width={28} alt="coinbase" />
          </Box>
        )}
        {isBSC() && (
          <Box
            mt={4}
            className={`${classes.wallet} ${bscConnector === connector ? 'active' : ''}`}
            onClick={() => {
              close()
              return activate(bscConnector as AbstractConnector, undefined, true)
            }}
          >
            <span />
            <Typography variant="subtitle1">Binance Smart Chain</Typography>
            <img src={BSC} width={28} alt="bsc" />
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default WalletsModal
