import {useCallback, useEffect, useState} from 'react'
import {
  TOKEN_BAND,
  TOKEN_BOR,
  TOKEN_BORING,
  TOKEN_BTC_PPTOKEN,
  TOKEN_DAI,
  TOKEN_DOGE_PPTOKEN,
  TOKEN_HBTC,
  TOKEN_LINK,
  TOKEN_LTC_PPTOKEN,
  TOKEN_NEST,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH,
  TOKEN_YFI,
  TOKEN_YFII,
  TokenModal
} from '../constants/token'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {CONTRACT_ORACLE, getPoolName} from '../constants/contract'
import {calcAPY} from '../helpers'
import {Datum, initDatum} from './usePool'
import {useActiveWeb3React, useMountedRef} from './index'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import {CHAIN_ETHER, CHAIN_ETHER_KOVAN} from '@w3u/chains'
import MulticallABI from '../abi/Multicall.json'
import {toBytes32} from '../helpers/solidity'
import {
  allowanceABI,
  balanceOfABI,
  earnedABI,
  getPriceABI,
  periodFinishABI,
  rewardRateABI,
  totalSupplyABI
} from '../constants/abi'

type Data = {
  bpp: Datum
  lpp: Datum
  obtc: Datum
  oltc: Datum
  dai: Datum
  usdc: Datum
  usdt: Datum
  weth: Datum
  yfi: Datum
  yfii: Datum
  link: Datum
  nest: Datum
  band: Datum
  hbtc: Datum
  dpp: Datum
  odoge: Datum
  boring: Datum
}

const initData = (): Data => {
  return {
    bpp: initDatum(TOKEN_BTC_PPTOKEN),
    lpp: initDatum(TOKEN_LTC_PPTOKEN),
    obtc: initDatum(TOKEN_OBTC),
    oltc: initDatum(TOKEN_OLTC),
    dai: initDatum(TOKEN_DAI),
    usdc: initDatum(TOKEN_USDC),
    usdt: initDatum(TOKEN_USDT),
    weth: initDatum(TOKEN_WETH),
    yfi: initDatum(TOKEN_YFI),
    yfii: initDatum(TOKEN_YFII),
    link: initDatum(TOKEN_LINK),
    nest: initDatum(TOKEN_NEST),
    band: initDatum(TOKEN_BAND),
    hbtc: initDatum(TOKEN_HBTC),
    dpp: initDatum(TOKEN_DOGE_PPTOKEN),
    odoge: initDatum(TOKEN_ODOGE),
    boring: initDatum(TOKEN_BORING)
  }
}

export const usePools = () => {
  const mountedRef = useMountedRef()
  const [data, setData] = useState<Data>(initData())
  const {account, library, chainId, connector} = useActiveWeb3React()

  const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
  const chainID = chainId ?? CHAIN_ETHER
  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const oracleAddress = getContractAddress(chainID, CONTRACT_ORACLE)

  const web3 = getWeb3(library)

  const poolQ = useCallback(
    async (tokenName: string) => {
      try {
        const priceName = TokenModal[tokenName].priceName
        const poolName = getPoolName(tokenName)

        const tokenAddress = getContractAddress(chainId, tokenName)
        const poolAddress = getContractAddress(chainId, poolName)

        const calls = [
          {
            target: oracleAddress,
            callData: web3.eth.abi.encodeFunctionCall(getPriceABI, [toBytes32(TOKEN_BOR)])
          },
          {
            target: oracleAddress,
            callData: web3.eth.abi.encodeFunctionCall(getPriceABI, [toBytes32(priceName)])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(rewardRateABI, [])
          },
          {
            target: tokenAddress,
            callData: web3.eth.abi.encodeFunctionCall(balanceOfABI, [defaultAccount])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(balanceOfABI, [defaultAccount])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(totalSupplyABI, [])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(earnedABI, [defaultAccount])
          },
          {
            target: tokenAddress,
            callData: web3.eth.abi.encodeFunctionCall(allowanceABI, [defaultAccount, poolAddress])
          },
          {
            target: poolAddress,
            callData: web3.eth.abi.encodeFunctionCall(periodFinishABI, [])
          }
        ]

        let results: any = []
        const values = await multicallContract.methods
          .aggregate(calls)
          .call()
          .catch((e: any) => console.error('Fetching price: ', e))

        results.push(web3.eth.abi.decodeParameter('uint256', values[1][0]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][1]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][2]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][3]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][4]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][5]))
        results.push(web3.eth.abi.decodeParameters(['uint256', 'uint256'], values[1][6]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][7]))
        results.push(web3.eth.abi.decodeParameter('uint256', values[1][8]))

        return Promise.resolve(results)
      } catch (e) {
        console.error(e)
      }
    },
    [library, account, chainId]
  )

  const handleResult = useCallback((tokenName: string, result: any) => {
    // console.log(tokenName, ": ", result[8], " -> ", Math.floor(new Date().getTime() / 1000))
    return {
      tokenName: tokenName,
      apy: calcAPY(tokenName, result[0], result[2], result[5], result[1]),
      userTokenBalance: result[3],
      userStakedAmount: result[4],
      totalStakedAmount: result[5],
      unlockEarned: result[6][0],
      lockEarned: result[6][1],
      userAllowance: result[7],
      expired: result[8] < Math.floor(new Date().getTime() / 1000)
    }
  }, [])

  const poolsQ = useCallback(() => {
    if (chainId === CHAIN_ETHER_KOVAN) return
    console.log('Fetching pools======')
    const bppQ = poolQ(TOKEN_BTC_PPTOKEN)
    const lppQ = poolQ(TOKEN_LTC_PPTOKEN)
    const obtcQ = poolQ(TOKEN_OBTC)
    const oltcQ = poolQ(TOKEN_OLTC)
    const daiQ = poolQ(TOKEN_DAI)
    const usdcQ = poolQ(TOKEN_USDC)
    const usdtQ = poolQ(TOKEN_USDT)
    const wethQ = poolQ(TOKEN_WETH)
    const yfiQ = poolQ(TOKEN_YFI)
    const yfiiQ = poolQ(TOKEN_YFII)
    const linkQ = poolQ(TOKEN_LINK)
    const bandQ = poolQ(TOKEN_BAND)
    const nestQ = poolQ(TOKEN_NEST)
    const hbtcQ = poolQ(TOKEN_HBTC)
    const dppQ = poolQ(TOKEN_DOGE_PPTOKEN)
    const odogeQ = poolQ(TOKEN_ODOGE)
    const boringQ = poolQ(TOKEN_BORING)

    return Promise.all([
      bppQ,
      lppQ,
      obtcQ,
      oltcQ,
      daiQ,
      usdcQ, // 5
      usdtQ,
      wethQ,
      yfiQ,
      yfiiQ,
      linkQ, // 10
      nestQ,
      bandQ,
      hbtcQ,
      dppQ,
      odogeQ, // 15
      boringQ
    ]).then(values => {
      if (!mountedRef.current) {
        return
      }

      try {
        setData({
          bpp: handleResult(TOKEN_BTC_PPTOKEN, values[0]),
          lpp: handleResult(TOKEN_LTC_PPTOKEN, values[1]),
          dpp: handleResult(TOKEN_DOGE_PPTOKEN, values[14]),
          obtc: handleResult(TOKEN_OBTC, values[2]),
          oltc: handleResult(TOKEN_OLTC, values[3]),
          odoge: handleResult(TOKEN_ODOGE, values[15]),
          dai: handleResult(TOKEN_DAI, values[4]),
          usdc: handleResult(TOKEN_USDC, values[5]),
          usdt: handleResult(TOKEN_USDT, values[6]),
          weth: handleResult(TOKEN_WETH, values[7]),
          yfi: handleResult(TOKEN_YFI, values[8]),
          yfii: handleResult(TOKEN_YFII, values[9]),
          link: handleResult(TOKEN_LINK, values[10]),
          nest: handleResult(TOKEN_NEST, values[11]),
          band: handleResult(TOKEN_BAND, values[12]),
          hbtc: handleResult(TOKEN_HBTC, values[13]),
          boring: handleResult(TOKEN_BORING, values[16])
        })
      } catch (e) {
        console.log('usePools/handleResult', e)
      }
    })
  }, [handleResult, poolQ, mountedRef])

  useEffect(() => {
    if (library) {
      poolsQ()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      console.log('Pools', ms)
      let timer = setInterval(poolsQ, ms)
      return () => clearInterval(timer)
    }
  }, [library, setData, poolsQ, connector])

  return data
}
