import React from 'react'
import {getTokenShowName} from '../../constants/helper'
import {Box, Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core'
import {getTokenIcon} from '../../constants/icon'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {NavLink} from 'react-router-dom'
import {usePancakePool} from '../../hooks/usePancakePool'
import {useCityPool} from '../../hooks/useCityPool'
import {LoadingDisplay} from '../style'
import {BigNumber} from 'bignumber.js'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    poolRow: {
      '&:not(:last-child)': {
        marginBottom: '10px',
        paddingBottom: '10px',
        borderBottom: '1px solid #f0f0f0'
      },
      '& svg': {
        fontSize: '12px'
      }
    },
    unlock: {
      color: theme.palette.primary.main
    },
    btn: {
      minWidth: '24px',
      height: '24px',
      border: `1px solid ${theme.palette.primary.main}`,
      '& .MuiButton-label': {
        lineHeight: '0'
      },
      '& svg': {
        fontSize: '10px'
      }
    }
  })
)

const MobileCityItem = ({tokenName}: {tokenName: string}) => {
  const classes = useStyles()
  const data = useCityPool(tokenName)

  return data ? (
    <div className={classes.poolRow}>
      <Box display="flex" alignItems={'center'}>
        <Box width={150} display="flex" alignItems="center">
          <img width={18} src={getTokenIcon(tokenName)} alt={tokenName} />
          <Box ml={2}>{getTokenShowName(tokenName)}</Box>
        </Box>
        <Box width={80} textAlign={'center'}>
          <LoadingDisplay ok={new BigNumber(data?.apy).gt(0)} result={data?.apy + '%'} width={42} height={18} />
          <Typography variant={'body2'}>APY</Typography>
        </Box>
        <Box ml={'auto'}>
          <Button size={'small'} variant={'outlined'} color={'primary'} className={classes.btn}>
            <NavLink to={`/pool/city/${data.tokenName}`}>
              <ArrowForwardIosIcon fontSize={'small'} color={'primary'} />
            </NavLink>
          </Button>
        </Box>
      </Box>
    </div>
  ) : (
    <></>
  )
}

export default MobileCityItem
