import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {
  TOKEN_BORING,
  TOKEN_BTC,
  TOKEN_DAI,
  TOKEN_DOGE,
  TOKEN_ETH,
  TOKEN_LTC,
  TOKEN_SUNDER,
  TOKEN_USDC,
  TOKEN_USDT,
  TOKEN_WETH
} from '../constants/token'
import {getPrice} from '../contracts/oracle'
import {BigNumber} from 'bignumber.js'
import {useActiveWeb3React} from '../hooks'
import {getContractAddress, getContractWithABI, getWeb3} from '../helpers/web3'
import {MULTICALL_NETWORKS} from '../constants/multicall'
import MulticallABI from '../abi/Multicall.json'
import {CHAIN_ETHER} from '@w3u/chains'
import {CONTRACT_ORACLE} from '../constants/contract'
import {toBytes32} from '../helpers/solidity'
import {getPriceABI} from '../constants/abi'

export interface IPriceContext {
  [TOKEN_BORING]: string
  [TOKEN_BTC]: string
  [TOKEN_LTC]: string
  [TOKEN_DOGE]: string
  [TOKEN_WETH]: string
  [TOKEN_USDT]: string
  [TOKEN_USDC]: string
  [TOKEN_DAI]: string
  [TOKEN_SUNDER]: string
}

const defaultValue = {
  [TOKEN_BORING]: '0',
  [TOKEN_BTC]: '0',
  [TOKEN_LTC]: '0',
  [TOKEN_DOGE]: '0',
  [TOKEN_WETH]: '0',
  [TOKEN_USDT]: '0',
  [TOKEN_USDC]: '0',
  [TOKEN_DAI]: '0',
  [TOKEN_SUNDER]: '0'
}

export const PriceContext = createContext<IPriceContext>(defaultValue)

const PriceProvider = ({children}: {children: ReactNode}) => {
  const {library, chainId, connector} = useActiveWeb3React()
  const chainID = chainId ?? CHAIN_ETHER
  const [ctx, setCtx] = useState<IPriceContext>(defaultValue)
  const multicallContract = getContractWithABI(library, MULTICALL_NETWORKS[chainId ?? CHAIN_ETHER], MulticallABI)
  const web3 = getWeb3(library)
  const oracleAddress = getContractAddress(chainID, CONTRACT_ORACLE)
  const tokens = [TOKEN_BORING, TOKEN_BTC, TOKEN_LTC, TOKEN_DOGE, TOKEN_ETH, TOKEN_SUNDER]

  const calls = useMemo(() => {
    return tokens.map(token => {
      return {
        target: oracleAddress,
        callData: web3.eth.abi.encodeFunctionCall(getPriceABI, [toBytes32(token)])
      }
    })
  }, [])

  const request = useCallback(() => {
    if (chainId !== CHAIN_ETHER) {
      const c = {
        [TOKEN_BORING]: '0.06',
        [TOKEN_BTC]: '60000',
        [TOKEN_LTC]: '200',
        [TOKEN_DOGE]: '0.02',
        [TOKEN_WETH]: '4700',
        [TOKEN_USDT]: '1',
        [TOKEN_USDC]: '1',
        [TOKEN_DAI]: '1',
        [TOKEN_SUNDER]: '1'
      }
      setCtx(c)
      return
    }
    try {
      console.log('Fetching price======')
      multicallContract.methods
        .aggregate(calls)
        .call()
        .then((values: any) => {
          let results: any = []
          tokens.map((_, i) => {
            const r = web3.eth.abi.decodeParameter('uint256', values[1][i])
            results.push(r)
          })
          const c = {
            [TOKEN_BORING]: new BigNumber(results[0]).div(10 ** 18).toString(),
            [TOKEN_BTC]: new BigNumber(results[1]).div(10 ** 18).toString(),
            [TOKEN_LTC]: new BigNumber(results[2]).div(10 ** 18).toString(),
            [TOKEN_DOGE]: new BigNumber(results[3]).div(10 ** 18).toString(),
            [TOKEN_WETH]: new BigNumber(results[4]).div(10 ** 18).toString(),
            [TOKEN_USDT]: '1',
            [TOKEN_USDC]: '1',
            [TOKEN_DAI]: '1',
            [TOKEN_SUNDER]: new BigNumber(results[5]).div(10 ** 18).toString()
          }
          setCtx(c)
          console.log(c)
        })
        .catch((e: any) => console.error('Fetching price: ', e))
    } catch (e) {
      console.error('price: ', e)
    }
  }, [library, chainId, setCtx])

  useEffect(() => {
    if (library) {
      request()
      let ms = 10000
      if (library && !library.isMetaMask) {
        ms = 900000
      }
      console.log('Price', ms)
      let timer = setInterval(request, ms)
      return () => clearInterval(timer)
    }
  }, [library, request])

  return <PriceContext.Provider value={ctx}>{children}</PriceContext.Provider>
}

export default PriceProvider
