import React, {useState} from 'react'
import {Box, Button, Card, Container, createStyles, Grid, makeStyles, Tab, Tabs, Theme} from '@material-ui/core'
import {useTitle} from 'react-use'
import {getTokenIcon} from '../../constants/icon'
import {
  TOKEN_AC,
  TOKEN_BANCOR,
  TOKEN_BNB,
  TOKEN_BOR,
  TOKEN_BORING,
  TOKEN_BSC,
  TOKEN_CAKE,
  TOKEN_CURVE,
  TOKEN_DODO,
  TOKEN_ETH,
  TOKEN_HARVEST,
  TOKEN_KSWAP,
  TOKEN_MPH,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OKT,
  TOKEN_OLTC,
  TOKEN_POLYGON,
  TOKEN_QUICKSWAP,
  TOKEN_SUSHI,
  TOKEN_UNI,
  TOKEN_USDT,
  TOKEN_WAULT,
  TOKEN_YFI
} from '../../constants/token'

import ETHBG from '../../assets/images/eth-bg.png'
import BSCBG from '../../assets/images/bsc-bg.png'
import OKBG from '../../assets/images/ok-bg.png'
import OKSwapBG from '../../assets/images/ok-swap-bg.png'
import ETHSwapBG from '../../assets/images/eth-swap-bg.png'
import BSCSwapBG from '../../assets/images/bsc-swap-bg.png'
import PolygonSwapBG from '../../assets/images/polygon-swap-bg.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      borderRadius: '16px',
      border: '1px solid #D7E0F1',
      padding: '30px 20px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -55px bottom -40px',
      backgroundSize: '80%',
      '& button': {
        borderRadius: '6px',
        background: 'linear-gradient(136deg, #0068FF 0%, #00A1FF 100%)'
      }
    },
    type: {
      background: 'linear-gradient(135deg, #D8FDF2 0%, #D6E4FE 100%)',
      borderRadius: '8px',
      textAlign: 'center',
      padding: '8px 0',
      marginBottom: '30px',
      border: '1px solid #adcefe',
      '& div': {
        width: '36px',
        height: '36px',
        background: '#ffffff',
        margin: '0 auto',
        borderRadius: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '& img': {
        // borderRadius: '100%'
      }
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '30px',
      '& img': {
        margin: '0 2px'
      }
    },
    statusWrap: {
      textAlign: 'center'
    },
    status: {
      background: 'rgba(0, 104, 255, 0.2)',
      borderRadius: '8px',
      padding: '4px 10px',
      display: 'inline-block',
      color: 'rgba(0, 104, 255, 1)',
      marginBottom: '30px'
    },
    date: {
      display: 'flex',
      marginBottom: '20px',
      '& .MuiTypography-root': {
        fontSize: '12px'
      },
      '& .MuiTypography-body1': {
        color: 'rgba(161, 168, 199, 1)'
      },
      '& .MuiTypography-subtitle1': {
        color: 'rgba(68, 76, 101, 1)',
        marginLeft: 'auto'
      }
    },
    tabs: {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'flex-start'
      },
      '& .MuiTab-root': {
        minWidth: '50px !important',
        marginRight: '20px'
      },
      '& button': {
        padding: '0',
        minHeight: '40px !important',
        '&.Mui-selected': {
          borderBottom: '2px solid rgba(0, 104, 255, 1)'
        }
      }
    },
    selector: {
      marginTop: '-60px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '20px'
      },
      marginBottom: '40px',
      '& .item': {
        borderRadius: '50px',
        border: '1px solid #E3E6F1',
        padding: '6px 12px',
        display: 'flex',
        alignItems: 'center',
        '&:not(:last-child)': {
          marginRight: '10px'
        },
        '& img': {
          marginRight: '5px'
        }
      }
    },
    swapWrap: {
      borderRadius: '12px',
      border: '1px solid #E5E8F6',
      padding: '30px',
      marginBottom: '30px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -14px top -10px'
    },
    imgWrap: {
      '& img:nth-child(2)': {
        marginLeft: '-5px',
        marginRight: '10px'
      }
    },
    swapItem: {
      padding: '16px',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: '10px'
      }
    },
    btn: {
      borderRadius: '100px',
      width: '48px',
      height: '24px',
      marginLeft: 'auto',
      cursor: 'pointer',
      textAlign: 'center',
      color: '#fff',
      lineHeight: '24px'
    },
    line: {
      margin: '30px -30px',
      height: '1px',
      background: '#E5EBF6'
    },
    farmBtn: {
      borderRadius: '10px',
      background: 'linear-gradient(136deg, #0068FF 0%, #00A1FF 100%)',
      height: '44px'
    }
  })
)

const farms = [
  {
    project: TOKEN_CURVE,
    deposits: [TOKEN_OBTC],
    earns: [TOKEN_CURVE],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://curve.fi/obtc/deposit',
    isActive: true
  },
  {
    project: TOKEN_YFI,
    deposits: [TOKEN_OBTC, TOKEN_CURVE],
    earns: [TOKEN_CURVE, TOKEN_OBTC],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://yearn.finance/vaults/0x7F83935EcFe4729c4Ea592Ab2bC1A32588409797',
    isActive: true,
    fullImg: true
  },
  {
    project: TOKEN_HARVEST,
    deposits: [TOKEN_OBTC, TOKEN_CURVE],
    earns: [TOKEN_CURVE, TOKEN_BOR, TOKEN_HARVEST],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://harvest.finance/',
    isActive: true,
    fullImg: true
  },
  {
    project: TOKEN_AC,
    deposits: [TOKEN_OBTC, TOKEN_CURVE, TOKEN_OBTC],
    earns: [TOKEN_OBTC, TOKEN_CURVE, TOKEN_AC],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://app.acbtc.fi/savings',
    isActive: true
  },
  {
    project: TOKEN_MPH,
    deposits: [TOKEN_OBTC, TOKEN_CURVE, TOKEN_OBTC],
    earns: [TOKEN_MPH],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://88mph.app/',
    isActive: true,
    fullImg: true
  },
  {
    project: TOKEN_BANCOR,
    deposits: [TOKEN_BORING, TOKEN_BANCOR],
    earns: [TOKEN_BANCOR],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://app.bancor.network/eth/portfolio/stake/add/single/0x767B7e151D66a90B9539a02709361D711bdDfC04',
    isActive: true,
    fullImg: true
  },
  {
    project: TOKEN_WAULT,
    bg: BSCBG,
    deposits: [TOKEN_BORING, TOKEN_BNB],
    earns: [TOKEN_WAULT],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://app.wault.finance/bsc/index.html#farm',
    isActive: true
  },
  // {
  //   project: TOKEN_QUICKSWAP,
  //   bg: PolygonSwapBG,
  //   deposits: [TOKEN_BORING, TOKEN_ETH],
  //   earns: [TOKEN_QUICKSWAP],
  //   startDate: '30 Mar 2021 21:00 UTC',
  //   link:
  //     'https://quickswap.exchange/#/quick/0xff88434E29d1E2333aD6baa08D358b436196da6b/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x5b0a814D971aF818DA2BD7A7e1163d11674d4dCB',
  //   isActive: true,
  //   fullImg: true
  // },
  {
    project: TOKEN_KSWAP,
    bg: OKBG,
    deposits: [TOKEN_BORING, TOKEN_USDT],
    earns: [TOKEN_KSWAP],
    startDate: '30 Mar 2021 21:00 UTC',
    link: 'https://app.kswap.finance/#/farm/liquidity/0x66B8EF94956f34437ad366Fe4aF4744Ee60c676C',
    isActive: true
  }
]

const swaps = [
  {
    chain: TOKEN_ETH,
    chainBG: ETHSwapBG,
    borderColor: '#a6c1f4',
    metas: [
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_ETH],
          name: 'BORING/ETH'
        },
        links: [
          {
            project: TOKEN_BANCOR,
            name: 'Bancor',
            background:
              'linear-gradient(132deg, #FFFFFF 0%, rgba(245, 245, 245, 0.76) 58%, rgba(216, 216, 216, 0.18) 100%)',
            borderColor: '#dddddd',
            link:
              'https://app.bancor.network/eth/swap?from=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&to=0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
            btnBackground: '#000D2B'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_ETH],
          name: 'BORING/ETH'
        },
        links: [
          {
            project: TOKEN_UNI,
            name: 'Uniswap V3',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #FFECF4 100%)',
            borderColor: '#FFD8E9',
            link:
              'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
            btnBackground: '#EB2377'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_ETH],
          name: 'BOR/ETH'
        },
        links: [
          {
            project: TOKEN_UNI,
            name: 'Uniswap V2',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #FFECF4 100%)',
            borderColor: '#FFD8E9',
            link:
              'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9&use=V2',
            btnBackground: '#EB2377'
          },
          {
            project: TOKEN_SUSHI,
            name: 'Sushiswap',
            background: 'linear-gradient(132deg, #D0F2FF 0%, #FFDEF7 100%)',
            borderColor: '#d0f2ff',
            link:
              'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9',
            btnBackground: 'rgba(240, 87, 163, 1)'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_OBTC, TOKEN_ETH],
          name: 'oBTC/ETH'
        },
        links: [
          {
            project: TOKEN_UNI,
            name: 'Uniswap',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #FFECF4 100%)',
            borderColor: '#FFD8E9',
            link:
              'https://app.uniswap.org/#/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
            btnBackground: '#EB2377'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_OBTC, TOKEN_ETH],
          name: 'oBTC Pool(oBTC/wBTC/renBTC/sBTC)'
        },
        links: [
          {
            project: TOKEN_CURVE,
            name: 'Curve',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #FFECF4 100%)',
            borderColor: '#FFD8E9',
            link: 'https://curve.fi/obtc/',
            btnBackground: '#EB2377'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_OLTC, TOKEN_ETH],
          name: 'oLTC/ETH'
        },
        links: [
          {
            project: TOKEN_UNI,
            name: 'Uniswap',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #FFECF4 100%)',
            borderColor: '#FFD8E9',
            link:
              'https://app.uniswap.org/#/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x07c44b5ac257c2255aa0933112c3b75a6bff3cb1',
            btnBackground: '#EB2377'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_ODOGE, TOKEN_ETH],
          name: 'oDoge/ETH'
        },
        links: [
          {
            project: TOKEN_UNI,
            name: 'Uniswap',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #FFECF4 100%)',
            borderColor: '#FFD8E9',
            link:
              'https://app.uniswap.org/#/swap?inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x9c306a78b1a904e83115c05ac67c1ef07c653651',
            btnBackground: '#EB2377'
          }
        ]
      }
    ]
  },
  {
    chain: TOKEN_BNB,
    chainBG: BSCSwapBG,
    borderColor: '#f2e3be',
    metas: [
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_BNB],
          name: 'BORING/BNB'
        },
        links: [
          {
            project: TOKEN_CAKE,
            name: 'PancakeSwap',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #D5FCFF 99%)',
            borderColor: '#E5EBF6',
            link: 'https://pancakeswap.finance/swap?inputCurrency=0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f',
            btnBackground: '#1FC7D4'
          },
          {
            project: TOKEN_WAULT,
            name: 'Wault',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #D5FCFF 99%)',
            borderColor: '#E5EBF6',
            link: 'https://swap.wault.finance/bsc/index.html#/swap',
            btnBackground: '#1FC7D4'
          },
          {
            project: TOKEN_DODO,
            name: 'DODO',
            background: 'linear-gradient(132deg, #FFFFFF 0%, rgba(255, 254, 224, 1) 99%)',
            borderColor: 'rgba(255, 247, 6, 1)',
            link: 'https://app.dodoex.io/exchange/BNB-BORING?network=bsc-mainnet',
            btnBackground: 'rgba(51, 51, 51, 1)'
          }
        ]
      },
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_BNB],
          name: 'BOR/BNB'
        },
        links: [
          {
            project: TOKEN_CAKE,
            name: 'PancakeSwap',
            background: 'linear-gradient(132deg, #FFFFFF 0%, #D5FCFF 99%)',
            borderColor: '#E5EBF6',
            link: 'https://pancakeswap.finance/swap?inputCurrency=0x92d7756c60dcfd4c689290e8a9f4d263b3b32241',
            btnBackground: '#1FC7D4'
          },
          {
            project: TOKEN_DODO,
            name: 'DODO',
            background: 'linear-gradient(132deg, #FFFFFF 0%, rgba(255, 254, 224, 1) 99%)',
            borderColor: 'rgba(255, 247, 6, 1)',
            link: 'https://app.dodoex.io/exchange/BNB-BOR?network=bsc-mainnet',
            btnBackground: 'rgba(51, 51, 51, 1)'
          }
        ]
      }
    ]
  },
  {
    chain: TOKEN_POLYGON,
    chainBG: PolygonSwapBG,
    borderColor: '#e4ccfe',
    metas: [
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_ETH],
          name: 'BORING/ETH'
        },
        links: [
          {
            project: TOKEN_QUICKSWAP,
            name: 'Quickswap',
            background: 'linear-gradient(132deg, rgba(205, 231, 254, 0.14) 0%, rgba(15, 132, 249, 0.11) 99%)',
            borderColor: 'rgba(15, 132, 249, 1)',
            link:
              'https://quickswap.exchange/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xff88434e29d1e2333ad6baa08d358b436196da6b',
            btnBackground: 'rgba(15, 41, 102, 1)'
          }
        ]
      }
    ]
  },
  {
    chain: TOKEN_OKT,
    chainBG: OKSwapBG,
    borderColor: '#a6c1f4',
    metas: [
      {
        type: {
          icons: [TOKEN_BOR, TOKEN_USDT],
          name: 'BORING/USDT'
        },
        links: [
          {
            project: TOKEN_KSWAP,
            name: 'KSwap',
            background: 'linear-gradient(132deg, rgba(205, 231, 254, 0.14) 0%, rgba(15, 132, 249, 0.11) 99%)',
            borderColor: 'rgba(15, 132, 249, 1)',
            link:
              'https://app.kswap.finance/#/swap?inputCurrency=0x382bB369d343125BfB2117af9c149795C6C65C50&outputCurrency=0x5cE01aa1528A6A0C8EDEcC4cEe909F0a24CF54de',
            btnBackground: 'rgba(15, 41, 102, 1)'
          }
        ]
      }
    ]
  }
]

const Ecosyatem = () => {
  useTitle('BoringDAO App | Ecosystem')
  const classes = useStyles()
  const [tab, setTab] = useState(0)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Container maxWidth={'lg'}>
      <Box mt={{xs: 3, md: 6}}>
        <Card>
          <Box p={{xs: 6, md: 6}}>
            <Box mb={4}>
              <Tabs
                style={{marginTop: '-15px'}}
                textColor={'primary'}
                indicatorColor={'primary'}
                value={tab}
                onChange={handleTabChange}
                centered
                className={classes.tabs}
              >
                <Tab disableRipple={true} label={'Swap'} />
                <Tab disableRipple={true} label={'Farm'} />
              </Tabs>
            </Box>
            <Box display="flex" justifyContent="flex-end" className={classes.selector}>
              <Box className="item">
                <img src={getTokenIcon(TOKEN_ETH)} width={16} alt="" /> Ethereum
              </Box>
              <Box className="item">
                <img src={getTokenIcon(TOKEN_BSC)} width={16} alt="" /> BSC
              </Box>
              <Box className="item">
                <img src={getTokenIcon(TOKEN_POLYGON)} width={16} alt="" /> Polygon
              </Box>
              <Box className="item">
                <img src={getTokenIcon(TOKEN_OKT)} width={16} alt="" /> OKEXChain
              </Box>
            </Box>
            {tab === 0 && (
              <>
                {swaps.map(s => (
                  <Box
                    className={classes.swapWrap}
                    style={{backgroundImage: `url(${s.chainBG})`, borderColor: `${s.borderColor}`}}
                  >
                    {s.metas.map((meta, i) => (
                      <>
                        <Box display="flex" alignItems="center" mb={4} className={classes.imgWrap}>
                          {meta.type.icons.map(icon => (
                            <img src={getTokenIcon(icon)} alt="token" width={24} height={24} />
                          ))}
                          {meta.type.name}
                        </Box>
                        <Grid container spacing={6}>
                          {meta.links.map(link => (
                            <Grid item md={4} xs={12}>
                              <Box
                                className={classes.swapItem}
                                style={{
                                  background: `${link.background}`,
                                  border: `1px solid ${link.borderColor}`
                                }}
                              >
                                <img src={getTokenIcon(link.project)} width={30} alt="" />
                                {link.name}
                                <a
                                  className={classes.btn}
                                  href={link.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{background: `${link.btnBackground}`}}
                                >
                                  Go
                                </a>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        {i !== s.metas.length - 1 && <Box className={classes.line} />}
                      </>
                    ))}
                  </Box>
                ))}
              </>
            )}
            {tab === 1 && (
              <Grid container spacing={6}>
                {farms.map(farm => (
                  <Grid item md={4} xs={12}>
                    <Box className={classes.item} style={{backgroundImage: `url(${farm.bg ? farm.bg : ETHBG})`}}>
                      <Box className={classes.type}>
                        <Box>
                          <img src={getTokenIcon(farm.project)} width={farm.fullImg ? '36' : '23'} alt="type" />
                        </Box>
                      </Box>
                      <Box className={classes.info}>
                        Deposit
                        <Box mx={2} display="flex" alignItems="center">
                          {farm.deposits.map(deposit => (
                            <img src={getTokenIcon(deposit)} alt="token" width={15} />
                          ))}
                        </Box>
                        to earn
                        <Box mx={2} display="flex" alignItems="center">
                          {farm.earns.map(earn => (
                            <img src={getTokenIcon(earn)} alt="token" width={15} />
                          ))}
                        </Box>
                      </Box>
                      <Box className={classes.statusWrap}>
                        <Box className={classes.status}>{farm.isActive ? 'active' : 'inactive'}</Box>
                      </Box>
                      {/*<Box className={classes.date}>*/}
                      {/*  <Typography variant="body1">Start date</Typography>*/}
                      {/*  <Typography variant="subtitle1">{farm.startDate}</Typography>*/}
                      {/*</Box>*/}
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        disableElevation={true}
                        component="a"
                        href={farm.link}
                        target="_blank"
                        className={classes.farmBtn}
                      >
                        Go
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Card>
      </Box>
    </Container>
  )
}

export default Ecosyatem
