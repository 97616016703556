import {useCallback, useContext, useEffect, useState} from 'react'
import {BigNumber} from 'bignumber.js'
import {Context} from '../contexts/BoringProvider'
import {useFetch} from 'use-http'
import {handleError} from '../helpers'
import {useMountedRef} from './index'

type multiAddressBalance = {
  btc: string
  ltc: string
  doge: string
}

export const useMultiAddressBalance = () => {
  const mountedRef = useMountedRef()
  const [data, setData] = useState<multiAddressBalance>({
    btc: '0.00',
    ltc: '0.00',
    doge: '0.00'
  })

  const {get} = useFetch('https://sochain.com/api/v2/')
  const btcFetch = useFetch('https://blockchain.info/rawaddr')
  const ctx = useContext(Context)

  const handle = useCallback(res => {
    const balance = res.data.confirmed_balance ? res.data.confirmed_balance : '0'
    return new BigNumber(balance).toFixed(0)
  }, [])

  const handleBTC = useCallback(res => {
    const balance = res.final_balance ? res.final_balance : '0'
    return new BigNumber(balance).div(100000000).toFixed(3)
  }, [])

  const fetch = useCallback(() => {
    if (ctx.multiAddress.btc || ctx.multiAddress.ltc || ctx.multiAddress.doge) {
      const ltcQ = get(`/get_address_balance/LTC/${ctx.multiAddress.ltc}`).catch(handleError)
      // const btcQ = get(`/get_address_balance/BTC/${ctx.multiAddress.btc}`).catch(handleError)
      const btcQ = btcFetch.get(`${ctx.multiAddress.btc}?limit=0`).catch(handleError)
      const dogeQ = get(`/get_address_balance/DOGE/${ctx.multiAddress.doge}`).catch(handleError)
      Promise.all([btcQ, ltcQ, dogeQ])
        .then(values => {
          if (mountedRef.current) {
            // console.log(values)
            setData({
              btc: handleBTC(values[0]),
              ltc: handle(values[1]),
              doge: handle(values[2])
            })
          }
        })
        .catch(handleError)
    }
  }, [ctx, handle, get, mountedRef])

  useEffect(() => {
    fetch()
    let timer = setInterval(fetch, 10000)
    return () => clearInterval(timer)
  }, [fetch])

  return data
}
