import React, {useCallback, useState} from "react";
import {getTokenIconByChainID} from "../../constants/icon";
import {displayBalance, ellipseAddress} from "../../helpers/display";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {useWeb3React} from "@web3-react/core";
import Web3 from "web3";
import {handleError} from "../../helpers";
import {useInterval} from "../../hooks/useInterval";
import {useNetworkChecker} from "../../hooks/useNetworkChecker";
import WrongNetwork from "../Models/WrongNetwork";
import WalletsModal from "../Models/Wallets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      cursor: 'pointer',
      background: '#F1F2F6',
      padding: '8px 12px',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      color: '#333A50',
      '& img': {
        width: '16px',
        height: '16px',
        marginRight: '5px'
      }
    },
    wrong: {
      background: theme.palette.error.main,
      color: '#ffffff'
    }
  })
)


const Network = () => {
  const classes = useStyles()
  const {library, chainId, account} = useWeb3React()
  const [balance, setBalance] = useState<string>('0')
  const isCorrectNetwork = useNetworkChecker()
  const [openWallets, setOpenWallets] = useState(false)

  const timer = useCallback(() => {
    if (account) {
      const web3 = new Web3(library)
      web3.eth
        .getBalance(account)
        .then(setBalance)
        .catch(handleError)
    }
  }, [account, library])

  useInterval(timer, 3000, true)

  return (
    <>
      <WalletsModal open={openWallets} close={() => setOpenWallets(false)}/>
      <WrongNetwork open={!isCorrectNetwork}/>
      {isCorrectNetwork ? (
        <div className={classes.bar} onClick={() => setOpenWallets(true)}>
          <img src={getTokenIconByChainID(chainId)} alt="eth"/> {displayBalance(balance)} &nbsp;|&nbsp;
          {ellipseAddress(account, 4)}
        </div>
      ) : (
        <div className={`${classes.bar} ${classes.wrong}`}>
          Wrong Network
        </div>
      )}
    </>
  )
}

export default Network