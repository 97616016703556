import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, Card, CircularProgress, Container, Grid, InputBase, Typography} from '@material-ui/core'
import {TOKEN_BOR, TOKEN_ETH, TOKEN_OKT} from '../../constants/token'
import {getTokenIcon} from '../../constants/icon'
import ArrowRight from '../../assets/images/arrow-right.png'
import {verifyNumericInput} from '../../helpers/verify'
import {useTranslation} from 'react-i18next'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
// @ts-ignore
import validator from 'multicoin-address-validator'
import {toast} from 'react-toastify'
import ToastContent from '../../components/ToastContent'
import {allowance, approveMax} from '../../helpers/erc20'
import {handleError} from '../../helpers'
import {useInterval} from '../../hooks/useInterval'
import {useWeb3React} from '@web3-react/core'
import {getContractAddress} from '../../helpers/web3'
import {CONTRACT_CROSS_LOCK, CONTRACTS} from '../../constants/contract'
import {BigNumber} from 'bignumber.js'
import {Context} from '../../contexts/BoringProvider'
import {displayBalance} from '../../helpers/display'
import {MaxButton} from '../../components/MaxButton'
import {crossBurn} from '../../contracts/cross'
import {useStyles} from '../Lock/Lock'
import Web3 from 'web3'
import {ChainIDType} from '../../contracts/type'
import {useActiveWeb3React} from '../../hooks'

const testUrl = 'https://ropsten.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e'
const mainnetUrl = 'https://mainnet.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e'

export const getContract = (chainID: ChainIDType, name: string) => {
  chainID = chainID ? chainID : 3
  const web3 = new Web3(chainID === 3 ? testUrl : mainnetUrl)
  return new web3.eth.Contract(CONTRACTS[name].abi, CONTRACTS[name]['addresses'][chainID])
}

const FromOKC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const ctx = useContext(Context)

  const borAddress = getContractAddress(chainId, TOKEN_BOR)
  const unlockAddress = getContractAddress(chainId, TOKEN_BOR)

  const [can, setCan] = useState(true)
  const [amount, setAmount] = useState('')
  const [doing, setDoing] = useState(false)
  const [aprDoing, setAprDoing] = useState(false)
  const [address, setAddress] = useState('')
  const [userAllowance, setUserAllowance] = useState('')
  const [fee, setFee] = useState('0')
  const [result, setResult] = useState('0')

  const max = () =>
    setAmount(new BigNumber(ctx.balance.bor).dividedBy(Math.pow(10, 18)).toFixed(3, BigNumber.ROUND_DOWN))

  const approved = new BigNumber(userAllowance).gt(ctx.balance.bor)

  useInterval(
    () => {
      allowance(library, borAddress, account, unlockAddress)
        .then(setUserAllowance)
        .catch(handleError)
    },
    5000,
    true
  )

  useEffect(() => {
    setCan(false)
    if (amount) {
      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
      if (amountBN.lte(ctx.balance.bor) && amountBN.gt(0)) {
        setCan(true)
      }
    }

    setFee('0')
    setResult('0')
    if (amount !== '') {
      if (chainId === 56) {
        const contract = getContract(1, CONTRACT_CROSS_LOCK)
        const q = contract.methods.calculateFee(getContractAddress(1, TOKEN_BOR), Web3.utils.toWei(amount), 1).call()
        q.then((res: any) => {
          setFee(res[0])
          setResult(res[1])
        }).catch(handleError)
      }
      if (chainId === 97) {
        const contract = getContract(3, CONTRACT_CROSS_LOCK)
        const q = contract.methods.calculateFee(getContractAddress(3, TOKEN_BOR), Web3.utils.toWei(amount), 1).call()
        q.then((res: any) => {
          console.log(res)
          setFee(res[0])
          setResult(res[1])
        }).catch(handleError)
      }
      if (chainId === 65) {
        const contract = getContract(3, CONTRACT_CROSS_LOCK)
        const q = contract.methods.calculateFee(getContractAddress(3, TOKEN_BOR), Web3.utils.toWei(amount), 1).call()
        q.then((res: any) => {
          setFee(res[0])
          setResult(res[1])
        }).catch((e: any) => {
          console.error(e)
        })
      }
    }
  }, [amount, ctx, chainId])

  const approving = async () => {
    setAprDoing(true)
    console.log(borAddress)
    console.log(unlockAddress)
    await approveMax(library, borAddress, unlockAddress, account).catch(handleError)
    setAprDoing(false)
  }

  const unlock = async () => {
    // @ts-ignore
    if (!validator.validate(address, TOKEN_ETH)) {
      toast.info(<ToastContent content={`Invalid ethereum address`} status={false} />)
      return
    }

    const amountBN = new BigNumber(amount)
    if (amountBN.lt(new BigNumber(1))) {
      toast.info(<ToastContent status={false} content={`The minimum amount is 1`} />)
      return
    }

    try {
      setDoing(true)
      console.log(`Cross unlock ${amount} amount to ${address}`)
      await crossBurn(library, chainId, account, address, amount)
    } catch (e) {
      console.error(e)
    }
    setDoing(false)
  }

  return (
    <Container maxWidth={'lg'}>
      <Box mt={{xs: 3, md: 6}}>
        <Card className={classes.main}>
          <Grid container>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Box p={{xs: 6, md: 12}}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box flexShrink={0} flexGrow={1}>
                    <Typography variant={'body2'}>From</Typography>
                    <Box className={classes.value} mt={1}>
                      <img width={16} height={16} src={getTokenIcon(TOKEN_OKT)} alt="okt" />
                      OKExChain&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Box>
                  </Box>
                  <img src={ArrowRight} style={{margin: '20px 15px 0'}} width={20} height={20} alt="arrow-right" />
                  <Box flexShrink={0} flexGrow={1}>
                    <Typography variant={'body2'}>To</Typography>
                    <Box className={classes.value} mt={1}>
                      <img width={16} height={16} src={getTokenIcon(TOKEN_ETH)} alt="eth" />
                      Ethereum
                    </Box>
                  </Box>
                </Box>
                <Box mt={4} mb={2} className={classes.input} display={'flex'} alignItems={'center'}>
                  <img width={16} height={16} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
                  <InputBase
                    fullWidth={true}
                    placeholder="0.00"
                    value={amount}
                    onChange={(e: any) => verifyNumericInput(e, setAmount)}
                  />
                  <MaxButton onClick={max}>Max</MaxButton>
                </Box>
                <Typography align={'right'} variant={'body2'}>
                  {t('balance')}：{displayBalance(ctx.balance.bor)}
                </Typography>
                <Box mt={4} mb={4} className={classes.input} display={'flex'} alignItems={'center'}>
                  <InputBase
                    fullWidth={true}
                    placeholder="Enter your Ethereum address"
                    value={address}
                    onChange={(e: any) => setAddress(e.target.value)}
                  />
                </Box>
                <Typography variant={'body2'}>{t('crosschain_fee')}</Typography>
                <Box className={classes.value} mt={1} mb={4}>
                  <img src={getTokenIcon(TOKEN_BOR)} width={16} height={16} alt="bor" />
                  {displayBalance(fee)}
                </Box>
                <Typography variant={'body2'}>{t('you_will_get')}</Typography>
                <Box className={classes.value} mt={1}>
                  <img src={getTokenIcon(TOKEN_BOR)} width={16} height={16} alt="bor" />
                  {displayBalance(result)}
                </Box>
                <Box display={'flex'} mt={4} alignItems={'center'} justifyContent={'center'}>
                  {approved ? (
                    <Button
                      disabled={approved}
                      style={{marginRight: '10px'}}
                      disableElevation={true}
                      size={'large'}
                      variant={'contained'}
                      color={'secondary'}
                    >
                      <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'} />
                      {t('approved')}
                    </Button>
                  ) : (
                    <Button
                      style={{marginRight: '10px'}}
                      disableElevation={true}
                      size={'large'}
                      variant={'contained'}
                      color={'secondary'}
                      onClick={approving}
                    >
                      {aprDoing && <CircularProgress size={14} color={'inherit'} />}
                      {t('approve')}
                    </Button>
                  )}
                  <Button
                    style={{marginLeft: '10px'}}
                    disableElevation={true}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={!can || doing || !approved}
                    onClick={unlock}
                  >
                    {doing && <CircularProgress size={14} color={'inherit'} />}
                    {t('confirm')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Container>
  )
}

export default FromOKC
