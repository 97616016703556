import React, {useCallback, useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {getMapTokenName, getTokenIcon} from '../../../../constants/icon'
import {TOKEN_BOR, TOKEN_BORING, TOKEN_BTC, TOKEN_LTC} from '../../../../constants/token'
import {displayBalance, ellipseAddress} from '../../../../helpers/display'
import TokenValue from '../../../TokenValue'
import {burnBToken} from '../../../../contracts/dao'
import {useWeb3React} from '@web3-react/core'
import {handleError} from '../../../../helpers'
import {allowance, approveMax, balanceOf} from '../../../../helpers/erc20'
import {getContractAddress} from '../../../../helpers/web3'
import {getBoringTunnelName} from '../../../../constants/contract'
import {useInterval} from '../../../../hooks/useInterval'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import BigNumber from 'bignumber.js'
import {Context} from '../../../../contexts/BoringProvider'
import {BurnFee} from '../../../../constants'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import ToastContent from '../../../ToastContent'
import {useActiveWeb3React} from '../../../../hooks'
import {PriceContext} from '../../../../contexts/PriceProvider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    address: {
      border: '1px solid #D8E0ED',
      borderRadius: '6px',
      background: '#F1F2F6',
      padding: '15px 20px',
      fontSize: '14px',
      color: '#444C66',
      display: 'flex',
      alignItems: 'center'
    }
  })
)

const BurnStepSecond = ({
  amount,
  address,
  type,
  onPrev,
  onNext
}: {
  amount: string
  address: string
  type: string
  onPrev: any
  onNext: any
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ctx = useContext(Context)
  const priceCtx = useContext(PriceContext)
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const [userAllowance, setUserAllowance] = useState('')
  const [approved, setApproved] = useState(false)
  const [userBOR, setUserBOR] = useState('')
  const [pay, setPay] = useState('')
  const [gain, setGain] = useState('')

  const burn = () => {
    if (account) {
      const payBN = new BigNumber(pay)
      const balanceBN = new BigNumber(ctx.balance.boring)
      if (payBN.gt(balanceBN)) {
        toast.info(<ToastContent content={t('burn_insufficient_notice')} status={false} />)
        return
      }
      console.log(`=====> Burn [address=${address}], [amount=${amount}]`)
      burnBToken(library, chainId, account, amount, address, type).catch(handleError)
      onNext()
    }
  }

  const approveBurn = () => {
    if (account) {
      const borAddress = getContractAddress(chainId, TOKEN_BORING)
      const tunnelAddress = getContractAddress(chainId, getBoringTunnelName(type))
      approveMax(library, borAddress, tunnelAddress, account).catch(handleError)
    }
  }

  const getPrice = useCallback(() => {
    return type === TOKEN_BTC ? ctx.price.btc : type === TOKEN_LTC ? ctx.price.ltc : ctx.price.doge
  }, [ctx, type])

  useEffect(() => {
    setApproved(new BigNumber(userAllowance).gt(userBOR))
    const price = getPrice()
    const priceBN = new BigNumber(price)
    const boringPriceBN = new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18)
    const pay = priceBN
      .div(boringPriceBN)
      .multipliedBy('0.002')
      .multipliedBy(amount)
      .multipliedBy(Math.pow(10, 18))
    setPay(pay.toString())

    const valueBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
    const minBN = new BigNumber(BurnFee).multipliedBy(Math.pow(10, 18))
    setGain(valueBN.minus(minBN).toString())
  }, [userAllowance, userBOR, amount, ctx, getPrice])

  useInterval(
    () => {
      if (account) {
        const borAddress = getContractAddress(chainId, TOKEN_BORING)
        const tunnelAddress = getContractAddress(chainId, getBoringTunnelName(type))
        allowance(library, borAddress, account, tunnelAddress)
          .then(setUserAllowance)
          .catch(handleError)
        balanceOf(library, borAddress, account)
          .then(setUserBOR)
          .catch(handleError)
      }
    },
    3000,
    true
  )

  return (
    <Box>
      <Box mb={4}>
        <TokenValue img={getTokenIcon(getMapTokenName(type))} amount={amount} price={getPrice()} />
      </Box>
      <Box mb={4} className={classes.address}>
        <Box mr={2} flexShrink={0}>
          <Typography variant={'body2'}>{t('receiving_address')}</Typography>
        </Box>
        <Typography variant={'caption'}>
          {isMobile && ellipseAddress(address, 8)}
          {!isMobile && address}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <Box mb={2}>
            <Typography variant={'body2'}>{t('transaction_fee')}</Typography>
          </Box>
          <TokenValue img={getTokenIcon(type)} amount={'0.0001'} price={getPrice()} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box mb={2}>
            <Typography variant={'body2'}>{t('burning_fee')}</Typography>
          </Box>
          <TokenValue
            img={getTokenIcon(TOKEN_BOR)}
            amount={displayBalance(pay, 18, 6)}
            price={new BigNumber(priceCtx[TOKEN_BORING]).multipliedBy(10 ** 18).toString()}
          />
        </Grid>
      </Grid>
      <Box mt={4} mb={2}>
        <Typography variant={'body2'}>{t('you_will_get')}</Typography>
      </Box>
      <TokenValue img={getTokenIcon(type)} amount={displayBalance(gain, 18, 4)} price={getPrice()} />
      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <Button
              onClick={onPrev}
              size={'large'}
              variant={'contained'}
              color={'default'}
              disableElevation
              fullWidth={true}
            >
              {t('prev')}
            </Button>
          </Grid>
          <Grid item sm={4} xs={12}>
            {approved ? (
              <Button
                disabled={approved}
                disableElevation={true}
                size={'large'}
                variant={'contained'}
                color={'secondary'}
                fullWidth={true}
              >
                <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'} />
                {t('approved')}
              </Button>
            ) : (
              <Button
                disableElevation={true}
                size={'large'}
                variant={'contained'}
                color={'secondary'}
                onClick={approveBurn}
                fullWidth={true}
              >
                {t('approve')}
              </Button>
            )}
          </Grid>
          <Grid item sm={4} xs={12}>
            <Button
              disabled={!approved}
              onClick={burn}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disableElevation
              fullWidth={true}
            >
              {t('confirm')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default BurnStepSecond
