import React, {useEffect, useState} from 'react'
import {Box, Card, Container, createStyles, Divider, Grid, makeStyles, Tab, Tabs, Theme} from '@material-ui/core'
import {TOKEN_BTC, TOKEN_LTC} from '../../constants/token'
import {useWeb3React} from '@web3-react/core'
import {calculateMintBORAmount} from '../../contracts/dao'
import {handleError} from '../../helpers'
import MintNotice from '../../components/Mint/Notice'
import MintNoticeTitle from '../../components/Mint/NoticeTitle'
import MintStepFirst from '../../components/Mint/Step/First'
import MintStepSecond from '../../components/Mint/Step/Second'
import MintStepThird from '../../components/Mint/Step/Third'
import MintStepFour from '../../components/Mint/Step/Four'
import MintStepFive from '../../components/Mint/Step/Five'
import MintRemaining from '../../components/Mint/Remaining'
import {useTranslation} from 'react-i18next'
import BurnStepFirst from '../../components/Burn/Step/First'
import BurnStepSecond from '../../components/Burn/Step/Second'
import BurnStepThird from '../../components/Burn/Step/Third'
import {AssetName} from '../../contracts/address'
import BurnNoticeTitle from '../../components/Burn/NoticeTitle'
import BurnNotice from '../../components/Burn/Notice'
import MintStepSix from '../../components/Mint/Step/Six'
import MintStepSeven from '../../components/Mint/Step/Seven'
import PledgePrepare from '../../components/Models/Pledge/Prepare'
import {useTitle} from 'react-use'
import {useActiveWeb3React} from '../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 200px)'
    }
  })
)

const Mint = () => {
  useTitle('BoringDAO App | Mint')
  const classes = useStyles()
  const {t} = useTranslation()
  const {library, chainId, account} = useActiveWeb3React()
  const [amount, setAmount] = useState('')
  const [type, setType] = useState<AssetName>(TOKEN_BTC)

  const [tab, setTab] = useState(0)
  const [step, setStep] = useState(0)
  const [burnAddress, setBurnAddress] = useState('')

  const [bor, setBOR] = useState('')
  const [txID, setTxID] = useState('')
  const [isPre, setIsPre] = useState(false)

  useEffect(() => {
    if (account) {
      calculateMintBORAmount(library, chainId, type, amount)
        .then(setBOR)
        .catch(handleError)
    }
  }, [library, chainId, amount, account, type])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setStep(0)
    setTab(newValue)
  }

  return (
    <Container maxWidth={'lg'}>
      <PledgePrepare
        open={isPre}
        onClose={() => {
          setIsPre(false)
        }}
        type={TOKEN_LTC}
      />
      <Box mt={{xs: 3, md: 6}}>
        <Card className={classes.main}>
          <Box pt={{xs: 4, md: 6}} pb={6} px={{xs: 4, md: 8}}>
            <MintRemaining type={type} />
            {tab === 0 ? (
              <>
                <MintNoticeTitle step={step} type={type} />
                <MintNotice step={step} type={type} />
              </>
            ) : (
              <>
                <BurnNoticeTitle step={step} type={type} />
                <BurnNotice type={type} step={step} />
              </>
            )}
            <Box my={6}>
              <Divider />
            </Box>
            {step === 0 ? (
              <Tabs
                style={{marginTop: '-15px'}}
                textColor={'primary'}
                indicatorColor={'primary'}
                value={tab}
                onChange={handleTabChange}
                centered
              >
                <Tab disableRipple={true} label={t('mint')} />
                <Tab disableRipple={true} label={t('burn')} />
              </Tabs>
            ) : (
              <></>
            )}
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
              <Grid item sm={6}>
                {tab === 0 ? (
                  <>
                    {step === 0 && (
                      <MintStepFirst
                        initAmount={amount}
                        borAmount={bor}
                        onChange={setAmount}
                        onTypeChange={setType}
                        onIsPre={() => {
                          // const ltcThreshold = new BigNumber(LTCThreshold).multipliedBy(Math.pow(10, 18))
                          // setIsPre(new BigNumber(pledgeAmount).lt(ltcThreshold))
                          setIsPre(true)
                        }}
                        onClick={() => setStep(1)}
                      />
                    )}
                    {step === 1 && (
                      <MintStepSecond
                        amount={amount}
                        type={type}
                        borAmount={bor}
                        onPrev={() => setStep(0)}
                        onNext={() => setStep(2)}
                      />
                    )}
                    {step === 2 && <MintStepThird onPrev={() => setStep(1)} onNext={() => setStep(3)} />}
                    {step === 3 && <MintStepFour type={type} onPrev={() => setStep(2)} onNext={() => setStep(4)} />}
                    {step === 4 && <MintStepFive type={type} onPrev={() => setStep(0)} onNext={() => setStep(5)} />}
                    {step === 5 && (
                      <MintStepSix type={type} setTxID={setTxID} onPrev={() => setStep(0)} onNext={() => setStep(6)} />
                    )}
                    {step === 6 && (
                      <MintStepSeven type={type} txID={txID} onPrev={() => setStep(5)} onNext={() => setStep(0)} />
                    )}
                  </>
                ) : (
                  <>
                    {step === 0 && (
                      <BurnStepFirst
                        onChange={setAmount}
                        onTypeChange={setType}
                        onAddressChange={setBurnAddress}
                        initAmount={amount}
                        onIsPre={() => {
                          // const ltcThreshold = new BigNumber(LTCThreshold).multipliedBy(Math.pow(10, 18))
                          // setIsPre(new BigNumber(pledgeAmount).lt(ltcThreshold))
                          setIsPre(true)
                        }}
                        onClick={() => setStep(1)}
                      />
                    )}
                    {step === 1 && (
                      <BurnStepSecond
                        address={burnAddress}
                        type={type}
                        onPrev={() => setStep(0)}
                        onNext={() => setStep(2)}
                        amount={amount}
                      />
                    )}
                    {step === 2 && <BurnStepThird type={type} onPrev={() => setStep(0)} />}
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
    </Container>
  )
}

export default Mint
