import Certik from '../assets/images/certik.png'
import DefiPulse from '../assets/images/defipulse.png'
import Discord from '../assets/images/discord.png'
import Github from '../assets/images/github.png'
import Medium from '../assets/images/medium.png'
import {default as PeckShield, default as SBPeckShield} from '../assets/images/peckshield.png'
import SBDefiPulse from '../assets/images/sb_defipulse.png'
import SBDiscord from '../assets/images/sb_discord.png'
import SBGithub from '../assets/images/sb_github.png'
import SBMedium from '../assets/images/sb_medium.png'
import SBTelegram from '../assets/images/sb_telegram.png'
import SBTwitter from '../assets/images/sb_twitter.png'
import Telegram from '../assets/images/telegram.png'
import Twitter from '../assets/images/twitter.png'
import {isEthereum} from '../helpers'

const getPeckShieldHref = () => {
  return isEthereum()
    ? 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BoringDAO-1.0-2020-89.pdf'
    : 'https://github.com/BoringDAO/boringDAO-contract/blob/master/PeckShield-Audit-Report-BoringDAOBSC-v1.0.pdf'
}

export const links = [
  {
    name: 'PeckShield',
    href: getPeckShieldHref()
  },
  {
    name: 'Certik',
    href: 'https://github.com/BoringDAO/boringDAO-contract/blob/master/REP-BoringDAO-2021-05-29.pdf'
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/TheBoringDAO'
  },
  {
    name: 'Medium',
    href: 'https://theboringdao.medium.com'
  },
  {
    name: 'Discord',
    href: 'https://discord.com/invite/zCQ2ddp'
  },
  {
    name: 'Telegram',
    href: 'https://t.me/boringDAO'
  },
  {
    name: 'Github',
    href: 'https://github.com/BoringDAO'
  },
  {
    name: 'DefiPulse',
    href: 'https://defipulse.com/boringdao'
  }
]

export const getLinkIcon = (type: string) => {
  switch (type) {
    case 'PeckShield':
      return PeckShield
    case 'Certik':
      return Certik
    case 'Twitter':
      return Twitter
    case 'Medium':
      return Medium
    case 'Discord':
      return Discord
    case 'Telegram':
      return Telegram
    case 'Github':
      return Github
    case 'DefiPulse':
      return DefiPulse
    default:
      return PeckShield
  }
}

export const getSidebarLinkIcon = (type: string) => {
  switch (type) {
    case 'Certik':
      return Certik
    case 'PeckShield':
      return SBPeckShield
    case 'Twitter':
      return SBTwitter
    case 'Medium':
      return SBMedium
    case 'Discord':
      return SBDiscord
    case 'Telegram':
      return SBTelegram
    case 'Github':
      return SBGithub
    case 'DefiPulse':
      return SBDefiPulse
    default:
      return SBPeckShield
  }
}
