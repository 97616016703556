import React from 'react'
import {Box, Dialog, Grid, makeStyles} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import {getTokenIcon} from '../../../constants/icon'
import {
  TOKEN_BOR,
  TOKEN_BSC,
  TOKEN_ETH,
  TOKEN_OBTC,
  TOKEN_ODOGE,
  TOKEN_OLTC,
  TOKEN_POLYGON
} from '../../../constants/token'
import Barter from '../../../assets/images/barter.png'
import Swap from '../../../assets/images/swap.png'

const useStyles = makeStyles({
  type: {
    cursor: 'pointer',
    color: '#363636',
    '&.active, &:hover': {
      color: '#1164fb',
      '& $logo': {
        border: '1px solid #1164FB'
      }
    }
  },
  logo: {
    width: '80px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e1e3e5',
    borderRadius: '20px'
  }
})

const ExchangeSelectModal = ({open, onClose}: {open: boolean; onClose: any}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} keepMounted maxWidth="sm" fullWidth={true} transitionDuration={300}>
      <Box p={{xs: 6, md: 8}}>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <a href={'https://bsc.boringdao.com'} onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_BSC)} alt="bsc" />
                </Box>
                BSC
              </Box>
            </a>
          </Grid>
          <Grid item xs={4}>
            <NavLink to={'/transfer'} onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_BOR)} alt="bor" />
                </Box>
                BORING
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={4}>
            <NavLink to="/barter" onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={Barter} alt="barter" />
                </Box>
                Barter
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={4}>
            <NavLink to="/pledge/bor/BTC" onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_OBTC)} alt="barter" />
                </Box>
                Tunnel(BOR-BTC)
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={4}>
            <NavLink to="/pledge/bor/LTC" onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_OLTC)} alt="barter" />
                </Box>
                Tunnel(BOR-LTC)
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={4}>
            <NavLink to="/pledge/bor/DOGE" onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_ODOGE)} alt="barter" />
                </Box>
                Tunnel(BOR-DOGE)
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={4}>
            <NavLink to="/farm" onClick={onClose}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_ETH)} alt="barter" />
                </Box>
                Farm(BOR)
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={4}>
            <a href={'https://polygon.boringdao.com'} onClick={onClose} target="_blank">
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.type}>
                <Box className={classes.logo} mb={2}>
                  <img width={56} src={getTokenIcon(TOKEN_POLYGON)} alt="bsc" />
                </Box>
                Farm(Polygon)
              </Box>
            </a>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default ExchangeSelectModal
