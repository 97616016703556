import React, {useContext, useEffect, useState} from 'react'
import {displayBalance} from '../../../helpers/display'
import {BigNumber} from 'bignumber.js'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {BoringTooltip} from '../../BoringTooltip'
import {TOKEN_BTC, TOKEN_LTC} from '../../../constants/token'
import {Context} from '../../../contexts/BoringProvider'
import {getMapTokenShowName} from '../../../constants/icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      fontSize: '12px',
      marginLeft: '10px'
    }
  })
)

const MintRemaining = ({type}: {type: string}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const ctx = useContext(Context)
  const name = getMapTokenShowName(type)

  const [remain, setRemain] = useState('')
  const [remainRate, setRemainRate] = useState('')
  const [used, setUsed] = useState('')
  const [usedRate, setUsedRate] = useState('')
  const [canIssue, setCanIssue] = useState('')
  const [ratio, setRatio] = useState('')

  useEffect(() => {
    const canIssue = type === TOKEN_BTC ? ctx.canIssue.btc : type === TOKEN_LTC ? ctx.canIssue.ltc : ctx.canIssue.doge
    const used = type === TOKEN_BTC ? ctx.total.oBTC : type === TOKEN_LTC ? ctx.total.oLTC : ctx.total.oDOGE
    const remain = new BigNumber(canIssue).minus(used)
    if (remain.lt(0)) {
      setRemain("0")
      setRemainRate("0")
    } else {
      setRemain(remain.toString())
      setRemainRate(
        remain.dividedBy(canIssue)
          .multipliedBy(100)
          .toFixed(0)
      )
    }
    setUsed(used)
    setCanIssue(canIssue)
    setUsedRate(
      new BigNumber(used)
        .dividedBy(canIssue)
        .multipliedBy(100)
        .toFixed(0)
    )
    const r = type === TOKEN_BTC ? ctx.pledgeRatio.btc : type === TOKEN_LTC ? ctx.pledgeRatio.ltc : ctx.pledgeRatio.doge
    setRatio(new BigNumber(r).plus(Math.pow(10, 18)).toString())
  }, [type, ctx])

  const tooltip = () => {
    return (
      <>
        <Typography variant={'body2'}>
          {t('assets_ratio')}: {displayBalance(ratio, 16, 2)}%
        </Typography>
        <Typography variant={'body2'}>
          {t('total_capacity')}: {displayBalance(canIssue)} {name}
        </Typography>
        <Typography variant={'body2'}>
          {t('used_capacity')}: {displayBalance(used)} {name}({usedRate}
          %)
        </Typography>
        <Typography variant={'body2'}>
          {t('remaining_capacity')}: {displayBalance(remain)} {name}({remainRate}
          %)
        </Typography>
      </>
    )
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
      mb={{
        xs: 4,
        md: 6
      }}
    >
      <BoringTooltip enterTouchDelay={20} arrow title={<Typography variant={'body2'}>{tooltip()}</Typography>}>
        <Box display={'flex'} alignItems={'center'}>
          {t('remaining_capacity')} {displayBalance(remain)} {getMapTokenShowName(type)}
          {new BigNumber(remain).gt(0) ? (
            <FiberManualRecordIcon className={classes.status} htmlColor={'#51DB8B'} />
          ) : (
            <FiberManualRecordIcon className={classes.status} htmlColor={'#ED3939'} />
          )}
        </Box>
      </BoringTooltip>
    </Box>
  )
}

export default MintRemaining
