import {useCallback, useEffect, useState} from 'react'
import {getPrice} from '../contracts/oracle'
import {periodFinish, rewardRate, stakingEarned} from '../contracts/farm'
import {useWeb3React} from '@web3-react/core'
import {BigNumber} from 'bignumber.js'
import web3 from 'web3'
import {TOKEN_BOR, TokenModal} from '../constants/token'
import {allowance, balanceOf, totalSupply} from '../helpers/erc20'
import {getContractAddress} from '../helpers/web3'
import {getPoolName} from '../constants/contract'
import {handleError} from '../helpers'

export type Datum = {
  tokenName: string
  apy: string
  userTokenBalance: string
  userStakedAmount: string
  totalStakedAmount: string
  unlockEarned: string
  lockEarned: string
  userAllowance: string
  expired?: boolean
}

export const initDatum = (tokenName: string): Datum => {
  return {
    tokenName: tokenName,
    apy: '0',
    userTokenBalance: '0',
    userStakedAmount: '0',
    totalStakedAmount: '0',
    unlockEarned: '0',
    lockEarned: '0',
    userAllowance: '0'
  }
}

export const usePool = (tokenName: string) => {
  const [data, setData] = useState<Datum>(initDatum(tokenName))
  const {account, library, chainId} = useWeb3React()

  const calcAPY = useCallback(
    (borPrice: string, borRate: string, lockedAmount: string, lockedPrice: string) => {
      borRate = borRate ? borRate : '0'
      const decimals = TokenModal[tokenName].decimals ? TokenModal[tokenName].decimals : 18
      const bp = new BigNumber(borPrice)
      const br = new BigNumber(web3.utils.fromWei(borRate))
      const a = bp.multipliedBy(br).multipliedBy(365 * 3600 * 24)
      const la = new BigNumber(lockedAmount).dividedBy(Math.pow(10, decimals))
      const lp = new BigNumber(lockedPrice)
      const b = la.multipliedBy(lp)
      if (b.eq(new BigNumber('0'))) {
        return '0.00'
      }
      const result = a.div(b).multipliedBy(100)
      return result.toFixed(2)
    },
    [tokenName]
  )

  const get = useCallback(async () => {
    try {
      const priceName = TokenModal[tokenName].priceName
      const poolName = getPoolName(tokenName)

      const tokenAddress = getContractAddress(chainId, tokenName)
      const poolAddress = getContractAddress(chainId, poolName)

      const borPriceQ = getPrice(library, chainId, TOKEN_BOR).catch(handleError)
      const priceQ = getPrice(library, chainId, priceName).catch(handleError)
      const rateQ = rewardRate(library, chainId, poolName).catch(handleError)
      const userTokenBalanceQ = balanceOf(library, tokenAddress, account).catch(handleError)
      const userStakedAmountQ = balanceOf(library, poolAddress, account).catch(handleError)
      const totalStakedAmountQ = totalSupply(library, poolAddress).catch(handleError)
      const stakingEarnedQ = stakingEarned(library, chainId, tokenName, account).catch(e => {
        console.error(e)
        return ['0', '0']
      })
      const allowanceQ = allowance(library, tokenAddress, account, poolAddress).catch(handleError)

      Promise.all([
        borPriceQ,
        priceQ,
        rateQ,
        userTokenBalanceQ,
        userStakedAmountQ,
        totalStakedAmountQ,
        stakingEarnedQ,
        allowanceQ
      ]).then(values => {
        setData({
          tokenName: tokenName,
          apy: calcAPY(values[0], values[2], values[5], values[1]),
          userTokenBalance: values[3],
          userStakedAmount: values[4],
          totalStakedAmount: values[5],
          unlockEarned: values[6][0],
          lockEarned: values[6][1],
          userAllowance: values[7]
        })
      })
    } catch (e) {
      console.error(e)
    }
  }, [library, account, chainId, tokenName, calcAPY])

  useEffect(() => {
    if (account && library) {
      get()
      let timer = setInterval(get, 5000)
      return () => clearInterval(timer)
    }
  }, [account, library, setData, get])

  return data
}
