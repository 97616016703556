import React from 'react'
import {Box, Card, Divider, Typography} from '@material-ui/core'
import MobilePoolRow from '../../MobilePoolRow'
import {usePools} from '../../../hooks/usePools'
import {useTranslation} from 'react-i18next'

const FarmMobile = () => {
  const pools = usePools()
  const {t} = useTranslation()

  return (
    <>
      <Box mt={3}>
        <Card>
          <Box p={4}>
            <Typography variant={'subtitle1'}>{t('manifest_destiny')}</Typography>
            <Typography variant={'body2'}>{t('manifest_destiny_desc')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobilePoolRow data={pools.bpp} />
            <MobilePoolRow data={pools.lpp} />
            <MobilePoolRow data={pools.dpp} />
          </Box>
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <Box p={4}>
            <Typography variant={'subtitle1'}>{t('wild_wild_west')}</Typography>
            <Typography variant={'body2'}>{t('wild_wild_west_desc')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobilePoolRow data={pools.obtc} />
            <MobilePoolRow data={pools.oltc} />
            <MobilePoolRow data={pools.odoge} />
          </Box>
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <Box p={4}>
            <Typography variant={'subtitle1'}>{t('satellite_city')}</Typography>
            <Typography variant={'body2'}>{t('satellite_city_desc')}</Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <MobilePoolRow data={pools.dai} />
            <MobilePoolRow data={pools.usdc} />
            <MobilePoolRow data={pools.usdt} />
            <MobilePoolRow data={pools.weth} />
            <MobilePoolRow data={pools.yfi} />
            <MobilePoolRow data={pools.yfii} />
            <MobilePoolRow data={pools.link} />
            <MobilePoolRow data={pools.nest} />
            <MobilePoolRow data={pools.band} />
            <MobilePoolRow data={pools.hbtc} />
          </Box>
        </Card>
      </Box>
      {/*<Box mt={3}>*/}
      {/*  <Card style={{marginTop: '20px'}}>*/}
      {/*    <Box p={4}>*/}
      {/*      <Typography variant={'subtitle1'}>SushiSwap</Typography>*/}
      {/*      <Box my={3}>*/}
      {/*        <Divider/>*/}
      {/*      </Box>*/}
      {/*      <SushiMobilePoolRow tokenName={TOKEN_ETH_BOR_ETH} />*/}
      {/*      <SushiMobilePoolRow tokenName={TOKEN_ETH_OBTC_WETH} />*/}
      {/*    </Box>*/}
      {/*  </Card>*/}
      {/*</Box>*/}
    </>
  )
}

export default FarmMobile
