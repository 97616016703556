import {TOKEN_BOR, TOKEN_BORING, TokenModal} from '../constants/token'
import {BigNumber} from 'bignumber.js'
import web3 from 'web3'
import {chainInfo} from '../constants/chainInfo'

export const handleError = (e: any) => {
  console.error(e)
}

export const handleErrorAndZero = (e: any) => {
  // console.error(e)
  return '0'
}

export const isValidNumberInput = (s: string) => {
  return !(s === '')
}

export const calcAPY = (
  tokenName: string,
  borPrice: string,
  borRate: string,
  lockedAmount: string,
  lockedPrice: string
) => {
  if (tokenName === TOKEN_BORING) {
    // console.log(borRate)
    // console.log("bor price: ", borPrice)
    // console.log(lockedAmount)
    // console.log(lockedPrice)
  }
  borRate = borRate ? borRate : '0'
  borPrice = borPrice ? borPrice : '0'
  lockedAmount = lockedAmount ? lockedAmount : '0'
  lockedPrice = lockedPrice ? lockedPrice : '0'
  const decimals = TokenModal[tokenName].decimals ? TokenModal[tokenName].decimals : 18
  let bp
  if (tokenName === TOKEN_BORING) {
    bp = new BigNumber(borPrice).div(1)
  } else {
    bp = new BigNumber(borPrice)
  }

  const br = new BigNumber(web3.utils.fromWei(borRate))
  const a = bp.multipliedBy(br).multipliedBy(365 * 3600 * 24)
  const la = new BigNumber(lockedAmount).dividedBy(Math.pow(10, decimals))
  const lp = new BigNumber(lockedPrice)
  const b = la.multipliedBy(lp)
  if (b.eq(new BigNumber('0'))) {
    return '0.00'
  }
  const result = a.div(b).multipliedBy(100)
  return result.toFixed(2)
}

export const getEtherScanUrl = (chainID: number | undefined, address: string) => {
  switch (chainID) {
    case 1:
      return `https://etherscan.io/token/${address}`
    case 3:
      return `https://ropsten.etherscan.io/address/${address}`
    case 56:
      return `https://bscscan.com/address/${address}`
    case 97:
      return `https://testnet.bscscan.com/address/${address}`
    case 137:
      return `https://explorer-mainnet.maticvigil.com/address/${address}`
    case 80001:
      return `https://explorer-mumbai.maticvigil.com/address/${address}`
    default:
      return `https://etherscan.io/token/${address}`
  }
}

export const isBSC = () => {
  return process.env.REACT_APP_TARGET_CHAIN === 'BSC'
}

export const isHeco = () => {
  return process.env.REACT_APP_TARGET_CHAIN === 'HECO'
}

export const isEthereum = () => {
  return process.env.REACT_APP_TARGET_CHAIN === 'ETHEREUM'
}

export const isOKExChain = () => {
  return process.env.REACT_APP_TARGET_CHAIN === 'OKExChain'
}

export const isMatic = () => {
  return process.env.REACT_APP_TARGET_CHAIN === 'MATIC'
}

export const getTargetChainName = () => {
  if (isEthereum()) {
    return 'ETH'
  }

  if (isBSC()) {
    return 'BSC'
  }

  if (isOKExChain()) {
    return 'OKExChain'
  }

  if (isMatic()) {
    return 'Matic'
  }

  return 'ETH'
}
/**
 * Prompt the user to add a network to the Metamask, and if the wallet is on a different network, switch to the corresponding network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainID: any) => {
  const provider = (window as Window).ethereum
  if (provider) {
    const chainId = parseInt(chainID, 10)
    try {
      // @ts-ignore
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: chainInfo[chainID].name, // 'Binance Smart Chain',
            nativeCurrency: {
              name: chainInfo[chainID].symbol,
              symbol: chainInfo[chainID].symbol,
              decimals: chainInfo[chainID].decimals
            },
            rpcUrls: [chainInfo[chainID].rpcUrls],
            blockExplorerUrls: [chainInfo[chainID].blockExplorerUrls]
          }
        ]
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string
) => {
  const provider = (window as Window).ethereum
  if (provider) {
    try {
      // @ts-ignore
      const tokenAdded = await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage
          }
        }
      })
      return tokenAdded
    } catch (e) {
      console.error(e)
    }
  }
}
