import React from 'react'
import {Box, Button, Container, createStyles, Link, makeStyles, Theme, useMediaQuery, useTheme} from '@material-ui/core'
// import { useObtcFarms } from '../../hooks/useObtcFarms'
import {getTokenIcon} from '../../constants/icon'
import Curve from '../../assets/images/curve.png'
import {TOKEN_BOR, TOKEN_ETH, TOKEN_OBTC, TOKEN_YFI} from '../../constants/token'
import Sushi from '../../assets/images/food.png'
import Harvest from '../../assets/images/harvest.png'
import Alpha from '../../assets/images/alpha.png'
import AC from '../../assets/images/ac.jpg'
import MPH from '../../assets/images/88mph.png'
import For from '../../assets/images/for.png'
import OBTCFarmMobile from '../../components/Farm/OBTCMobile'
import {useTitle} from 'react-use'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      paddingBottom: '15px',
      marginBottom: '15px',
      borderBottom: '1px solid #f0f0f0'
    },
    double: {
      '& img:not(:first-child)': {
        marginLeft: '-5px'
      }
    },
    type: {
      padding: '6px',
      border: '2px solid #E4E9F0',
      borderRadius: '50%'
    },
    grow: {
      flexGrow: 1
    },
    pools: {
      borderRadius: '12px',
      boxShadow: '0px 5px 21px 0px rgba(228, 232, 245, 0.92)',
      background: '#fff'
    },
    unlock: {
      color: '#1164FB'
    }
  })
)

const OBTCFarm = () => {
  useTitle('BoringDAO App | Farm(OBTC)')
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container maxWidth={'lg'}>
      {isMobile ? (
        <OBTCFarmMobile />
      ) : (
        <>
          <Box mt={10}>
            <Box display="flex">
              <Box width={200}>&nbsp;</Box>
              <Box width={380}>Asset</Box>
              <Box>Earned</Box>
              <Box width={120}>&nbsp;</Box>
            </Box>
          </Box>
          <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box textAlign="center" style={{width: '170px'}}>
              Liquidity-Farming
            </Box>
            <Box flexGrow={1}>
              <Box display="flex" alignItems="center" className={classes.item}>
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} width={42} src={Curve} alt="token" />
                    <Box ml={-1}>
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={8}>CRV: oBTC</Box>
                </Box>
                <Box flexGrow={1}>
                  <img width={24} src={Curve} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link
                      href="https://curve.fi/obtc/deposit"
                      rel={'noopener nofollow'}
                      target={'_blank'}
                      color={'inherit'}
                    >
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" className={classes.item}>
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} width={42} src={Sushi} alt="token" />
                    <Box className={classes.double} ml={-1}>
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                      <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={5}>oBTC/ETH</Box>
                </Box>
                <Box flexGrow={1}>
                  <img width={24} src={Sushi} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link
                      href="https://app.sushi.com/pair/0x53e9fb796b2feb4b3184afdf601c2a2797548d88"
                      rel={'noopener nofollow'}
                      target={'_blank'}
                      color={'inherit'}
                    >
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} width={42} style={{padding: '0'}} src={Alpha} alt="token" />
                    <Box className={classes.double} ml={-1}>
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                      <img width={20} src={getTokenIcon(TOKEN_ETH)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={5}>oBTC/ETH</Box>
                </Box>
                <Box flexGrow={1} className={classes.double}>
                  <img src={getTokenIcon(TOKEN_OBTC)} width={24} alt="token" />
                  <img src={getTokenIcon(TOKEN_ETH)} width={24} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link
                      href="https://homora.alphafinance.io/"
                      rel={'noopener nofollow'}
                      target={'_blank'}
                      color={'inherit'}
                    >
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box textAlign="center" style={{width: '170px'}}>
              Vault
            </Box>
            <Box flexGrow={1}>
              <Box display="flex" alignItems="center" className={classes.item}>
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img
                      className={classes.type}
                      width={42}
                      style={{padding: '0'}}
                      src={getTokenIcon(TOKEN_YFI)}
                      alt="token"
                    />
                    <Box ml={-1} className={classes.double}>
                      <img width={20} src={Curve} alt="obtc" />
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={8}>CRV: oBTC</Box>
                </Box>
                <Box flexGrow={1} className={classes.double}>
                  <img src={Curve} width={24} alt="token" />
                  <img src={getTokenIcon(TOKEN_OBTC)} width={24} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link
                      href="https://yearn.finance/vaults/0x7F83935EcFe4729c4Ea592Ab2bC1A32588409797"
                      rel={'noopener nofollow'}
                      target={'_blank'}
                      color={'inherit'}
                    >
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.item}>
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} width={42} src={Harvest} alt="token" />
                    <Box ml={-1} className={classes.double}>
                      <img width={20} src={Curve} alt="obtc" />
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={8}>CRV: oBTC</Box>
                </Box>
                <Box flexGrow={1} className={classes.double}>
                  <img src={Curve} width={24} alt="token" />
                  <img src={getTokenIcon(TOKEN_BOR)} width={24} alt="token" />
                  <img src={Harvest} width={24} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link href="https://harvest.finance/" rel={'noopener nofollow'} target={'_blank'} color={'inherit'}>
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" className={classes.item}>
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} style={{padding: '0'}} width={42} src={MPH} alt="token" />
                    <Box ml={-1} className={classes.double}>
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                      <img width={20} src={Curve} alt="obtc" />
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={5}>oBTC＆CRV：oBTC</Box>
                </Box>
                <Box flexGrow={1}>
                  <img width={24} src={MPH} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link href="https://88mph.app/" rel={'noopener nofollow'} target={'_blank'} color={'inherit'}>
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} width={42} src={AC} alt="token" />
                    <Box ml={-1} className={classes.double}>
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                      <img width={20} src={Curve} alt="obtc" />
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={5}>oBTC＆CRV：oBTC</Box>
                </Box>
                <Box flexGrow={1} className={classes.double}>
                  <img src={AC} width={24} alt="token" />
                  <img src={getTokenIcon(TOKEN_OBTC)} width={24} alt="token" />
                  <img width={24} src={Curve} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link
                      href="https://app.acbtc.fi/savings"
                      rel={'noopener nofollow'}
                      target={'_blank'}
                      color={'inherit'}
                    >
                      {' '}
                      Enter{' '}
                    </Link>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={4} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box textAlign="center" style={{width: '170px'}}>
              Saving & Lending
            </Box>
            <Box flexGrow={1}>
              <Box display="flex" alignItems="center">
                <Box width={380} display="flex" alignItems="center">
                  <Box display="flex" alignItems="flex-end">
                    <img className={classes.type} style={{padding: '0'}} width={42} src={For} alt="token" />
                    <Box ml={-1}>
                      <img width={20} src={getTokenIcon(TOKEN_OBTC)} alt="obtc" />
                    </Box>
                  </Box>
                  <Box ml={5}>oBTC</Box>
                </Box>
                <Box flexGrow={1}>
                  <img width={24} src={For} alt="token" />
                </Box>
                <Box>
                  <Button variant="contained" size="small" color="primary" disableElevation={true}>
                    <Link
                      href="https://for.tube/bank/deposit?item=%7B%22balance%22%3A%221.058400000000000000%22,%22balance_in_cheque%22%3A%221.058400000000000000%22,%22balance_in_dollar%22%3A%2250406.404887440000000000%22,%22cheque_token_address%22%3A%220x10e438782d0eaccecbae5fceeab5401a6816297e%22,%22cheque_token_value%22%3A%221.000000000000000000%22,%22debt%22%3A%220.000000000000000000%22,%22debt_in_dollar%22%3A%220.000000000000000000%22,%22deposit_interest_rate%22%3A%220.000000000000000000%22,%22global_token_reserved%22%3A%221.058400000000000000%22,%22loan_interest_rate%22%3A%220.019999999999728000%22,%22token_address%22%3A%220x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68%22,%22token_ccr%22%3A%220.750000000000000000%22,%22token_numeric_scale%22%3A18,%22token_price%22%3A%2247625.0991%22,%22token_symbol%22%3A%22oBTC%22,%22address%22%3A%220x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68%22,%22borrow_weight%22%3A%22-1%22,%22decimals%22%3A18,%22deposite_weight%22%3A%220%22,%22estimated_ar%22%3A%220.1095%22,%22symbol%22%3A%22oBTC%22,%22useRate%22%3A%220%22,%22totalIncome%22%3A%220.1095%22,%22status%22%3Atrue,%22num%22%3A%220.1095%22%7D"
                      rel={'noopener nofollow'}
                      target={'_blank'}
                      color={'inherit'}
                    >
                      Enter
                    </Link>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Container>
  )
}

export default OBTCFarm
