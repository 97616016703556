import React, {useContext, useState} from 'react'
import {Box, Button, Checkbox, createStyles, FormControlLabel, makeStyles, Theme, Typography} from '@material-ui/core'
import QRCode from 'qrcode.react'
import {ellipseAddress} from '../../../../helpers/display'
import {AssetName} from '../../../../contracts/address'
import CopyToClipboard from 'react-copy-to-clipboard'
import Copy from '../../../../assets/images/copy.png'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {Context} from '../../../../contexts/BoringProvider'
import {TOKEN_BTC, TOKEN_DOGE, TOKEN_LTC} from '../../../../constants/token'
import ToastContent from '../../../ToastContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copy: {
      cursor: 'pointer',
      marginLeft: '10px'
    }
  })
)

const MintStepFour = ({type, onPrev, onNext}: {type: AssetName; onPrev: any; onNext: any}) => {
  const classes = useStyles()
  const ctx = useContext(Context)
  const {t} = useTranslation()
  const [first, setFirst] = useState(false)

  const multiAddress =
    type === TOKEN_BTC ? ctx.multiAddress.btc : type === TOKEN_LTC ? ctx.multiAddress.ltc : ctx.multiAddress.doge

  return (
    <Box textAlign={'center'}>
      <QRCode value={multiAddress} size={150} />
      <Box my={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {ellipseAddress(multiAddress, -1)}
        <CopyToClipboard
          text={multiAddress}
          onCopy={() => {
            toast.info(<ToastContent status={true} content={`Address copied`} />, {
              autoClose: 2000
            })
          }}
        >
          <img className={classes.copy} width={12} height={12} src={Copy} alt="copy" />
        </CopyToClipboard>
      </Box>
      <Box mb={2} textAlign={{xs: 'left', md: 'center'}}>
        <FormControlLabel
          control={<Checkbox color={'primary'} onChange={(e: any) => setFirst(e.target.checked)} />}
          label={<Typography variant={'subtitle2'}>{t('mint_step_4_checkbox')}</Typography>}
        />
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4}>
        <Button
          onClick={onPrev}
          size={'large'}
          style={{marginRight: '10px'}}
          variant={'contained'}
          color={'default'}
          disableElevation
        >
          {t('prev')}
        </Button>
        <Button
          onClick={onNext}
          disabled={!first}
          size={'large'}
          style={{marginLeft: '10px'}}
          variant={'contained'}
          color={'primary'}
          disableElevation
        >
          {t('next')}
        </Button>
      </Box>
    </Box>
  )
}

export default MintStepFour
