import React from 'react'
import {Box, Container, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme} from '@material-ui/core'
import PoolRow from '../../components/PoolRow'
import {useTranslation} from 'react-i18next'
import {useBSCPools} from '../../hooks/useBSCPools'
import BSCFarmMeta from '../../components/Farm/BSCMeta'
import BSCFarmMobile from '../../components/Farm/BSCMobile'
import PoolItem from '../../components/PoolItem'
import {TOKEN_BOR_BNB} from '../../constants/token'
import {useMaticPools} from '../../hooks/useMaticPools'
import MaticFarmMeta from '../../components/Farm/MaticMeta'
import MaticFarmMobile from '../../components/Farm/MaticMobile'
import {useTitle} from 'react-use'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    pools: {
      borderRadius: '12px',
      boxShadow: '0px 5px 21px 0px rgba(228, 232, 245, 0.92)',
      background: '#fff'
    },
    unlock: {
      color: '#1164FB'
    },
    main: {
      minHeight: 'calc(100vh - 200px)'
    }
  })
)

const MaticFarm = () => {
  useTitle("BoringDAO App | Farm(Matic)")
  const classes = useStyles()
  const theme = useTheme()
  const {t} = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const pools = useMaticPools()

  return (
    <Container maxWidth={'lg'} className={classes.main}>
      {isMobile ? (
        <MaticFarmMobile />
      ) : (
        <>
          <MaticFarmMeta />
          <Box mt={10}>
            <Box display="flex">
              <Box width={170}>&nbsp;</Box>
              <Box width={175}>POOL</Box>
              <Box width={120}>APR</Box>
              <Box width={170}>{t('total_staked')}</Box>
              <Box width={165}>{t('balance')}</Box>
              <Box width={175}>{t('your_staked')}</Box>
              <Box width={100}>{t('rewards')}</Box>
            </Box>
          </Box>
          <Box mt={6} p={5} display="flex" alignItems="center" className={classes.pools}>
            <Box style={{width: '140px'}}>{t('wild_wild_west')}</Box>
            <Box flexGrow={1}>
              <PoolRow data={pools.obtc} />
            </Box>
          </Box>
          <Box mt={6} p={5} display="flex" alignItems="center" justifyContent={'center'} className={classes.pools}>
            <Typography variant={'body1'}>More pools are coming soon</Typography>
          </Box>
        </>
      )}
    </Container>
  )
}

export default MaticFarm
