import React from 'react'
import {Box, Container, createStyles, Divider, Link, makeStyles, Theme} from '@material-ui/core'
import {getLinkIcon, links} from '../../constants/link'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      '& a': {
        color: '#A1A8C7'
      },
      '& hr': {
        margin: '0 10px',
        width: '2px',
        height: '14px'
      }
    },
    href: {},
    logos: {
      '& a': {
        display: 'block',
        width: '30px',
        height: '30px',
        '&:not(:last-child)': {
          margin: '0 15px 0 0'
        }
      },
      '& img': {
        width: '30px',
        height: '30px'
      }
    }
  })
)

const Footer = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Container maxWidth={'lg'}>
      <Box className={classes.footer} py={6} display={'flex'} alignItems={'center'} mb={{xs: 12, sm: 0}}>
        <Box display={'flex'} alignItems={'center'} className={classes.href}>
          <Link href={'https://boringdao.com'}>{t('website')}</Link>
          <Divider orientation={'vertical'} />
          <Link href={'https://forum.boringdao.com/'}>{t('governance')}</Link>
        </Box>
        <Box className={classes.logos} ml={'auto'} display={{xs: 'none', sm: 'flex'}} alignItems={'center'}>
          {links.map((value, index) => {
            return (
              <Link key={value.name} href={value.href} rel={'noopener noreferrer'} target={'_blank'}>
                <img src={getLinkIcon(value.name)} alt="link" />
              </Link>
            )
          })}
        </Box>
      </Box>
    </Container>
  )
}

export default Footer
